import { SHIFTS_PER_HOUR, SHIFT_STEP, TOTAL_DAY_SHIFT } from '@components/rota-table';
import { Box, Stack, Typography } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import WeeklySchedule from './calendar-schedule/WeeklySchedule';
import WorkCalendar from './WorkCalendar';

export default function CalendarAndScheduleTab() {
    return (
        <Box>
            <WorkCalendar />
            <WeeklySchedule />

            {/* <FullDayShift /> */}
        </Box>
    );
}

const FullDayShift = () => {
    const dayShifts = [
        { start: 0, end: 12, status: 'lieu' },
        { start: 7, end: 17, status: 'assigned' },
        // { start: 13, end: 17, status: 'assigned' },
    ];

    // const shiftWidth = size.width / TOTAL_DAY_SHIFT;
    // console.log(`shiftWidth`, shiftWidth);

    return (
        <Stack direction={'row'} sx={{ position: 'relative' }}>
            {[...new Array(TOTAL_DAY_SHIFT)].map((_, index) => {
                const isOverlapped = dayShifts.some(
                    (shift) => index >= shift.start * SHIFTS_PER_HOUR && index < shift.end * SHIFTS_PER_HOUR
                );

                const isOverlappedBorder = dayShifts.some(
                    (shift) => index > shift.start * SHIFTS_PER_HOUR && index < shift.end * SHIFTS_PER_HOUR
                );

                const lieuShifts = dayShifts.filter((shift) => shift.status === 'lieu');
                const isLieu = lieuShifts.some(
                    (shift) => index >= shift.start * SHIFTS_PER_HOUR && index < shift.end * SHIFTS_PER_HOUR
                );

                return (
                    <Stack
                        key={index}
                        direction="row"
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            flex: 1,
                            flexBasis: `calc(100% / ${TOTAL_DAY_SHIFT})`,
                            backgroundColor: isLieu ? 'red' : '#eee',
                            height: '40px',
                            borderLeft:
                                index % (60 / SHIFT_STEP) === 0 && !!index && !isOverlappedBorder
                                    ? '1px solid white'
                                    : 'none',
                            color: 'transparent',
                            ':hover': {
                                backgroundColor: '#ddd',
                                color: 'black',
                            },
                            maxWidth: `calc(100% / ${TOTAL_DAY_SHIFT})`,
                            overflow: 'hidden',
                        }}
                    >
                        +
                    </Stack>
                );
            })}

            {dayShifts
                .filter((shift) => shift.status !== 'lieu')
                .map((shift, index) => {
                    const widthPercent = (((shift.end - shift.start) * SHIFTS_PER_HOUR) / TOTAL_DAY_SHIFT) * 100;
                    return (
                        <Stack
                            key={index}
                            direction="row"
                            alignItems={'center'}
                            sx={{
                                position: 'absolute',
                                flexBasis: `calc(${widthPercent}%)`,
                                left: `calc(${((shift.start * SHIFTS_PER_HOUR) / TOTAL_DAY_SHIFT) * 100}%)`,
                                backgroundColor: '#FFDC83',
                                // height: '100%',
                                top: 5,
                                bottom: 5,
                                maxWidth: `calc(${widthPercent}%)`,
                                // flexGrow: 0,
                            }}
                        >
                            <Typography noWrap>
                                Placing border inside of div and not on its edge - Stack Overflow
                            </Typography>
                        </Stack>
                    );
                })}
        </Stack>
    );
};
