import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import IcSearch from '@components/icon/IcSearch';
import StyledSelect from '@components/select-search/StyledSelect';
import { locationController, regionController, subRegionController, zoneController } from '@controllers';
import { ListProps } from '@Core';
import { defaultPaging } from '@helpers';
import { GetLocationCustomProps } from '@LocationOps/controller/ILocationController';
import { AvgPCNVisitColor, CountryRegion, CountrySubRegion, Location } from '@LocationOps/model';
import { CleaningServices } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption from '@pages/rota-coverage/components/SelectOption';
import { debounce, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BaseList from '../../components/BaseList';
import EmptyPage from '../../components/EmptyPage';
import LocationListItem, { ColorMapping } from './components/LocationListItem';
import usePopUp from '@hooks/usePopUp';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/StyledToast';

export type Filter = {
    region?: CountryRegion;
    subRegion?: CountrySubRegion;
    locationType?: any;
    clusterStatus?: any;
    locationStatus?: any;
    pcnType?: any;
    locationColor?: any;
} & Pick<ListProps<any>, 'page' | 'search'>;

const locationTypes = [
    { Name: 'Mobile', Value: 'mobile', Id: 1 },
    { Name: 'Static', Value: 'static', Id: 2 },
    { Name: 'Uncategorised', Value: null, Id: 3 },
];
const clusterStatuses = [
    { Name: 'Assigned', Value: 'assigned', Id: 1 },
    { Name: 'Not-assigned', Value: 'not-assigned', Id: 2 },
];

const locationStatuses = [
    {
        Name: 'Valid',
        Value: 'valid',
        Id: 1,
    },
    {
        Name: 'Invalid',
        Value: 'invalid',
        Id: 2,
    },
    {
        Name: 'Suspended',
        Value: 'suspended',
        Id: 3,
    },
    // {
    //     Name: 'All statuses',
    //     Value: undefined,
    //     Id: 3,
    // },
];

const pcnTypes = [
    {
        Name: 'Virtual',
        Value: 'Virtual',
        Id: 1,
    },
    {
        Name: 'Physical',
        Value: 'Physical',
        Id: 2,
    },
];

const locationColor = [
    {
        Name: 'Green',
        Value: AvgPCNVisitColor.Green,
        Id: 1,
    },
    {
        Name: 'Amber',
        Value: AvgPCNVisitColor.Amber,
        Id: 2,
    },
    {
        Name: 'Red',
        Value: AvgPCNVisitColor.Red,
        Id: 3,
    },
    {
        Name: 'No colour',
        Value: AvgPCNVisitColor.NoColour,
        Id: 4,
    },
];

export default function LocationBase() {
    const { t } = useTranslation();
    const breadcrumbs: IBreadCrumbs[] = [{ title: 'Admin' }, { title: 'Location' }];
    const popUpSuspendAllRedZones = usePopUp();

    const [searchParams, setSearchParams] = useSearchParams({});
    const search = searchParams.get('search');
    const regionId = searchParams.get('regionId');
    const subRegionId = searchParams.get('subRegionId');
    const locationTypeId = searchParams.get('locationType');
    const clusterStatusId = searchParams.get('clusterStatus');
    const locationStatusId = searchParams.get('locationStatus');
    const pcnTypeId = searchParams.get('pcnType');
    const locationColourId = searchParams.get('locationColour');
    const page = searchParams.get('page');

    const [state, setState] = useState({
        pagingLocation: defaultPaging<Location>(),
        pagingRegion: defaultPaging<CountryRegion>(),
        pagingSubRegion: defaultPaging<CountrySubRegion>(),
    });

    const [loading, setLoading] = useState(true);
    const [loadingSuspendAllRedZones, setLoadingSuspendAllRedZones] = useState(false);

    const [filter, setFilter] = useState<Filter>({
        // locationStatus: locationStatuses[0],
        search: {
            content: '',
            fields: ['Name'],
        },
    });

    const [subRegionSearch, setSubRegionSearch] = useState('');
    const [loadingSubRegion, setLoadingSubRegion] = useState(true);
    const filteredSubRegion = state.pagingSubRegion.rows.filter((subR) =>
        subR.Name.toLocaleLowerCase().includes((subRegionSearch || '').toLocaleLowerCase())
    );

    const locationType = locationTypes.find((l) => l.Id === Number(locationTypeId));

    const clusterStatus = clusterStatuses.find((c) => c.Id === Number(clusterStatusId));

    const locationStatus = locationStatuses.find((l) => l.Id === Number(locationStatusId));

    const pcnType = pcnTypes.find((p) => p.Id === Number(pcnTypeId));
    const locationColorType = locationColor.find((p) => p.Id === Number(locationColourId));

    const onGetMoreRegions = async () => {
        await regionController
            .list({
                page: state.pagingRegion.page + 1,
                pageSize: 10,
                sorts: ['Name'],
            })
            .then((res) => {
                const _res = { ...res, rows: [...state.pagingRegion.rows, ...res.rows] };
                setState((prev) => ({ ...prev, pagingRegion: _res }));
            });
    };

    const onGetMoreSubRegions = async () => {
        getSubRegion({
            listProps: {
                page: state.pagingSubRegion.page + 1,
            },
            options: {
                fetchMore: true,
            },
        });
    };

    const getSubRegion = (props?: { listProps?: ListProps<CountrySubRegion>; options?: { fetchMore?: boolean } }) => {
        setLoadingSubRegion(true);
        subRegionController
            .list({
                page: 1,
                pageSize: 1000,
                filter: { CountryRegionId: filter.region?.Id },
                sorts: ['Name'],
                search: {
                    content: subRegionSearch,
                    fields: ['Name'],
                },
                ...props?.listProps,
            })
            .then((res) => {
                if (subRegionId) {
                    const _subRegion = res.rows.find((sr) => sr.Id === Number(subRegionId));
                    setFilter((prev) => ({ ...prev, subRegion: _subRegion }));
                }
                if (props?.options?.fetchMore) {
                    setState((prev) => ({
                        ...prev,
                        pagingSubRegion: { ...res, rows: [...state.pagingSubRegion.rows, ...res.rows] },
                    }));
                } else {
                    setState((prev) => ({ ...prev, pagingSubRegion: res }));
                }
            })
            .finally(() => {
                setLoadingSubRegion(false);
            });
    };

    const handleChangeSearchSubRegion = (text: string) => {
        setSubRegionSearch(text);
    };
    const getLocation = useCallback(
        (listProps: GetLocationCustomProps) => {
            setLoading(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const _filterProps: GetLocationCustomProps = {
                page: Number(page) || 1,
                search: { content: search || '', fields: ['Name'] },
                filter: {
                    CountryRegionId: parseInt(regionId ?? '') ?? undefined,
                    CountrySubRegionId: parseInt(subRegionId ?? '') ?? undefined,
                    LocationType: locationType?.Value as any,
                },
                pageSize: 10,
                sorts: ['Name'],
                clusterRelationStatus: clusterStatus?.Value as any,
                assignableStatus: locationStatus?.Value as any,
                PCNType: pcnType?.Value as any,
                Color: locationColorType?.Value as any,
                ...omit(listProps),
            };
            // if (_filterProps.filter) {
            //     Object.entries(_filterProps.filter).forEach(([key, value]) => {
            //         if (!value) delete _filterProps.filter?.[key as keyof Location];
            //     });
            // }
            if (!_filterProps.filter?.CountryRegionId) delete _filterProps.filter?.CountryRegionId;
            if (!_filterProps.filter?.CountrySubRegionId) delete _filterProps.filter?.CountrySubRegionId;
            locationController
                .list(_filterProps)
                .then((res) => {
                    setState((prev) => ({ ...prev, pagingLocation: res }));
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => setLoading(false));
        },
        [
            clusterStatus?.Value,
            locationType?.Value,
            locationStatus?.Value,
            pcnType?.Value,
            locationColorType?.Value,
            page,
            regionId,
            search,
            subRegionId,
        ]
    );
    function handleChangeFilter(params: any) {
        const _searchParams: any = {
            search,
            regionId,
            subRegionId,
            locationType: locationTypeId,
            clusterStatus: clusterStatusId,
            locationStatus: locationStatusId,
            pcnType: pcnTypeId,
            locationColour: locationColourId,
            page: 1,
            ...params,
        };

        Object.entries(_searchParams).forEach(([key, value]) => {
            const firstPage = key === 'page' && Number(value) === 1;
            if (!value || firstPage) delete _searchParams[key];
        });

        setSearchParams(new URLSearchParams(_searchParams));
    }

    const debSearchLocation = useCallback(
        debounce((nextValue) => {
            handleChangeFilter({ search: nextValue });

            return getLocation({
                search: {
                    content: nextValue,
                    fields: ['Name'],
                },
                page: 1,
            });
        }, 1000),
        [
            filter.region?.Id,
            filter.subRegion?.Id,
            filter.locationType?.Value,
            filter.clusterStatus?.Value,
            filter.locationStatus?.Value,
            filter.pcnType?.Value,
            filter.locationColor?.Value,
            filter.page,
            searchParams,
        ]
    );

    const handleChangeSearchLocation = (e: any) => {
        setLoading(true);
        setFilter((prev) => ({
            ...prev,
            search: {
                ...prev.search,
                content: e.target.value,
            },
        }));
        debSearchLocation(e.target.value);
    };

    useEffect(() => {
        const _filter: Filter = {
            locationType: locationType?.Value ? locationType : undefined,
            clusterStatus: clusterStatus || undefined,
            locationStatus: locationStatus,
            pcnType: pcnType,
            locationColor: locationColorType,
            search: {
                content: search || '',
                fields: ['Name'],
            },
            page: page ? Number(page) : undefined,
        };

        regionController.list({ pageSize: 1000, sorts: ['Name'] }).then((res) => {
            if (regionId) {
                const _region = res.rows.find((r) => r.Id === Number(regionId));
                _filter.region = _region;
            }
            setState((prev) => ({ ...prev, pagingRegion: res }));
        });

        console.log('_filter', filter, _filter);
        setFilter((prev) => ({ ...prev, ..._filter }));
    }, []);

    useEffect(() => {
        getSubRegion();
    }, [filter.region?.Id]);

    useEffect(() => {
        getLocation({});
    }, [
        filter.region?.Id,
        filter.subRegion?.Id,
        filter.locationType?.Value,
        filter.clusterStatus?.Value,
        filter.locationStatus?.Value,
        filter.pcnType?.Value,
        filter.locationColor?.Value,
        filter.page,
    ]);

    const onSuspendAllRedZones = async () => {
        setLoadingSuspendAllRedZones(true);
        await zoneController
            .suspendAllRedZones()
            .then((res) => {
                pushSuccess(res);
                getLocation({});
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setLoadingSuspendAllRedZones(false);
            });
    };

    return (
        <div style={{ position: 'relative', pointerEvents: loading ? 'none' : undefined }}>
            {loadingSuspendAllRedZones && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        zIndex: 2,
                    }}
                />
            )}

            <Box
                sx={{
                    filter: loadingSuspendAllRedZones ? 'blur(4px)' : undefined,
                    transition: 'all 0.3s',
                    height: loadingSuspendAllRedZones ? '100vh' : '100%',
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography fontSize={24}>Location</Typography>

                    <Button
                        variant="errorOutlined"
                        sx={{ width: 200 }}
                        onClick={() => {
                            popUpSuspendAllRedZones.setTrue();
                        }}
                    >
                        Suspend all red zones
                    </Button>
                </Stack>

                <BreadCrumbs breadcrumbs={breadcrumbs} />

                <Stack>
                    <Stack width="100%" direction="row" gap={2} mt={2}>
                        <FormControl
                            fullWidth
                            sx={{
                                maxWidth: {
                                    md: 300,
                                    lg: 350,
                                    xl: 450,
                                },
                                flex: 1,
                            }}
                            variant="outlined"
                            size="small"
                            onChange={handleChangeSearchLocation}
                        >
                            <InputLabel htmlFor="outlined-adornment" sx={{ color: '#DDDDDD' }}>
                                {t('locationPage.text.searchBy')}
                            </InputLabel>
                            <OutlinedInput
                                value={filter.search?.content}
                                id="outlined-adornment"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle search" edge="end">
                                            <IcSearch />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('locationPage.text.searchBy')}
                                sx={{ py: '1.5px' }}
                            />
                        </FormControl>

                        <StyledSelect
                            label="Region"
                            disabledSearch
                            data={state.pagingRegion.rows}
                            value={filter.region}
                            hasMore={Boolean(state.pagingRegion.page < state.pagingRegion.totalPages)}
                            next={onGetMoreRegions}
                            onChange={(region) => {
                                handleChangeFilter({ regionId: region?.Id, subRegionId: null });
                                setSubRegionSearch('');
                                setFilter((prev) => ({ ...prev, region, subRegion: undefined, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All regions'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All regions" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />

                        <StyledSelect
                            renderNoOption
                            loadingSearchOption={loadingSubRegion}
                            label="Sub-region"
                            data={filteredSubRegion}
                            value={filter.subRegion}
                            hasMore={Boolean(state.pagingSubRegion.page < state.pagingSubRegion.totalPages)}
                            next={onGetMoreSubRegions}
                            searchValue={subRegionSearch}
                            onChangeSearch={handleChangeSearchSubRegion}
                            onChange={(subRegion) => {
                                handleChangeFilter({ subRegionId: subRegion?.Id });
                                setSubRegionSearch('');
                                setFilter((prev) => ({ ...prev, subRegion, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All sub-regions'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All sub-regions" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                        <StyledSelect
                            label="Location colour"
                            disabledSearch
                            data={locationColor}
                            value={filter.locationColor}
                            hasMore={true}
                            next={() => {}}
                            onChange={(color) => {
                                handleChangeFilter({ locationColour: color?.Id });
                                setFilter((prev) => ({ ...prev, locationColor: color, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All types'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All colours" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                    </Stack>
                    <Stack width="100%" direction="row" gap={2} mt={2}>
                        <StyledSelect
                            label="Location type"
                            disabledSearch
                            data={locationTypes}
                            value={filter.locationType}
                            hasMore={true}
                            next={() => {}}
                            onChange={(locationType) => {
                                handleChangeFilter({ locationType: locationType?.Id });
                                setFilter((prev) => ({ ...prev, locationType, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All types'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All types" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                        <StyledSelect
                            label="Location status"
                            disabledSearch
                            data={locationStatuses}
                            value={filter.locationStatus}
                            hasMore={true}
                            next={() => {}}
                            onChange={(locationStatus) => {
                                handleChangeFilter({ locationStatus: locationStatus?.Id });
                                setFilter((prev) => ({ ...prev, locationStatus, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All statuses'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All statuses" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                        <StyledSelect
                            label="Cluster status"
                            disabledSearch
                            data={clusterStatuses}
                            value={filter.clusterStatus}
                            hasMore={true}
                            next={() => {}}
                            onChange={(clusterStatus) => {
                                handleChangeFilter({ clusterStatus: clusterStatus?.Id });
                                setFilter((prev) => ({ ...prev, clusterStatus, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All statuses'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All statuses" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                        <StyledSelect
                            label="PCN type"
                            disabledSearch
                            data={pcnTypes}
                            value={filter.pcnType}
                            hasMore={true}
                            next={() => {}}
                            onChange={(pcnType) => {
                                handleChangeFilter({ pcnType: pcnType?.Id });
                                setFilter((prev) => ({ ...prev, pcnType, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All types'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All types" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{ flex: 1 }}
                        />
                    </Stack>
                </Stack>

                <PopUpWarning
                    {...popUpSuspendAllRedZones}
                    title="Confirm"
                    message={'Are you sure you want to suspend all red zones?'}
                    onConfirm={() => {
                        popUpSuspendAllRedZones.onClose();
                        onSuspendAllRedZones();
                    }}
                />

                <Box mt={4}>
                    <BaseList
                        list={state.pagingLocation.rows}
                        renderItem={(location) => <LocationListItem location={location} />}
                        count={state.pagingLocation.totalPages}
                        page={filter.page}
                        loading={loading}
                        onChangePage={(value) => {
                            console.log(`value`, value);
                            handleChangeFilter({ page: value });
                            setFilter((prev) => ({ ...prev, page: value }));
                        }}
                        renderEmpty={() => <EmptyPage />}
                    />
                </Box>
            </Box>
        </div>
    );
}
