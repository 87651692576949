import { useMsal } from '@azure/msal-react';
import { featureFlags, FeatureName, Role } from '@config';
import { ReactNode, useState } from 'react';
type Props = {
    name: FeatureName;
    children?: ReactNode;
    childrenOff?: ReactNode;
};

const whiteList = [
    'quyet.nguyen@ukparkingcontrol.com',
    'hai.nguyen@ukparkingcontrol.com',
    'hung.nguyen@ukparkingcontrol.com',
    'tu.nguyen@ukparkingcontrol.com',
    'kim.vuong@ukparkingcontrol.com',
    'tuan.ho@ukparkingcontrol.com',
];

export const useFeatureFlag = () => {
    const { accounts } = useMsal();
    const userRole: Role = 'admin';

    const getActive = (featureName: FeatureName) => {
        const feature = featureFlags[featureName];

        if (!userRole) return false;
        if (!feature.active) return false;
        if (userRole !== 'admin' && !feature.roles?.includes(userRole)) return false;
        if (feature.roles?.includes('white-list') && !whiteList.includes(accounts[0]?.username)) return false;

        return true;
    };

    return {
        getActive,
    };
};

export default function Flag(props: Props) {
    const { getActive } = useFeatureFlag();

    if (!getActive(props.name)) return <></>;

    return <>{props.children}</>;
}
