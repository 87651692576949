import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcDashboard(props: SvgIconProps) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0768 12.9231C11.7562 12.9231 12.3076 13.4745 12.3076 14.1538V22.7692C12.3076 23.4486 11.7562 24 11.0768 24H8.61529C7.93591 24 7.38452 23.4486 7.38452 22.7692V14.1538C7.38452 13.4745 7.93591 12.9231 8.61529 12.9231H11.0768ZM23.3845 16.6154C24.0639 16.6154 24.6153 17.1668 24.6153 17.8462V22.7692C24.6153 23.4486 24.0639 24 23.3845 24H20.923C20.2436 24 19.6922 23.4486 19.6922 22.7692V17.8462C19.6922 17.1668 20.2436 16.6154 20.923 16.6154H23.3845ZM17.2307 8C17.9101 8 18.4614 8.55138 18.4614 9.23077V22.7692C18.4614 23.4486 17.9101 24 17.2307 24H14.7691C14.0898 24 13.5384 23.4486 13.5384 22.7692V9.23077C13.5384 8.55138 14.0898 8 14.7691 8H17.2307Z"
                fill="currentColor"
            />
        </svg>
    );
}
