import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    IWardenNotification,
    SendWorkCalendar,
    Warden,
    WardenWithExpenseRate,
    WardenWithRelations,
} from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { GetWardenActualWeekWorkHour, GetWardenCustomProps, IWardenController } from '../IWardenController';
import { EmailSents, EmailStatus } from '@WardenOps/model/EmailSents';

export class WardenHttpController extends BaseHttpController<Warden> implements IWardenController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'warden', client);
    }

    async create(t: WardenWithExpenseRate): Promise<Warden> {
        return await this.doPost({ path: 'create', body: t }).then((res) => res.data);
    }

    async get(id: string): Promise<WardenWithExpenseRate> {
        return (await super.get(id)) as WardenWithExpenseRate;
    }

    async getByEmail(email: string): Promise<Warden> {
        return await this.doPost({ path: `get-by-email/${email}` }).then((res) => res.data);
    }

    list(params: GetWardenCustomProps): Promise<Paging<WardenWithRelations>> {
        return super.list(params);
    }

    async getWardenActualWeekWorkHour(props: GetWardenActualWeekWorkHour): Promise<WardenWithRelations[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/actual-week-work-hour`, props);
        return res.data;
    }

    async syncWardenWithLeaveDays(): Promise<boolean> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/sync-peopleHR-wardens-leaveDays`);
        return res.data;
    }

    async sendNotification(props: IWardenNotification): Promise<boolean> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/send-mail`, props);
        return res.data;
    }

    async sendWorkCalendar(props: SendWorkCalendar): Promise<boolean> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/send-work-calendar`, props);
        return res.data;
    }

    async getEmailSents(props: {
        page: number;
        pageSize: number;
        search?: string;
        status?: EmailStatus;
        startOfWeek: Date;
    }): Promise<Paging<EmailSents>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/get-email-sents`, props);
        return res.data;
    }
}
