import IcApproval from '@components/icon/navbar/IcApproval';
import IcDashboard from '@components/icon/navbar/IcDashboard';
import IcExpenses from '@components/icon/navbar/IcExpenses';
import IcLieu from '@components/icon/navbar/IcLieu';
import IcLocation from '@components/icon/navbar/IcLocation';
import IcMobileRota from '@components/icon/navbar/IcMobileRota';
import IcReport from '@components/icon/navbar/IcReport';
import IcRoute from '@components/icon/navbar/IcRoute';
import IcStaticRota from '@components/icon/navbar/IcStaticRota';
import IcTimeSheets from '@components/icon/navbar/IcTimeSheets';
import IcWarden from '@components/icon/navbar/IcWarden';
import { FeatureName } from '@config';
import { ArrowRightRounded, Menu } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    Badge,
    Collapse,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { AiOutlineDollar } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { NotificationContext } from 'src/contexts/NotificationProvider';
import color from 'src/theme/color';
import AppBar from './AppBar';
import Flag from './Flag';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
export interface INav {
    icon?: React.ReactElement;
    title: string;
    path: string;
    children?: INav[];
    defaultOpen?: boolean;
    name: FeatureName;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const FixedDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const Drawer = styled(MuiDrawer, {
    // shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    // width: drawerWidth,
    // flexShrink: 0,
    whiteSpace: 'nowrap',
    // boxSizing: 'border-box',
    // ...(open && {
    //     ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    // }),
    // ...(!open && {
    //     ...closedMixin(theme),
    //     '& .MuiDrawer-paper': closedMixin(theme),
    // }),
}));

type Props = {
    children: React.ReactNode;
};

const NavBarItem = (props: { index: number; route: INav; menuOpen: boolean }) => {
    const location = useLocation();
    const notify = React.useContext(NotificationContext);
    const { index, route } = props;
    const match = location.pathname.includes(route.path);

    const hasChild = !!route.children?.length;

    const invisibleBadge = ![FeatureName.ALERTS, FeatureName.LIEU_LEAVE].includes(route.name);
    const hasNotify =
        !invisibleBadge &&
        (route.name === FeatureName.ALERTS
            ? !!notify.totalAlerts
            : route.name === FeatureName.LIEU_LEAVE
            ? !!notify.totalNewApprovals
            : false);
    const [open, setOpen] = React.useState(match);

    // useEffect(() => {
    //     if (props.menuOpen) {
    //         setOpen(match);
    //     }
    // }, [match, props.menuOpen]);

    // React.useEffect(() => {
    //     setOpen(props.menuOpen);
    // }, [props.menuOpen]);

    const ref = React.useRef<any | null>(null);
    console.log(`ref`, ref);
    return (
        <Flag key={index} name={route.name}>
            <Tooltip disableInteractive title={props.menuOpen || hasChild ? '' : route.title} placement="right-start">
                <ListItem
                    key={route.path}
                    disablePadding
                    sx={{
                        display: 'block',
                        borderRadius: '7px',
                        backgroundColor: match ? 'rgba(0, 124, 186, 0.1)' : undefined,
                        '& *': {
                            color: match ? color.darkPrimary : undefined,
                        },
                        position: 'relative',
                        ':hover .menu-item': {
                            opacity: 1,
                            visibility: 'visible !important',
                        },
                        mt: 1,
                    }}
                    onClick={() => (hasChild ? setOpen((prev) => !prev) : undefined)}
                    {...((!hasChild && {
                        button: true,
                        component: Link,
                        to: route.path,
                    }) as any)}
                    onMouseEnter={() => {
                        if (ref.current) {
                            ref.current.removeAttribute('style');
                        }
                    }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: props.menuOpen ? 'initial' : 'center',
                            p: '8px 12px',
                            borderRadius: 'inherit',
                            position: 'relative',
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: props.menuOpen ? '12px' : 'auto',
                                ml: !props.menuOpen ? '-1px' : undefined,
                                justifyContent: 'center',
                            }}
                        >
                            <Badge
                                color="error"
                                invisible={!hasNotify}
                                badgeContent={
                                    props.route.name === FeatureName.ALERTS
                                        ? notify.totalAlerts < 100
                                            ? notify.totalAlerts
                                            : '99+'
                                        : props.route.name === FeatureName.LIEU_LEAVE
                                        ? notify.totalNewApprovals < 100
                                            ? notify.totalNewApprovals
                                            : '99+'
                                        : 0
                                }
                                sx={{
                                    '& .MuiBadge-badge': {
                                        top: props.menuOpen ? '50%' : '8px',
                                        left: props.menuOpen ? '177px' : 'auto',
                                        color: '#fff !important',
                                        fontSize: '0.6rem',
                                        minWidth: '16px',
                                        lineHeight: '16px',
                                        padding: props.menuOpen ? '10px' : '4px',
                                        height: props.menuOpen ? '16px' : '18px',
                                    },
                                }}
                            >
                                {route.icon}
                            </Badge>
                        </ListItemIcon>
                        <ListItemText
                            primary={route.title}
                            sx={{
                                opacity: props.menuOpen ? 1 : 0,
                                visibility: props.menuOpen ? 'visible' : 'hidden',
                            }}
                        />

                        {route.children && (
                            <ArrowRightRounded
                                fontSize="small"
                                sx={{
                                    transform: `translateY(-50%) rotate(${!open || !props.menuOpen ? 0 : 90}deg)`,
                                    transition: '0.3s',
                                    position: 'absolute',
                                    top: '50%',
                                    right: !props.menuOpen ? -2 : 8,
                                    // transform: '',
                                    color: 'gray',
                                }}
                            />
                        )}
                    </ListItemButton>

                    {hasChild && (
                        <Stack
                            className="menu-item"
                            ref={ref}
                            sx={{
                                position: 'absolute',
                                bottom: '50%',
                                left: '100%',
                                transform: 'translateY(50%)',
                                visibility: 'hidden',
                                opacity: 0,

                                paddingLeft: '10px',
                                transition: 'opacity 0.3s',
                            }}
                        >
                            <Paper sx={{ minWidth: 135 }}>
                                <MenuList>
                                    {route.children?.map((ch) => {
                                        const _path = route.path + ch.path;
                                        return (
                                            <Link
                                                key={ch.path}
                                                to={_path}
                                                style={{ textDecoration: 'none' }}
                                                onClick={() => {
                                                    // document.querySelectorAll('.menu-item').forEach((v) => {
                                                    //     console.log(v);
                                                    //     v.setAttribute('style', 'visibility: hidden');
                                                    // });
                                                    if (ref.current) {
                                                        ref.current.setAttribute('style', 'pointer-events: none;');
                                                    }
                                                }}
                                            >
                                                <MenuItem
                                                    sx={{
                                                        '& *': {
                                                            color: location.pathname.includes(_path)
                                                                ? color.darkPrimary
                                                                : 'gray !important',
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            '& svg': {
                                                                width: 24,
                                                                height: 24,
                                                            },
                                                        }}
                                                    >
                                                        {ch.icon}
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">{ch.title}</Typography>
                                                </MenuItem>
                                            </Link>
                                        );
                                    })}
                                </MenuList>
                            </Paper>
                        </Stack>
                    )}
                </ListItem>
            </Tooltip>

            {route.children && (
                // open = open && match
                <Collapse in={open && props.menuOpen} timeout="auto" unmountOnExit>
                    <List component="div" sx={{ paddingLeft: '16px' }}>
                        {route.children.map((child: INav) => {
                            const match = location.pathname.includes(route.path + child.path);
                            console.log(`child.path`, child.path);
                            return (
                                <Flag key={child.path} name={child.name}>
                                    <ListItem
                                        key={child.path}
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            borderRadius: '7px',
                                            backgroundColor: 'none',
                                            '& *': {
                                                color: match ? color.darkPrimary : undefined,
                                            },
                                        }}
                                        button
                                        component={Link}
                                        to={route.path.slice(1) + child.path}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: props.menuOpen ? 'initial' : 'center',
                                                p: '8px 12px',
                                                borderRadius: 'inherit',
                                                position: 'relative',
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: props.menuOpen ? '12px' : 'auto',
                                                    ml: !props.menuOpen ? '-1px' : undefined,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {child.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={child.title}
                                                sx={{ opacity: props.menuOpen ? 1 : 0 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </Flag>
                            );
                        })}
                    </List>
                </Collapse>
            )}
        </Flag>
    );
};

const NavBar = (props: { open: boolean }) => {
    const routes: INav[] = [
        {
            icon: <IcStaticRota />,
            title: 'Static coverage',
            path: '/rota-static',
            name: FeatureName.ROTA_STATIC,
        },
        // {

        //     icon: <IcRota />,
        //     title: 'Rota coverage',
        //     path: '/rota-coverage',
        //     children: [
        //         {
        //             icon: <IcStaticRota />,
        //             title: 'Static',
        //             path: '/static',
        //             name: 'rota-static',
        //         },
        //         {
        //             icon: <IcMobileRota />,
        //             title: 'Mobile',
        //             path: '/mobile',
        //             name: 'rota-mobile',
        //         },
        //     ],
        //     defaultOpen: true,
        //     name: 'rota-coverage',
        // },
        {
            icon: <IcDashboard />,
            title: 'Dashboard',
            path: '/dashboard',
            name: FeatureName.DASHBOARD,
        },
        {
            icon: <AssessmentOutlinedIcon style={{ width: 26, height: 26, marginLeft: 2, marginRight: 3.5 }} />,
            title: 'Insights',
            path: '/insights',
            name: FeatureName.REPORT,
        },
        {
            icon: <IcMobileRota />,
            title: 'Admin',
            path: '/admin',
            name: FeatureName.SETTING,
            children: [
                {
                    icon: <IcWarden />,
                    title: 'Parking operative',
                    path: '/parking-operative',
                    name: FeatureName.SETTING__WARDEN,
                },
                {
                    icon: <IcLocation />,
                    title: 'Location',
                    path: '/location',
                    name: FeatureName.SETTING__LOCATION,
                },
                {
                    icon: <IcRoute />,
                    title: 'Cluster',
                    path: '/cluster',
                    name: FeatureName.SETTING__CLUSTER,
                },
            ],
        },
        {
            icon: <IcApproval />,
            title: 'Approvals',
            path: '/approvals',
            name: FeatureName.LIEU_LEAVE,
        },
        {
            icon: <AiOutlineDollar style={{ width: 32, height: 24 }} />,
            title: 'Payment',
            path: '/payment',
            name: FeatureName.PAYMENT,
            children: [
                {
                    icon: <IcTimeSheets width={18} />,
                    title: 'Time-sheets',
                    path: '/time-sheets',
                    name: FeatureName.PAYMENT__TIME_SHEETS,
                },
                {
                    icon: <IcExpenses width={18} />,
                    title: 'Expenses',
                    path: '/expenses',
                    name: FeatureName.PAYMENT__EXPENSES,
                },
            ],
        },
        {
            icon: <IcLieu />,
            title: 'Alerts',
            path: '/alerts',
            name: FeatureName.ALERTS,
        },
    ];

    return (
        <List sx={{ px: props.open ? 1 : 0.5 }}>
            {routes.map((route, index) => {
                return <NavBarItem key={index + 'NavBarItem'} index={index} route={route} menuOpen={props.open} />;
            })}
        </List>
    );
};

export default function WrapLayout({ children }: Props) {
    const [openMenuFixed, setOpenMenuFixed] = React.useState(true);
    const [openMenuDrawer, setOpenMenuDrawer] = React.useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const handleFixedMenuOpen = () => {
        if (matches) {
            setOpenMenuDrawer(true);
        } else {
            setOpenMenuFixed(true);
        }
    };

    const handleFixedMenuClose = () => {
        setOpenMenuFixed(false);
    };

    const handleDrawerOpen = () => {
        setOpenMenuDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenMenuDrawer(false);
    };

    React.useEffect(() => {
        if (matches) {
            handleFixedMenuClose();
            // openMenuFixed && handleDrawerOpen();
        } else {
            handleDrawerClose();
            // openM && handleFixedMenuOpen();
        }
    }, [matches]);

    return (
        <Box sx={{ display: 'flex', flex: 1 }}>
            <CssBaseline />

            <AppBar />

            <FixedDrawer
                variant={'permanent'}
                open={openMenuFixed}
                sx={{
                    // width: open ? 240 : undefined,
                    // flexShrink: 0,
                    // [`& .MuiDrawer-paper`]: {
                    //   width: open ? 240 : undefined,
                    //   boxSizing: 'border-box',
                    // },
                    '& .MuiDrawer-paper': {
                        border: 'none',
                        background: '#FAFAFA',
                        overflow: !openMenuFixed ? 'unset' : undefined,
                    },
                }}
            >
                <Toolbar />

                <Stack
                    direction="row"
                    justifyContent={openMenuFixed ? 'end' : 'center'}
                    alignItems={'center'}
                    sx={{ pr: openMenuFixed ? 1 : 0, height: '110px' }}
                >
                    <IconButton
                        onClick={openMenuFixed ? handleFixedMenuClose : handleFixedMenuOpen}
                        sx={{
                            boxShadow: !openMenuFixed ? 'none' : '0px 0px 5px rgba(0, 0, 0, 0.25) !important',
                            width: 32,
                            height: 32,
                            p: 0,
                            top: 8,
                            left: 0,
                            bgcolor: '#fff',
                            ...(openMenuFixed && {
                                position: 'fixed',
                                zIndex: theme.zIndex.drawer + 1,
                                top: 110,
                                left: 224,
                            }),
                            transition: 'left 0.2s linear',
                        }}
                    >
                        {!openMenuFixed ? <Menu /> : <FiArrowLeft />}
                    </IconButton>
                </Stack>

                <NavBar key={'nav1'} open={openMenuFixed} />
            </FixedDrawer>

            <Drawer
                // variant={'temporary'}
                open={openMenuDrawer}
                sx={
                    {
                        // width: open ? 240 : undefined,
                        // flexShrink: 0,
                        // [`& .MuiDrawer-paper`]: {
                        //   width: open ? 240 : undefined,
                        //   boxSizing: 'border-box',
                        // },
                    }
                }
                onClose={handleDrawerClose}
            >
                <Toolbar />

                <Stack direction="row" justifyContent={openMenuDrawer ? 'end' : 'center'}>
                    <IconButton onClick={openMenuDrawer ? handleDrawerClose : handleDrawerOpen}>
                        {!openMenuDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Stack>

                <NavBar key={'nav2'} open={true} />
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    margin: '0 auto',
                    maxWidth: {
                        xl: '80%',
                        lg: '75%',
                        md: '70%',
                        sm: '80%',
                    },
                }}
            >
                <Toolbar />
                <Box mt={'40px'} />
                {children}
                <Box mb={'50px'} />
            </Box>
        </Box>
    );
}
