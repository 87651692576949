import { BaseTextField } from '@components/BaseTextField';
import { wardenController } from '@controllers';
import { Button, Grid, Stack } from '@mui/material';
import { IWardenNotification } from '@WardenOps/model';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pushSuccess } from '@components/StyledToast';
import { useWarden } from '..';
type FormValues = {
    subject: string;
    content: string;
};
export default function SendMail() {
    const { t } = useTranslation();
    const { warden, setWarden } = useWarden();

    const {
        handleSubmit,
        watch,
        reset,
        control,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            subject: '',
            content: '',
        },
    });
    const handleSave = async () => {
        const mailToWarden: IWardenNotification = {
            subject: watch().subject,
            content: watch().content,
            recipient: {
                email: warden.Email!,
            },
        };
        await wardenController.sendNotification(mailToWarden).then((res) => {
            reset({
                subject: '',
                content: '',
            });
            pushSuccess('Sent successfully!');
        });
    };
    return (
        <>
            <Grid container xs={12}>
                <Stack width="100%" gap={2}>
                    <Controller
                        name="subject"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('wardenDetailsPage.placeHolder.error'),
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                label={t('wardenDetailsPage.text.subject')}
                                required
                                maxLength={200}
                                placeholder={t('wardenDetailsPage.placeHolder.subject')}
                                fullWidth
                                error={!!error?.message}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="content"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('wardenDetailsPage.placeHolder.error'),
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                fullWidth
                                maxLength={500}
                                required
                                multiline
                                label={t('wardenDetailsPage.text.message')}
                                placeholder={t('wardenDetailsPage.placeHolder.message')}
                                rows={5}
                                error={!!error?.message}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Stack>
            </Grid>
            <Stack direction="row" justifyContent="space-between" mt={4}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        reset({
                            subject: '',
                            content: '',
                        });
                    }}
                >
                    Clear
                </Button>
                <Button variant="contained" onClick={handleSubmit(handleSave)} disabled={!(isDirty && isValid)}>
                    {t('wardenDetailsPage.button.sendNow')}
                </Button>
            </Stack>
        </>
    );
}
