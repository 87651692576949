import { ListProps } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { RotaCoverage } from '@LocationOps/model';
import { Rota } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import {
    BulkCreateRotaByLocation,
    BulkDeleteRotaByLocation,
    IGetWorkCalendarResult,
    IRotaController,
    IUpdateWorkCalendar,
} from '../IRotaController';

export class RotaHttpController extends BaseHttpController<Rota> implements IRotaController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'rota', client);
    }

    async getWorkCalendar(props: ListProps<Rota>): Promise<IGetWorkCalendarResult> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/get-work-calendar`, props);
        return res.data;
    }

    async updateWorkCalendar(props: IUpdateWorkCalendar): Promise<IGetWorkCalendarResult> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/update-work-calendar`, props);
        return res.data;
    }

    async bulkCreateByLocation(props: BulkCreateRotaByLocation): Promise<RotaCoverage[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/location-bulk-create`, props);
        return res.data;
    }

    async bulkDeleteByLocation(props: BulkDeleteRotaByLocation): Promise<RotaCoverage[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/location-bulk-delete`, props);
        return res.data;
    }
}
