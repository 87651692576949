import { Rate } from './Rate';
import { BaseModel } from '@Core';
import { Address } from './Address';
import { AssignableToRota, Cluster, ClusterLocation } from './Cluster';
import { CountryRegion } from './CountryRegion';
import { CountrySubRegionWithRelations } from './CountrySubRegion';
import { OperationalPeriod } from './OperationPeriod';
import { OperationalPeriodHistory } from './OperationPeriodHistory';
import { Region } from './Region';
import { RotaCoverage } from './RotaCoverage';
import { Zone } from './Zone';
import { LocationDocuments } from './LocationDocuments';
import { StatPOMPerformanceByZone, StatPOMPerformanceByZoneHourly } from './StatPOMPerformanceByZone';

export interface Location extends BaseModel, Address {
    ExternalReference: string;
    Name: string;
    LocationType?: LocationType | null;
    LocationStatus?: LocationStatus;

    AmountPCNLastWeek?: number;
    AmountVisitedLastWeek?: number;

    CountryRegionId?: number;
    CountrySubRegionId: number;
    ClusterId?: number;

    Longitude?: number;
    Latitude?: number;
    Notes: string;
    RegionId: number;
    Region?: Region;

    Distance?: number;

    AvgPCNVisitColor?: AvgPCNVisitColor;
    StatPOMPerformanceByZone?: StatPOMPerformanceByZone[];
    IsSuspended?: boolean;
    StatPomPerformanceByZoneHourlys?: StatPOMPerformanceByZoneHourly[];
}
export enum AvgPCNVisitColor {
    Green, // greater than 1
    Amber, // between 0.2 - 1
    Red, // lower than 0.2
    NoColour, // Infinity or NaN
}

export interface LocationWithRelations extends Location {
    CountryRegion?: CountryRegion;
    CountrySubRegion?: CountrySubRegionWithRelations;

    Clusters?: Cluster[];
    Cluster?: Cluster;
    ClusterWithLocations?: ClusterLocation[];
    LocationDocuments?: LocationDocuments[];

    OperationalPeriods?: OperationalPeriod[];
    OperationalPeriodHistories?: OperationalPeriodHistory[];
    RotaCoverage?: RotaCoverage[];

    MissingWarden?: number;
    hasPeriod?: boolean;
    DistanceWithAWarden?: number;

    isSetupRates?: boolean;
    Rates?: Rate[];

    Zones?: Zone[];
    TotalZones?: number;
    TotalValidZones?: number;

    Assignable?: AssignableToRota;

    PeriodLastUpdatedBy?: string;
    PeriodLastUpdatedAt?: Date;

    isSetupSTS?: boolean;
}

export interface LocationWithZones extends Location {
    Zones?: Zone[];
    OperationalPeriodHistories?: OperationalPeriodHistory[];
    From?: Date;
    To?: Date;
    Rates?: Rate[];
}

export type LocationType = 'static' | 'mobile';

export enum LocationStatus {
    Prospective,
    Live,
    Transferred,
    Decommissioned,
    OnHold,
}

export enum LocationModelScope {
    LocationValid = 'LocationValid',
}
export interface TotalPCNsIssued {
    LocationId: number;
    PcnsPerHour: PcnsPerHour[];
    TotalPcnsPerWeekday: TotalPcnsPerWeekday[];
    TotalPcnsPerHour: TotalPcnsPerHour[];
}
export interface PcnsPerHour {
    Weekday: number;
    TotalPcns: number;

    TimeFrom: number;
    TimeTo: number;
}
export interface TotalPcnsPerWeekday {
    Weekday: number;
    TotalPcns: number;
}
export interface TotalPcnsPerHour {
    TotalPcns: number;

    TimeFrom: number;
    TimeTo: number;
}
export interface TotalPcnFilter {
    LocationId: number;
    Type: TotalPcnFilterType;
    From?: Date;
    To?: Date;
}

export enum TotalPcnFilterType {
    TotalPCNsIssued,
    TotalPCNsPerCheckIn,
}
