import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcNoTicket(props: SvgIconProps) {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.5 2H13.207L14.5 0.707L13.793 0L0.5 13.293L1.207 14L3.207 12H13.5C13.7651 11.9996 14.0192 11.8941 14.2067 11.7067C14.3941 11.5192 14.4996 11.2651 14.5 11V8.5C14.5 8.36739 14.4473 8.24021 14.3536 8.14645C14.2598 8.05268 14.1326 8 14 8C13.7348 8 13.4804 7.89464 13.2929 7.70711C13.1054 7.51957 13 7.26522 13 7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6C14.1326 6 14.2598 5.94732 14.3536 5.85355C14.4473 5.75979 14.5 5.63261 14.5 5.5V3C14.4997 2.73486 14.3943 2.48066 14.2068 2.29319C14.0193 2.10571 13.7651 2.00026 13.5 2ZM13.5 5.0635C13.0707 5.17434 12.6904 5.42471 12.4188 5.77524C12.1473 6.12578 12 6.5566 12 7C12 7.4434 12.1473 7.87422 12.4188 8.22476C12.6904 8.57529 13.0707 8.82566 13.5 8.9365V11H10V9.5H9V11H4.207L9 6.207V8.5H10V5.207L12.207 3H13.5V5.0635ZM1.5 5.0635V3H9V2H1.5C1.23486 2.00026 0.980665 2.10571 0.793186 2.29319C0.605707 2.48066 0.500265 2.73486 0.5 3V5.5C0.5 5.63261 0.552678 5.75979 0.646447 5.85355C0.740215 5.94732 0.867392 6 1 6C1.26522 6 1.51957 6.10536 1.70711 6.29289C1.89464 6.48043 2 6.73478 2 7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8C0.867392 8 0.740215 8.05268 0.646447 8.14645C0.552678 8.24021 0.5 8.36739 0.5 8.5V11H1.5V8.9365C1.92954 8.82583 2.3101 8.57551 2.58182 8.2249C2.85354 7.8743 3.001 7.44332 3.001 6.99975C3.001 6.55618 2.85354 6.1252 2.58182 5.7746C2.3101 5.42399 1.92954 5.17367 1.5 5.063V5.0635Z"
                fill="currentColor"
            />
        </svg>
    );
}
