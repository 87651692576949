import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcSiteLogin(props: SvgIconProps) {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.58667 7.40008C6.84048 6.84234 7.20169 6.34006 7.64965 5.92194C8.09762 5.50382 8.62357 5.17804 9.19746 4.96322C9.77135 4.7484 10.3819 4.64874 10.9943 4.66993C11.6068 4.69113 12.209 4.83276 12.7667 5.08675C13.3244 5.34056 13.8267 5.70177 14.2448 6.14973C14.6629 6.5977 14.9887 7.12365 15.2035 7.69754C15.4183 8.27143 15.518 8.88202 15.4968 9.49443C15.4756 10.1068 15.334 10.7091 15.08 11.2667C14.7104 12.0828 14.113 12.7748 13.3597 13.2596C12.6064 13.7445 11.7292 14.0016 10.8333 14.0001C9.03333 14.0001 7.38667 12.9601 6.61333 11.3334H0.166667V10.0001C0.206667 9.24008 0.726667 8.62008 1.72667 8.12008C2.72667 7.62008 3.98 7.36008 5.5 7.33341C5.88 7.33341 6.24 7.36675 6.58667 7.40008M5.5 0.666748C6.24667 0.686748 6.87333 0.946748 7.37333 1.44675C7.87333 1.94675 8.12 2.57341 8.12 3.33341C8.12 4.09341 7.87333 4.72008 7.37333 5.22008C6.87333 5.72008 6.24667 5.96675 5.5 5.96675C4.75333 5.96675 4.12667 5.72008 3.62667 5.22008C3.12667 4.72008 2.88 4.09341 2.88 3.33341C2.88 2.57341 3.12667 1.94675 3.62667 1.44675C4.12667 0.946748 4.75333 0.686748 5.5 0.666748ZM10.8333 12.6667C11.7174 12.6667 12.5652 12.3156 13.1904 11.6904C13.8155 11.0653 14.1667 10.2175 14.1667 9.33341C14.1667 8.44936 13.8155 7.60151 13.1904 6.97639C12.5652 6.35127 11.7174 6.00008 10.8333 6.00008C9.94928 6.00008 9.10143 6.35127 8.47631 6.97639C7.85119 7.60151 7.5 8.44936 7.5 9.33341C7.5 10.2175 7.85119 11.0653 8.47631 11.6904C9.10143 12.3156 9.94928 12.6667 10.8333 12.6667ZM10.1667 7.33341H11.1667V9.21341L12.7933 10.1534L12.2933 11.0201L10.1667 9.79342V7.33341Z"
                fill="currentColor"
            />
        </svg>
    );
}
