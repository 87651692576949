import { Weekday } from '@components/rota-table';
import { Paging } from '@Core';
import moment from 'moment';
import { v4 } from 'uuid';
export * from './axiosHelper';
export * from './wait';

export const _weekdays = [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
    Weekday.Saturday,
    Weekday.Sunday,
];

export const getDateWithWeekday = (startDate = new Date(), weekday: number) => {
    const _weekday = weekday === 0 ? 7 : weekday;
    return moment(startDate).isoWeekday(_weekday).startOf('date').toDate();
};

export const getWeekday = (startDate?: Date) => {
    const arr = [];
    for (let i = 0; i < 7; i++) {
        arr.push({
            weekday: _weekdays[i],
            date: moment(startDate)
                .startOf('week')
                .add(i + 1, 'day')
                .toDate(),
        });
    }

    return arr;
};

export const fakeArray = (length: number) => {
    return [...new Array(length)].map((_) => ({ id: v4() }));
};
export const formatDate = (date: Date) => moment(date).format('DD/MM/YYYY');
export const formatNumber = (value: number) => value.toLocaleString();
export function defaultPaging<T>(params?: Partial<Paging<T>>): Paging<T> {
    return {
        page: 1,
        pageSize: 10,
        rows: [],
        total: 0,
        totalPages: 1,
        ...params,
    };
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export const isEndDate = (date?: Date | string) => {
    const endHour = moment(date).hours();
    const endMinute = moment(date).minutes();

    return moment(date).endOf('date').isSame(date) || (endHour === 23 && endMinute === 59);
};

export const formatSingleNumber = (num: number) => {
    return num < 10 ? `0${num}` : num;
};

export const getTimeByMin = (minutes = 0) => {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;

    return {
        hour,
        min,
    };
};

export const getMin = (date: Date) => {
    const minutes = moment(date).hour() * 60 + moment(date).minutes();
    return minutes;
};

export const getDifferenceBetweenDate = (TimeFrom: Date, TimeTo: Date) => {
    const _TimeFrom = moment(TimeFrom);
    const _TimeTo = moment(TimeTo);
    const diffHours = moment(moment.duration(_TimeFrom.diff(_TimeTo)).as('hours'));
    const diffMilliseconds = moment(moment.duration(_TimeFrom.diff(_TimeTo)).as('milliseconds'));
    const diffBetweenDate =
        Number(diffHours) > 0
            ? diffMilliseconds.format('H[h]mm[m]')
            : Number(diffMilliseconds) > 0
            ? diffMilliseconds.format('mm[m]')
            : '00m';
    return diffBetweenDate;
};

export const rounded = (number: number) => {
    const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100;
    return Number(roundedNumber.toFixed(2));
};
