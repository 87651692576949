import { createContext, useContext, useState } from 'react';

export interface State {
    exportType: ExportType;
    isExportLoading: boolean;
}
export enum ExportType {
    none = 'none',
    excel = 'excel',
    pdf = 'pdf',
}
export default function useExportData() {
    const [state, setState] = useState<State>({
        exportType: ExportType.none,
        isExportLoading: false,
    });

    return {
        state,
        setState,
    };
}
type ExportDataType = ReturnType<typeof useExportData>;
export const ExportData = createContext<ExportDataType>({} as ExportDataType);
export const useExport = () => useContext(ExportData);
