import { styled, TableCell, TableCellProps, TextField, Theme, Typography, SxProps, Stack } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { TextAlign } from 'chart.js';
import React from 'react';
import { NumericFormat } from 'react-number-format';

const CellOfRow = ({
    value,
    color,
    align,
}: {
    value: JSX.Element | string | number;
    color?: string;
    align?: ResponsiveStyleValue<TextAlign>;
}) => {
    return (
        <TableCell sx={{ textAlign: align ? align : 'center' }}>
            <Typography variant="body1" color={color ? color : 'inherit'}>
                {value}
            </Typography>
        </TableCell>
    );
};

const TableCells = ({
    value,
    isEdit,
    onClick,
    onChange,
    onFocus,
}: {
    value: number | string;
    isEdit: boolean;
    onClick(): void;
    onFocus(): void;
    onChange(e: any): void;
}) => {
    const MAX_VAL = 24;
    const withValueCap = (inputObj: any) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };
    return (
        <TableCell sx={{ textAlign: 'center' }}>
            {!isEdit ? (
                <Typography variant="body1">{value}</Typography>
            ) : (
                <NumericFormat
                    customInput={TextField}
                    onClick={onClick}
                    onChange={(e: any) => onChange(e)}
                    value={value}
                    onFocus={onFocus}
                    allowNegative={false}
                    InputProps={{
                        sx: {
                            '& .MuiOutlinedInput-input': {
                                width: 30,
                                height: 5,
                                textAlign: 'center',
                                padding: '14px 10px',
                            },
                        },
                    }}
                    decimalScale={2}
                    isAllowed={withValueCap}
                />
            )}
        </TableCell>
    );
};

const SummaryText = ({
    value,
    color,
    align,
}: {
    value: string | number;
    color?: string;
    align?: ResponsiveStyleValue<TextAlign>;
}) => {
    return (
        <TableCell sx={{ textAlign: align ? align : 'center', borderBottom: 'none' }}>
            <Typography variant="body1" color={color ? color : 'inherit'}>
                {value}
            </Typography>
        </TableCell>
    );
};

const BaseHead = ({
    title,
    sx,
    align,
}: {
    title: string | any;
    sx?: SxProps<Theme>;
    align?: TableCellProps['align'];
}) => {
    return (
        <TableCell sx={sx} align={align ?? 'right'}>
            <Typography variant="h6" fontWeight={600}>
                {title}
            </Typography>
        </TableCell>
    );
};

const RowInformation = ({ title, content }: { title: string; content: any }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1">{title}:</Typography>
            <Typography variant="body1">{content}</Typography>
        </Stack>
    );
};

export { CellOfRow, TableCells, SummaryText, BaseHead, RowInformation };
