import { useMsal } from '@azure/msal-react';
import IcUser from '@components/icon/IcUser';
import { wardenController } from '@controllers';
import { AZURE_ID } from '@helpers';
import { CheckCircleOutlineRounded, CheckCircleRounded, CheckRounded } from '@mui/icons-material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { Backdrop, Button, CircularProgress, Stack, styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScaleLoader } from 'react-spinners';
import { pushSuccess } from '@components/StyledToast';
import color from 'src/theme/color';

const SButton = styled(Button)({
    backgroundColor: 'white',
    color: color.darkPrimary,
    border: 'none !important',
    paddingLeft: 16,
    paddingRight: 19,
    ':hover': {
        borderColor: '#eee',
        backgroundColor: '#eee',
    },
});

const pages = ['Products', 'Pricing', 'Blog'];

interface Props {
    isDenied?: boolean;
}

const AppBar = (props?: Props) => {
    const { instance, accounts } = useMsal();
    const { i18n } = useTranslation();

    const handleLogout = () => {
        instance
            .logoutRedirect({
                postLogoutRedirectUri: '/',
            })
            .then((res) => {
                sessionStorage.removeItem(AZURE_ID);
            });
    };

    const settings = [
        {
            label: 'Logout',
            onClick: handleLogout,
        },
    ];

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [isSync, setIsSync] = React.useState(false);

    const handleSync = () => {
        setIsSync(true);
        wardenController
            .syncWardenWithLeaveDays()
            .then((res) => {
                pushSuccess('Data has been synced!');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .finally(() => {
                setIsSync(false);
            });
    };

    return (
        <MuiAppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor: color.darkPrimary,
            }}
        >
            <Container maxWidth={false} sx={{ px: '42px !important' }}>
                <Toolbar disableGutters>
                    {/* <AdbIcon
						sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
					/> */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                            sx={{
                                width: 90,
                                height: 30,
                                background: `url("/assets/images/UKPC.png")`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                        />
                        <Box sx={{ width: 2, height: 32, bgcolor: (theme) => theme.palette.primary.main }} />
                        <Typography
                            variant="h4"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 600,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Parking Operative Management
                        </Typography>
                    </Stack>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        {/* <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu> */}
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography> */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {/* {pages.map((page) => (
							<Button
								key={page}
								onClick={handleCloseNavMenu}
								sx={{ my: 2, color: "white", display: "block" }}
							>
								{page}
							</Button>
						))} */}
                    </Box>

                    <Stack direction={'row'} alignItems="center" spacing={3} sx={{ flexGrow: 0 }}>
                        {/* <Tooltip title="Open settings"> */}
                        {/* <Button
                            onClick={() => {
                                i18n.changeLanguage(Language.en);
                            }}
                        >
                        EN
                        </Button>
                        <Button
                            onClick={() => {
                                i18n.changeLanguage(Language.gb);
                            }}
                        >
                            GB
                        </Button> */}

                        {!props?.isDenied && (
                            <SButton
                                onClick={handleSync}
                                disabled={isSync}
                                variant="contained"
                                sx={{}}
                                endIcon={
                                    <svg
                                        width="10"
                                        height="14"
                                        viewBox="0 0 10 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.46967 9.7803C4.76256 10.0732 5.23744 10.0732 5.53033 9.7803L9.2803 6.03033C9.5732 5.73744 9.5732 5.26256 9.2803 4.96967C8.9874 4.67678 8.5126 4.67678 8.2197 4.96967L5.75 7.43934V0.75C5.75 0.33579 5.41421 0 5 0C4.58579 0 4.25 0.33579 4.25 0.75V7.43934L1.78033 4.96967C1.48744 4.67678 1.01256 4.67678 0.71967 4.96967C0.42678 5.26256 0.42678 5.73744 0.71967 6.03033L4.46967 9.7803ZM0.75 12.0001C0.33579 12.0001 0 12.3359 0 12.7501C0 13.1643 0.33579 13.5001 0.75 13.5001H9.25C9.6642 13.5001 10 13.1643 10 12.7501C10 12.3359 9.6642 12.0001 9.25 12.0001H0.75Z"
                                            fill="#1A2B6D"
                                        />
                                    </svg>
                                }
                            >
                                Sync data
                            </SButton>
                        )}
                        <Button
                            variant="text"
                            onClick={handleOpenUserMenu}
                            sx={{
                                p: 0,
                                color: 'white',
                                textTransform: 'unset',
                            }}
                        >
                            {accounts[0].name}
                            <Avatar
                                alt={accounts[0].name}
                                sx={{ marginLeft: '10px', bgcolor: '#85858A', width: 32, height: 32 }}
                            >
                                <IcUser />
                            </Avatar>
                        </Button>
                        {/* <IconButton
								onClick={handleOpenUserMenu}
								sx={{ p: 0 }}
							>
								
							</IconButton> */}
                        {/* </Tooltip> */}
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.label} onClick={setting.onClick}>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>
                </Toolbar>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(5px)' }}
                open={isSync}
            >
                <Stack alignItems={'center'}>
                    <>
                        <CircularProgress sx={{ color: '#fff' }} />
                        <Typography mt={3}>All data in People HR is being synced.</Typography>
                        <Typography>Please wait a moment.</Typography>
                    </>
                </Stack>
            </Backdrop>
        </MuiAppBar>
    );
};
export default AppBar;
