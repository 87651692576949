import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcExpenses(props: SvgIconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.9327 10.3763C7.63222 9.23194 7.50629 8.15353 7.45378 7.45419C8.15319 7.50655 9.23171 7.63228 10.3762 7.93262C11.7668 8.29755 13.1722 8.89917 14.1363 9.86344C15.1004 10.8278 15.7021 12.2332 16.0672 13.6236C16.3677 14.768 16.4936 15.8464 16.5461 16.5457C15.8467 16.4932 14.7681 16.3673 13.6236 16.0669C12.233 15.7018 10.8276 15.1002 9.86356 14.1361C8.89948 13.1721 8.2978 11.7668 7.9327 10.3763Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <circle cx="12" cy="12" r="11.25" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
}
