export enum FeatureName {
    ROTA_COVERAGE = 'rota-coverage',
    ROTA_STATIC = 'rota-static',
    ROTA_MOBILE = 'rota-mobile',

    DASHBOARD = 'dashboard',

    REPORT = 'report',

    SETTING = 'setting',
    SETTING__WARDEN = 'setting-warden',
    SETTING__WARDEN__TICKET_TRACKER = 'setting-warden-ticket-tracker',
    SETTING__WARDEN__TICKET_HOURS = 'setting-warden-ticket-hours',
    SETTING__LOCATION = 'setting-location',
    SETTING__CLUSTER = 'setting-cluster',

    LIEU_LEAVE = 'lieu-leave',

    PAYMENT = 'payment',
    PAYMENT__TIME_SHEETS = 'payment-time-sheets',
    PAYMENT__EXPENSES = 'payment-expenses',

    ALERTS = 'alerts',
    ALERTS__SITE_LOGIN = 'site-login',
    ALERTS__PHONE_STATIONARY = 'phone-stationary',
    ALERTS__NO_TICKETS = 'no-tickets',
    ALERTS__EARLY_LOGOFF = 'early-logoff',
}
export type Role = 'admin' | 'region-manager' | 'white-list';

export type FeatureOptions = {
    active: boolean;
    roles?: Role[];
};

export const featureFlags: Record<FeatureName, FeatureOptions> = {
    [FeatureName.ROTA_COVERAGE]: {
        active: process.env.REACT_APP_ROTA_COVERAGE !== 'false',
        roles: undefined,
    },
    [FeatureName.ROTA_STATIC]: {
        active: process.env.REACT_APP_ROTA_STATIC !== 'false',
        roles: undefined,
    },
    [FeatureName.ROTA_MOBILE]: {
        active: process.env.REACT_APP_ROTA_MOBILE !== 'false',
        roles: undefined,
    },
    [FeatureName.DASHBOARD]: {
        active: process.env.REACT_APP_DASHBOARD !== 'false',
        roles: undefined,
    },
    [FeatureName.REPORT]: {
        active: process.env.REACT_APP_REPORT !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING]: {
        active: process.env.REACT_APP_SETTING !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING__WARDEN]: {
        active: process.env.REACT_APP_SETTING__WARDEN !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING__LOCATION]: {
        active: process.env.REACT_APP_SETTING__LOCATION !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING__CLUSTER]: {
        active: process.env.REACT_APP_SETTING__CLUSTER !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING__WARDEN__TICKET_TRACKER]: {
        active: process.env.REACT_APP_SETTING__WARDEN__TICKET_TRACKER !== 'false',
        roles: undefined,
    },
    [FeatureName.SETTING__WARDEN__TICKET_HOURS]: {
        active: process.env.REACT_APP_SETTING__WARDEN__TICKET_TRACKER !== 'false',
        roles: undefined,
    },
    [FeatureName.LIEU_LEAVE]: {
        active: process.env.REACT_APP_LIEU_LEAVE !== 'false',
        roles: undefined,
    },
    [FeatureName.PAYMENT__TIME_SHEETS]: {
        active: process.env.REACT_APP_PAYMENT__TIME_SHEETS !== 'false',
        roles: undefined,
    },
    [FeatureName.PAYMENT]: {
        active: process.env.REACT_APP_PAYMENT !== 'false',
        roles: undefined,
    },
    [FeatureName.PAYMENT__EXPENSES]: {
        active: process.env.REACT_APP_PAYMENT__EXPENSES !== 'false',
        roles: undefined,
    },
    [FeatureName.ALERTS]: {
        active: process.env.REACT_APP_ALERTS !== 'false',
        roles: undefined,
    },
    [FeatureName.ALERTS__SITE_LOGIN]: {
        active: process.env.REACT_APP_ALERTS__SITE_LOGIN !== 'false',
        roles: undefined,
    },
    [FeatureName.ALERTS__PHONE_STATIONARY]: {
        active: process.env.REACT_APP_ALERTS__PHONE_STATIONARY !== 'false',
        roles: undefined,
    },
    [FeatureName.ALERTS__NO_TICKETS]: {
        active: process.env.REACT_APP_ALERTS__NO_TICKETS !== 'false',
        roles: undefined,
    },
    [FeatureName.ALERTS__EARLY_LOGOFF]: {
        active: process.env.REACT_APP_ALERTS__EARLY_LOGOFF !== 'false',
        roles: undefined,
    },
};
