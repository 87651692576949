import RotaTable, { DEFAULT_WEEKLY_PERIOD, ShiftStatus, Weekday } from '@components/rota-table';
import StyledShift from '@components/rota-table/Shift';
import { wardenEventController } from '@controllers';
import { Box, Stack, Typography } from '@mui/material';
import FilterDate from '@pages/expenses/components/FilterDate';
import { ShiftWithStatus } from '@pages/setting/location/detail/components/ShiftItem';
import { DailyTicketHour, TicketHour } from '@WardenOps/model/DailyTicketHour';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { useWarden } from '..';

type Props = {};

type ShiftTicketHour = ShiftWithStatus & { shift: TicketHour };

export default function ITicketHoursTab({}: Props) {
    const { warden, setWarden } = useWarden();
    const [query, setQuery] = useState({ startDate: moment().startOf('isoWeek').toDate() });
    const [weeklyTicketHours, setWeeklyTicketHours] = useState<DailyTicketHour[]>([]);

    const converted = useMemo(() => {
        const results: Record<Weekday, ShiftTicketHour[]> = cloneDeep(DEFAULT_WEEKLY_PERIOD);

        weeklyTicketHours.forEach((value) => {
            const _weekday = moment(value.WorkDate).weekday() as Weekday;
            results[_weekday] = value.TicketHours.map((p) => {
                return {
                    startMin: p.TimeFrom,
                    endMin: p.TimeTo,
                    weekday: _weekday,
                    status: ShiftStatus.assigned,
                    fakeId: v4(),
                    shift: p,
                    // date: getDateWithWeekday(startDate, value.Weekday),
                };
            });
        });

        return results;
    }, [weeklyTicketHours]);

    useEffect(() => {
        wardenEventController
            .getWeeklyTicketHours({
                From: query.startDate,
                To: moment(query.startDate).endOf('isoWeek').toDate(),
                filter: {
                    WardenId: warden.Id!,
                },
            })
            .then((res) => {
                setWeeklyTicketHours(res);
            });
    }, [query.startDate, warden.Id]);

    return (
        <Box>
            <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <Typography sx={{ fontSize: 17 }}>Ticket hours</Typography>

                <FilterDate
                    startDate={query.startDate}
                    onChange={(startDate) => {
                        setQuery((prev) => ({ ...prev, startDate: startDate }));
                    }}
                />
            </Stack>

            <Stack mt={3}>
                <RotaTable
                    hideLieuLeave
                    hideTitle
                    startDate={query.startDate}
                    // loading={}
                    data={converted}
                    renderOption={(option, index, arr) => {
                        return (
                            <StyledShift
                                readonlyOutside
                                shift={option}
                                renderTitleShift={(shift) => shift?.shift.IssuedTicket}
                            />
                        );
                    }}
                />
            </Stack>
        </Box>
    );
}
