import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcWden(props: SvgIconProps) {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.5999 3.45946C8.5999 4.81779 7.43582 5.91892 5.9999 5.91892C4.56398 5.91892 3.3999 4.81779 3.3999 3.45946C3.3999 2.10113 4.56398 1 5.9999 1C7.43582 1 8.5999 2.10113 8.5999 3.45946Z"
                fill="currentColor"
                stroke="currentColor"
            />
            <path
                d="M11 11.7838C11 12.5977 10.5127 13.3883 9.61104 13.9974C8.71056 14.606 7.43576 15 6 15C4.56424 15 3.28945 14.606 2.38894 13.9974C1.48729 13.3883 1 12.5977 1 11.7838C1 10.9698 1.48729 10.1793 2.38894 9.57012C3.28945 8.96161 4.56424 8.56757 6 8.56757C7.43576 8.56757 8.71056 8.96161 9.61104 9.57012C10.5127 10.1793 11 10.9698 11 11.7838Z"
                fill="currentColor"
                stroke="currentColor"
            />
        </svg>
    );
}
