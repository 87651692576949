import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcPhoneStation(props: SvgIconProps) {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.5 10.798C12.5 11.014 12.4519 11.236 12.3498 11.452C12.2476 11.668 12.1154 11.872 11.9411 12.064C11.6467 12.388 11.3223 12.622 10.9557 12.772C10.5951 12.922 10.2045 13 9.78393 13C9.171 13 8.516 12.856 7.825 12.562C7.13393 12.268 6.44293 11.872 5.75787 11.374C5.06685 10.87 4.41187 10.312 3.78693 9.694C3.168 9.07 2.60917 8.416 2.11041 7.732C1.61767 7.048 1.22108 6.364 0.932647 5.686C0.644213 5.002 0.5 4.348 0.5 3.724C0.5 3.316 0.572107 2.926 0.716327 2.566C0.86054 2.2 1.08889 1.864 1.40736 1.564C1.79194 1.186 2.21257 1 2.65723 1C2.82549 1 2.99374 1.036 3.14397 1.108C3.3002 1.18 3.43841 1.288 3.54657 1.444L4.94066 3.406C5.04883 3.556 5.12694 3.694 5.181 3.826C5.23513 3.952 5.26513 4.078 5.26513 4.192C5.26513 4.336 5.22307 4.48 5.13896 4.618C5.06084 4.756 4.94667 4.9 4.80245 5.044L4.34577 5.518C4.27967 5.584 4.24963 5.662 4.24963 5.758C4.24963 5.806 4.25563 5.848 4.26765 5.896C4.28568 5.944 4.30371 5.98 4.31573 6.016C4.42389 6.214 4.61017 6.472 4.87456 6.784C5.14497 7.096 5.4334 7.414 5.74587 7.732C6.07033 8.05 6.3828 8.344 6.70133 8.614C7.0138 8.878 7.27213 9.058 7.47647 9.166C7.50653 9.178 7.54253 9.196 7.5846 9.214C7.63267 9.232 7.6808 9.238 7.73487 9.238C7.837 9.238 7.91513 9.202 7.9812 9.136L8.43793 8.686C8.58813 8.536 8.73233 8.422 8.87053 8.35C9.00873 8.266 9.147 8.224 9.2972 8.224C9.4114 8.224 9.53153 8.248 9.66373 8.302C9.79593 8.356 9.93413 8.434 10.0844 8.536L12.0733 9.946C12.2296 10.054 12.3377 10.18 12.4039 10.33C12.4639 10.48 12.5 10.63 12.5 10.798Z"
                stroke="currentColor"
                stroke-miterlimit="10"
            />
        </svg>
    );
}
