import { CStack } from '@components/FlexedStack';
import IcEdit from '@components/icon/IcEdit';
import IcMap from '@components/icon/IcMap';
import { AvgPCNVisitColor, LocationWithRelations } from '@LocationOps/model';
import { CheckRounded } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { BiErrorCircle } from 'react-icons/bi';
import { BsClockHistory } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import color from 'src/theme/color';
import { IconBase } from '../../../warden/list/components/WardenCard';
type Props = {
    location: LocationWithRelations;
};
export type ColorMapping = {
    [key in AvgPCNVisitColor]: string;
};
export const colorLocationMapping: ColorMapping = {
    [AvgPCNVisitColor.Green]: 'rgba(239, 254, 243, 1)',
    [AvgPCNVisitColor.Amber]: 'rgba(254, 246, 231, 1)',
    [AvgPCNVisitColor.Red]: 'rgba(251, 234, 233, 1)',
    [AvgPCNVisitColor.NoColour]: 'rgba(255, 255, 255, 1)',
};
export default function LocationListItem(props: Props) {
    const theme = useTheme();
    const { location } = props;
    const isStaticHasZones = location.CountrySubRegionId && location.LocationType === 'static';

    return (
        <Box
            sx={{
                width: '100%',
                p: 3,
                border: '1px solid #DDDDDD',
                borderRadius: 2,
                backgroundColor: colorLocationMapping[location.AvgPCNVisitColor!],
            }}
        >
            <Stack width="100%" direction="row" alignItems="flex-start" justifyContent="space-between">
                <Stack gap={0.5}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            '& > a': {
                                color: 'black',
                                textDecoration: 'none',
                            },
                        }}
                    >
                        <Link to={`${location.Id}/total-pcns-issued`}>
                            <Typography
                                variant="body1"
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: color.darkPrimary,
                                        textDecoration: 'underline',
                                    },
                                    transition: '.5s',
                                }}
                            >
                                {location.Name}{' '}
                                {location.AvgPCNVisitColor === AvgPCNVisitColor.NoColour && '(No data available yet)'}
                            </Typography>
                        </Link>

                        {!!location.Clusters?.length && (
                            <Tooltip title={`Assigned to cluster`} placement="top" arrow>
                                <Stack
                                    direction={'row'}
                                    justifyContent="center"
                                    alignItems={'center'}
                                    ml={1}
                                    sx={{
                                        height: 18,
                                        width: 18,
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        borderRadius: '50%',
                                    }}
                                >
                                    <CheckRounded sx={{ color: 'white', fontSize: 13 }} />
                                </Stack>
                            </Tooltip>
                        )}

                        {location.LocationType && (
                            <Stack
                                ml={1.5}
                                sx={{
                                    minWidth: 80,
                                    p: '2px 16px',
                                    background: location.LocationType === 'static' ? '#E8F5E9' : '#F09453',
                                    textAlign: 'center',
                                    borderRadius: 10,
                                    color: location.LocationType === 'static' ? theme.palette.secondary.main : '#fff',
                                }}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-around"
                                gap={0.8}
                            >
                                {location.LocationType === 'static' ? 'Static' : 'Mobile'}
                                {location.hasPeriod && (
                                    <Tooltip
                                        title="This location has been set up operation periods."
                                        arrow
                                        placement="top"
                                    >
                                        <BsClockHistory />
                                    </Tooltip>
                                )}
                                {isStaticHasZones && (
                                    <Tooltip title="This location has been set up sub-region." arrow placement="top">
                                        <IcMap />
                                    </Tooltip>
                                )}
                            </Stack>
                        )}
                    </Stack>

                    <Stack mt={1} spacing={1}>
                        <Typography variant="body1" color={theme.palette.grey[500]}>
                            Region: {location.CountrySubRegion?.CountryRegion?.Name || 'NaN'} | Sub-region:{' '}
                            {location.CountrySubRegion?.Name || 'NaN'} | Location ID: {location.Id}
                        </Typography>
                        {/* {!!location.Clusters?.length && (
                            <Typography variant="body1" color={theme.palette.grey[500]}>
                                Cluster: {location.Clusters?.map((c) => c.Name).join(', ')}
                            </Typography>
                        )} */}
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Typography
                                variant="body1"
                                color={location.TotalValidZones === 0 ? 'error' : theme.palette.grey[500]}
                            >
                                Valid zones to assigned: {location.TotalValidZones}/{location.TotalZones}
                            </Typography>
                            {location.TotalValidZones === 0 && (
                                <Tooltip
                                    title="Please know that a location is only able to be Added into a Cluster or Assigned to a PO when it has at least 1 zone already setup Warden Patrol service."
                                    arrow
                                    placement="top"
                                >
                                    <BiErrorCircle color={theme.palette.error.main} fontSize={16} />
                                </Tooltip>
                            )}
                        </Stack>
                        <Typography fontSize={14} color="#3EB6E4">
                            Issued PCNs last week: {location.AmountPCNLastWeek ?? 0}
                        </Typography>
                    </Stack>
                </Stack>
                <Link to={`${location.Id}`}>
                    {/* <Tooltip title="Edit" placement="top" arrow>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                width: 30,
                                height: 30,
                                background: '#DDDDDD',
                                borderRadius: 5,
                                cursor: 'pointer',
                                color: '#85858A',
                                transition: '.5s',
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                },
                            }}
                        >
                            <IcEdit />
                        </Stack>
                    </Tooltip> */}
                    <IconBase title="Edit" colorHover={theme.palette.primary.main} children={<IcEdit />} />
                </Link>
            </Stack>
        </Box>
    );
}
