import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const pushSuccess = (message: string) => {
    toast.success(message, {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <rect width="24" height="24" fill="#009D4F" rx="12"></rect>
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7.252 12l3.165 3.165 6.33-6.33"
                ></path>
            </svg>
        ),
    });
};

const pushError = (message: string) => {
    toast.error(message, {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                <path
                    fillRule="evenodd"
                    stroke="#E01B00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M11 1c5.522 0 10 4.477 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C1 5.477 5.477 1 11 1z"
                    clipRule="evenodd"
                ></path>
                <path
                    stroke="#E01B00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M10.994 6.896v4.778"
                ></path>
                <path
                    stroke="#E01B00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.994 15.104h.01"
                ></path>
            </svg>
        ),
    });
};

const pushWarning = (message: ReactNode, options?: ToastOptions) => {
    toast.warning(message, {
        icon: (
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 5.73684V11.4211M11 19C6.69215 19 4.53823 19 3.29308 18.0953C2.20701 17.3063 1.50676 16.0934 1.36643 14.7583C1.20555 13.2276 2.28252 11.3623 4.43644 7.63158C6.59036 3.90088 7.66732 2.03553 9.07335 1.40952C10.2998 0.863493 11.7002 0.863493 12.9266 1.40952C14.3327 2.03553 15.4096 3.90088 17.5636 7.63158C19.7175 11.3623 20.7944 13.2276 20.6336 14.7583C20.4932 16.0934 19.793 17.3063 18.7069 18.0953C17.4618 19 15.3078 19 11 19Z"
                    stroke="#ff8b00"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M11.9473 14.2632C11.9473 14.7864 11.5232 15.2105 10.9999 15.2105C10.4767 15.2105 10.0526 14.7864 10.0526 14.2632C10.0526 13.7399 10.4767 13.3158 10.9999 13.3158C11.5232 13.3158 11.9473 13.7399 11.9473 14.2632Z"
                    fill="#ff8b00"
                />
            </svg>
        ),
        ...options,
    });
};

export { pushSuccess, pushError, pushWarning };
