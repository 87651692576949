import { CStack } from '@components/FlexedStack';
import { Grow, CircularProgress, Stack, Box, BoxProps } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
    loading: boolean;
    children: ReactNode;
    boxProps?: BoxProps;
};

export default function LoadingWrap({ loading, children, ...props }: Props) {
    return loading ? (
        <Grow in={true}>
            <div>
                <CStack mt={3}>
                    <CircularProgress />
                </CStack>
            </div>
        </Grow>
    ) : (
        <Grow in={!loading}>
            <Box mt={2} {...props.boxProps}>
                {children}
            </Box>
        </Grow>
    );
}
