import { CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { createArray, WeeklyCalendarShift } from '@pages/setting/warden/detail/components/WorkCalendar';
import { RotaType, Warden } from '@WardenOps/model';
import { initial, intersection } from 'lodash';
import moment from 'moment';
import { ReactNode } from 'react';
import { _weekdays } from 'src/helpers';
import { v4 } from 'uuid';
import { Shift, ShiftStatus, SHIFTS_PER_HOUR, SHIFT_STEP, TOTAL_DAY_SHIFT, TOTAL_HOURS, Weekday } from '.';
import StyledShift, { ShiftTypeDisplay } from './Shift';

type Props<T> = {
    data: {
        [weekday: string]: T[];
    };
    startDate?: Date;
    displayType?: ShiftTypeDisplay;
    renderOption(option: T): JSX.Element;
    renderAction?(shifts: T[], weekday: number, date?: Date): JSX.Element;
    isDisplayDate?: boolean;
    hideLieuLeave?: boolean;
    hideTitle?: boolean;
    loading?: boolean;
    getLieuTitle?(shifts: T[]): ReactNode;
};

export default function RotaWorkCalendar<T extends WeeklyCalendarShift>(props: Props<WeeklyCalendarShift>) {
    const { data, displayType, renderOption, renderAction, isDisplayDate = true, hideTitle } = props;

    const dateCol = `110px`;
    const hoursCol = `1fr`;
    const actionCol = renderAction ? '80px' : '';
    const gridTemplateCols = `${dateCol} ${hoursCol} ${actionCol}`;

    return (
        <div style={{ position: 'relative', pointerEvents: props.loading ? 'none' : undefined }}>
            {props.loading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        zIndex: 2,
                        // transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
            <Box sx={{ filter: props.loading ? 'blur(4px)' : undefined, transition: 'all 0.3s' }}>
                <Stack sx={{ backgroundColor: '#FAFAFA', borderRadius: '5px', p: '8px 16px', pr: 0 }} direction="row">
                    <Stack flexBasis={dateCol} flexShrink={0}>
                        <Typography color="success.main">Hour</Typography>
                    </Stack>

                    <Stack flex={1} direction="row">
                        {[...new Array(TOTAL_HOURS)].map((hour, index, arr) => {
                            return (
                                <Stack
                                    key={index}
                                    flex={1}
                                    direction={'row'}
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                >
                                    <Typography color="success.main" variant="subtitle2" ml={'-3px'}>
                                        {index}
                                    </Typography>
                                    {index === arr.length - 1 && (
                                        <Typography color="success.main" variant="subtitle2" mr={'-4.5px'}>
                                            {index + 1}
                                        </Typography>
                                    )}
                                </Stack>
                            );
                        })}
                    </Stack>

                    {/* Actions */}
                    <Stack flexBasis={'80px'} flexShrink={0}></Stack>
                </Stack>

                {_weekdays
                    .map((i) => ({ weekday: i, shifts: data[i] }))
                    .map(({ weekday, shifts }, index) => {
                        const isoWeekday = weekday === 0 ? 7 : weekday;
                        return (
                            <Stack
                                key={v4()}
                                direction="row"
                                flexShrink={0}
                                pl={2}
                                sx={{ py: 1, borderBottom: '1px solid #ddd' }}
                                alignItems="center"
                            >
                                <Stack flexBasis={dateCol} flexShrink={0}>
                                    <Typography>
                                        <Typography
                                            component={'span'}
                                            variant="body2"
                                            color={
                                                [Weekday.Saturday, Weekday.Sunday].includes(weekday) ? 'red' : 'initial'
                                            }
                                        >
                                            {moment(props.startDate).isoWeekday(isoWeekday).format('ddd')}{' '}
                                        </Typography>

                                        {props.startDate && isDisplayDate && (
                                            <Typography component={'span'} variant="body2" color="gray">
                                                {moment(props.startDate).isoWeekday(isoWeekday).format('(DD/MM)')}
                                            </Typography>
                                        )}
                                    </Typography>

                                    {props.getLieuTitle && <>{props.getLieuTitle?.(shifts)}</>}
                                </Stack>

                                <Stack
                                    flex={1}
                                    direction="row"
                                    sx={{
                                        // backgroundColor: 'red',
                                        height: '40px',
                                        position: 'relative',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {/* Layer 1 */}
                                    {Array(TOTAL_DAY_SHIFT)
                                        .fill(true)
                                        .map((_, index) => {
                                            const isOverlapped = shifts
                                                .filter(
                                                    (s) =>
                                                        s._rota?.RotaType === RotaType.WORK_HARD ||
                                                        s.status === ShiftStatus.otherEvents
                                                )
                                                .some(
                                                    (shift) =>
                                                        !!intersection(
                                                            [index],
                                                            initial(
                                                                createArray(
                                                                    shift.startMin / SHIFT_STEP,
                                                                    shift.endMin / SHIFT_STEP,
                                                                    1
                                                                )
                                                            )
                                                        ).length
                                                );
                                            return (
                                                <Stack
                                                    key={'rota-work' + index}
                                                    direction="row"
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    sx={{
                                                        flex: 1,
                                                        flexBasis: `calc(100% / ${TOTAL_DAY_SHIFT})`,
                                                        // backgroundColor: isLieu ? 'red' : '#eee',
                                                        // backgroundColor: '#eee',
                                                        height: '40px',
                                                        borderLeft:
                                                            index % (60 / SHIFT_STEP) === 0 && !!index && !isOverlapped
                                                                ? '1px solid #fff'
                                                                : 'none',
                                                        color: 'transparent',
                                                        ':hover': {
                                                            backgroundColor: '#ddd',
                                                            color: 'black',
                                                        },
                                                        maxWidth: `calc(100% / ${TOTAL_DAY_SHIFT})`,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {props.renderOption({
                                                        _id: v4(),
                                                        startMin: index * SHIFT_STEP,
                                                        endMin: (index + 1) * SHIFT_STEP,
                                                        weekday: weekday,
                                                        date: moment(props.startDate)
                                                            .isoWeekday(isoWeekday)
                                                            .startOf('day')
                                                            .toDate(),
                                                        status: shifts.some(
                                                            (shift) =>
                                                                shift.status === ShiftStatus.lieuLeave &&
                                                                index >= shift.startMin / SHIFT_STEP &&
                                                                index < shift.endMin / SHIFT_STEP
                                                        )
                                                            ? ShiftStatus.lieuLeave
                                                            : undefined,
                                                        isOverlapped: isOverlapped,
                                                    } as T)}
                                                </Stack>
                                            );
                                        })}

                                    {/* Layer 2 */}
                                    {shifts
                                        .filter((shift) =>
                                            [RotaType.WORK_HARD, RotaType.WORK].includes(shift._rota?.RotaType as any)
                                        )
                                        .map((shift, index) => {
                                            const widthPercent =
                                                ((shift.endMin - shift.startMin) / 15 / TOTAL_DAY_SHIFT) * 100;

                                            const borderWidth = shift._rota?.RotaType === RotaType.WORK_HARD ? 2 : 0;

                                            return (
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    alignItems={'center'}
                                                    justifyContent="center"
                                                    sx={{
                                                        position: 'absolute',
                                                        // flexBasis: `calc(${widthPercent}%)`,
                                                        left: `calc(${
                                                            (shift.startMin / SHIFT_STEP / TOTAL_DAY_SHIFT) * 100
                                                        }%)`,
                                                        // backgroundColor: '#FFDC83',
                                                        // height: '100%',
                                                        top: borderWidth,
                                                        bottom: borderWidth,
                                                        width: `${widthPercent}%`,
                                                        maxWidth: `calc(${widthPercent}%)`,
                                                        zIndex: 3,
                                                        // flexGrow: 0,
                                                    }}
                                                >
                                                    {/* <Typography noWrap>
                                                        {shift.startMin / 60} - {shift.endMin / 60}
                                                    </Typography> */}
                                                    {props.renderOption(shift)}
                                                </Stack>
                                            );
                                        })}
                                    {/* Layer 3 */}
                                    {shifts
                                        .filter((shift) => shift.status === ShiftStatus.otherEvents)
                                        .map((shift, index) => {
                                            const widthPercent =
                                                ((shift.endMin - shift.startMin) / 15 / TOTAL_DAY_SHIFT) * 100;

                                            return (
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    alignItems={'center'}
                                                    justifyContent="center"
                                                    sx={{
                                                        position: 'absolute',
                                                        left: `calc(${
                                                            (shift.startMin / SHIFT_STEP / TOTAL_DAY_SHIFT) * 100
                                                        }%)`,
                                                        top: 0,
                                                        bottom: 0,
                                                        width: `${widthPercent}%`,
                                                        maxWidth: `calc(${widthPercent}%)`,
                                                        zIndex: 3,
                                                    }}
                                                >
                                                    {props.renderOption(shift)}
                                                </Stack>
                                            );
                                        })}
                                </Stack>

                                {renderAction?.(
                                    shifts,
                                    _weekdays[index],
                                    !props.startDate
                                        ? undefined
                                        : moment(props.startDate).isoWeekday(isoWeekday).startOf('day').toDate()
                                )}
                            </Stack>
                        );
                    })}

                {!hideTitle && (
                    <Stack mt={2} direction={'row'} spacing={2} justifyContent="flex-end">
                        {[
                            { color: '#E8F5E9', label: 'Assigned' },
                            { color: '#FFDC83', label: 'Unassigned' },
                            { color: '#FD5F6A', label: 'Not working', hidden: props.hideLieuLeave },
                            { color: '#DDDDDD', label: 'Outside of working hours' },
                        ]
                            .filter((c) => !c.hidden)
                            .map((t) => {
                                return (
                                    <Stack key={t.color} direction={'row'} alignItems="center">
                                        <Box
                                            sx={{
                                                width: '80px',
                                                height: '16px',
                                                bgcolor: t.color,
                                                mr: 1,
                                                borderRadius: '2px',
                                            }}
                                        ></Box>
                                        <Typography variant="body2">{t.label}</Typography>
                                    </Stack>
                                );
                            })}
                    </Stack>
                )}
            </Box>
        </div>
    );
}
