import { Paging } from '@Core';
import { IGetListAlertProps, IRemoveAlertByDate } from '@WardenOps/controller/IAlertHttpController';
import { Alert, AlertType } from '@WardenOps/model';
import { pushSuccess } from '@components/StyledToast';
import { alertController } from '@controllers';
import { defaultPaging } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNotifyContext } from 'src/contexts/NotificationProvider';
type Props = {
    alertType: AlertType;
};

type RemoveType = 'all' | 'multiple' | 'single';

export default function useAlerts(props: Props) {
    const popUpRemoveAlert = usePopUp();

    const { lastAlert, getStatisticAlert, filter } = useNotifyContext();
    const [state, setState] = useState<Paging<Alert>>(defaultPaging({ pageSize: 10 }));

    const [alertId, setAlertId] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    const [removeType, setRemoveType] = useState<RemoveType>('all');

    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const isSelectedAllRows = Boolean(selectedRows.length === state.rows.length);

    const handleSelectedAllRows = (e: any) => {
        const checked = e.target.checked;
        if (checked) {
            const _selectedAllRows = state.rows.map((item) => item.Id!);
            setSelectedRows(_selectedAllRows);
        } else {
            setSelectedRows([]);
        }
    };

    const handleSelectedRows = (e: any, row: Alert) => {
        const checked = e.target.checked;
        if (checked) {
            setSelectedRows((prev) => [...prev, row.Id!]);
        } else {
            setSelectedRows((prev) => {
                const clonePrev = cloneDeep(prev);
                const _clonePrev = clonePrev.filter((item) => item !== row.Id!);
                return _clonePrev;
            });
        }
    };

    const isEmpty = !state.rows.length && !loading;

    const getListAlert = useCallback(
        (args?: Partial<IGetListAlertProps>) => {
            setLoading(true);
            return alertController
                .listAlerts({
                    TimeFrom: moment().startOf('date').toDate(),
                    filter: { AlertType: props.alertType },
                    CountryRegionId: filter.region?.Id,
                    page: 1,
                    pageSize: 10,
                    sorts: ['Updated'],
                    ...args,
                })
                .then((res) => setState(res))
                .finally(() => setLoading(false));
        },
        [props.alertType]
    );

    const handleRemove = (AlertIds: number[], Reason: string) => {
        alertController.removeAlert({ AlertIds, Reason }).then((res) => {
            getListAlert({ TimeFrom: filter.startDate });
            getStatisticAlert(moment(filter.startDate).startOf('date').toDate(), filter.region?.Id);
            setPage(1);
            pushSuccess('Deleted successfully');
            popUpRemoveAlert.onClose();
        });
    };

    const handleRemoveAll = (props: IRemoveAlertByDate) => {
        alertController.removeAlertByDate(props).then((res) => {
            getListAlert({ TimeFrom: filter.startDate });
            getStatisticAlert(moment(filter.startDate).startOf('date').toDate(), filter.region?.Id);
            setPage(1);
            pushSuccess('Deleted successfully');
        });
    };

    useEffect(() => {
        getListAlert({ page: 1, TimeFrom: filter.startDate, CountryRegionId: filter.region?.Id });
        getStatisticAlert(moment(filter.startDate).startOf('date').toDate(), filter.region?.Id);
    }, [filter.startDate, filter.region?.Id, props.alertType, getListAlert]);

    useEffect(() => {
        lastAlert?.AlertType === props.alertType && getListAlert({ page: 1 });
    }, [getListAlert, lastAlert, props.alertType]);

    return {
        state,
        setState,
        loading,
        setLoading,
        page,
        setPage,
        isEmpty,
        getListAlert,
        handleRemove,
        popUpRemoveAlert,
        alertId,
        setAlertId,
        selectedRows,
        setSelectedRows,
        isSelectedAllRows,
        handleSelectedRows,
        handleSelectedAllRows,
        handleRemoveAll,
        removeType,
        setRemoveType,
    };
}
