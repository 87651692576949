import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, Typography } from '@mui/material';
import { AlertType } from '@WardenOps/model';
import { Controller, useForm } from 'react-hook-form';
type Props = Omit<IPopUp, 'onConfirm'> & {
    onConfirm?(reason: string): void;
    isRequired?: boolean;
};
type FormValues = {
    reason: string;
};
export default function PopUpRemoveAlert(props: Props) {
    const { isRequired = true } = props;
    const {
        handleSubmit,
        watch,
        reset,
        control,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            reason: '',
        },
    });

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '490px', overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Typography variant="h4" color="error">
                    Remove alert
                </Typography>
            }
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <>
                    {isRequired ? (
                        <Controller
                            name="reason"
                            control={control}
                            rules={{
                                required: {
                                    value: isRequired,
                                    message: 'Please enter reason to remove',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    fullWidth
                                    required={isRequired}
                                    maxLength={500}
                                    multiline
                                    label={'Reason for removing'}
                                    placeholder={'Please enter reason'}
                                    rows={5}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    ) : (
                        <Typography variant="body1" align="center">
                            This alert will be removed. Do you still want to remove it?
                        </Typography>
                    )}
                </>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            reset({ reason: '' });
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        color="error"
                        variant="contained"
                        onClick={() => {
                            handleSubmit(props.onConfirm?.(watch().reason)!);
                            reset({ reason: '' });
                        }}
                        disabled={isRequired && !(isDirty && isValid)}
                    >
                        Confirm
                    </Button>
                </Stack>
            }
        />
    );
}
