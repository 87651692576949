import { useMsal } from '@azure/msal-react';
import { useFeatureFlag } from '@components/layouts/Flag';
import { pushWarning } from '@components/StyledToast';
import { appConfig, FeatureName } from '@config';
import { alertController } from '@controllers';
import { Typography } from '@mui/material';
import { AlertResponse } from '@WardenOps/model';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifyContext } from 'src/contexts/NotificationProvider';

function getNotifyMessage(alert: AlertResponse) {
    // I haven't found a solution to reuse this function yet every time app receive notifications in foreground and background,
    // if you edit this function, please edit it also in 'pubic/firebase-messaging-sw.js'

    const notification = {
        title: 'Parking operator alert!',
        body: '',
        path: '/alerts',
    };

    switch (alert.AlertType) {
        case 0:
            notification.body += 'Site login';
            notification.path += '/site-login';
            break;
        case 1:
            notification.body += 'Phone stationary';
            notification.path += '/phone-stationary';
            break;
        case 2:
            notification.body += 'No ticket';
            notification.path += '/no-tickets';
            break;
        case 3:
            notification.body += 'Site early logoff';
            notification.path += '/early-logoff';
            break;

        default:
            break;
    }

    notification.title = `Issue: ${notification.title}`;
    return notification;
}

type Props = {};
const FCM_TOKEN_SENT_KEY = 'sentToServer';

export default function useFirebaseFCM(props?: Props) {
    const navigate = useNavigate();
    const messaging = getMessaging();
    const { accounts } = useMsal();
    const { getActive } = useFeatureFlag();
    const activeFeature = getActive(FeatureName.ALERTS);
    const { onNewMessage } = useNotifyContext();

    function isTokenSentToServer() {
        return window.localStorage.getItem(FCM_TOKEN_SENT_KEY) === '1';
    }

    function setTokenSentToServer(sent: boolean) {
        window.localStorage.setItem(FCM_TOKEN_SENT_KEY, sent ? '1' : '0');
    }

    async function sendTokenToServer(token: string) {
        if (!isTokenSentToServer() && accounts[0].username) {
            console.log('Sending token to server...');
            alertController.registerReceiveAlert({ Email: accounts[0].username, Token: token }).then((res) => {
                setTokenSentToServer(true);
            });
        } else {
            console.log("Token already sent to server so won't send it again " + 'unless it changes');
        }
    }

    async function requestPermission() {
        console.log('Requesting permission...');
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            return getToken(messaging, {
                vapidKey: appConfig.vapidKey,
            })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('currentToken: ', currentToken);
                        sendTokenToServer(currentToken);
                        return currentToken;
                    } else {
                        console.log('Can not get token');
                        setTokenSentToServer(false);
                    }
                })
                .catch((error) => {
                    setTokenSentToServer(false);
                });
        } else {
            console.log('Do not have permission!');
            setTokenSentToServer(false);
        }
    }

    useEffect(() => {
        if (!activeFeature) return;
        requestPermission();

        onMessage(messaging, (payload) => {
            console.log('onMessage foreground', payload);
            const data = payload.data ? JSON.parse(payload.data.JSON) : null;
            const { title, body, path } = getNotifyMessage(data);
            onNewMessage(data);

            pushWarning(
                <div>
                    <Typography sx={{ wordBreak: 'break-word', fontWeight: 600 }}>{title}</Typography>
                    <Typography sx={{ wordBreak: 'break-word' }}>{body}</Typography>
                </div>,
                {
                    onClick(event) {
                        console.log(event);
                        navigate(path, { replace: true });
                    },
                }
            );
        });
    }, [activeFeature]);

    return {
        isTokenSentToServer,
        setTokenSentToServer,
        sendTokenToServer,
        requestPermission,
    };
}
