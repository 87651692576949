import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, OverlayView, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { LatLng } from '@pages/rota-coverage/components/GMapInterface';
import { WardenEvent } from '@LocationOps/model/WardenEvent';
import { wardenEvents } from './warden-event-components/BaseWardenEventItem';
import { Typography } from '@mui/material';

const containerStyle = {
    height: '601px',
    borderRadius: '5px',
    border: '1px solid #ddd',
};

const center = {
    lat: 54.998149293566996,
    lng: -1.5914383911306305,
};

// 54.99586779029357, -1.5873553637248625
// 54.99725806632977, -1.5798800554123573
// 54.998708214477666, -1.5837560610862136
// 54.99344214640054, -1.5912215256802738

const options = {
    strokeColor: 'rgba(26, 43, 109, 1)',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: 'rgba(26, 43, 109, 1)',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
};

type WardenTrackerMapProps = {
    wardenEvents: WardenEvent[];
    activeMarkerId?: number;
};

function WardenTrackerMap(props: WardenTrackerMapProps) {
    const { wardenEvents: propsWardenEvents } = props;
    const paths = propsWardenEvents.map((w) => ({ id: w.Id, lat: w.Latitude, lng: w.Longitude }));

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [activeMarker, setActiveMarker] = useState<number | null>(null);

    const onLoad = useCallback(
        function callback(map: google.maps.Map) {
            console.log('onload', propsWardenEvents);
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            const bounds = new google.maps.LatLngBounds();
            propsWardenEvents.forEach((e) => bounds.extend({ lat: e.Latitude, lng: e.Longitude }));
            map.fitBounds(bounds);

            setMap(map);
        },
        [propsWardenEvents]
    );

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null);
    }, []);

    const handleClickMarker = (id: number) => {
        // const { latLng } = e;
        // const lat = latLng?.lat();
        // const lng = latLng?.lng();
        // console.log(lat, lng);

        if (id === activeMarker) {
            return;
        }
        setActiveMarker(id);
    };

    useEffect(() => {
        props.activeMarkerId && setActiveMarker(props.activeMarkerId);
    }, [props.activeMarkerId]);

    useEffect(() => {
        if (map && propsWardenEvents.length) {
            onLoad(map);
        }
    }, [map, propsWardenEvents]);

    const scale = 7;

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} onLoad={onLoad} onUnmount={onUnmount}>
            {/* Child components, such as markers, info windows, etc. */}

            {propsWardenEvents.map((w, index) => {
                return (
                    <>
                        {/* <OverlayView
                            position={{ lat: w.Latitude, lng: w.Longitude }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <div style={{ borderRadius: '50%', backgroundColor: 'red' }}>
                                <h1 onClick={() => w.Id && handleClickMarker(w.Id)}>{w.Id}</h1>
                               
                            </div>
                        </OverlayView> */}

                        <Marker
                            key={index}
                            // onLoad={onLoad}
                            position={{ lat: w.Latitude, lng: w.Longitude }}
                            onClick={() => w.Id && handleClickMarker(w.Id)}
                            icon={{
                                // scaledSize: new google.maps.Size(20, 20),
                                scale: scale,
                                path: google.maps.SymbolPath.CIRCLE,
                                strokeColor: wardenEvents[w.Type]?.mapColor,
                                strokeWeight: scale * 2,
                                // size: new google.maps.Size(20, 20),
                            }}
                            label={{
                                text: `${index + 1}`,
                                color: wardenEvents[w.Type]?.textColor,
                                fontWeight: '500',
                                fontFamily: 'inherit',
                                className: 'maker-label',
                            }}
                            zIndex={index}
                        >
                            {activeMarker === w.Id ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                    {wardenEvents[w.Type]?.components.MapItem(w)}
                                </InfoWindow>
                            ) : null}
                        </Marker>

                        {/* <InfoWindow
                            //   onLoad={onLoad}
                            position={p}
                        >
                            <div style={{ background: `white`, border: `1px solid #ccc`, padding: 15 }}>
                                <h1>InfoWindow</h1>
                            </div>
                        </InfoWindow> */}
                    </>
                );
            })}

            <Polyline
                //   onLoad={onLoad}
                path={paths}
                options={options}
            />
        </GoogleMap>
    );
}

export default React.memo(WardenTrackerMap);
