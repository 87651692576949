import { BaseModel } from '@Core';
import { Warden } from './Warden';

export interface LeaveDay extends BaseModel {
    Type: LeaveDayType;
    TimeFrom: Date;
    TimeTo: Date;
    Status: LeaveDayStatus;
    Comment: string;
    Duration: LeaveDayDuration;

    WardenId: number;
    Warden?: Warden;
}

export enum LeaveDayType {
    HOLIDAY = 'holiday',
    LIEU = 'lieu',
    SICK = 'sick',
    ABSENT = 'absent',
    UN_ABSENT = 'un-absent',
    OTHER = 'other',
}

export enum LeaveDayDuration {
    FULL_DAY = 'full-day',
    HALF_DAY = 'half-day',
    HOUR = 'hour',
}

export type LeaveDayStatus = 'pending' | 'approved' | 'decline';
