import { Paging } from '@Core';
import { ClusterWithRelations, CountrySubRegion } from '@LocationOps/model';
import { ExpenseRate, Warden, WardenType } from '@WardenOps/model';
import PopUpBase from '@components/PopUpBase';
import { pushSuccess } from '@components/StyledToast';
import IcEdit from '@components/icon/IcEdit';
import IcHistoryRate from '@components/icon/IcHistoryRate';
import IcWarning from '@components/icon/IcWarning';
import StyledSelect from '@components/select-search/StyledSelect';
import { RowInformation } from '@components/utils';
import { clusterController, expensesRateController, subRegionController, wardenController } from '@controllers';
import { defaultPaging, formatDate } from '@helpers';
import useDebounceSearch from '@hooks/useDebounceSearch';
import usePopUp, { IPopUp } from '@hooks/usePopUp';
import {
    Avatar,
    Box,
    Button,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useWarden } from '..';
import FilterDateChangeRate from './personal-info/FilterDateChangeRate';
import PopUpHistoryChangeRate from './personal-info/PopUpHistoryChangeRate';
export type Filter = {
    subRegion?: any;
    cluster?: any;
    startDate?: Date;
    rate?: number;
};
export default function PersonalInformation() {
    const { t } = useTranslation();
    const { warden, setWarden } = useWarden();
    const popUpChangeRate = usePopUp();
    const popUpHistoryChangeRate = usePopUp();
    const theme = useTheme();

    const _pageSize = 10;
    const [subRegions, setSubRegions] = useState<Paging<CountrySubRegion>>(defaultPaging({ pageSize: _pageSize }));
    const [cluster, setCluster] = useState<Paging<ClusterWithRelations>>(defaultPaging({ pageSize: _pageSize }));
    const [isLoadingCluster, setIsLoadingCluster] = useState(true);
    const [isSearchedCluster, setIsSearchedCluster] = useState(false);

    const [filter, setFilter] = useState<Filter>({});

    const rate = warden.ExpenseRates?.length ? warden.ExpenseRates[0]?.Rate : 0.27;

    const handleChange = (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const isStsUser = warden.WardenType === WardenType.STS;

    const handleChangeSearchLocation = async (value: string) => {
        if (!isStsUser) {
            await subRegionController
                .list({
                    search: { content: value, fields: ['Name'] },
                    sorts: ['Name'],
                    pageSize: 1000,
                })
                .then((res) => {
                    const _filter = res.rows.find((s) => s.Id === warden.CountrySubRegionId);
                    setFilter({ subRegion: filter.subRegion || _filter });
                    setSubRegions(res);
                });
        }
    };

    const getWardenCurrentCluster = async (value: string, cluster: Paging<ClusterWithRelations>) => {
        await clusterController
            .list({
                search: { content: value, fields: ['Name'] },
                sorts: ['Name'],
                filter: {
                    Id: warden.ClusterId,
                },
                pageSize: 1,
            })
            .then((res) => {
                const _filter = res.rows.find((s) => s.Id === warden.ClusterId);
                setFilter({ cluster: _filter });
                const clusters = [...res.rows, ...cluster.rows];
                setCluster({
                    ...cluster,
                    pageSize: clusters.length,
                    rows: clusters,
                });
            });
    };

    const handleChangeSearchCluster = async (value: string) => {
        if (!isStsUser) return;
        setIsLoadingCluster(isLoadingCluster);
        await clusterController
            .list({
                search: { content: value, fields: ['Name'] },
                sorts: ['Name'],
                pageSize: _pageSize,
            })
            .then(async (res) => {
                if (value !== '') {
                    setCluster(res);
                } else {
                    const _filter = res.rows.find((s) => s.Id === warden.ClusterId);
                    if (_filter) {
                        setFilter({ cluster: filter.cluster || _filter });
                        setCluster(res);
                    } else if (isSearchedCluster) {
                        setCluster(res);
                    } else {
                        await getWardenCurrentCluster(value, res);
                    }
                }
            });
        setIsLoadingCluster(false);
    };

    const debSearchLocation = useDebounceSearch({ onSearch: handleChangeSearchLocation });
    const debSearchCluster = useDebounceSearch({ onSearch: handleChangeSearchCluster });

    const showMoreCluster = async (page: number) => {
        await clusterController
            .list({
                search: { content: debSearchCluster.value, fields: ['Name'] },
                sorts: ['Name'],
                page: page,
                pageSize: _pageSize,
            })
            .then((res) => {
                const clusters = [...cluster.rows, ...res.rows];
                const uniqueClusters = _.uniqBy(clusters, (c) => c.Id);
                setCluster({
                    ...cluster,
                    pageSize: uniqueClusters.length,
                    rows: uniqueClusters,
                });
            });
    };

    const isDisabledSaveButton = () => {
        if (isStsUser) {
            return Boolean(filter.cluster === undefined) || Boolean(warden.ClusterId === filter.cluster.Id);
        }
        return Boolean(filter.subRegion === undefined) || Boolean(warden.CountrySubRegionId === filter.subRegion.Id);
    };

    // useEffect(() => {
    //     (async () => {
    //         if (!warden.Id) return;
    //         if (isStsUser) {
    //             await clusterController.list({ sorts: ['Name'], pageSize: 1000 }).then((res) => {
    //                 const _filter = res.rows.find((s) => s.Id === warden.ClusterId);
    //                 setFilter({ cluster: _filter });
    //                 setCluster(res);
    //             });
    //         } else {
    //             await subRegionController.list({ sorts: ['Name'], pageSize: 1000 }).then((res) => {
    //                 const _filter = res.rows.find((s) => s.Id === warden.CountrySubRegionId);
    //                 setFilter({ subRegion: _filter });
    //                 setSubRegions(res);
    //             });
    //         }
    //     })();
    // }, [warden.Id]);

    return (
        <Grid container xs={12}>
            <Grid item xs={4} pr={2}>
                <Stack
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100%',
                        background: '#FAFAFA',
                        p: '32px 16px',
                        borderRadius: 1,
                    }}
                    gap={1}
                >
                    <Avatar
                        src={warden.Picture ?? ''}
                        alt=""
                        sx={{
                            width: 100,
                            height: 100,
                            border: '8px solid #E7F3F8',
                        }}
                    />
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Box
                            sx={{
                                width: 8,
                                height: 8,
                                background: '#009D4F',
                                borderRadius: '100%',
                            }}
                        />
                        <Typography variant="body1">{t('wardenDetailsPage.text.active')}</Typography>
                    </Stack>
                    <Stack width="100%" mt={3} gap={1}>
                        <RowInformation title={t('wardenDetailsPage.text.fullName')} content={warden.FullName} />
                        <RowInformation title={'Job Tile'} content={warden.JobTitle} />
                        <RowInformation title={t('wardenDetailsPage.text.email')} content={warden.Email} />
                        <RowInformation title={t('wardenDetailsPage.text.phoneNumber')} content={warden.PhoneNumber} />
                        <RowInformation title={t('wardenDetailsPage.text.postCode')} content={warden.Postcode} />
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={8}
                sx={{
                    background: '#FAFAFA',
                }}
            >
                <Stack
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        p: '32px 16px',
                        borderRadius: 1,
                    }}
                    gap={1}
                >
                    <Typography variant="h4" color="#85858A" mt={1.5}>
                        {t('wardenDetailsPage.title.workingSummary')}
                    </Typography>
                    <Stack
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            background: '#FFFFFF',
                            p: 2,
                            borderRadius: 1,
                        }}
                        mt={3}
                    >
                        <Stack width="100%" gap={1}>
                            <RowInformation
                                title={t('wardenDetailsPage.text.wardenNumber')}
                                content={warden.ExternalId}
                            />
                            <RowInformation
                                title={t('wardenDetailsPage.text.iWardenNumber')}
                                content={warden.IWardenNumber}
                            />
                            {!isStsUser && (
                                <>
                                    <RowInformation title={'Payroll ID'} content={warden.PayrollId} />
                                    <RowInformation
                                        title={t('wardenDetailsPage.text.contractedHours')}
                                        content={warden.ContractHours}
                                    />
                                </>
                            )}
                            <RowInformation
                                title={t('wardenDetailsPage.text.startDate')}
                                content={formatDate(warden.StartDate ?? moment().toDate())}
                            />
                            {!isStsUser && (
                                <RowInformation
                                    title={'Expense rate'}
                                    content={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                '& .icon': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Typography variant="h6">{`£${rate}`}</Typography>
                                            <WrapperIcons colorHover={theme.palette.secondary.main}>
                                                <Tooltip title="Edit" arrow placement="top">
                                                    <IcEdit
                                                        className="icon"
                                                        onClick={() => popUpChangeRate.setTrue()}
                                                    />
                                                </Tooltip>
                                            </WrapperIcons>
                                            <WrapperIcons colorHover="#F09453">
                                                <Tooltip title="History" arrow placement="top">
                                                    <IcHistoryRate
                                                        className="icon"
                                                        onClick={() => popUpHistoryChangeRate.setTrue()}
                                                    />
                                                </Tooltip>
                                            </WrapperIcons>
                                        </Stack>
                                    }
                                />
                            )}
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="body1">Working area:</Typography>
                                {!isStsUser && (
                                    <StyledSelect
                                        label="Sub-region"
                                        onChangeSearch={(text) => debSearchLocation.handleChange(text)}
                                        searchValue={debSearchLocation.value}
                                        data={subRegions.rows}
                                        value={filter.subRegion}
                                        hasMore={false}
                                        next={() => {}}
                                        onChange={(option) => {
                                            handleChange({ subRegion: option });
                                            debSearchLocation.setValue('');
                                        }}
                                        renderValue={(value) => {
                                            return (
                                                <Typography noWrap>
                                                    {value?.Name || 'Please select sub-region'}
                                                </Typography>
                                            );
                                        }}
                                        renderOption={(option) => (
                                            <Stack
                                                direction={'row'}
                                                sx={{
                                                    py: 1,
                                                    borderBottom: '1px solid #eee',
                                                    minHeight: '55px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Stack flex={1}>
                                                    <Typography>{option.Name}</Typography>
                                                    <Typography color="GrayText" variant="body2">
                                                        {option.CountryRegion?.Name}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                        sx={{ minWidth: 230 }}
                                    />
                                )}
                                {isStsUser && (
                                    <StyledSelect
                                        isLoading={isLoadingCluster}
                                        disabled={isLoadingCluster}
                                        label="Cluster"
                                        onChangeSearch={(text) => debSearchCluster.handleChange(text)}
                                        searchValue={debSearchCluster.value}
                                        data={cluster.rows}
                                        value={filter.cluster}
                                        hasMore={cluster.rows.length !== cluster.total}
                                        next={async () => {
                                            const page = (cluster.page += 1);
                                            await showMoreCluster(page);
                                        }}
                                        onChange={(option) => {
                                            handleChange({ cluster: option });
                                            debSearchCluster.setValue('');
                                            setIsSearchedCluster(true);
                                        }}
                                        renderValue={(value) => {
                                            return (
                                                <Typography noWrap>{value?.Name || 'Please select cluster'}</Typography>
                                            );
                                        }}
                                        renderOption={(option) => {
                                            const cluster = option as ClusterWithRelations;
                                            return (
                                                <Stack
                                                    direction={'row'}
                                                    sx={{
                                                        py: 1,
                                                        borderBottom: '1px solid #eee',
                                                        minHeight: '55px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Stack
                                                        flex={1}
                                                        direction={'row'}
                                                        alignItems={'center'}
                                                        justifyContent={'space-between'}
                                                    >
                                                        <Typography>{cluster.Name}</Typography>
                                                        {!cluster.Locations?.find(
                                                            (location) => location.isSetupSTS === true
                                                        ) && (
                                                            <Tooltip
                                                                title={
                                                                    'This cluster contain location(s) not available to STS users.'
                                                                }
                                                                placement="top"
                                                            >
                                                                <Stack alignItems={'center'} justifyContent={'center'}>
                                                                    <IcWarning />
                                                                </Stack>
                                                            </Tooltip>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            );
                                        }}
                                        sx={{ minWidth: 230 }}
                                    />
                                )}
                            </Stack>
                            <Stack width="100%" direction="row" justifyContent="flex-end" pt={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ flexBasis: 280 }}
                                    onClick={async () => {
                                        const _warden: Warden = {
                                            ...warden,
                                            CountrySubRegionId: filter.subRegion?.Id,
                                            ClusterId: filter.cluster?.Id,
                                        };
                                        await wardenController.upsert(_warden).then((res) => {
                                            setWarden(res as any);
                                            pushSuccess('Saved successfully!');
                                        });
                                    }}
                                    disabled={isDisabledSaveButton()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
            <PopUpChangeRate
                {...popUpChangeRate}
                title="Edit expense rate"
                rate={warden.ExpenseRates[0].Rate}
                effectFromDate={warden.ExpenseRates[0].EffectFromDate}
                onConfirm={async (applyFromDate, rateChanged) => {
                    const _expenseRate: ExpenseRate = {
                        WardenId: warden.Id!,
                        Rate: Number(rateChanged),
                        EffectFromDate: applyFromDate,
                    };
                    await expensesRateController.createAndGetCurrentExpenseRate(_expenseRate).then((res) => {
                        setWarden((prev) => ({ ...prev, ExpenseRates: [res] }));
                        pushSuccess('Saved successfully!');
                    });
                    popUpChangeRate.onClose();
                }}
            />
            <PopUpHistoryChangeRate {...popUpHistoryChangeRate} title="History expense rate" wardenId={warden.Id!} />
        </Grid>
    );
}

type Props = Omit<IPopUp, 'onConfirm'> & {
    title?: string;
    content?: React.ReactNode;
    rate?: number;
    effectFromDate?: Date;
    onConfirm?(applyFromDate: Date, rateChanged: number): void;
};
function PopUpChangeRate(props: Props) {
    const theme = useTheme();
    const [filter, setFilter] = useState<Filter>({});
    const prevState = useRef<Filter>();

    const MAX_VAL = 99.99;
    const withValueCap = (inputObj: any) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };

    const isPastEffectFrom = moment(filter.startDate).isBefore(moment().startOf('date'));
    const disabled =
        !filter.rate ||
        Number(filter.rate) === 0 ||
        JSON.stringify(prevState.current) === JSON.stringify(filter) ||
        isPastEffectFrom;

    const handleChange = (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const reset = () => {
        handleChange({ startDate: moment(props.effectFromDate).startOf('date').toDate(), rate: props.rate });
    };
    useEffect(() => {
        if (!props.open) return;
        handleChange({ rate: props.rate, startDate: moment(props.effectFromDate).startOf('date').toDate() });
        prevState.current = { rate: props.rate, startDate: moment(props.effectFromDate).startOf('date').toDate() };
    }, [props.open]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '500px', overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={<Typography variant="h4">{props.title}</Typography>}
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack mt={1} pb={0.5} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="h6" flex={1}>
                            Effective From:{' '}
                        </Typography>
                        <Box flex={3.5}>
                            <FilterDateChangeRate
                                startDate={filter.startDate!}
                                onChange={(startDate) => handleChange({ startDate })}
                            />
                        </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="h6" flex={1}>
                            Expense rate:{' '}
                        </Typography>
                        <Box flex={3.5}>
                            <NumericFormat
                                value={filter.rate}
                                onChange={(e: any) => handleChange({ rate: e.target.value })}
                                placeholder="Expense rate"
                                customInput={TextField}
                                fullWidth
                                InputProps={{
                                    sx: {
                                        padding: 0,
                                        height: 37,
                                        '& .MuiInputAdornment-root': {
                                            backgroundColor: '#EEEEEE',
                                            padding: '18px 16px',
                                            borderTopLeftRadius: theme.shape.borderRadius + 'px',
                                            borderBottomLeftRadius: theme.shape.borderRadius + 'px',
                                        },
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography variant="h6" fontWeight={600}>
                                                £
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                allowNegative={false}
                                decimalScale={2}
                                isAllowed={withValueCap}
                            />
                        </Box>
                    </Stack>
                    <Stack mt={3} direction={'row'} justifyContent="space-between">
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="cancel"
                            onClick={() => {
                                props.onClose?.();
                                reset();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="contained"
                            onClick={() => {
                                props.onConfirm?.(filter.startDate!, filter.rate!);
                                reset();
                            }}
                            disabled={disabled}
                        >
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
const WrapperIcons = ({ children, colorHover }: { children: React.ReactNode; colorHover: string }) => {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 26,
                height: 26,
                background: '#EEEEEE',
                borderRadius: '100%',
                color: theme.palette.grey[600],
                '&:hover': {
                    color: colorHover,
                },
                transition: '0.5s ease-in-out',
            }}
        >
            {children}
        </Stack>
    );
};
