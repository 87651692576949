import IcCalendar from '@components/icon/IcCalendar';
import IcRemove from '@components/icon/IcRemove';
import IcWden from '@components/icon/navbar/IcWden';
import PopUpWarning from '@components/PopUpWarning';
import { formatDate } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import { Avatar, Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { WardenType, WardenWithRelations } from '@WardenOps/model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import color from 'src/theme/color';

export const IconBase = ({
    title,
    colorHover,
    children,
    onClick,
}: {
    title: string;
    colorHover: string;
    children: React.ReactElement;
    onClick?(): void;
}) => {
    return (
        <Tooltip title={title} placement="top" arrow>
            {/* <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: 32,
                    height: 32,
                    background: '#EEEEEE',
                    borderRadius: 5,
                    cursor: 'pointer',
                    color: '#737378',
                    transition: '.5s',
                    '&:hover, &:hover .svgCalendar, &:hover .svgEmail': {
                        stroke: colorHover,
                        color: colorHover,
                    },
                    border: '4px solid #FAFAFA',
                }}
            >
                {children}
            </Stack> */}
            <IconButton
                onClick={onClick}
                sx={{
                    width: 32,
                    height: 32,
                    background: '#EEEEEE',
                    borderRadius: 5,
                    cursor: 'pointer',
                    color: '#737378',
                    transition: '.5s',
                    p: 0,
                    '&:hover, &:hover .svgCalendar, &:hover .svgEmail': {
                        stroke: colorHover,
                        color: colorHover,
                    },
                    border: '4px solid #FAFAFA',
                }}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
};
type Props = {
    warden: WardenWithRelations;
    onDelete?(warden: WardenWithRelations): void;
};
export default function WardenCard({ warden, ...props }: Props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const popUpWarning = usePopUp();

    const convertWardenType = {
        [WardenType.PERMANENT_STAFF]: 'Permanent',
        [WardenType.TEMPORARY_STAFF]: 'Temporary',
        [WardenType.STS]: 'STS',
        [WardenType.ITICKET_LITE]: 'iTicket Lite',
    };
    const isDeleteWarden = warden.WardenType !== WardenType.PERMANENT_STAFF;

    return (
        <Box sx={{ width: '100%', p: '16px 24px', border: '1px solid #DDDDDD', borderRadius: 2 }}>
            <Stack width="100%" direction="row" alignItems="flex-start" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={2}>
                    <Avatar src={warden.Picture} alt="" sx={{ width: 60, height: 60, border: '4px solid #FAFAFA' }} />
                    <Stack gap={1.5}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1.5}
                            sx={{
                                '& > a': {
                                    color: 'black',
                                    textDecoration: 'none',
                                },
                                mt: 0.5,
                            }}
                        >
                            <Link to={`${warden.Id}/details`}>
                                <Typography
                                    variant="h5"
                                    fontWeight={400}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: color.darkPrimary,
                                            textDecoration: 'underline',
                                        },
                                        transition: '.3s',
                                    }}
                                >
                                    {warden.FullName}
                                </Typography>
                            </Link>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    width: 75,
                                    height: 25,
                                    background: '#EEEEEE',
                                    fontSize: 12,
                                    p: 0.5,
                                    borderRadius: '5px',
                                }}
                            >
                                {convertWardenType[warden.WardenType!]}
                            </Stack>
                        </Stack>
                        <Typography variant="body1" color={theme.palette.grey[500]}>
                            {t('wardenPage.text.phone')}: {warden.PhoneNumber}{' '}
                            <Typography component="span" variant="body1" color={theme.palette.grey[500]} pl={1}>
                                {t('wardenPage.text.email')}: {warden.Email}
                            </Typography>
                        </Typography>
                        <Typography>
                            {/* {t('wardenPage.text.allocated')}: {warden.Allocated ?? 0} |  */}
                            {t('wardenPage.text.startDate')}: {formatDate(warden.StartDate ?? moment().toDate())} |{' '}
                            {t('wardenPage.text.contractedHours')}: {warden.ContractHours}h
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" gap={1}>
                    <Link to={`${warden.Id}/details`}>
                        <IconBase
                            title="View details"
                            colorHover={theme.palette.secondary.main}
                            children={<IcWden height={14} />}
                        />
                    </Link>
                    {warden.WardenType !== WardenType.STS && (
                        <Link to={`${warden.Id}/calendar-schedule`}>
                            <IconBase title="Calendar" colorHover="#F09453" children={<IcCalendar />} />
                        </Link>
                    )}
                    {isDeleteWarden && (
                        <IconBase
                            onClick={() => popUpWarning.setTrue()}
                            title="Delete"
                            colorHover={theme.palette.error.main}
                            children={<IcRemove />}
                        />
                    )}

                    {/* <Link to={`${warden.Id}/send-email`}>
                        <IconBase
                            title="Send email"
                            colorHover={theme.palette.secondary.main}
                            children={<IcEmail fontSize={18} />}
                        />
                    </Link> */}
                </Stack>
            </Stack>
            <PopUpWarning
                {...popUpWarning}
                title="Confirm"
                message={'Are you sure you want to delete this parking operative?'}
                onConfirm={() => {
                    props.onDelete?.(warden);
                    popUpWarning.onClose();
                }}
            />
        </Box>
    );
}
