import { VStack } from '@components/FlexedStack';
import { subRegionController } from '@controllers';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ListProps } from '@Core/Query';
import { defaultPaging } from '@helpers';
import { CountrySubRegion } from '@LocationOps/model';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Fade,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

type Props<T, P extends ListProps<T>> = {
    controller: BaseHttpController<T>;
    listProps: P;
};

export default function useLoadMore<T, P extends ListProps<T>>(props: Props<T, P>) {
    const [searchLoading, setSearchLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const [query, setQuery] = useState<P>({
        page: props.listProps.page || 1,
        pageSize: props.listProps.pageSize || 10,
        ...props.listProps,
    } as P);

    const [paging, setPaging] = useState(defaultPaging<T>());

    const debounceSearch = useCallback(
        debounce((text) => {
            setQuery((prev) => ({ ...prev, page: 1, search: { ...prev.search, content: text } }));
        }, 300),
        []
    );

    function handleChangeSearch(text: string) {
        setSearchLoading(true);
        setSearchValue(text);
        debounceSearch(text);
    }

    function fetchMore() {
        setQuery((prev) => ({ ...prev, page: prev.page! + 1 }));
    }

    useEffect(() => {
        props.controller
            .list(query)
            .then((res) => {
                setPaging((prev) => ({ ...res, rows: res.page === 1 ? res.rows : prev.rows.concat(res.rows) }));
            })
            .finally(() => setSearchLoading(false));
    }, [props.controller, query]);

    return {
        query,
        paging,
        searchValue,
        setQuery,
        setSearchValue,
        handleChangeSearch,
        fetchMore,
        searchLoading,
    };
}

export const ListTest = () => {
    const { fetchMore, handleChangeSearch, paging, query, searchValue, setQuery, setSearchValue, searchLoading } =
        useLoadMore({
            controller: subRegionController,
            listProps: { search: { content: '', fields: ['Name'] } } as ListProps<CountrySubRegion>,
        });

    const getItemSize = (index: number) => 40;

    const { rows } = paging;
    const rowLength = rows.length;
    const hasNextPage = paging.page < paging.totalPages;
    const itemCount = hasNextPage ? rowLength + 1 : rowLength;

    const isItemLoaded = (index: number) => !hasNextPage || index < rowLength;

    const [open, setOpen] = useState(false);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)} mouseEvent="onMouseDown" touchEvent="onTouchEnd">
            <Box width={300} sx={{ position: 'relative' }}>
                <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    autoComplete="off"
                    value={searchValue}
                    onChange={(e) => handleChangeSearch(e.target.value)}
                    onFocus={() => setOpen(true)}
                    // InputProps={{
                    //     endAdornment: searchLoading && (
                    //         <InputAdornment position="end">
                    //             <CircularProgress style={{ width: 20, height: 20 }} />
                    //         </InputAdornment>
                    //     ),
                    // }}
                ></TextField>
                /watch
                <Fade in={open}>
                    <Paper sx={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1 }}>
                        {!searchLoading && !!rowLength && (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={paging.total}
                                loadMoreItems={fetchMore}
                                threshold={3}
                            >
                                {({ onItemsRendered, ref }) => {
                                    return (
                                        <VariableSizeList
                                            ref={ref}
                                            onItemsRendered={onItemsRendered}
                                            height={40 * Math.min(6, itemCount)}
                                            itemCount={itemCount}
                                            itemSize={
                                                (index) => 40
                                                // getHeight(
                                                //     refZ.current?.offsetWidth,
                                                //     items[index] ? props.getOptionLabel(items[index]) : ''
                                                // )
                                            }
                                            width={'100%'}
                                            className="styled-scroll"
                                        >
                                            {({ index, style }) => {
                                                const option = rows[index];

                                                return !isItemLoaded(index) ? (
                                                    <VStack style={style}>
                                                        <Typography>Loading...</Typography>
                                                    </VStack>
                                                ) : (
                                                    <VStack
                                                        style={style}
                                                        sx={{
                                                            ':hover': {
                                                                backgroundColor: '#eee',
                                                            },
                                                            transition: 'all 0.2s',
                                                        }}
                                                    >
                                                        <Typography>
                                                            {index} {option.Name}
                                                        </Typography>
                                                    </VStack>
                                                );
                                            }}
                                        </VariableSizeList>
                                    );
                                }}
                            </InfiniteLoader>
                        )}

                        {searchLoading && (
                            <VStack height={40}>
                                <Typography>Loading...</Typography>
                            </VStack>
                        )}

                        {!searchLoading && !rowLength && (
                            <VStack height={40}>
                                <Typography>No options</Typography>
                            </VStack>
                        )}
                    </Paper>
                </Fade>
            </Box>
        </ClickAwayListener>
    );
};
