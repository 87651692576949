import { Paging } from '@Core';
import { ClusterWithRelations, Location, LocationWithRelations } from '@LocationOps/model';
import { Rota } from '@WardenOps/model';
import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/StyledToast';
import IcExportData from '@components/icon/IcExportData';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { formatHour } from '@components/rota-table/Shift';
import { BaseHead } from '@components/utils';
import { appConfig } from '@config';
import { clusterController } from '@controllers';
import { defaultPaging, formatDate } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import { ArrowDropDownRounded, CloseRounded, DragIndicatorRounded, VisibilityOutlined } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    IconButton,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import FilterDate from '@pages/rota-coverage/components/FilterDate';
import { Filter } from '@pages/rota-coverage/hooks/useRota';
import EmptyPage from '@pages/setting/components/EmptyPage';
import { omit, upperFirst } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import PopUpAddLocation from './components/PopUpAddLocation';
import ReactGMap from './components/ReactGMap';

import useExportData, { ExportData, ExportType } from '@hooks/useExportData';
import { LoadingButton } from '@mui/lab';
import { BlobFile, fileType } from 'src/helpers/BlobFileHepler';
import { colorLocationMapping } from '@pages/setting/location/list/components/LocationListItem';

const BaseTitle = (props: { label: string; required?: boolean; length?: number; maxLength?: number }) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems="center">
            <Typography sx={{ fontWeight: 500, fontSize: 13 }}>
                {props.label} {props.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>

            {props.maxLength && (
                <Typography sx={{ fontSize: 13 }}>
                    {props.length ?? 0}/{props.maxLength}
                </Typography>
            )}
        </Stack>
    );
};

const widths = {
    iconDrag: '5%',
    name: '22.5%',
    avg: '15%',
    dailyVisit: '15%',
    address: '35.5%',
    status: '10%',
    action: '5%',
};

export default function ClusterDetail() {
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const theme = useTheme();

    const popUpAddLocation = usePopUp();
    const popUpWarning = usePopUp();
    const collapsedMap = useBoolean();
    const collapsedList = useBoolean();

    const [name, setName] = useState(' ');
    const [cluster, setCluster] = useState<ClusterWithRelations>({} as ClusterWithRelations);
    const [wardens, setWardens] = useState<Paging<Rota>>(defaultPaging({ pageSize: 10 }));
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [indexOfCluster, setIndexOfCluster] = useState(-1);

    const [filter, setFilter] = useState<Filter>({
        startDate: moment().startOf('isoWeek').toDate(),
    });

    const exportCtx = useExportData();
    const { setState, state } = exportCtx;

    const _TimeTo = moment(filter.startDate).add(6, 'days').endOf('day').toDate();

    const handleChange = (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Admin' },
        { title: 'Cluster', href: '/admin/cluster' },
        { title: cluster.Name ?? 'Add a new cluster' },
    ];
    const form = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            clusterName: '',
            locations: [],
        },
    });

    const { fields, append, remove, replace, update, swap, move } = useFieldArray({
        name: 'locations',
        control: form.control,
    });
    const disabledSave =
        !form.formState.isDirty || !form.formState.isValid || !fields?.length || form.formState.isSubmitting;

    const getWorkingWardens = () => {
        const isNew = params.id === 'new';
        if (!params.id || isNew) return;
        clusterController
            .getWardenWorkings({
                filter: {
                    ClusterId: Number(params.id),
                    TimeFrom: {
                        $gte: filter.startDate,
                    },
                    TimeTo: {
                        $lte: _TimeTo,
                    },
                },
                page: page,
            })
            .then((res) => {
                setWardens(res);
            });
    };

    function onDragEnd(result: DropResult, provided: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        move(result.source.index, result.destination.index);
    }

    const handleSave = async (data: FormValues) => {
        const _cluster: ClusterWithRelations = {
            ...cluster,
            Name: data.clusterName,
            Locations: data.locations,
        };

        await clusterController.upsert(_cluster).then((res) => {
            pushSuccess('Saved successfully!');
            navigate(`/admin/cluster`);
        });
    };

    useEffect(() => {
        const isNew = params.id === 'new';
        if (!params.id || isNew) {
            setName('Create');
            setLoading(false);
            return;
        }
        clusterController
            .get(params.id)
            .then((res) => {
                console.log(`res`, res);
                setCluster(res);
                setName(res.Name);
                form.reset({
                    clusterName: res.Name,
                    locations: res.Locations || [],
                });
            })
            .finally(() => setLoading(false));
    }, [params.id]);

    useEffect(() => {
        getWorkingWardens();
    }, [page, filter.startDate]);

    const exportPDFCluster = () => {
        const ClusterId = Number(params.id);
        clusterController
            .downloadCluster({ ClusterId: ClusterId })
            .then((res) => {
                const nameFile = fileType(`${name}`);
                BlobFile(res, nameFile);
                pushSuccess('Downloaded successfully!');
            })
            .catch(() => {
                pushError('Downloaded fail!');
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };

    const onSelectReportType = (exportType: ExportType) => {
        exportPDFCluster();

        setState({
            exportType: exportType,
            isExportLoading: true,
        });
    };

    return (
        <ExportData.Provider value={exportCtx}>
            <div style={{ marginBottom: 10, position: 'relative', pointerEvents: loading ? 'none' : undefined }}>
                {loading && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                            top: '40%',
                            left: '50%',
                            zIndex: 2,
                        }}
                    />
                )}
                <Box sx={{ filter: loading ? 'blur(4px)' : undefined, transition: 'all 0.3s' }}>
                    <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                        <Typography fontSize={24} sx={{ minHeight: 36 }}>
                            {name}
                        </Typography>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            sx={{ minWidth: 135 }}
                            loading={state.isExportLoading}
                            loadingPosition="center"
                            endIcon={state.isExportLoading ? <></> : <IcExportData />}
                            onClick={() => onSelectReportType(ExportType.pdf)}
                        >
                            Export
                        </LoadingButton>
                    </Stack>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                    <Stack mt={3} spacing={3}>
                        <Stack>
                            <BaseTitle
                                label="Cluster name"
                                required
                                length={form.watch('clusterName').length}
                                maxLength={200}
                            />

                            <TextField
                                {...form.register('clusterName', {
                                    required: {
                                        value: true,
                                        message: 'Please enter the cluster name!',
                                    },
                                    maxLength: {
                                        message: 'Maximum length is 200 characters!',
                                        value: 200,
                                    },
                                })}
                                fullWidth
                                placeholder="Enter cluster name"
                                size="small"
                                autoComplete="off"
                                error={!!form.formState.errors.clusterName?.message}
                                helperText={form.formState.errors.clusterName?.message}
                            />
                        </Stack>

                        <Stack>
                            <Typography variant="h6" color="primary">
                                All locations
                            </Typography>

                            {!!fields.length && (
                                <Table
                                    aria-label="simple table"
                                    sx={{ tableLayout: 'auto', borderCollapse: 'collapse', width: '100%' }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={widths.iconDrag}></TableCell>
                                            <TableCell width={widths.name} sx={{ fontSize: 14 }}>
                                                Location name
                                            </TableCell>
                                            <TableCell width={widths.avg} align="center" sx={{ fontSize: 14 }}>
                                                PCN last week
                                            </TableCell>
                                            <TableCell width={widths.dailyVisit} align="center" sx={{ fontSize: 14 }}>
                                                Daily visit
                                            </TableCell>
                                            <TableCell width={widths.address} sx={{ fontSize: 14 }}>
                                                Address
                                            </TableCell>
                                            <TableCell width={widths.address} sx={{ fontSize: 14 }} align="center">
                                                Status
                                            </TableCell>
                                            <TableCell width={widths.action}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                                    {fields.map((item: LocationWithRelations, index) => (
                                                        <Draggable
                                                            key={item.Id}
                                                            draggableId={item.Id?.toString() || ''}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <TableRow
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    sx={{
                                                                        ...provided.draggableProps.style,
                                                                        backgroundColor:
                                                                            colorLocationMapping[
                                                                                item.AvgPCNVisitColor!
                                                                            ],
                                                                    }}
                                                                >
                                                                    <TableCell width={widths.iconDrag}>
                                                                        <DragIndicatorRounded
                                                                            color="disabled"
                                                                            fontSize="medium"
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        width={widths.name}
                                                                        sx={{ fontSize: 14 }}
                                                                    >
                                                                        {`${index + 1}. ${item.Name}`}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        width={widths.avg}
                                                                        sx={{ fontSize: 14 }}
                                                                    >
                                                                        {item.AmountPCNLastWeek || 0}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        width={widths.dailyVisit}
                                                                        sx={{ fontSize: 14 }}
                                                                    >
                                                                        {item.AmountVisitedLastWeek || 0}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        width={widths.address}
                                                                        sx={{ fontSize: 14 }}
                                                                    >
                                                                        {item.Address}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        width={widths.status}
                                                                        sx={{
                                                                            fontSize: 14,
                                                                            color:
                                                                                item.Assignable === 'valid'
                                                                                    ? theme.palette.secondary.main
                                                                                    : item.Assignable === 'invalid'
                                                                                    ? theme.palette.error.main
                                                                                    : theme.palette.warning.main,
                                                                        }}
                                                                    >
                                                                        {upperFirst(item.Assignable)}
                                                                    </TableCell>
                                                                    <TableCell width={widths.action}>
                                                                        <Stack
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                        >
                                                                            <a
                                                                                href={`${appConfig.publicUrl}/admin/location/${item.Id}`}
                                                                                target="blank"
                                                                                style={{ textDecoration: 'none' }}
                                                                            >
                                                                                <Tooltip
                                                                                    title="View details location"
                                                                                    arrow
                                                                                    placement="top"
                                                                                >
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        size="medium"
                                                                                        sx={{ padding: '3px' }}
                                                                                    >
                                                                                        <VisibilityOutlined
                                                                                            sx={{ fontSize: '22px' }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </a>
                                                                            <Tooltip
                                                                                title="Remove location"
                                                                                arrow
                                                                                placement="top"
                                                                            >
                                                                                <IconButton
                                                                                    color="error"
                                                                                    size="medium"
                                                                                    sx={{ padding: '3px' }}
                                                                                    onClick={() => {
                                                                                        setIndexOfCluster(index);
                                                                                        popUpWarning.setTrue();
                                                                                    }}
                                                                                >
                                                                                    <CloseRounded
                                                                                        sx={{ fontSize: '24px' }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Stack>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Table>
                            )}

                            <Stack mt={2} direction={'row'} justifyContent={fields.length ? 'flex-end' : 'flex-start'}>
                                <Button
                                    variant="outlined"
                                    onClick={popUpAddLocation.setTrue}
                                    // disabled={!CountrySubRegion?.Id}
                                >
                                    Add location +
                                </Button>
                            </Stack>

                            <Stack mt={2} sx={{ borderRadius: '10px', border: '1px solid #eee' }}>
                                <Collapse in={collapsedMap.value} collapsedSize="53px">
                                    <Box mt={2} p={2} pt={0}>
                                        <Stack
                                            flex={1}
                                            direction={'row'}
                                            alignItems="center"
                                            justifyContent={'space-between'}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => collapsedMap.toggle()}
                                        >
                                            <Typography sx={{ fontWeight: 500 }} color="primary">
                                                Movement map
                                            </Typography>

                                            <IconButton sx={{ padding: 0 }}>
                                                <ArrowDropDownRounded
                                                    sx={{
                                                        transform: `rotate(${collapsedMap.value ? 0 : 180}deg)`,
                                                        transition: '0.3s',
                                                    }}
                                                />
                                            </IconButton>
                                        </Stack>

                                        <Stack mt={2}>
                                            <ReactGMap locations={fields} />
                                        </Stack>
                                    </Box>
                                </Collapse>
                            </Stack>
                            <Stack mt={2} sx={{ borderRadius: '10px', border: '1px solid #eee' }}>
                                <Collapse in={collapsedList.value} collapsedSize="53px">
                                    <Box mt={2} p={2} pt={0}>
                                        <Stack
                                            flex={1}
                                            direction={'row'}
                                            alignItems="center"
                                            justifyContent={'space-between'}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => collapsedList.toggle()}
                                        >
                                            <Typography color="primary">{`Assigned POs in week (${formatDate(
                                                filter.startDate
                                            )} - ${formatDate(_TimeTo)})`}</Typography>

                                            <IconButton sx={{ padding: 0 }}>
                                                <ArrowDropDownRounded
                                                    sx={{
                                                        transform: `rotate(${collapsedList.value ? 0 : 180}deg)`,
                                                        transition: '0.3s',
                                                    }}
                                                />
                                            </IconButton>
                                        </Stack>
                                        {!!collapsedList.value && (
                                            <Box mt={1}>
                                                <FilterDate
                                                    startDate={filter.startDate}
                                                    onChange={(startDate) => handleChange({ startDate })}
                                                />
                                            </Box>
                                        )}

                                        <Stack alignItems="center">
                                            {!!wardens.rows.length ? (
                                                <TableContainer component={Box} sx={{ mt: 2 }}>
                                                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <BaseHead
                                                                    sx={{ pl: 0 }}
                                                                    align="left"
                                                                    title="Parking operative"
                                                                />
                                                                <BaseHead sx={{ pl: 0 }} align="center" title="Rota" />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {wardens.rows.map((item, index) => {
                                                                return (
                                                                    <TableRow key={item.Id}>
                                                                        <TableCell sx={{ pl: 0, textAlign: 'left' }}>
                                                                            <Typography variant="body1">
                                                                                {item.Warden?.FullName}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell sx={{ pl: 0, textAlign: 'center' }}>
                                                                            <Typography variant="body1">{`${formatHour(
                                                                                moment
                                                                                    .duration(
                                                                                        moment(item.TimeFrom).format(
                                                                                            'HH:mm'
                                                                                        )
                                                                                    )
                                                                                    .asMinutes()
                                                                            )} - ${formatHour(
                                                                                moment
                                                                                    .duration(
                                                                                        moment(item.TimeTo).format(
                                                                                            'HH:mm'
                                                                                        )
                                                                                    )
                                                                                    .asMinutes()
                                                                            )}, ${formatDate(
                                                                                item.TimeFrom
                                                                            )}`}</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            ) : (
                                                <EmptyPage subTitle="We could not find any wardens assigned." />
                                            )}
                                            {wardens.totalPages > 1 && (
                                                <Pagination
                                                    count={wardens.totalPages}
                                                    page={wardens.page}
                                                    onChange={(e: any, value: any) => {
                                                        setPage(value);
                                                    }}
                                                    shape="rounded"
                                                    sx={{
                                                        mt: 2,
                                                        '& .Mui-selected': {
                                                            background: '#3479BB1A !important',
                                                        },
                                                        '& .MuiPaginationItem-previousNext': {
                                                            background: '#EEEEEE',
                                                        },
                                                    }}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            components={{ previous: IcPrev, next: IcNext }}
                                                            {...item}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Stack>
                                    </Box>
                                </Collapse>
                            </Stack>
                        </Stack>

                        <Stack mt={3} direction="row" justifyContent={'space-between'}>
                            <Button variant="cancel" sx={{ minWidth: 150 }} onClick={() => navigate('/admin/cluster')}>
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                sx={{ minWidth: 150 }}
                                disabled={disabledSave}
                                onClick={form.handleSubmit(handleSave)}
                            >
                                Save
                            </Button>
                        </Stack>

                        <PopUpAddLocation
                            {...omit(popUpAddLocation, 'onConfirm')}
                            selectedLocations={fields}
                            onConfirm={(locations) => {
                                replace(locations);
                                popUpAddLocation.onClose();
                            }}
                        />

                        <PopUpWarning
                            {...popUpWarning}
                            message="Are you sure you want to remove this location from this cluster?"
                            onConfirm={() => {
                                remove(indexOfCluster);
                                popUpWarning.onClose();
                            }}
                        />
                    </Stack>
                </Box>
            </div>
        </ExportData.Provider>
    );
}

type FormValues = {
    clusterName: string;
    locations: Location[];
};
