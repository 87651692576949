import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ExpenseRate } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { IExpenseRateHttpController } from '../IExpenseRateHttpController';

export class ExpenseRateHttpController extends BaseHttpController<ExpenseRate> implements IExpenseRateHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'expense-rate', client);
    }

    async createAndGetCurrentExpenseRate(expenseRate: ExpenseRate): Promise<ExpenseRate> {
        return await this.doPost({ path: 'createAndGetCurrent', body: expenseRate }).then((res) => res.data);
    }
}
