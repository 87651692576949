import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcStaticRota(props: SvgIconProps) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.0001 3.66669H14.0001C12.7114 3.66669 11.6667 4.71136 11.6667 6.00002C11.6667 7.28868 12.7114 8.33335 14.0001 8.33335H18.0001C19.2887 8.33335 20.3334 7.28868 20.3334 6.00002C20.3334 4.71136 19.2887 3.66669 18.0001 3.66669Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7286 5.37744C10.6879 5.5918 10.6667 5.81303 10.6667 6.03923C10.6667 6.51048 10.759 6.96016 10.9266 7.37116C10.2179 7.39668 9.61219 7.43936 9.08104 7.51077C7.85048 7.67621 7.32383 7.96215 6.97631 8.30965C6.6288 8.65716 6.34287 9.18383 6.17743 10.4144C6.00425 11.7025 6 13.4293 6 16V18.6667C6 21.2373 6.00425 22.9641 6.17743 24.2523C6.34287 25.4828 6.6288 26.0095 6.97631 26.3571C7.32383 26.7045 7.85048 26.9905 9.08104 27.1559C10.3691 27.3291 12.096 27.3333 14.6667 27.3333H17.3333C19.904 27.3333 21.6308 27.3291 22.9189 27.1559C24.1495 26.9905 24.6761 26.7045 25.0237 26.3571C25.3712 26.0095 25.6571 25.4828 25.8225 24.2523C25.9957 22.9641 26 21.2373 26 18.6667V16C26 13.4293 25.9957 11.7025 25.8225 10.4144C25.6571 9.18383 25.3712 8.65716 25.0237 8.30965C24.6761 7.96215 24.1495 7.67621 22.9189 7.51077C22.3879 7.43936 21.7821 7.39668 21.0733 7.37116C21.2409 6.96016 21.3333 6.51048 21.3333 6.03923C21.3333 5.81303 21.312 5.5918 21.2713 5.37744C23.8044 5.47817 25.3515 5.80899 26.4379 6.89544C28 8.45753 28 10.9717 28 16V18.6667C28 23.6949 28 26.2092 26.4379 27.7712C24.8759 29.3333 22.3616 29.3333 17.3333 29.3333H14.6667C9.63835 29.3333 7.1242 29.3333 5.56209 27.7712C4 26.2092 4 23.6949 4 18.6667V16C4 10.9717 4 8.45753 5.56209 6.89544C6.64855 5.80899 8.19553 5.47817 10.7286 5.37744Z"
                fill="currentColor"
            />
            <path
                d="M13.3333 18L15.3333 20L19.3333 16"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
