import PopUpBase from '@components/PopUpBase';
import { formatHour } from '@components/rota-table/Shift';
import { BaseHead } from '@components/utils';
import FilterByDay from '@pages/setting/warden/detail/components/calendar-schedule/FilterByDay';
import { IPopUp } from '@hooks/usePopUp';
import { Location } from '@LocationOps/model';
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Warden } from '@WardenOps/model';
import { compact, has, isUndefined, sample, upperFirst, values } from 'lodash';
import moment from 'moment';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import EmptyPage from '../../../components/EmptyPage';
import { WeeklyCalendarShift } from './WorkCalendar';
import Map from './Map';
import * as lodash from 'lodash';

type PopUpViewMapBaseProps = IPopUp & {
    title: string;
    warden: Warden;
    selectComponent: ReactNode;
    hasResult: boolean;
    shiftsOfDay: WeeklyCalendarShift[];
};

export function PopUpViewMapBase(props: PopUpViewMapBaseProps) {
    return (
        <PopUpBase
            {...props}
            fixOverflow
            dialogProps={{
                scroll: 'paper',
                fullWidth: true,
                maxWidth: 'lg',
            }}
            onConfirm={() => {
                props.onConfirm?.();
            }}
            title={props.title}
            subTitle={`Parking operative name: ${props.warden.FullName}`}
            hideConfirm
            dialogActionsProps={{ sx: { justifyContent: 'flex-end' } }}
            desc={
                <Stack>
                    <Stack direction={'row'} spacing={2} justifyContent="flex-end">
                        {props.selectComponent}
                    </Stack>

                    <Stack mt={3} direction={'row'} spacing={3}>
                        {props.hasResult ? (
                            <>
                                <Stack height="400px" flex={0.7}>
                                    <Map
                                        locations={compact(props.shiftsOfDay.map((shift) => shift.location))}
                                        warden={props.warden}
                                    />
                                </Stack>

                                <Stack flex={0.3}>
                                    <TableContainer sx={{ mt: 2, maxHeight: '380px' }}>
                                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <BaseHead
                                                        sx={{ pl: 0, color: (theme) => theme.palette.primary.dark }}
                                                        align="left"
                                                        title="Time"
                                                    />
                                                    <BaseHead align="left" title="Location" />
                                                    <BaseHead align="right" title="Type" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.shiftsOfDay
                                                    .filter((shift) => shift.status !== 'lieu_leave')
                                                    .map((item, index) => {
                                                        return (
                                                            <TableRow key={index + 'z'}>
                                                                <TableCell sx={{ pl: 0 }}>
                                                                    <Typography variant="body1" color="primary.dark">
                                                                        <Typography variant="body1">{`${formatHour(
                                                                            item.startMin
                                                                        )} - ${formatHour(item.endMin)} `}</Typography>
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            width: 220,
                                                                            display: '-webkit-box',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            WebkitLineClamp: 1,
                                                                            overflow: 'hidden',
                                                                            wordBreak: 'break-word',
                                                                        }}
                                                                    >
                                                                        {item.location?.Name || 'Unassign'}
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell align="right">
                                                                    <Typography
                                                                        variant="body1"
                                                                        color={
                                                                            item.location?.LocationType === 'static'
                                                                                ? 'secondary'
                                                                                : item.location?.LocationType ===
                                                                                  'mobile'
                                                                                ? '#F09453'
                                                                                : 'inherit'
                                                                        }
                                                                    >
                                                                        {upperFirst(
                                                                            item.location?.LocationType || ''
                                                                        ) || 'Unknown'}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </>
                        ) : (
                            <EmptyPage
                                title="No results for working route on this day."
                                subTitle={
                                    <>
                                        We couldn't find what results for. <br />
                                        Try another day.
                                    </>
                                }
                            />
                        )}
                    </Stack>
                </Stack>
            }
        />
    );
}

type Props = IPopUp & {
    allShifts: Record<string, WeeklyCalendarShift[]>;
    selectDate?: Date;
    warden: Warden;
    startDateOfSelectedWeek?: Date;
};

export default function PopUpViewMap(props: Props) {
    console.log('_props', props);
    const [selectedDate, setSelectedDate] = useState(props.selectDate);

    const shifts = values(props.allShifts)
        .flat()
        .filter((item) => {
            const itemDate = moment(item.date).format('DD/MM/YYYY');
            const startDate = moment(selectedDate).format('DD/MM/YYYY');
            return itemDate === startDate;
        });

    const _shifts = useMemo(() => {
        const [shiftHaveClusters, shiftNoCluster] = lodash.partition(
            shifts,
            (item) => item.cluster && item.cluster.Locations
        );
        shiftHaveClusters.forEach((shift) => {
            shift.cluster?.Locations?.forEach((location: any) => {
                shiftNoCluster.push({ ...shift, location });
            });
        });

        return shiftNoCluster.sort((a, b) => a.startMin - b.startMin);
    }, [shifts]);

    useEffect(() => {
        setSelectedDate(props.selectDate);
    }, [props.selectDate]);

    const isLieuLeave = shifts.every((s) => s.status === 'lieu_leave');
    const hasResult = !!_shifts.length && !isLieuLeave;

    if (!selectedDate) return <></>;

    return (
        <PopUpViewMapBase
            {...props}
            hasResult={hasResult}
            shiftsOfDay={_shifts}
            selectComponent={
                <FilterByDay
                    startDate={selectedDate}
                    onChange={(startDate) => {
                        setSelectedDate(startDate);
                    }}
                    hideEndDate
                    startDateOfSelectedWeek={props.startDateOfSelectedWeek}
                />
            }
            title={`View working route on ${moment(selectedDate).format('dddd')}`}
        />
    );
}
