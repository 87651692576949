import { CStack } from '@components/FlexedStack';
import PopUpAssignWarden from '@components/rota-table/PopUpAssignWarden';
import PopUpEditCalendar from '@components/rota-table/PopUpEditCalendar';
import PopUpSendMail from '@components/rota-table/PopUpSendMail';
import { formatNumber } from '@helpers';
import { Avatar, Button, Fade, Stack, Typography } from '@mui/material';
import { isAfter4Week, isPast } from '@pages/setting/warden/detail/components/WorkCalendar';
import RotaTable, { Shift, ShiftStatus } from 'src/components/rota-table';
import StyledShift from 'src/components/rota-table/Shift';
import StyledSelect from 'src/components/select-search/StyledSelect';
import { v4 } from 'uuid';
import DefaultOption from '../components/DefaultOption';
import FilterDate from '../components/FilterDate';
import SelectOption from '../components/SelectOption';
import ShiftActions from '../components/ShiftActions';
import useRota, { ShiftRota } from '../hooks/useRota';
import PopUpCheckPOsEmail from '@components/rota-table/PopUpCheckPOsEmail';

type Props = {};

export default function StaticRota({}: Props) {
    const rotaHook = useRota({ locationType: 'static' });
    const isReadOnly = (option: ShiftRota) => {
        const _isPast = isPast(option.date);
        const valueMonth = isAfter4Week(option);
        const isAssigned = Boolean((option.missing ?? 0) < (option.requiredWarden ?? 0));
        const isNoShift = option.status && [ShiftStatus.assigned, ShiftStatus.unassigned].includes(option.status);
        return (_isPast && !isAssigned) || !isNoShift || valueMonth || !rotaHook.filter.location;
    };

    return (
        <Stack>
            <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <Typography variant="h3">Static rota coverage</Typography>
                <FilterDate
                    startDate={rotaHook.filter.startDate}
                    onChange={(startDate) => rotaHook.handleChange({ startDate })}
                />
            </Stack>

            <Stack mt={5} direction="row" spacing={1}>
                <Stack direction={'row'} spacing={1} flex={1}>
                    <Stack flex={0.3}>
                        <StyledSelect
                            label="Region"
                            disabledSearch
                            sx={{ width: '100%' }}
                            data={rotaHook.state.regions.rows}
                            value={rotaHook.filter.region}
                            hasMore={rotaHook.hasMore('regions')}
                            next={rotaHook.fetchMoreRegions}
                            onChange={(option) => {
                                rotaHook.handleChange({ region: option, subRegion: undefined, location: undefined });
                            }}
                            renderValue={(value) => {
                                return (
                                    <Typography noWrap>
                                        {value?.Name || `All regions (${rotaHook.state.regions.total})`}
                                    </Typography>
                                );
                            }}
                            renderDefaultOption={() => <DefaultOption title="All regions" />}
                            renderOption={(option) => <SelectOption option={option} />}
                        />
                    </Stack>
                    <Stack flex={0.3}>
                        <StyledSelect
                            label="Sub-region"
                            sx={{ width: '100%' }}
                            // listProps={{ style: { width: 250 } }}
                            searchValue={rotaHook.subRegionSearch}
                            onChangeSearch={(text) => rotaHook.setSubRegionSearch(text)}
                            data={rotaHook.filteredSubRegion}
                            value={rotaHook.filter.subRegion}
                            hasMore={rotaHook.hasMore('subRegions')}
                            next={rotaHook.fetchMoreSubRegions}
                            onChange={(option) => {
                                rotaHook.handleChange({ subRegion: option, location: undefined });
                            }}
                            renderValue={(value) => {
                                return (
                                    <Typography noWrap>
                                        {value?.Name || `All sub-regions (${rotaHook.state.subRegions.total})`}
                                    </Typography>
                                );
                            }}
                            renderDefaultOption={() => <DefaultOption title="All sub-regions" />}
                            renderOption={(option) => <SelectOption option={option} />}
                        />
                    </Stack>
                    <Stack flex={0.4}>
                        <StyledSelect
                            label="Location"
                            sx={{ width: '100%' }}
                            data={rotaHook.state.locations.rows}
                            value={rotaHook.filter.location}
                            hasMore={rotaHook.hasMore('locations')}
                            next={rotaHook.fetchMoreLocations}
                            onChange={(option) => {
                                rotaHook.handleChange({ location: option });
                            }}
                            searchValue={rotaHook.debSearchLocation.value}
                            onChangeSearch={(text) => {
                                rotaHook.debSearchLocation.handleChange(text);
                            }}
                            renderValue={(value) => {
                                return (
                                    <Typography noWrap>
                                        {value?.Name || `All locations (${rotaHook.state.locations.total})`}
                                    </Typography>
                                );
                            }}
                            renderDefaultOption={() => <DefaultOption title="All locations" />}
                            renderOption={(option) => <SelectOption option={option} />}
                        />
                    </Stack>
                </Stack>
                <Button variant="contained" sx={{ flexBasis: 180 }} onClick={() => rotaHook.popUpSendMail.setTrue()}>
                    Send rota
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        minWidth: 260,
                    }}
                    onClick={() => rotaHook.popUpCheckPOsEmail.setTrue()}
                >
                    Check POs email read status
                </Button>
            </Stack>

            {rotaHook.filter.location && (
                <Fade in={true}>
                    <div>
                        <CStack mt={2} sx={{ bgcolor: 'error.light', borderRadius: '5px', py: 1.5 }}>
                            <Typography color="error.main">
                                You can assign a parking operative for a period by click on yellow area.
                            </Typography>
                        </CStack>
                    </div>
                </Fade>
            )}

            {!rotaHook.filter.location && (
                <Fade in={!rotaHook.loading}>
                    <Stack
                        mt={2}
                        direction={'row'}
                        justifyContent="space-between"
                        sx={{
                            '& > *': {
                                color: rotaHook.fullCoverage ? 'primary.main' : 'error.main',
                            },
                        }}
                    >
                        {rotaHook.fullCoverage ? (
                            <>
                                <Typography color="success.main">All locations are full-coverage!</Typography>
                                <Typography color="success.main">
                                    Total assigned parking operatives: {formatNumber(rotaHook.assignedWarden)}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography>NOT full-coverage hours: {formatNumber(rotaHook.missingHours)}</Typography>
                                <Typography>
                                    Est. required parking operatives: {formatNumber(rotaHook.missingWarden)}
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Fade>
            )}

            <Stack my={3}>
                <RotaTable
                    hideLieuLeave
                    startDate={rotaHook.filter.startDate}
                    loading={rotaHook.loading}
                    data={rotaHook.converted}
                    renderOption={(option, index, arr) => {
                        return (
                            <StyledShift
                                readonlyOutside
                                readonly={isReadOnly(option)}
                                onAddPeriod={(shift) => {
                                    rotaHook.setSelected((prev) => ({ ...prev, shift }));
                                    if ((shift.missing || 0) < (shift.requiredWarden || 0)) {
                                        rotaHook.popUpEditCalendar.setTrue();
                                    } else {
                                        rotaHook.popUpAssignWarden.setTrue();
                                    }
                                }}
                                shift={option}
                                toolTipProps={{
                                    title: !!option.rota?.Wardens?.length && rotaHook.filter.location && (
                                        <Stack
                                            direction={'row'}
                                            sx={{ background: '#85858A', borderRadius: '100px', p: '3px' }}
                                            spacing={'3px'}
                                        >
                                            {option.rota?.Wardens?.map((w) => {
                                                return (
                                                    <Avatar
                                                        key={v4()}
                                                        src={w.Picture}
                                                        sx={{ width: 30, height: 30 }}
                                                    ></Avatar>
                                                );
                                            })}
                                        </Stack>
                                    ),
                                }}
                                renderActions={(shift) => (
                                    <>
                                        {!isPast(shift.date) ? (
                                            <ShiftActions
                                                shift={shift}
                                                onEdit={(shift) => {
                                                    rotaHook.setSelected((prev) => ({ ...prev, shift }));
                                                    rotaHook.popUpEditCalendar.setTrue();
                                                }}
                                                onAddWarden={(shift) => {
                                                    rotaHook.setSelected((prev) => ({ ...prev, shift }));
                                                    rotaHook.popUpAssignWarden.setTrue();
                                                }}
                                            />
                                        ) : undefined}
                                    </>
                                )}
                                renderTitleShift={(shift) => `${shift?.missing ?? 0}(${shift?.requiredWarden ?? 0})`}
                            />
                        );
                    }}
                />
            </Stack>

            {rotaHook.selected.shift && rotaHook.filter.location && (
                <PopUpEditCalendar
                    {...rotaHook.popUpEditCalendar}
                    shift={rotaHook.selected.shift}
                    location={rotaHook.filter.location}
                    onConfirm={(r) => {
                        rotaHook.reGetList();
                        rotaHook.popUpEditCalendar.onClose();
                    }}
                    isPast={isPast(rotaHook.selected.shift?.date)}
                />
            )}

            {rotaHook.selected.shift && rotaHook.filter.location && (
                <PopUpAssignWarden
                    {...rotaHook.popUpAssignWarden}
                    location={rotaHook.filter.location}
                    shift={rotaHook.selected.shift}
                    startDate={rotaHook.filter.startDate}
                    shiftsByWeekday={rotaHook.converted}
                    onConfirmed={(r) => {
                        rotaHook.reGetList();
                        rotaHook.popUpAssignWarden.onClose();
                    }}
                    onTimeChange={(start, end) =>
                        rotaHook.setSelected((prev) => {
                            const shift: Shift = {
                                ...prev.shift,
                                startMin: start!,
                                endMin: end!,
                            };

                            return { ...prev, shift } as any;
                        })
                    }
                />
            )}
            <PopUpSendMail
                startOfWeek={rotaHook.filter.startDate}
                open={rotaHook.popUpSendMail.value}
                onClose={rotaHook.popUpSendMail.setFalse}
                onConfirm={rotaHook.handleSendMail}
            />
            <PopUpCheckPOsEmail {...rotaHook.popUpCheckPOsEmail} startOfWeek={rotaHook.filter.startDate} />
        </Stack>
    );
}
