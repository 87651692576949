import { BaseModel } from '@Core';
import styled from '@emotion/styled';
import { ArrowDropDownRounded } from '@mui/icons-material';
import {
    Box,
    BoxProps,
    CircularProgress,
    ClickAwayListener,
    Fade,
    IconButton,
    InputAdornment,
    Stack,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import { theme } from '@theme';
import { isEqual, isUndefined } from 'lodash';
import { ReactNode, useEffect, useId, useRef, useState } from 'react';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';
import color from 'src/theme/color';
import { v4 } from 'uuid';

const selectedColor = '#FAFAFA';

const SBox = styled(Box)({
    height: '40px',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
    minWidth: 150,
    border: '1px solid rgba(0, 0, 0, 0.23)',
});

export const BoxOption = styled(Box)({
    position: 'absolute',
    top: 'calc(100% + 8px)',
    border: '1px solid #eee',
    borderRadius: 'inherit',
    right: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 1199,
    // maxHeight: 300,
    // overflowY: 'auto',
});

export const Option = styled(Stack)({
    transition: '0.25s',
    ':hover': {
        backgroundColor: selectedColor,
    },
    padding: '0px 12px',
    marginTop: '-1px',
    // borderBottom: "1px solid #eee",
});

const SIconButton = styled(IconButton)({
    position: 'absolute',
    right: 4,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
});

type OwnProps<T> = {
    renderDefaultOption?(): ReactNode;
    renderOption(option: T): JSX.Element;
    onChange(option?: T): any;
    data: T[];
    value?: T;
    renderValue(selectedValue?: T): JSX.Element;
    searchValue?: string;
    onChangeSearch?(text: string): void;
    disabledSearch?: boolean;
    sx?: SxProps;
    label?: React.ReactNode;
    listProps?: BoxProps;
    isEqual?(option: T, value?: T): boolean;
    disabled?: boolean;
    optionHeight?: number;
    renderNoOption?: boolean;
    emptyText?: string;
    loadingSearchOption?: boolean;
    isLoading?: boolean;
    totalItem?: number;
    maxItemPopover?: number;
    isError?: boolean;
} & Pick<Props, 'next' | 'hasMore'>;

export default function StyledSelect<T extends BaseModel>(props: OwnProps<T>) {
    const [open, setOpen] = useState(false);
    const isEmpty = props.totalItem !== undefined && !props.totalItem;
    const preventMouseEvent = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const id = useId();

    const allOptionHeight = 46;
    const searchHeight = 51;
    const disabledSearch = props.disabledSearch || isEmpty || (props.totalItem !== undefined && props.totalItem < 4);
    console.log(`props.totalItem`, props.totalItem);
    const hasDefaultOption = !!props.renderDefaultOption?.() && !isEmpty;

    const getPopOverHeight = () => {
        let height = 98;

        // Search
        if (disabledSearch) height -= searchHeight;

        // Default option
        if (!hasDefaultOption) height -= allOptionHeight;

        // Data
        if (props.data.length)
            height += Math.min(props.maxItemPopover || 4, props.data.length) * (props.optionHeight || 55);
        else {
            // No options
            height += allOptionHeight;
        }

        return height;
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setOpen(false)}>
            <Box width={'150px'} sx={props.sx}>
                <SBox
                    onClick={() => !props.disabled && setOpen(!open)}
                    sx={
                        props.disabled
                            ? {
                                  cursor: 'initial !important',
                                  userSelect: 'none',
                                  color: 'rgba(0, 0, 0, .38)',

                                  //   filter: 'contrast(0.1)',

                                  //   backgroundColor: '#ffffff54',
                                  //   border: 'none !important',
                              }
                            : {
                                  border: props.isError
                                      ? `1px solid ${theme.palette.error.main} !important`
                                      : '1px solid rgba(0, 0, 0, 0.23)',
                                  //   border: open ? `2px solid ${color.primary} !important` : undefined,
                                  //   outline: open ? `1px solid  ${color.primary}` : undefined,
                              }
                    }
                >
                    {props.label && (
                        <Typography
                            component={'label'}
                            sx={{
                                top: 0,
                                position: 'absolute',
                                transform: 'translate(-13px, -12px) scale(0.75)',
                                backgroundColor: 'white',
                                px: '6px',
                            }}
                        >
                            {props.label}
                        </Typography>
                    )}
                    <Stack sx={{ maxWidth: 'calc(100% - 35px)', overflow: 'hidden' }}>
                        {props.renderValue(props.value)}
                    </Stack>
                    {props.isLoading ? (
                        <SIconButton disabled sx={{ mr: '12px' }} size="small">
                            <CircularProgress size={'1rem'} />
                        </SIconButton>
                    ) : (
                        <SIconButton size="small">
                            <ArrowDropDownRounded
                                fontSize="small"
                                sx={{
                                    transform: open ? 'rotate(0deg)' : 'rotate(-180deg)',
                                    transition: '0.3s',
                                }}
                            />
                        </SIconButton>
                    )}

                    <Fade in={open}>
                        <BoxOption {...props.listProps} id={id}>
                            <InfiniteScroll
                                dataLength={props.data.length}
                                next={props.next}
                                hasMore={props.hasMore}
                                loader={
                                    <Option sx={{ bgcolor: 'unset !important', py: '8px !important' }}>
                                        <Typography>Loading...</Typography>
                                    </Option>
                                }
                                scrollableTarget={id}
                                // style={{ maxHeight: '300px' }}
                                height={getPopOverHeight()}
                            >
                                {/* Default option */}
                                {hasDefaultOption && (
                                    <Option
                                        position={'sticky'}
                                        top={0}
                                        zIndex={1}
                                        onClick={(e) => {
                                            setOpen(false);
                                            props.onChange?.();
                                        }}
                                        bgcolor={props.value ? 'white' : selectedColor}
                                        color={props.value ? theme.palette.text.primary : theme.palette.primary.main}
                                    >
                                        {props.renderDefaultOption?.()}
                                    </Option>
                                )}

                                {/* Search */}
                                {!disabledSearch && (
                                    <Option
                                        sx={{
                                            bgcolor: 'white !important',
                                            p: '6px !important',
                                            position: 'sticky',
                                            top: !props.renderDefaultOption ? 0 : allOptionHeight,
                                            zIndex: 1,
                                        }}
                                        onClick={(e) => {
                                            preventMouseEvent(e);
                                        }}
                                    >
                                        <TextField
                                            placeholder="Search"
                                            size="small"
                                            autoComplete="off"
                                            value={props.searchValue}
                                            onChange={(e) => props.onChangeSearch?.(e.target.value)}
                                            InputProps={{
                                                endAdornment: props.loadingSearchOption && (
                                                    <InputAdornment position="end">
                                                        <CircularProgress size={'1rem'} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Option>
                                )}

                                {!props.data.length && (
                                    <Stack
                                        sx={{
                                            height: allOptionHeight,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            pl: '12px',
                                        }}
                                        onClick={(e) => {
                                            preventMouseEvent(e);
                                        }}
                                    >
                                        <Typography color={'gray'}>
                                            {isEmpty ? props.emptyText : 'No options'}
                                        </Typography>
                                    </Stack>
                                )}

                                {props.data.map((a) => {
                                    const selected = props.isEqual
                                        ? props.isEqual(a, props.value)
                                        : props.value && isEqual(a, props.value);

                                    return (
                                        <Option
                                            key={v4()}
                                            onClick={(e) => {
                                                preventMouseEvent(e);
                                                setOpen(false);
                                                props.onChange?.(a);
                                            }}
                                            bgcolor={selected ? selectedColor : undefined}
                                            color={selected ? theme.palette.primary.main : theme.palette.text.primary}
                                        >
                                            {props.renderOption(a)}
                                        </Option>
                                    );
                                })}
                            </InfiniteScroll>
                        </BoxOption>
                    </Fade>
                </SBox>
            </Box>
        </ClickAwayListener>
    );
}
