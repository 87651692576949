import StyledDateRangePicker from '@components/StyledDateRangePicker';
import { wardenEventController } from '@controllers';
import { Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { WardenEvent } from '@LocationOps/model/WardenEvent';
import { Box, Stack, Typography } from '@mui/material';
import LoadingWrap from '@pages/rota-coverage/components/LoadingWrap';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DMY } from 'src/config/moment';
import { useBoolean } from 'usehooks-ts';
import EmptyPage from '../../../components/EmptyPage';
import { useWarden } from '..';
import { wardenEvents } from './ticket-tracker/warden-event-components/BaseWardenEventItem';
import WardenTrackerMap from './ticket-tracker/WardenTrackerMap';

type Props = {};

export default function ITicketTrackerTab({}: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { warden, setWarden } = useWarden();
    const [pagingWardenEvent, setPagingWardenEvent] = useState<Paging<WardenEvent>>(defaultPaging());
    const loading = useBoolean(true);
    const [activeMarkerId, setActiveMarkerId] = useState<number>();

    const query = {
        from: searchParams.get('from') || moment().startOf('day').toISOString(),
        to: searchParams.get('to') || moment().endOf('day').toISOString(),
    };
    console.log(`query`, query);

    useEffect(() => {
        loading.setTrue();
        wardenEventController
            .filterByTime({
                page: 1,
                pageSize: 10000,
                filter: {
                    WardenId: warden.Id!,
                },
                From: moment(query.from).startOf('day').toDate(),
                To: moment(query.to).endOf('day').toDate(),
            })
            .then((res) => {
                setPagingWardenEvent(res);
            })
            .finally(() => {
                loading.setFalse();
            });
    }, [query.from, query.to, warden.Id]);

    return (
        <Box>
            <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <Typography sx={{ fontSize: 17 }}>iTicket tracker</Typography>

                <Stack sx={{ minWidth: '230px' }}>
                    <StyledDateRangePicker
                        value={{
                            startDate: moment(query.from).startOf('day').toDate(),
                            endDate: moment(query.to).endOf('day').toDate(),
                        }}
                        onChange={({ startDate, endDate }) => {
                            setSearchParams({
                                from: moment(startDate).toISOString(),
                                to: moment(endDate).toISOString(),
                            });
                        }}
                        textFieldProps={{ label: 'Time range', fullWidth: true }}
                    />
                </Stack>
            </Stack>

            <LoadingWrap loading={loading.value}>
                {!pagingWardenEvent.rows.length ? (
                    <EmptyPage stackProps={{ height: 601 }} />
                ) : (
                    <Stack
                        direction={'row'}
                        justifyContent="center"
                        mt={3}
                        spacing={2}
                        sx={{ height: '601px', overflowY: 'hidden' }}
                    >
                        <Box flex={1}>
                            <WardenTrackerMap wardenEvents={pagingWardenEvent.rows} activeMarkerId={activeMarkerId} />
                        </Box>

                        <Stack className="styled-scroll" spacing={2} sx={{ minWidth: 400, overflowY: 'auto', pr: 1 }}>
                            {pagingWardenEvent.rows.map((wardenEvent, index) => {
                                const Element = wardenEvents[wardenEvent.Type]?.components.ListItem;
                                if (!Element) return null;

                                return (
                                    <Element
                                        key={index}
                                        index={index}
                                        item={wardenEvent}
                                        onSelectEvent={(item) => setActiveMarkerId(item.Id)}
                                    />
                                );
                            })}
                        </Stack>
                    </Stack>
                )}
            </LoadingWrap>
        </Box>
    );
}
