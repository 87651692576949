import { Shift } from '@components/rota-table';
import { formatHour } from '@components/rota-table/Shift';
import ShiftRange from '@components/rota-table/ShiftRange';
import StyledSelect from '@components/select-search/StyledSelect';
import { appConfig } from '@config';
import { rotaCoverageController } from '@controllers';
import { getTimeByMin } from '@helpers';
import { GetLocationMissingWarden } from '@LocationOps/controller/IRotaCoverageHttpController';
import { ClusterWithRelations, Location } from '@LocationOps/model';
import {
    FormControl,
    FormControlLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption, { WrapSelectOption } from '@pages/rota-coverage/components/SelectOption';
import { ShiftWithStatus } from '@pages/setting/location/detail/components/ShiftItem';
import { isPast } from '@pages/setting/warden/detail/components/WorkCalendar';
import { Warden } from '@WardenOps/model';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useIsFirstRender } from 'usehooks-ts';

export type Value = Pick<Shift, 'startMin' | 'endMin' | 'breakHours'>;
type OwnProps = {
    shift: ShiftWithStatus;
    onChange(value: ShiftWithStatus): void;
    onDelete?(shift: ShiftWithStatus): void;
    toggleOpen?(shift: ShiftWithStatus): void;
    warden?: Warden;
    isPast?: boolean;
    clusters: ClusterWithRelations[];
};

export default function BaseShift(props: OwnProps) {
    console.log('props', props);
    const { hour: startHour, min: startMin } = getTimeByMin(props.shift.startMin);
    const { hour: endHour, min: endMin } = getTimeByMin(props.shift.endMin);

    const selectedLocationType = props.shift.type || 'static';

    const [pagingLocation, setPagingLocation] = useState<Location[]>([]);
    const [locationSearch, setLocationSearch] = useState<string>();

    const locationSelected = pagingLocation.filter((location) => selectedLocationType === location.LocationType);
    const filteredLocation = locationSelected.filter((location) =>
        location.Name.toLocaleLowerCase().includes((locationSearch || '').toLocaleLowerCase())
    );
    const [searchCluster, setSearchCluster] = useState('');
    const handleChangeSearchCluster = (text: string) => {
        setSearchCluster(text);
    };

    const filteredCluster = props.clusters.filter((cluster) =>
        cluster.Name.toLocaleLowerCase().includes((searchCluster || '').toLocaleLowerCase())
    );

    const isPastShift = isPast(props.shift.date);
    const isInvalidLocation = props.shift.location && props.shift?.location?.Assignable === 'invalid';
    const isInvalidCluster = props.shift.cluster && props.shift?.cluster?.Assignable === 'invalid';

    const timeFrom = !isNaN(startHour)
        ? moment(props.shift.date).startOf('day').hour(startHour).minutes(startMin).toDate()
        : undefined;

    const timeEnd =
        endHour === 24
            ? moment(props.shift.date).endOf('date').toDate()
            : moment(props.shift.date)
                  .startOf('day')
                  .hour(endHour ?? 0)
                  .minutes(endMin)
                  .toDate();

    const timeTo = !isNaN(endHour) ? timeEnd : undefined;

    const disabledBreakTime = (time: number) => {
        return endHour - startHour <= time;
    };

    const handleChange = (value: Partial<Value>) => {
        props.onChange({ ...props.shift, ...value });
    };

    const onChangeLocationType = (event: any) => {
        const type = event.target.value;
        const _shift = { ...props.shift, type: type };

        props.onChange(_shift);
    };

    const getLocation = (listProps: Partial<GetLocationMissingWarden>) => {
        console.log(
            'getLocation',
            !props.warden?.CountrySubRegionId || !timeFrom || !timeTo,
            props.warden?.CountrySubRegionId,
            timeFrom,
            timeTo
        );
        if (!props.warden?.CountrySubRegionId || !timeFrom || !timeTo) return;

        return rotaCoverageController.getLocationsFreeTime({
            page: 1,
            pageSize: 1000,
            TimeFrom: timeFrom,
            TimeTo: timeTo,
            search: { content: locationSearch || '', fields: ['Name'] },
            filter: { CountrySubRegionId: props.warden?.CountrySubRegionId },
            sorts: ['Name'],
            ...listProps,
            WardenId: Number(props.warden.Id),
        });
    };

    const isFirst = useIsFirstRender();

    useEffect(() => {
        if (isPastShift || isNaN(props.shift.startMin) || isNaN(props.shift.endMin)) return;
        setLocationSearch('');
        getLocation({})?.then((locations) => {
            setPagingLocation(locations);
            if (isFirst) return;
            const location = locations.find((l) => l.Id === props.shift.location?.Id);
            if (props.shift.location && !location) {
                props.onChange({ ...props.shift, location: undefined });
            }
        });

        console.log('_base shift mount');

        return () => {
            console.log('_base shift unmount');
        };
    }, [props.shift.startMin, props.shift.endMin, props.shift.date]);

    return (
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems={'flex-start'} gap={2}>
            <Stack width="100%" sx={{ cursor: 'pointer' }} justifyContent="space-between">
                <Typography>
                    Time shift
                    <span style={{ color: '#EF1320', textShadow: '#EF1320' }}> *</span>
                </Typography>
                <ShiftRange
                    fullWidth
                    value={{ startMin: props.shift.startMin, endMin: props.shift.endMin }}
                    onChange={(value) => {
                        setLocationSearch('');
                        if (value.endMin! - value.startMin! <= 120) {
                            handleChange({ startMin: value.startMin, endMin: value.endMin, breakHours: undefined });
                        } else {
                            handleChange({ startMin: value.startMin, endMin: value.endMin });
                        }
                        console.log(value);
                    }}
                    startMins={props.shift.validStartMins || []}
                    endMins={props.shift.validEndMins || []}
                    disabled={props.isPast}
                />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} gap={3.5}>
                    <Typography variant="body1">Lunch/Break: </Typography>
                    <FormControl fullWidth sx={{ '& .MuiSvgIcon-root': { fontSize: '1.25rem' } }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            MenuProps={{
                                sx: {
                                    maxHeight: 200,
                                },
                            }}
                            sx={{ ml: '4px' }}
                            value={props.shift.breakHours ?? 0}
                            onChange={(value) => {
                                handleChange({ breakHours: value.target.value as any });
                            }}
                            disabled={props.isPast}
                        >
                            {[0, 1, 2].map((t, index) => {
                                return (
                                    <MenuItem key={index + 'start'} value={t} disabled={disabledBreakTime(t)}>
                                        {formatHour(t * 60)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack mt={2}>
                    <Typography variant="body1">Assign area: </Typography>
                    <FormControl disabled={props.isPast} sx={{ '& .MuiFormControlLabel-label': { width: 43 } }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={selectedLocationType}
                            onChange={onChangeLocationType}
                        >
                            <Stack gap={2}>
                                <Stack direction="row" alignItems="flex-start" gap={3} width="100%">
                                    <FormControlLabel
                                        value={'static'}
                                        control={<Radio />}
                                        label={'Static'}
                                        sx={{ width: 100 }}
                                    />
                                    <Stack width="100%">
                                        <StyledSelect
                                            isEqual={(option, value) => option.Id === value?.Id}
                                            totalItem={locationSelected.length}
                                            emptyText="Allocated shift does not meet any location operational requirements."
                                            sx={{ width: '100%' }}
                                            data={filteredLocation}
                                            value={props.shift.location}
                                            hasMore={false}
                                            next={() => {}}
                                            onChange={(option) => {
                                                props.onChange({ ...props.shift, location: option });
                                            }}
                                            onChangeSearch={(text) => setLocationSearch(text)}
                                            searchValue={locationSearch}
                                            renderValue={(value) => {
                                                return (
                                                    <Typography noWrap>
                                                        {value?.Name || `Enter/select location name`}
                                                    </Typography>
                                                );
                                            }}
                                            disabled={selectedLocationType === 'cluster' || props.isPast}
                                            renderDefaultOption={() => <DefaultOption title="Unassigned" />}
                                            renderOption={(option) => <SelectOption hideIcon={false} option={option} />}
                                        />
                                        {isInvalidLocation && (
                                            <AssignableShiftStatus message="The selected location is invalid." />
                                        )}
                                    </Stack>
                                </Stack>

                                <Stack direction="row" alignItems="flex-start" gap={3} width="100%">
                                    <FormControlLabel
                                        value={'cluster'}
                                        control={<Radio />}
                                        label={'Cluster'}
                                        sx={{ width: 100 }}
                                    />
                                    <Stack width="100%">
                                        <StyledSelect
                                            maxItemPopover={3}
                                            totalItem={props.clusters.length}
                                            emptyText="Allocated shift does not meet any cluster operational requirements."
                                            sx={{ width: '100%' }}
                                            data={filteredCluster}
                                            value={props.shift.cluster}
                                            hasMore={false}
                                            next={() => {}}
                                            isEqual={(option, value) => option.Id === value?.Id}
                                            onChange={(option) => {
                                                props.onChange({ ...props.shift, cluster: option });
                                            }}
                                            onChangeSearch={(text) => handleChangeSearchCluster?.(text)}
                                            searchValue={searchCluster}
                                            renderValue={(value) => {
                                                return (
                                                    <Typography noWrap>
                                                        {value?.Name || `Enter/select cluster name`}
                                                    </Typography>
                                                );
                                            }}
                                            disabled={selectedLocationType === 'static' || props.isPast}
                                            renderDefaultOption={() => <DefaultOption title="Unassigned" />}
                                            renderOption={(option) => (
                                                <WrapSelectOption sx={{ justifyContent: 'space-between' }}>
                                                    <Stack>
                                                        <Typography>{option.Name}</Typography>
                                                    </Stack>
                                                    <Stack>
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    {option.Locations?.map((l, index) => (
                                                                        <>
                                                                            <Typography key={l.Id} variant="caption">
                                                                                {index + 1}.{' '}
                                                                                <span style={{ marginLeft: 4 }}>
                                                                                    {l.Name}
                                                                                </span>
                                                                            </Typography>{' '}
                                                                            <br />
                                                                        </>
                                                                    ))}
                                                                </>
                                                            }
                                                            disableInteractive
                                                            placement="right"
                                                            arrow
                                                        >
                                                            <Link
                                                                sx={{ fontSize: '12px' }}
                                                                href={`${appConfig.publicUrl}/admin/cluster/${option.Id}`}
                                                                rel="noopener noreferrer"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    window.open(
                                                                        `${appConfig.publicUrl}/admin/cluster/${option.Id}`,
                                                                        '_blank',
                                                                        'noopener,noreferrer'
                                                                    );
                                                                }}
                                                            >
                                                                View locations ({option.Locations?.length})
                                                            </Link>
                                                        </Tooltip>
                                                    </Stack>
                                                </WrapSelectOption>
                                            )}
                                        />
                                        {isInvalidCluster && (
                                            <AssignableShiftStatus message="The selected cluster is invalid." />
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </Stack>
        </Stack>
    );
}
const AssignableShiftStatus = ({ message }: { message: string }) => {
    return (
        <Stack direction="row" alignItems="center" sx={{ color: (theme) => theme.palette.error.main }} spacing={0.2}>
            <MdErrorOutline style={{ fontSize: 16, marginTop: 2 }} />
            <Typography variant="body1">{message}</Typography>
        </Stack>
    );
};
