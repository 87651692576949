import { AlertType } from '@WardenOps/model';
import Checkbox from '@components/Checkbox';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { formatHour } from '@components/rota-table/Shift';
import { BaseHead, CellOfRow } from '@components/utils';
import { getDifferenceBetweenDate, getMin } from '@helpers';
import {
    Box,
    Button,
    CircularProgress,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import moment from 'moment';
import { useNotifyContext } from 'src/contexts/NotificationProvider';
import useAlerts from '../hooks/useAlerts';
import PopUpRemoveAlert from './PopUpRemoveAlert';

export default function SiteLogin() {
    const {
        state,
        loading,
        isEmpty,
        setPage,
        handleRemove,
        handleRemoveAll,
        removeType,
        setRemoveType,
        alertId,
        setAlertId,
        popUpRemoveAlert,
        selectedRows,
        setSelectedRows,
        isSelectedAllRows,
        handleSelectedAllRows,
        handleSelectedRows,
        getListAlert,
    } = useAlerts({
        alertType: AlertType.SiteLateLogin,
    });
    const { filter } = useNotifyContext();

    return (
        <Box>
            <TableContainer component={Box}>
                {loading ? (
                    <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <Stack>
                        {isEmpty && <EmptyPage />}
                        {!isEmpty && (
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5}>
                                    <Button
                                        variant="errorContained"
                                        sx={{ px: 1, py: 0.8 }}
                                        onClick={() => {
                                            setRemoveType('all');
                                            popUpRemoveAlert.setTrue();
                                        }}
                                    >
                                        Remove all
                                    </Button>
                                    <Button
                                        variant="errorOutlined"
                                        sx={{ px: 1, py: 0.8 }}
                                        disabled={!selectedRows.length}
                                        onClick={() => {
                                            setRemoveType('multiple');
                                            popUpRemoveAlert.setTrue();
                                        }}
                                    >{`Remove (${selectedRows.length})`}</Button>
                                </Stack>
                                <Table sx={{ minWidth: 500, mt: 2 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#FAFAFA' }}>
                                            <BaseHead
                                                align="center"
                                                sx={{ p: '0px !important', width: 60 }}
                                                title={
                                                    <Checkbox
                                                        onChange={(e: any) => {
                                                            handleSelectedAllRows(e);
                                                        }}
                                                        checked={isSelectedAllRows}
                                                    />
                                                }
                                            />
                                            <BaseHead align="left" title="Allocated location/cluster" />
                                            <BaseHead align="left" title="Parking operative" />
                                            <BaseHead align="center" title="Arrived time" />
                                            <BaseHead align="center" title="Late by" />
                                            <BaseHead align="center" title="Rota" />
                                            <BaseHead align="center" title="" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.rows.map((item, index) => {
                                            return (
                                                <TableRow key={item.Id}>
                                                    <CellOfRow
                                                        value={
                                                            <Checkbox
                                                                onChange={(e: any) => {
                                                                    handleSelectedRows(e, item);
                                                                }}
                                                                checked={selectedRows.includes(item.Id!)}
                                                            />
                                                        }
                                                        align="center"
                                                    />
                                                    <CellOfRow
                                                        value={item.Cluster?.Name || item.Location?.Name || ''}
                                                        align="left"
                                                    />
                                                    <CellOfRow value={item.Warden?.FullName ?? ''} align="left" />
                                                    <CellOfRow
                                                        value={
                                                            item.LogsAtTime
                                                                ? moment(item.LogsAtTime)
                                                                      .tz('Europe/London')
                                                                      .format('HH:mm:ss')
                                                                : 'Not yet'
                                                        }
                                                    />
                                                    <CellOfRow
                                                        value={getDifferenceBetweenDate(
                                                            new Date(item.LogsAtTime ? item.LogsAtTime : item.Updated!),
                                                            new Date(item.LogsStandardTime!)
                                                        )}
                                                    />
                                                    <CellOfRow
                                                        value={`${formatHour(getMin(item.RotaTimeFrom))} - ${formatHour(
                                                            getMin(item.RotaTimeTo)
                                                        )}`}
                                                    />
                                                    <CellOfRow
                                                        value={
                                                            <Button
                                                                color="error"
                                                                size="small"
                                                                sx={{ flexBasis: 80, py: 0.8, fontSize: 13 }}
                                                                onClick={() => {
                                                                    setRemoveType('single');
                                                                    setAlertId(item.Id);
                                                                    popUpRemoveAlert.setTrue();
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        }
                                                        align="right"
                                                    />
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                        {state.totalPages > 1 && (
                            <Stack direction="row" width="100%" justifyContent="center">
                                <Pagination
                                    count={state.totalPages}
                                    page={state.page}
                                    onChange={(e: any, value: any) => {
                                        // setPage(value);
                                        getListAlert({
                                            page: value,
                                            TimeFrom: filter.startDate,
                                            CountryRegionId: filter.region?.Id,
                                        });
                                        setSelectedRows([]);
                                    }}
                                    shape="rounded"
                                    sx={{
                                        mt: 2.5,
                                        '& .Mui-selected': {
                                            background: '#3479BB1A !important',
                                        },
                                        '& .MuiPaginationItem-previousNext': {
                                            background: '#EEEEEE',
                                        },
                                    }}
                                    renderItem={(item) => (
                                        <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                    )}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
            </TableContainer>
            <PopUpRemoveAlert
                {...popUpRemoveAlert}
                onConfirm={(reason) => {
                    if (removeType === 'all') {
                        handleRemoveAll({
                            AlertType: AlertType.SiteLateLogin,
                            InDate: filter.startDate,
                            Reason: reason,
                        });
                        popUpRemoveAlert.onClose();
                    } else if (removeType === 'multiple') {
                        handleRemove(selectedRows, reason);
                    } else if (removeType === 'single') {
                        handleRemove([alertId!], reason);
                    }
                    setSelectedRows([]);
                    setAlertId(undefined);
                }}
            />
        </Box>
    );
}
