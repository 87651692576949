import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ZoneEventLog } from '@WardenOps/model/ZoneEventLog';
import { AxiosInstance } from 'axios';
import { IZoneEventLogController, ZoneEventLogProps } from '../IZoneEventLogController';
import { ListProps, Paging } from '@Core';

export class ZoneEventLogHttpController extends BaseHttpController<ZoneEventLog> implements IZoneEventLogController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'zoneEventLog', client);
    }

    list(params: ZoneEventLogProps): Promise<Paging<ZoneEventLog>> {
        return super.list(params);
    }
}
