import { TextField, TextFieldProps } from '@mui/material';
import { omit } from 'lodash';
import React from 'react';

type Props = {
    maxLength?: number;
    labelText?: string;
};
export default function BaseTextFieldRequired(props: TextFieldProps & Props) {
    return (
        <TextField
            autoComplete={'off'}
            label={
                <span style={{ paddingRight: 10 }}>
                    {props.labelText} {props.required && <span style={{ color: 'red' }}>*</span>}
                </span>
            }
            InputLabelProps={{
                shrink: true,
                sx: { fontSize: 16 },
            }}
            InputProps={{
                sx: {
                    height: 40,
                },
            }}
            inputProps={{ maxLength: props.maxLength }}
            {...omit(props, 'required')}
        />
    );
}
