import { CStack } from '@components/FlexedStack';
import IcCancel from '@components/icon/IcCancel';
import IcRoute from '@components/icon/navbar/IcRoute';
import RotaTable, { DEFAULT_WEEKLY_PERIOD, Shift, ShiftStatus } from '@components/rota-table';
import StyledShift from '@components/rota-table/Shift';
import usePopUp from '@hooks/usePopUp';
import { Collapse, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ShiftActionRota from '@pages/setting/location/detail/components/ShiftActionRota';
import {
    isWarning,
    renderTitleWarning,
    ShiftTooltip,
    WeeklyCalendarShift,
} from '@pages/setting/warden/detail/components/WorkCalendar';
import { useWarden } from '@pages/setting/warden/detail';
import { RotaTemplate, RotaTemplateDetail, Warden } from '@WardenOps/model';
import { cloneDeep, difference, groupBy, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { MdArrowDropUp } from 'react-icons/md';
import { v4 } from 'uuid';
import PopUpAddShift from './PopUpAddShift';
import PopUpScheduleMap from './PopUpScheduleMap';
import { ShiftWithStatus } from '@pages/setting/location/detail/components/ShiftItem';

export type TemplateDetail = RotaTemplateDetail & { _id?: string };

export interface RotaTemplateWithFakeId extends RotaTemplate {
    RotaTemplateDetails?: TemplateDetail[];
}

export type ScheduleWithStatus = RotaTemplateWithFakeId & {
    _id?: string;
    defaultOpen?: boolean;
    scheduleStatus?: 'deleted' | 'new' | 'change';
};

export default function RotaWeeklyItem(props: {
    schedule: ScheduleWithStatus;
    onChange?(schedule: ScheduleWithStatus): void;
    onDelete?(schedule: ScheduleWithStatus): void;
    onToggle?(schedule: ScheduleWithStatus): void;
}) {
    const { warden } = useWarden();
    const open = props.schedule.defaultOpen;
    const popUpAddShift = usePopUp();
    const popupViewSchedule = usePopUp();
    const theme = useTheme();

    const [weekday, setWeekday] = useState<number>();
    const [selectedShift, setSelectedShift] = useState<Shift>();
    const [rotaTemplate, setRotaTemplate] = useState<ScheduleWithStatus>({} as ScheduleWithStatus);
    const contractedHours = warden?.ContractHours ?? 0;

    const _rotaList = useMemo(() => {
        const data = groupBy(rotaTemplate.RotaTemplateDetails || [], 'Weekday');
        console.log(`rotaTemplate.RotaTemplateDetails`, data, rotaTemplate.RotaTemplateDetails);
        const _d: Record<string, WeeklyCalendarShift[]> = cloneDeep(DEFAULT_WEEKLY_PERIOD);
        Object.entries(data).forEach(([key, templates]) => {
            _d[key] = templates.map((shift) => ({
                startMin: shift.TimeFrom,
                endMin: shift.TimeTo,
                breakHours: shift.BreakHours,
                weekday: shift.Weekday,
                id: isNil(shift.Id) ? shift.Id : shift.Id,
                _id: shift._id,
                status: shift.Location?.Id || shift.Cluster?.Id ? ShiftStatus.assigned : ShiftStatus.unassigned,
                location: shift.Location,
                cluster: shift.Cluster,
                type: shift.Cluster?.Id ? 'cluster' : 'static',
            }));
        });
        return _d;
    }, [rotaTemplate.RotaTemplateDetails]);
    console.log(`rotaList`, _rotaList);

    const handleChangeShift = (checkedWeekdays: number[], shiftSelected: ShiftWithStatus) => {
        const convertShift = (shift: WeeklyCalendarShift) => {
            return {
                TimeFrom: shift.startMin,
                TimeTo: shift.endMin,
                Weekday: shift.weekday ?? 1,
                BreakHours: shift.breakHours,
                LocationId: shift.location?.Id,
                Location: shift.location,
                Cluster: shift.cluster,
                ClusterId: shift.cluster?.Id,
            } as RotaTemplateDetail;
        };

        const clonePrev = cloneDeep(rotaTemplate);
        let periods = clonePrev.RotaTemplateDetails || [];

        switch (shiftSelected.shiftStatus) {
            case 'deleted':
                periods = periods?.filter((p) => p._id !== shiftSelected._id);
                break;
            case 'change':
                periods = periods?.map((p) => {
                    return shiftSelected._id === p._id ? { ...p, ...convertShift(shiftSelected) } : p;
                });
                break;
            default:
                periods.push({ ...convertShift(shiftSelected), _id: shiftSelected._id });
                break;
        }

        console.log(`shiftSelected, periods`, shiftSelected, periods);

        const addWeekdays = difference(checkedWeekdays, [shiftSelected?.weekday!]);
        addWeekdays.forEach((weekday) => {
            if (weekday !== shiftSelected?.weekday) {
                periods.push({
                    ...convertShift(shiftSelected),
                    TimeFrom: shiftSelected.startMin,
                    TimeTo: shiftSelected.endMin,
                    Weekday: weekday,
                    _id: v4(),
                    Location: shiftSelected.location,
                });
            }
        });
        clonePrev.RotaTemplateDetails = periods;
        console.log(`shiftSelected`, shiftSelected, periods);
        props.onChange?.(clonePrev);
        popUpAddShift.onClose();
    };

    const totalHours = rotaTemplate.RotaTemplateDetails?.reduce((total, prev) => {
        if (
            (prev.Location && !!Object.keys(prev.Location).length) ||
            (prev.Cluster && !!Object.keys(prev.Cluster.Locations!).length)
        ) {
            total += (prev.TimeTo - prev.TimeFrom) / 60;
        }
        return total;
    }, 0);

    useEffect(() => {
        setRotaTemplate(props.schedule);
    }, [props.schedule]);
    console.log(`props.schedule`, props.schedule);

    return (
        <Stack
            direction="row"
            width="100%"
            mt={2}
            justifyContent="space-between"
            alignItems={open ? 'flex-start' : 'center'}
        >
            <Stack
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: 1,
                    p: 1,
                    cursor: 'pointer',
                    width: '100%',
                    mr: '40px',
                }}
                justifyContent="space-between"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                        props.onToggle?.(props.schedule);
                    }}
                >
                    <Stack direction="row" gap={12}>
                        <Typography>Week {props.schedule.OrderIndex}</Typography>
                        <Stack direction="row" gap={2}>
                            <Typography color={(totalHours ?? 0) >= contractedHours ? 'secondary' : 'error'}>
                                Total hours: {totalHours}h ({contractedHours}h)
                            </Typography>
                            {/* <Typography>
                                The lasted week applied will start on {formatDate(props.schedule.TimeStart)}
                            </Typography> */}
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            transform: `rotate(${open ? -180 : 0}deg)`,
                            color: '#85858A',
                            transition: 'all 0.3s',
                        }}
                        justifyContent="center"
                    >
                        <MdArrowDropUp fontSize={20} />
                    </Stack>
                </Stack>

                <Collapse in={!!open}>
                    <RotaTable
                        data={_rotaList || []}
                        startDate={props.schedule.TimeStart}
                        isDisplayDate={false}
                        hideLieuLeave
                        renderOption={(option, index, arr) => {
                            const { Assignable } = option.location ?? option.cluster ?? {};

                            return (
                                <StyledShift
                                    key={'weekly-schedule' + option.id}
                                    shift={option}
                                    // toolTipSx={{ width: 270 }}
                                    onAddPeriod={(shift) => {
                                        setSelectedShift(shift);
                                        popUpAddShift.setTrue();
                                    }}
                                    warning={
                                        Assignable === 'invalid'
                                            ? 'error'
                                            : Assignable === 'part-valid'
                                            ? 'warning'
                                            : undefined
                                    }
                                    toolTipProps={{
                                        title: !option.status ? undefined : <ShiftTooltip option={option} />,
                                    }}
                                    renderActions={(shift) => {
                                        return (
                                            <ShiftActionRota
                                                key={shift.id}
                                                shift={shift}
                                                onEdit={(shift) => {
                                                    setSelectedShift(shift);
                                                    popUpAddShift.setTrue();
                                                }}
                                            />
                                        );
                                    }}
                                />
                            );
                        }}
                        renderAction={(shifts, weekday) => (
                            <CStack sx={{ width: 80 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setWeekday(weekday);
                                        popupViewSchedule.setTrue();
                                    }}
                                >
                                    <IcRoute style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </CStack>
                        )}
                    />
                </Collapse>
            </Stack>

            <Stack direction="row" alignItems="center" pt={open ? 1 : 0}>
                <Tooltip title={'Delete'} placement="top" arrow>
                    <IconButton
                        onClick={() => {
                            props.onDelete?.(props.schedule);
                        }}
                    >
                        <IcCancel
                            style={{
                                color: '#E01B00',
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>

            <PopUpAddShift
                {...popUpAddShift}
                selectShift={selectedShift}
                onConfirm={(checkedWeekdays, shiftSelected) => {
                    handleChangeShift(checkedWeekdays, shiftSelected);
                    console.log(`shiftSelected`, shiftSelected);
                }}
                workCalendar={_rotaList}
                disabledPast={false}
                isShowSubtitle={false}
            />

            <PopUpScheduleMap
                weekday={weekday}
                allShifts={_rotaList}
                warden={warden ?? ({} as Warden)}
                {...popupViewSchedule}
            />
        </Stack>
    );
}
