import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IciTicketTracker(props: SvgIconProps) {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.5 7.025C0.5 7.017 0.5 7.008 0.5 7C0.5 3.137 3.637 0 7.5 0C11.363 0 14.5 3.137 14.5 7C14.5 7.008 14.5 7.017 14.5 7.025C14.484 12.256 9.45 15.378 7.859 15.934C7.753 15.971 7.626 16 7.5 16C7.374 16 7.253 15.976 7.141 15.934C5.55 15.378 0.516 12.256 0.5 7.025V7.025V7.025ZM7.5 4C9.156 4 10.5 5.344 10.5 7C10.5 8.656 9.156 10 7.5 10C5.844 10 4.5 8.656 4.5 7C4.5 5.344 5.844 4 7.5 4Z"
                fill="currentColor"
            />
        </svg>
    );
}
