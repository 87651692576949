import { BaseModel } from '@Core';
import { Country } from './Country';
import { CountrySubRegionWithRelations } from './CountrySubRegion';

export interface Service extends BaseModel {
    ZoneId: number;
    ServiceType?: ServiceType;
    Status?: ServiceStatus;
    InstallDate?: Date;
    GoLiveDate?: Date;
    TerminationDate?: Date;
    Notes?: string;
    ServiceConfig: ServiceConfig;
}

export interface ServiceConfig {
    WardenNotes?: string;
    IssuePCNType: IssuePCNType;
    FirstSeenPeriod: number;
    GracePeriod: number;
}
export interface IssuePCNType {
    Physical: boolean;
    Virtual: boolean;
}
export enum ServiceType {
    Anpr,
    WardenPatrol,
    SelfTicket,
    iTicket,
    ContraventionManagement,
    Other,
    iPark,
    eCam,
    iTicketLite,
    SelfTicketingNew,
}

export enum ServiceStatus {
    Install,
    Test,
    Production,
    Terminated,
}
