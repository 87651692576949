import { BaseModel } from '@Core';
import { WardenWithRelations } from './Warden';
import { EmailEvent } from './EmailEvents';

export interface EmailSent extends BaseModel {
    WardenId: number;
    Email: string;
    Status: EmailStatus;
    SendDate: Date;
    Type: EmailSentType;
    MessageId: string;
    Detail: string;

    EmailEvents?: EmailEvent[];
}
export interface EmailSents extends EmailSent {
    Warden: WardenWithRelations;
}

export enum EmailStatus {
    Sent,
    Unsent,
    Open,
}

export enum EmailSentType {
    SendRota,
}
