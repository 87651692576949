import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { pushError } from '@components/StyledToast';
import { loginRequest } from '@config';
import { Box, Typography } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import NotificationProvider from 'src/contexts/NotificationProvider';
import axiosInstant from 'src/helpers/axiosHelper';
import { Loading } from './Loading';
import UnAuth from './UnAuth';
import WrapLayout from './WrapLayout';
import AppBar from './AppBar';

const blackListEmails = ['motahir.k@ukparkingcontrol.com'];

function ProtectedChild() {
    const { instance, inProgress, accounts } = useMsal();

    const getToken = async () => {
        try {
            console.log('Getting access token...');

            const accessTokenRequest = {
                scopes: ['https://analysis.windows.net/powerbi/api/.default'],
                account: accounts[0],
            };

            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            const _accessToken = accessTokenResponse.idToken;

            return _accessToken;
        } catch (error: any) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    useLayoutEffect(() => {
        axiosInstant.interceptors.request.use(
            async (config) => {
                const token = await getToken();
                config.headers!['Authorization'] = 'Bearer ' + token;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosInstant.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    console.error('_error', error.response);
                    instance.acquireTokenRedirect(loginRequest);
                } else {
                    pushError(error?.response?.data?.message || error.message || 'Server error!');
                    return Promise.reject(error);
                }
            }
        );
    }, []);

    if (inProgress !== InteractionStatus.None) {
        return <Loading />;
    }

    const isDenied = blackListEmails.includes(accounts[0].username);
    console.log(`isDenied:`, isDenied);

    return (
        <NotificationProvider>
            {isDenied ? (
                <>
                    <AppBar isDenied={isDenied} />
                    <Box mt={'100px'} ml={6}>
                        <Typography variant="h2" color={'red'}>
                            Access Denied
                        </Typography>
                        <Typography>You do not have permission to view this page.</Typography>
                    </Box>
                </>
            ) : (
                <WrapLayout>
                    <Outlet />
                </WrapLayout>
            )}
        </NotificationProvider>
    );
}

function AuthLayout() {
    return (
        <Box sx={{ display: 'flex' }}>
            <AuthenticatedTemplate>
                <ProtectedChild />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnAuth />
            </UnauthenticatedTemplate>
        </Box>
    );
}

export default React.memo(AuthLayout);
