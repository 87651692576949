import { BaseModel } from '@Core';
import { Warden } from './Warden';
import { Zone } from '@LocationOps/model/Zone';

export interface ZoneEventLog extends BaseModel {
    FullName: string;
    Email: string;
    Type: ZoneEventLogType;
    Details: string;
    ZoneId: number;

    Zone?: Zone;
}

export enum ZoneEventLogType {
    Suspension,
    EndSuspension,
}
