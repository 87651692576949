import Checkbox from '@components/Checkbox';
import { CStack, HStack } from '@components/FlexedStack';
import IcUser from '@components/icon/IcUser';
import IcInformation from '@components/icon/navbar/IcInformation';
import { getValidHour } from '@pages/setting/location/detail/components/PopUpAddPeriod';
import StyledSelect from '@components/select-search/StyledSelect';
import { rotaController, rotaCoverageController } from '@controllers';
import { getTimeByMin, getWeekday, isEndDate, _weekdays } from '@helpers';
import { GetWardenFreeInTime } from '@LocationOps/controller/IRotaCoverageHttpController';
import { Location, RotaCoverage } from '@LocationOps/model';
import { ArrowDropDownRounded } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import { getDistance, getLatLng } from '@pages/rota-coverage/components/GMapInterface';
import { ShiftRota } from '@pages/rota-coverage/hooks/useRota';
import { Rota, RotaType, WardenWithRelations } from '@WardenOps/model';
import { difference, isNumber, orderBy, uniq } from 'lodash';
import moment from 'moment';
import { useEffect, useId, useState } from 'react';
import { pushSuccess } from '@components/StyledToast';
import { IPopUp } from 'src/hooks/usePopUp';
import { useBoolean } from 'usehooks-ts';
import { v4 } from 'uuid';
import { END_DAY_MINUTE } from '.';
import PopUpBase from '../PopUpBase';
import { formatHour } from './Shift';
import ShiftRange from './ShiftRange';
import useDeepCompareEffect from '@hooks/useDeepCompareEffect';

type WardenWithDistance = WardenWithRelations & { distance?: number };

type Props = Omit<IPopUp, 'onConfirm'> & {
    location: Location;
    shift: ShiftRota;
    startDate: Date;
    shiftsByWeekday: Record<string, ShiftRota[]>;
    onConfirmed(newRota: RotaCoverage[]): void;
    isMobile?: boolean;
    onTimeChange(startMin?: number, endMin?: number): void;
};

const usePopUpAssign = () => {
    const assignPeriodId = useId();
    const collapse = useBoolean(true);
    return {
        assignPeriodId,
        collapse,
    };
};

type C = ReturnType<typeof usePopUpAssign>;

const Option = (props: {
    weekday: number;
    option: WardenWithDistance;
    isValue?: boolean;
    location: Location;
    hideDistance?: boolean;
}) => {
    const { option, isValue, weekday, location } = props;
    const selectWeekday = (option.WardenFreeTimes ?? []).find((w) => w.Weekday === weekday);
    if (!selectWeekday) return null;

    const available = {
        start: moment(selectWeekday.FreeFrom).format('HH:mm'),
        end: isEndDate(selectWeekday.FreeTo) ? `24:00` : moment(selectWeekday.FreeTo).format('HH:mm'),
    };

    const isOutOfWorking = selectWeekday.Status === 'out-of-working';

    const isOverTime = (option.ActualWeekWorkHour ?? 0) > (option.ContractHours ?? 0);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ py: isValue ? 0 : 1, borderBottom: isValue ? 'none' : '1px solid #eee' }}
            spacing={1}
        >
            <Avatar src={option.Picture} sx={{ width: '32px', height: '32px' }}>
                H
            </Avatar>
            <Stack flex={1}>
                <Stack direction={'row'} spacing={1}>
                    <Typography flex={1}>{option.FullName}</Typography>
                    {!props.hideDistance && (
                        <Typography variant="caption">
                            {option.DistanceWithALocation
                                ? `${(option.DistanceWithALocation / 1000).toFixed(1)}km`
                                : ''}
                        </Typography>
                    )}
                </Stack>
                <Stack mt={'-1px'} direction={'row'} alignItems={'center'} justifyContent="space-between" spacing={1}>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <Stack
                            sx={{
                                width: '5px',
                                height: '5px',
                                bgcolor: isOutOfWorking ? 'gray' : 'primary.main',
                                borderRadius: '50%',
                                flexShrink: 0,
                            }}
                        ></Stack>
                        <Typography variant="caption" color="gray">
                            {isOutOfWorking ? 'Out of working' : `Available: ${available.start} - ${available.end}`}
                        </Typography>
                    </Stack>

                    <Typography variant="caption" color={isOverTime ? 'error.main' : 'primary.main'}>
                        {option.ActualWeekWorkHour ?? 0}/{option.ContractHours}h
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export type AssignPeriod = 'onlyThisWeek' | 'permanent';

const assignPeriodLabel: Record<AssignPeriod, string> = {
    onlyThisWeek: 'Only this week',
    permanent: 'Permanent (For new parking operative)',
};

type Form = {
    period: AssignPeriod;
    selectWarden?: WardenWithRelations;
    lunch: number;
    startMin: number;
    endMin: number;
    selectDays: number[];
    date?: Date;
};

const getInitSelectDays = (days: number[], shift: ShiftRota) => {
    return days.includes(shift.weekday) ? [shift.weekday] : [];
};

const getValidWeekdays = (shift: ShiftRota, shiftByWeekday: Record<string, ShiftRota[]>) => {
    const validWeekdays: number[] = [];
    if (!shift) return validWeekdays;

    Object.entries(shiftByWeekday).forEach(([key, shifts], index) => {
        shifts.forEach((sh) => {
            console.log('_s', sh.weekday, shift, sh);
            if (
                sh!.startMin <= shift.startMin &&
                sh!.endMin >= shift.endMin &&
                moment(sh.date).isSameOrAfter(moment().startOf('date'))
            ) {
                validWeekdays.push(Number(key));
            }
        });
    });

    return uniq(validWeekdays);
};

export default function PopUpAssignWarden(props: Props) {
    const [wardens, setWardens] = useState<WardenWithDistance[]>([]);
    const [filterWardens, setFilterWardens] = useState<WardenWithDistance[]>([]);

    const [validWeekdays, setValidWeekdays] = useState(getValidWeekdays(props.shift, props.shiftsByWeekday));
    console.log(`validWeekdays`, validWeekdays);

    const [searchValue, setSearchValue] = useState('');

    const currentDate = props.shift.date;

    const [form, setForm] = useState<Form>({
        period: 'onlyThisWeek',
        lunch: 0,
        startMin: props.shift.startMin,
        endMin: props.shift.endMin,
        selectDays: [props.shift.weekday],
    });

    const { assignPeriodId, collapse } = usePopUpAssign();
    console.log(`props.location`, props.location);
    const handleSelectDay = (weekday: number, checked: boolean) => {
        setForm((p) => {
            const newState = { ...p };
            let selectDays = newState.selectDays;

            if (weekday === -1) {
                selectDays = checked ? _weekdays : [];
            } else {
                selectDays = checked ? [...selectDays, weekday] : selectDays.filter((d) => d !== weekday);
            }

            const ifDiff = difference(_weekdays, selectDays).length === 0;
            selectDays = ifDiff ? [-1].concat(selectDays) : selectDays.filter((d) => d !== -1);

            newState.selectDays = selectDays;
            return newState;
        });
    };

    const handleChangeWarden = (warden: any) => {
        setForm((p) => ({ ...p, selectWarden: warden, selectDays: [props.shift.weekday] }));
        setSearchValue('');
    };

    useEffect(() => {
        const init = async () => {
            if (!form.date) return;

            const { hour: startHour, min: startMin } = getTimeByMin(form.startMin);
            const { hour: endHour, min: endMin } = getTimeByMin(form.endMin);
            const _getWardenFreeTime: GetWardenFreeInTime = {
                LocationId: props.location.Id!,
                TimeFrom: moment(form.date).hour(startHour).minutes(startMin).toDate(),
                TimeTo:
                    form.endMin === END_DAY_MINUTE
                        ? moment(form.date).endOf('date').toDate()
                        : moment(form.date).hour(endHour).minutes(endMin).toDate(),
            };

            const _wardens = await rotaCoverageController.getWardensFreeTime(_getWardenFreeTime);

            setWardens(_wardens);
        };

        if (isNumber(form.startMin) && isNumber(form.endMin)) {
            init();
        } else {
            setWardens([]);
            setForm((prev) => ({ ...prev, selectWarden: undefined }));
        }
    }, [form.startMin, form.date, form.endMin, props.location.Id]);

    useDeepCompareEffect(() => {
        if (!props.open) return;
        setForm({
            period: 'onlyThisWeek',
            lunch: 0,
            startMin: props.shift.startMin,
            endMin: props.shift.endMin,
            selectDays: [props.shift.weekday],
            date: props.shift.date,
        });

        setSearchValue('');
    }, [props.open, props.shift]);

    useEffect(() => {
        setValidWeekdays(
            getValidWeekdays({ ...props.shift, startMin: form.startMin, endMin: form.endMin }, props.shiftsByWeekday)
        );
    }, [form.startMin, form.endMin, props.shiftsByWeekday, props.shift]);

    const handleSave = async () => {
        console.log('first', form.selectDays);

        try {
            const rota: Rota[] = form.selectDays
                .filter((s) => s !== -1)
                .map((weekday) => {
                    const { hour: startHour, min: startMin } = getTimeByMin(form.startMin);
                    const { hour: endHour, min: endMin } = getTimeByMin(form.endMin);

                    const _weekday = weekday === 0 ? 7 : weekday;
                    const timeTo = moment(currentDate)
                        .isoWeekday(_weekday)
                        .startOf('day')
                        .hour(endHour)
                        .minute(endMin)
                        .toDate();
                    const endTimeTo = moment(currentDate).isoWeekday(_weekday).endOf('day').toDate();

                    const r = {
                        BreakHours: form.lunch,
                        TimeFrom: moment(currentDate)
                            .isoWeekday(_weekday)
                            .startOf('day')
                            .hour(startHour)
                            .minute(startMin)
                            .toDate(),
                        TimeTo: form.endMin === END_DAY_MINUTE ? endTimeTo : timeTo,
                        RotaType: form.selectWarden?.WardenFreeTimes?.find((s) => s.Weekday === weekday)?.RotaType,
                    } as Rota;
                    // Handle select day on Sunday
                    // if (weekday === 0) {
                    //     r.TimeFrom = moment(r.TimeFrom).add(1, 'week').toDate();
                    //     r.TimeTo = moment(r.TimeTo).add(1, 'week').toDate();
                    // }
                    return r;
                });
            console.log(`rotaConverted`, rota);

            const rotaCoverages = await rotaController.bulkCreateByLocation({
                List: rota,
                LocationId: props.location.Id!,
                CreateRotaTemplate: form.period === 'permanent',
                WardenId: form.selectWarden!.Id!,
            });
            props.onConfirmed(rotaCoverages);
            pushSuccess('Assigned successfully!');
        } catch (error) {}
    };

    const disabled = !form.selectWarden || !form.selectDays.length;

    const isDisabledDay = (day: number) => {
        return (
            !validWeekdays.includes(day) ||
            (form.selectWarden && !(form.selectWarden.WardenFreeTimes || []).map((w) => w.Weekday).includes(day))
        );
    };

    const disabledAll = _weekdays.some(isDisabledDay);

    const disabledBreakTime = (hour: number) => {
        return props.shift.endMin / 60 - props.shift.startMin / 60 <= hour;
    };
    useEffect(() => {
        setFilterWardens(
            wardens
                .filter((r) => (form.period === 'onlyThisWeek' ? true : !r.HasRotaTemplate))
                .filter((w) => w.FullName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
        );
    }, [form.period, searchValue, wardens]);

    return (
        <PopUpBase
            open={props.open}
            onClose={props.onClose}
            fixOverflow
            dialogProps={{
                scroll: 'paper',
                fullWidth: true,
                PaperProps: { sx: { maxWidth: '700px', overflowY: 'unset' } },
            }}
            hideConfirm
            hideClose
            title="Assign parking operative"
            subTitle={`Location: ${props.location.Name}`}
            desc={
                <Stack>
                    <HStack>
                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormLabel
                                id={assignPeriodId}
                                sx={{ marginRight: 3, fontWeight: 500, color: 'unset !important' }}
                            >
                                Assign period:
                            </FormLabel>
                            <RadioGroup
                                value={form.period}
                                onChange={(_, value) =>
                                    setForm((p) => ({ ...p, period: value as AssignPeriod, selectWarden: undefined }))
                                }
                                row
                                aria-labelledby={assignPeriodId}
                                name="row-radio-buttons-group"
                            >
                                {Object.entries(assignPeriodLabel).map(([key, label]) => {
                                    return (
                                        <Stack direction="row" alignItems="center">
                                            <FormControlLabel
                                                key={key}
                                                value={key}
                                                control={<Radio />}
                                                label={label}
                                                sx={{ userSelect: 'none' }}
                                            />
                                            {key === 'permanent' && (
                                                <Stack
                                                    sx={{
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        '&:hover > .info': {
                                                            display: 'block',
                                                        },
                                                        transition: '1s ease',
                                                    }}
                                                    ml={-1}
                                                >
                                                    <IcInformation />
                                                    <Box
                                                        sx={{
                                                            width: 370,
                                                            background: '#FFFFFF',
                                                            display: 'none',
                                                            position: 'absolute',
                                                            border: '1px solid #DDDDDD',
                                                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                                            borderRadius: '5px',
                                                            p: '8px 16px',
                                                            top: 16,
                                                            transform: 'translate(-72%,0%)',
                                                            zIndex: (theme) => theme.zIndex.drawer + 1,
                                                        }}
                                                        className="info"
                                                    >
                                                        This action is used to setup work template for only new parking
                                                        operative who has no template or work calendar before.
                                                    </Box>
                                                </Stack>
                                            )}
                                        </Stack>
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </HStack>

                    <CStack justifyContent={'space-between'}>
                        <Stack>
                            <ShiftRange
                                startMins={
                                    getValidHour(form.startMin, props.shift.startMin, props.shift.endMin).validStartMins
                                }
                                endMins={
                                    getValidHour(form.startMin, props.shift.startMin, props.shift.endMin).validEndMins
                                }
                                value={{ startMin: form.startMin, endMin: form.endMin }}
                                onChange={(v) => {
                                    setForm((p) => ({ ...p, startMin: v.startMin, endMin: v.endMin } as Form));
                                }}
                            />
                        </Stack>

                        <Stack>
                            <StyledSelect
                                sx={{ width: 300 }}
                                totalItem={wardens.length}
                                // listProps={{ style: { width: '300px', left: 'unset', right: 0 } }}
                                emptyText={searchValue ? 'No options' : 'No parking operative is suitable'}
                                value={form.selectWarden}
                                data={filterWardens}
                                hasMore={false}
                                next={() => {}}
                                onChange={handleChangeWarden}
                                renderOption={(option) => (
                                    <Option option={option} weekday={props.shift.weekday} location={props.location} />
                                )}
                                onChangeSearch={(text) => {
                                    setSearchValue(text);
                                }}
                                searchValue={searchValue}
                                renderValue={(value) =>
                                    form.selectWarden ? (
                                        <Option
                                            isValue
                                            option={form.selectWarden}
                                            weekday={props.shift.weekday}
                                            location={props.location}
                                            hideDistance={!props.isMobile}
                                        />
                                    ) : (
                                        <CStack justifyContent={'flex-start'} spacing={1}>
                                            <Avatar sx={{ width: '32px', height: '32px' }}>
                                                <IcUser />
                                            </Avatar>
                                            <Typography sx={{ userSelect: 'none', mt: '-1px !important' }}>
                                                Select parking operative
                                            </Typography>
                                        </CStack>
                                    )
                                }
                            />
                        </Stack>
                    </CStack>

                    <Stack mt={2} direction={'row'} alignItems="center" spacing={3}>
                        <Typography>Lunch/Break:</Typography>

                        <FormControl fullWidth size="small">
                            <Select
                                sx={{
                                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                                        py: '10px',
                                    },
                                }}
                                value={form.lunch}
                                onChange={(e) => setForm((p) => ({ ...p, lunch: e.target.value as number }))}
                                MenuProps={{ sx: { maxHeight: 200 } }}
                            >
                                {[0, 1, 2].map((t, index) => {
                                    return (
                                        <MenuItem key={index} value={t} disabled={disabledBreakTime(t)}>
                                            {formatHour(t * 60)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>

                    <Stack mt={2} sx={{ border: '1px solid #eee', borderRadius: '5px', p: '8px 16px' }}>
                        <CStack justifyContent={'flex-start'} sx={{ cursor: 'pointer' }} onClick={collapse.toggle}>
                            <Typography color="gray">
                                Assign this working hours to this parking operative for the others days
                            </Typography>
                            <ArrowDropDownRounded
                                color="disabled"
                                sx={{ transform: `rotate(${collapse.value ? 0 : 180}deg)`, transition: '0.3s' }}
                            />
                        </CStack>

                        <Collapse in={collapse.value}>
                            <CStack py={2} pb={1}>
                                <FormControl component="fieldset" fullWidth>
                                    <FormGroup aria-label="position" row sx={{ justifyContent: 'space-evenly' }}>
                                        <FormControlLabel
                                            key={v4()}
                                            value={-1}
                                            disabled={disabledAll}
                                            checked={form.selectDays.includes(-1)}
                                            onChange={(_, checked) => handleSelectDay(-1, checked)}
                                            control={<Checkbox />}
                                            label="All days"
                                            labelPlacement="bottom"
                                            sx={{ userSelect: 'none' }}
                                        />
                                        {getWeekday(props.startDate).map((w) => {
                                            const _weekday = w.weekday;

                                            const disabled =
                                                !validWeekdays.includes(_weekday) ||
                                                (form.selectWarden &&
                                                    !(form.selectWarden.WardenFreeTimes || [])
                                                        .map((w) => w.Weekday)
                                                        .includes(_weekday));

                                            return (
                                                <FormControlLabel
                                                    key={w.weekday}
                                                    value={_weekday}
                                                    control={<Checkbox />}
                                                    checked={form.selectDays.includes(_weekday) && !disabled}
                                                    onChange={(_, checked) => handleSelectDay(_weekday, checked)}
                                                    disabled={disabled}
                                                    sx={{ userSelect: 'none' }}
                                                    label={
                                                        <Typography textAlign={'center'}>
                                                            {moment(w.date).format('ddd')}
                                                            {/* <br />
                                                            <Typography color="gray" component={'span'}>
                                                                {moment(w.date).format('DD/MM')}
                                                            </Typography> */}
                                                        </Typography>
                                                    }
                                                    labelPlacement="bottom"
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </CStack>
                        </Collapse>
                    </Stack>

                    {form.period === 'permanent' && (
                        <Stack mt={2} sx={{ p: 1, borderRadius: '5px', bgcolor: '#EEEEEE' }}>
                            <Typography textAlign={'center'}>
                                Since{' '}
                                {(moment(props.startDate).isBefore(moment())
                                    ? moment()
                                    : moment(props.startDate)
                                ).format('DD/MM/YYYY')}
                                , this location and these time periods will have been assigned to <br />
                                the{' '}
                                <Typography color="primary.main" component={'span'}>
                                    {form.selectWarden?.FullName || 'selected parking operative'}
                                </Typography>{' '}
                                as a weekly schedule!
                            </Typography>
                        </Stack>
                    )}

                    <Stack mt={3} direction="row" justifyContent={'space-between'}>
                        <Button variant="cancel" sx={{ minWidth: 170 }} onClick={props.onClose}>
                            Close
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 170 }} onClick={handleSave} disabled={disabled}>
                            Submit
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
