type Props = {
    error?: boolean;
};

function StatusIcon(props: Props) {
    return props.error ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
            <path
                fillRule="evenodd"
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 .5a7.5 7.5 0 110 15 7.5 7.5 0 010-15z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.996 4.922v3.583"
            ></path>
            <path
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7.996 11.078h.008"
            ></path>
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
            <rect width="16" height="16" fill="#009D4F" rx="8"></rect>
            <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M5 8l2 2 4-4"></path>
        </svg>
    );
}

export default StatusIcon;
