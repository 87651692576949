import { Grow } from '@mui/material';
import { Container } from '@mui/system';
import { ScaleLoader } from 'react-spinners';
import color from 'src/theme/color';

export const Loading = () => {
    return (
        <Container
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grow>
                <div>
                    <ScaleLoader color={color.primary} />
                </div>
            </Grow>
        </Container>
    );
};
