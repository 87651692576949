import Flag, { useFeatureFlag } from '@components/layouts/Flag';
import { appConfig, FeatureName } from '@config';
import ExpensesPage from '@pages/expenses';
import TimeSheets from '@pages/time-sheets';
import { Navigate, Route, Routes } from 'react-router-dom';

type Props = {};

export default function PaymentPage({}: Props) {
    const routes: { index?: boolean; path?: string; element: JSX.Element; name?: FeatureName }[] = [
        {
            index: true,
            element: <Navigate to={'time-sheets'} />,
        },
        {
            path: 'time-sheets',
            element: <TimeSheets />,
            name: FeatureName.PAYMENT__TIME_SHEETS,
        },
        {
            path: 'expenses',
            element: <ExpensesPage />,
            name: FeatureName.PAYMENT__EXPENSES,
        },
        {
            path: '*',
            element: <Navigate to={'/'} />,
        },
    ];
    const { getActive } = useFeatureFlag();

    return (
        <Routes>
            {routes.map((r, index) => {
                if (!r.name || getActive(r.name))
                    return <Route key={index} index={r.index} path={r.path} element={r.element} />;

                return <></>;
            })}
        </Routes>
    );
}
