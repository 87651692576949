import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CountryRegion } from '@LocationOps/model';
import { AxiosInstance } from 'axios';
import { ICountryRegionController } from '../ICountryRegionController';

export class CountryRegionHttpController extends BaseHttpController<CountryRegion> implements ICountryRegionController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'country-region', client);
    }
}
