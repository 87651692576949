import { CStack, HStack, VStack } from '@components/FlexedStack';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { Box, CircularProgress, Fade, Grid, Grow, Pagination, PaginationItem, Skeleton, Stack } from '@mui/material';
import React from 'react';
type Props<T> = {
    list: T[];
    loading?: boolean;
    renderItem?(item: T): React.ReactElement;
    renderEmpty?(): JSX.Element;
    count?: number;
    page?: number;
    onChangePage?(value: number): void;
};
export default function BaseList<T>({ list, loading, renderItem, ...props }: Props<T>) {
    return (
        <div style={{ marginBottom: 100 }}>
            <Stack sx={{}}>
                {loading ? (
                    <HStack pt={5}>
                        <Grow in={loading}>
                            <CircularProgress sx={{}} />
                        </Grow>
                    </HStack>
                ) : !list.length ? (
                    <Fade in={true}>
                        <div>{props.renderEmpty?.() || <></>}</div>
                    </Fade>
                ) : (
                    <Grid xs={12} item container gap={2} justifyContent="center">
                        {list.map((item: T, index: number) => {
                            return (
                                <Grow in={true} key={index} timeout={Math.min(500 * index, 1000)}>
                                    <Grid item xs={12} sm={12} md={12} xl={12}>
                                        <Grow in={!!item} timeout={500}>
                                            <Box height={'100%'}>{renderItem ? renderItem?.(item) : <></>}</Box>
                                        </Grow>
                                    </Grid>
                                </Grow>
                            );
                        })}

                        {props.count! > 1 && (
                            <Pagination
                                count={props.count}
                                page={props.page}
                                onChange={(e: any, value: any) => {
                                    props.onChangePage?.(value);
                                }}
                                shape="rounded"
                                sx={{
                                    mt: 2,
                                    '& .Mui-selected': {
                                        background: '#3479BB1A !important',
                                    },
                                    '& .MuiPaginationItem-previousNext': {
                                        background: '#EEEEEE',
                                    },
                                }}
                                renderItem={(item) => (
                                    <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                )}
                            />
                        )}
                    </Grid>
                )}
            </Stack>
        </div>
    );
}
