import { regionController, subRegionController } from '@controllers';
import { ListProps, Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { CountryRegion, CountrySubRegion } from '@LocationOps/model';
import { Warden } from '@WardenOps/model';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

export type Filter = {
    startDate: Date;
    region?: any;
    subRegion?: any;
    wardenType?: any;
} & Pick<ListProps<Warden>, 'page' | 'search'>;
type Mode = 'time-sheets' | 'expenses';
export default function useFilterWarden({ mode }: { mode?: Mode }) {
    const [filter, setFilter] = useState<Filter>({
        startDate: moment()
            .startOf('isoWeek')
            .subtract(mode === 'time-sheets' ? 1 : 2, 'weeks')
            .toDate(),
        page: 1,
        search: {
            content: '',
            fields: ['FirstName', 'LastName', 'Email'],
        },
    });
    const [regions, setRegions] = useState<Paging<CountryRegion>>(defaultPaging({ pageSize: 10 }));
    const [subRegions, setSubRegions] = useState<Paging<CountrySubRegion>>(defaultPaging({ pageSize: 10 }));
    const [searchSubRegion, setSearchSubRegion] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);

    const filteredSubRegion = subRegions.rows.filter((subR) =>
        subR.Name.toLocaleLowerCase().includes((searchSubRegion || '').toLocaleLowerCase())
    );

    const handleChange = async (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const hasMoreRegions = Boolean(regions.page < regions.totalPages);
    const onGetMoreRegions = async () => {
        await regionController
            .list({
                page: regions.page + 1,
                pageSize: 10,
                sorts: ['Name'],
            })
            .then((res) => {
                const _res = { ...res, rows: [...regions.rows, ...res.rows] };
                setRegions(_res);
            });
    };

    const hasMoreSubRegions = Boolean(subRegions.page < subRegions.totalPages);
    const onGetMoreSubRegions = async () => {
        await subRegionController
            .list({
                page: subRegions.page + 1,
                pageSize: 10,
                sorts: ['Name'],
            })
            .then((res) => {
                const _res = { ...res, rows: [...subRegions.rows, ...res.rows] };
                setSubRegions(_res);
            });
    };

    const debSearchWarden = useCallback(
        debounce((nextValue) => {
            setFilter((prev) => ({ ...prev, search: { ...prev.search, content: nextValue }, page: 1 }));
        }, 300),
        []
    );
    const handleChangeSearchWarden = (e: any) => {
        const text = e.target.value;
        setSearchValue(text);
        setLoading(true);
        debSearchWarden(text);
    };

    const handleReset = () => {
        setFilter({ startDate: moment().startOf('isoWeek').subtract(1, 'weeks').toDate() });
        setSearchValue('');
        setSearchSubRegion('');
    };

    useEffect(() => {
        regionController.list({ pageSize: 1000, sorts: ['Name'] }).then((res) => setRegions(res));
    }, []);

    useEffect(() => {
        subRegionController
            .list({ page: 1, pageSize: 1000, filter: { CountryRegionId: filter.region?.Id }, sorts: ['Name'] })
            .then((res) => setSubRegions(res));
    }, [filter.region?.Id]);

    return {
        filter,
        setFilter,
        regions,
        setRegions,
        subRegions,
        setSubRegions,
        searchSubRegion,
        setSearchSubRegion,
        searchValue,
        setSearchValue,
        filteredSubRegion,
        handleChange,
        hasMoreRegions,
        hasMoreSubRegions,
        handleReset,
        onGetMoreRegions,
        onGetMoreSubRegions,
        loading,
        setLoading,
        handleChangeSearchWarden,
    };
}
