import { fakeArray } from '@helpers';
import { Stack, FormControl, Select, MenuItem, Typography, styled } from '@mui/material';
import { difference, isUndefined } from 'lodash';
import React from 'react';
import { SHIFT_STEP, TOTAL_DAY_SHIFT } from '.';
import { formatHour } from './Shift';

export type Value = {
    startMin?: number;
    endMin?: number;
};

type Props = {
    value?: Value;
    onChange?(value: Value): void;
    minWidth?: number;
    fullWidth?: boolean;
    startMins: number[];
    endMins: number[];
    disabled?: boolean;
};

const StyledSelect = styled(Select)({
    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        paddingTop: '9.9375px',
        paddingBottom: '9.9375px',
    },
    // '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    //     opacity: 1,
    //     WebkitTextFillColor: 'rgba(0, 0, 0, .65)',
    // },
    '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
    },
});

export default function ShiftRange(props: Props) {
    return (
        <Stack direction={'row'} spacing={1} pt={'1px'} width="100%">
            <FormControl
                fullWidth={props.fullWidth}
                size="small"
                sx={{ minWidth: props.minWidth ? props.minWidth : 130 }}
            >
                <StyledSelect
                    disabled={props.disabled}
                    value={props.value?.startMin ?? ''}
                    onChange={(e) => {
                        const start = e.target.value as number;
                        console.log(`_start`, start);
                        props.onChange?.({
                            ...props.value,
                            startMin: start,
                            endMin:
                                props.value?.endMin === undefined || start >= props.value.endMin
                                    ? undefined
                                    : props.value.endMin,
                        });
                    }}
                    MenuProps={{ sx: { maxHeight: 200 } }}
                >
                    {props.startMins.map((t) => {
                        return (
                            <MenuItem key={t + 'start'} value={t}>
                                {formatHour(t)}
                            </MenuItem>
                        );
                    })}
                </StyledSelect>
            </FormControl>

            <Typography sx={{ lineHeight: '37.13px' }}>-</Typography>

            <FormControl
                fullWidth={props.fullWidth}
                size="small"
                sx={{ minWidth: props.minWidth ? props.minWidth : 130 }}
                error={!props.value?.endMin}
            >
                <StyledSelect
                    disabled={isUndefined(props.value?.startMin) || props.disabled}
                    value={props.value?.endMin ?? ''}
                    onChange={(e) => props.onChange?.({ ...props.value, endMin: e.target.value as number })}
                    MenuProps={{ sx: { maxHeight: 200 } }}
                >
                    {props.endMins.map((t) => {
                        return (
                            <MenuItem key={t + 'end'} value={t}>
                                {formatHour(t)}
                            </MenuItem>
                        );
                    })}
                </StyledSelect>
            </FormControl>
        </Stack>
    );
}
