import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Cluster } from '@LocationOps/model';
import { PropsWithTime, WardenEvent } from '@LocationOps/model/WardenEvent';
import { DailyTicketHour } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { GetWeeklyTicketHoursProps, IWardenEventController } from '../IWardenEventController';

export interface CustomGetListCluster extends ListProps<Cluster> {
    CountryRegionId?: number;
}

export class WardenEventHttpController<T> extends BaseHttpController<WardenEvent<T>> implements IWardenEventController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'wardenEvent', client);
    }
    async getWeeklyTicketHours(props: GetWeeklyTicketHoursProps): Promise<DailyTicketHour[]> {
        const res = await this.doPost({ path: `ticket-hour`, body: props });
        return res.data;
    }

    async filterByTime<T = any>(props: PropsWithTime<T>): Promise<Paging<WardenEvent<T>>> {
        const res = await this.doPost({ path: 'filter-time-range', body: props });
        return res.data;
    }
}
