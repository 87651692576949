import { CheckInDetail, CheckOutDetail, WardenEvent } from '@LocationOps/model/WardenEvent';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import {
    BaseWardenEventItem,
    MapItemCreated,
    ReturnElement,
    secondToHHMMSS,
    WardenEventItemProps,
} from './BaseWardenEventItem';

export const CheckOut: ReturnElement = {
    ListItem: (props: WardenEventItemProps<CheckOutDetail>) => {
        const text = `OUT ${props.item.Location?.Name}`;

        return (
            <BaseWardenEventItem
                {...props}
                text={text}
                details={() => (
                    <Stack direction={'row'} justifyContent="space-between">
                        <Typography variant="caption" color="GrayText">
                            Time spent on site: {secondToHHMMSS(props.item.Detail.TimeSpentOnSite)}
                        </Typography>
                        <Typography variant="caption" color="GrayText">
                            Tickets issued: {props.item.Detail.TicketsIssued || 0}
                        </Typography>
                    </Stack>
                )}
            />
        );
    },
    MapItem: (props: WardenEvent) => (
        <div>
            <Typography>Check out {props.Location?.Name}</Typography>
            <MapItemCreated Created={props.Created!} />
        </div>
    ),
};
