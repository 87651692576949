import { Stack } from '@mui/material';

type Props = {
    size: number;
    color: string;
};

export default function Circle(props: Props) {
    return (
        <Stack
            sx={{ width: props.size, height: props.size, borderRadius: '50%', backgroundColor: props.color }}
        ></Stack>
    );
}
