import { rotaTemplateController } from '@controllers';
import usePopUp from '@hooks/usePopUp';
import { Box, Button, Collapse, Grow, Stack, Typography, Zoom } from '@mui/material';
import { Filter } from '@pages/rota-coverage/hooks/useRota';
import { useWarden } from '@pages/setting/warden/detail';
import { RotaTemplateDetail, Warden } from '@WardenOps/model';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlusSm } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { pushSuccess } from '@components/StyledToast';
import { v4 } from 'uuid';
import FilterDateRotaTemplate from './FilterDateRotaTemplate';
import PopUpConfirmUpdate from './PopUpConfirmUpdate';
import RotaWeeklyItem, { ScheduleWithStatus } from './RotaWeeklyItem';
import useThrottle from '@rooks/use-throttle';

type Props = {};

function WeeklySchedule(props: Props) {
    const { warden } = useWarden();
    const { t } = useTranslation();
    const params = useParams<{ id: string }>();
    const [filter, setFilter] = useState<Filter>({
        startDate: moment().startOf('isoWeek').add(1, 'weeks').toDate(),
    });

    const popUpConfirmUpdate = usePopUp();

    const [state, setState] = useState<ScheduleWithStatus[]>([]);
    console.log(`state`, state);
    const [lastOpen, setLastOpen] = useState<ScheduleWithStatus[]>([]);
    const [deleted, setDeleted] = useState<number[]>([]);

    const hasTemplate = Boolean(state.length > 0) && Boolean(state.length < 3);
    const handleChange = (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const findIndex = (schedules: ScheduleWithStatus[], schedule: ScheduleWithStatus) =>
        schedules.findIndex((s) => s._id === schedule._id);

    const handleAdd = async () => {
        setState((prev) => {
            const clonePrev = cloneDeep(prev);
            clonePrev.push({
                _id: v4(),
                defaultOpen: true,
                scheduleStatus: 'new',
                RotaTemplateDetails: [],
                WardenId: Number(params.id),
            } as any);
            setLastOpen(clonePrev);
            setDeleted((prev) => [...prev, clonePrev[clonePrev.length - 1].OrderIndex]);
            return clonePrev;
        });
    };

    const toggleOpen = (schedule: ScheduleWithStatus) => {
        setLastOpen((prev) => {
            const clonePrev = cloneDeep(prev);
            clonePrev.unshift(schedule);
            const listLastOpen = _.uniqBy(clonePrev, 'OrderIndex');
            return listLastOpen;
        });
        setState((prev) => {
            const newArr = prev.slice();
            const index = findIndex(prev, schedule);
            newArr[index].defaultOpen = !newArr[index].defaultOpen;
            return newArr;
        });
    };
    const handleUpdateTemplate = async (templates: ScheduleWithStatus[]) => {
        await rotaTemplateController.bulkUpsert({ list: templates, WardenId: Number(params.id) }).then((res) => {
            const _state: ScheduleWithStatus[] = res.map((item) => {
                const open = lastOpen.find((o) => o.OrderIndex === item.OrderIndex);

                const _schedule: ScheduleWithStatus = {
                    ...item,
                    _id: v4(),
                    RotaTemplateDetails: item.RotaTemplateDetails?.map((t) => ({
                        ...t,
                        _id: v4(),
                    })),
                    defaultOpen: open?.defaultOpen,
                };
                return _schedule;
            });

            setState(_state);
            if (res.length > 0) {
                setFilter((prev) => ({ ...prev, startDate: res[0].TimeStart }));
            }
            setDeleted([]);
            pushSuccess('Updated schedule successfully!');
        });
    };

    const handleSubmitUpdate = () => {
        handleUpdateTemplate(state);
        popUpConfirmUpdate.onClose();
    };

    const [throttleUpdateSchedule] = useThrottle(handleSubmitUpdate, 2000);

    useEffect(() => {
        state.forEach((item, index) => {
            item.TimeStart = moment(filter.startDate).add(index, 'weeks').toDate();
            item.OrderIndex = index + 1;
        });
    }, [state, filter.startDate]);

    const init = () => {
        rotaTemplateController.list({ filter: { WardenId: params.id as any }, pageSize: 1000 }).then((res) => {
            setState(
                res.rows.map((item) => {
                    const rotaTemplate = {
                        ...item,
                        _id: v4(),
                        RotaTemplateDetails: item.RotaTemplateDetails?.map((t) => ({ ...t, _id: v4() })),
                        defaultOpen: item.OrderIndex === 1,
                    };
                    setLastOpen((prev) => [...prev, rotaTemplate]);
                    return rotaTemplate;
                })
            );
        });
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Box width="100%" sx={{ background: '#FAFAFA', borderRadius: 2, p: 2 }} mt={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" gap={2} sx={{ cursor: 'pointer' }}>
                    <Typography fontSize={20} fontWeight={500}>
                        {t('wardenDetailsPage.title.weeklySchedule')}
                        <Typography component={'span'} color="#85858A" pl={1}>
                            {t('wardenDetailsPage.text.contractedHours')}: {warden?.ContractHours}h/week
                        </Typography>
                    </Typography>
                </Stack>
                {!hasTemplate && (
                    <Zoom in={Boolean(state.length <= 0)} timeout={350}>
                        <div>
                            <Button
                                sx={{ mt: 1, height: 40 }}
                                startIcon={<HiPlusSm />}
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                {t('wardenDetailsPage.button.addWeek')}
                            </Button>
                        </div>
                    </Zoom>
                )}
            </Stack>

            <Collapse in={true}>
                {state
                    .sort((a, b) => a.OrderIndex - b.OrderIndex)
                    .map((item, index) => {
                        return (
                            <Grow in={true} key={index} timeout={500}>
                                <div>
                                    <RotaWeeklyItem
                                        key={index + 's'}
                                        schedule={item}
                                        onDelete={(schedule) => {
                                            console.log(`schedule1`, schedule);
                                            setState((prev) => prev.filter((item) => item._id !== schedule._id));
                                            setDeleted((prev) => [...prev, schedule.OrderIndex]);
                                        }}
                                        onToggle={toggleOpen}
                                        onChange={(schedule) => {
                                            console.log('onChange schedule', schedule);
                                            setState((prev) =>
                                                prev.map((item) => {
                                                    setDeleted((prev) => [...prev, item.OrderIndex]);
                                                    return {
                                                        ...item,
                                                        RotaTemplateDetails:
                                                            item._id === schedule._id
                                                                ? schedule.RotaTemplateDetails
                                                                : item.RotaTemplateDetails,
                                                    };
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            </Grow>
                        );
                    })}

                {Boolean(state.length > 0) && (
                    <Button
                        sx={{
                            mt: 2,
                            height: 40,
                            '&.Mui-disabled': {
                                backgroundColor: 'unset',
                            },
                        }}
                        startIcon={<HiPlusSm />}
                        onClick={handleAdd}
                        disabled={Boolean(state.length === 3)}
                    >
                        {t('wardenDetailsPage.button.addWeek')}
                    </Button>
                )}

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2}>
                    <Button
                        variant="cancel"
                        onClick={() => {
                            init();
                            setDeleted([]);
                            pushSuccess('Cancelled successfully!');
                        }}
                        sx={{ flexBasis: 150 }}
                        disabled={!deleted.length}
                    >
                        {t('wardenDetailsPage.button.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => popUpConfirmUpdate.setTrue()}
                        disabled={!deleted.length}
                        sx={{ flexBasis: 150 }}
                    >
                        {t('wardenDetailsPage.button.updateSchedule')}
                    </Button>
                </Stack>
            </Collapse>

            <PopUpConfirmUpdate
                {...popUpConfirmUpdate}
                title="Confirm"
                message="This schedule will be valid from: "
                subMessage1="It will replace the work calendar for all following weeks from this date."
                subMessage2="Do you still want to confirm this schedule?"
                onClose={() => {
                    popUpConfirmUpdate.onClose();
                }}
                renderApplyDate={() => (
                    <FilterDateRotaTemplate
                        startDate={filter.startDate}
                        onChange={(startDate) => {
                            handleChange({ startDate });
                        }}
                    />
                )}
                onConfirm={throttleUpdateSchedule}
            />
        </Box>
    );
}

export default React.memo(WeeklySchedule);
