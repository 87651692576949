import Circle from '@components/Circle';
import IcEdit from '@components/icon/IcEdit';
import PopUpBase from '@components/PopUpBase';
import PopUpWarning from '@components/PopUpWarning';
import { BoxOption } from '@components/select-search/StyledSelect';
import { BaseHead, CellOfRow, SummaryText, TableCells } from '@components/utils';
import { paymentController } from '@controllers';
import { formatDate, rounded } from '@helpers';
import usePopUp, { IPopUp } from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Fade,
    Stack,
    styled,
    SxProps,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import { PeriodTimesheets, Timesheets } from '@WardenOps/model';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import useTimeSheets from '../hooks/useTimeSheets';
import IcExportData from '@components/icon/IcExportData';
import { pushSuccess } from '@components/StyledToast';

type Props = IPopUp & {
    startDate: Date;
    // selectedTimeSheet: TimesheetsStatistic;
    periodsTimesheetId: number;
    onApprove(timesheet: Timesheets[]): void;
};

const Option = styled(Stack)({
    transition: '0.25s',
    padding: '0px 12px',
    marginTop: '-1px',
    // borderBottom: "1px solid #eee",
});
const SelectOption = ({
    title,
    icon,
    onClickSelect,
    sxProps,
    selectedColor,
}: {
    title: string;
    icon: ReactNode;
    onClickSelect(): void;
    sxProps?: SxProps;
    selectedColor: string;
}) => {
    return (
        <Option
            p={'12px !important'}
            sx={{
                cursor: 'pointer',
                color: (theme) => theme.palette.secondary.main,
                ':hover': {
                    backgroundColor: selectedColor,
                },
                ...sxProps,
            }}
            onClick={onClickSelect}
        >
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                {icon}
                <Typography>{title}</Typography>
            </Stack>
        </Option>
    );
};
export default function PopUpTimeSheets(props: Props) {
    const popUpWarning = usePopUp();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [timesheetsDetails, setTimesheetsDetails] = useState<Timesheets[]>([]);
    const [selectedTimeSheet, setSelectedTimeSheet] = useState<PeriodTimesheets>({} as PeriodTimesheets);
    const initialSelectedTimeSheet = useRef<PeriodTimesheets>({} as PeriodTimesheets);

    const [selectedFields, setSelectedFields] = useState(-1);

    const { statistic, totalTimesheets } = useTimeSheets({ timesheetDetails: selectedTimeSheet.Timesheets! });

    const paidHoursText = () => {
        let workedHours: any = [];
        let dayOffHours: any = [];
        Object.values(statistic).forEach((item, index) => {
            if (!!item) {
                workedHours.push(index === 0 ? item : `- ${item}`);
            }
        });
        Object.values(statistic)
            .slice(5)
            .forEach((item) => {
                if (!!item) {
                    dayOffHours.push(rounded(item));
                }
            });
        return {
            workedHoursText: workedHours.join(' '),
            dayOffHoursText: dayOffHours.join(' + '),
        };
    };
    const currentWeek = moment().startOf('isoWeek').toDate();
    const enabledEditTimeSheet = moment(currentWeek).isSame(props.startDate);

    const TimeTo = moment(props.startDate).add(6, 'days').toDate();

    const isEmpty = !selectedTimeSheet.Timesheets?.length && !loading;

    const selectedColor = !selectedTimeSheet.Approved ? '#FAFAFA' : 'none';

    const assignedDay = selectedTimeSheet.Timesheets?.filter(
        (ts) => !!ts.AssignedHour || !!ts.HolidayHour || !!ts.LieuHour
    ).length;

    const tabledRows = [
        { title: `Day (${assignedDay}/7)`, align: 'left' },
        { title: 'Assigned', align: 'center', background: '#E8F5E9' },
        { title: 'Break', align: 'center', background: '#E8F5E9' },
        { title: 'Sick', align: 'center', background: '#E8F5E9' },
        { title: 'Absent', align: 'center', background: '#E8F5E9' },
        { title: 'Unabsent', align: 'center', background: '#E8F5E9' },
        {
            title: 'Holiday',
            align: 'center',
            background: 'rgba(224, 27, 0, 0.1)',
        },
        {
            title: 'Lieu',
            align: 'center',
            background: 'rgba(224, 27, 0, 0.1)',
        },
    ];

    useEffect(() => {
        setLoading(true);

        if (!props.periodsTimesheetId || !props.open) {
            setSelectedTimeSheet({} as PeriodTimesheets);
            setLoading(false);
            return;
        }
        paymentController
            .getTimesheetsDetails({ PeriodTimesheetsId: props.periodsTimesheetId })
            .then((res) => {
                initialSelectedTimeSheet.current = res;
                setSelectedTimeSheet(res);
            })
            .finally(() => setLoading(false));
    }, [props.periodsTimesheetId, props.open]);

    const getInitialSelectedTimeSheet = () => initialSelectedTimeSheet.current;

    const handleExportExcel = async () => {
        const operativesTimesheet = getInitialSelectedTimeSheet();
        await paymentController
            .downloadTimesheetsDetails({ operativesTimesheetsDetail: operativesTimesheet, TimeFrom: props.startDate })
            .then((res) => {
                const bufRes = (res as any).buffer.data;
                const buf = new Uint8Array(bufRes).buffer;
                const buftype = 'application/vnd.ms-excel;charset=utf-8';
                let blob = new Blob([buf], {
                    type: buftype,
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `PARKINGOPERATIVE_${moment(props.startDate).format('DD MMMM YYYY')} - ${moment(TimeTo).format(
                        'DD MMMM YYYY'
                    )}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                pushSuccess('Downloaded successfully');
            });
    };

    return (
        <PopUpBase
            {...props}
            fixOverflow
            dialogProps={{
                scroll: 'paper',
                fullWidth: true,
                maxWidth: 'lg',
            }}
            onConfirm={() => {
                props.onConfirm?.();
            }}
            title={
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                    <Stack>
                        <Typography fontSize={18} align="left" fontWeight={600}>
                            {`Details timesheet of ${selectedTimeSheet.Warden?.FullName}`}
                        </Typography>
                        <Typography variant="body2" color="secondary" align="left">
                            {`From ${formatDate(props.startDate)} to ${
                                !enabledEditTimeSheet
                                    ? formatDate(TimeTo)
                                    : formatDate(moment().subtract(1, 'days').toDate())
                            }`}
                        </Typography>
                    </Stack>

                    {!!selectedTimeSheet.Timesheets?.length && !enabledEditTimeSheet && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Stack
                                sx={{
                                    width: '100%',
                                    height: 30,
                                    background: '#FFDC83',
                                    borderRadius: '3px',
                                    p: '0px 8px',
                                }}
                                direction="row"
                                alignItems="center"
                            >
                                <Typography>
                                    {`Paid hour(s) for Full day holiday = Contracted hours/Assigned day = ${
                                        selectedTimeSheet.ContractedHour
                                    }h/${!!assignedDay ? assignedDay : 1} day(s) = ${rounded(
                                        selectedTimeSheet.ContractedHour / (!!assignedDay ? assignedDay : 1)
                                    )}`}
                                </Typography>
                            </Stack>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <Box width={'auto'} sx={{ position: 'relative' }}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => setOpen((prev) => !prev)}
                                        // startIcon={!isEdit ? <BiShowAlt style={{ fontSize: 20 }} /> : <IcEdit />}
                                        sx={{ p: '0.52rem 1rem', minWidth: 135 }}
                                        endIcon={
                                            <Box
                                                sx={{
                                                    transform: `rotate(${open ? 0 : 180}deg)`,
                                                    color: (theme) => theme.palette.secondary.main,
                                                    transition: 'all 0.3s',
                                                }}
                                            >
                                                <MdOutlineArrowDropDown fontSize={20} />
                                            </Box>
                                        }
                                    >
                                        {!isEdit ? 'View only' : 'Edit'}
                                    </Button>
                                    <Fade in={open}>
                                        <BoxOption sx={{ color: (theme) => theme.palette.primary.dark }}>
                                            <SelectOption
                                                icon={<BiShowAlt style={{ fontSize: 20 }} />}
                                                title="View only"
                                                selectedColor={selectedColor}
                                                onClickSelect={() => {
                                                    setIsEdit(false);
                                                    setOpen(false);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '1px',
                                                    borderTop: '1px solid #ddd',
                                                    paddingBottom: 1,
                                                }}
                                            />
                                            <SelectOption
                                                icon={<IcEdit />}
                                                title="Edit"
                                                selectedColor={selectedColor}
                                                onClickSelect={() => {
                                                    setIsEdit(true);
                                                    setOpen(false);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '1px',
                                                    borderTop: '1px solid #ddd',
                                                    paddingBottom: 1,
                                                }}
                                            />
                                            <SelectOption
                                                icon={<IcExportData color="secondary" />}
                                                title="Export"
                                                selectedColor={selectedColor}
                                                onClickSelect={() => {
                                                    handleExportExcel();
                                                    setOpen(false);
                                                }}
                                            />
                                        </BoxOption>
                                    </Fade>
                                </Box>
                            </ClickAwayListener>
                        </Stack>
                    )}
                </Stack>
            }
            dialogActionsProps={{ sx: { justifyContent: 'flex-end' } }}
            desc={
                <>
                    <TableContainer component={Box}>
                        {loading ? (
                            <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <>
                                {isEmpty ? (
                                    <EmptyPage />
                                ) : (
                                    <>
                                        <Stack direction="row" justifyContent="flex-end" sx={{ height: 35 }}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    flexBasis: 664,
                                                    background: (theme) => theme.palette.secondary.main,
                                                    borderRight: '1px solid #fff',
                                                    color: '#fff',
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight={600}>
                                                    Worked hours = Assigned - Break - Other events - Holiday - Lieu
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    flexBasis: 264,
                                                    background: '#FD5F6A',
                                                    color: '#fff',
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight={600}>
                                                    Holiday/Lieu
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ background: '#FAFAFA' }}>
                                                    {tabledRows.map((item, index) => {
                                                        return (
                                                            <BaseHead
                                                                title={item.title}
                                                                align={item.align as any}
                                                                sx={{
                                                                    background: item.background,
                                                                    minWidth: 90,
                                                                    height: 35,
                                                                    p: '0px 16px',
                                                                    borderRight:
                                                                        index !== tabledRows.length - 1
                                                                            ? '1px solid #fff'
                                                                            : 'none',
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedTimeSheet.Timesheets?.map((ts, index) => {
                                                    return (
                                                        <TableRow>
                                                            <CellOfRow
                                                                value={
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                    >
                                                                        <Circle
                                                                            size={8}
                                                                            color={
                                                                                !!ts.AssignedHour ||
                                                                                !!ts.HolidayHour ||
                                                                                !!ts.LieuHour
                                                                                    ? 'success.main'
                                                                                    : '#C5C5C5'
                                                                            }
                                                                        />
                                                                        <Typography component={'span'} variant="body2">
                                                                            {moment(props.startDate)
                                                                                .weekday(index + 1)
                                                                                .format('DD/MM/yyyy')}{' '}
                                                                        </Typography>
                                                                        <Typography
                                                                            component={'span'}
                                                                            variant="body2"
                                                                            color={
                                                                                [5, 6].includes(index) ? 'red' : 'gray'
                                                                            }
                                                                        >
                                                                            {moment(props.startDate)
                                                                                .weekday(index + 1)
                                                                                .format('(ddd)')}{' '}
                                                                        </Typography>
                                                                    </Stack>
                                                                }
                                                                align="left"
                                                            />
                                                            <TableCells
                                                                value={ts.AssignedHour!}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          AssignedHour: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                            <TableCells
                                                                value={ts.BreakHour}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          BreakHour: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                            <TableCells
                                                                value={ts.SickHour}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          SickHour: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />

                                                            <TableCells
                                                                value={ts.Absent}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          Absent: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                            <TableCells
                                                                value={ts.Unabsent}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          Unabsent: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                            <TableCells
                                                                value={rounded(ts.HolidayHour)}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          HolidayHour: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                            <TableCells
                                                                value={rounded(ts.LieuHour)}
                                                                isEdit={isEdit}
                                                                onClick={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                                onChange={(e) => {
                                                                    setSelectedTimeSheet((prev) => {
                                                                        const newState = prev.Timesheets?.map(
                                                                            (o, index) => {
                                                                                return selectedFields === index
                                                                                    ? {
                                                                                          ...o,
                                                                                          LieuHour: Number(
                                                                                              e.target.value
                                                                                          ),
                                                                                      }
                                                                                    : o;
                                                                            }
                                                                        );
                                                                        return { ...prev, Timesheets: newState };
                                                                    });
                                                                }}
                                                                onFocus={() => {
                                                                    setSelectedFields(index);
                                                                }}
                                                            />
                                                        </TableRow>
                                                    );
                                                })}
                                                <TableRow
                                                    sx={{
                                                        '& .MuiTableCell-body .MuiTypography-root': {
                                                            color: (theme) => theme.palette.secondary.main,
                                                            fontWeight: 700,
                                                        },
                                                    }}
                                                >
                                                    {[
                                                        { Value: 'Total', Align: 'left' },
                                                        { Value: rounded(statistic.AssignedHour) },
                                                        { Value: rounded(statistic.BreakHour) },
                                                        { Value: rounded(statistic.SickHour) },
                                                        { Value: rounded(statistic.Absent) },
                                                        { Value: rounded(statistic.Unabsent) },
                                                        { Value: rounded(statistic.HolidayHour) },
                                                        { Value: rounded(statistic.LieuHour) },
                                                    ].map((item) => (
                                                        <CellOfRow value={item.Value} align={item.Align as any} />
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Stack width="100%" justifyContent="center" alignItems="center" spacing={2}>
                                            {!enabledEditTimeSheet && (
                                                <Stack
                                                    direction="row"
                                                    width="100%"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{ background: '#FAFAFA', p: 1 }}
                                                >
                                                    <Typography component="span" fontSize={20}>
                                                        Total payment hours ={' '}
                                                        <Typography component="span" color="secondary" fontSize={20}>
                                                            Total worked hours
                                                        </Typography>
                                                        {' + '}
                                                        <Typography component="span" color="#FD5F6A" fontSize={20}>
                                                            Total holiday/lieu
                                                        </Typography>
                                                        {` = `}
                                                        <Typography component="span" color="secondary" fontSize={20}>
                                                            {paidHoursText().workedHoursText
                                                                ? `(${paidHoursText().workedHoursText})`
                                                                : 0}
                                                        </Typography>
                                                        {` + `}
                                                        <Typography component="span" color="#FD5F6A" fontSize={20}>
                                                            {paidHoursText().dayOffHoursText
                                                                ? `(${paidHoursText().dayOffHoursText})`
                                                                : 0}
                                                        </Typography>
                                                        {` = `}
                                                        <Typography component="span" fontSize={20}>
                                                            {rounded(totalTimesheets)}
                                                        </Typography>
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {selectedTimeSheet.Approved && (
                                                <Typography
                                                    variant="h6"
                                                    color="#85858A"
                                                    fontStyle="italic"
                                                >{`Approved at ${moment(selectedTimeSheet.Updated!)
                                                    .tz('Europe/London')
                                                    .format('HH:mm:ss')} on ${formatDate(
                                                    selectedTimeSheet.Updated!
                                                )} by ${selectedTimeSheet.UpdatedByEmail}`}</Typography>
                                            )}
                                        </Stack>
                                    </>
                                )}
                            </>
                        )}
                    </TableContainer>
                </>
            }
            customActions={
                <>
                    <Stack direction="row" width="100%" justifyContent="space-between">
                        <Button
                            variant="cancel"
                            onClick={() => {
                                props.onClose?.();
                                setIsEdit(false);
                            }}
                            sx={{ minWidth: 135 }}
                        >
                            Cancel
                        </Button>
                        {!!selectedTimeSheet.Timesheets?.length && !!isEdit && (
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ minWidth: 135 }}
                                onClick={() => popUpWarning.setTrue()}
                            >
                                Approve
                            </Button>
                        )}
                    </Stack>
                    <PopUpWarning
                        {...popUpWarning}
                        title="Confirm"
                        message={'Please confirm approval for this timesheet.'}
                        onConfirm={() => {
                            props.onApprove(selectedTimeSheet.Timesheets!);
                            setIsEdit(false);
                            popUpWarning.onClose?.();
                            props.onClose?.();
                        }}
                    />
                </>
            }
        />
    );
}
