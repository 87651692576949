import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import PopUpBase from '@components/PopUpBase';
import { BaseHead, CellOfRow } from '@components/utils';
import { expensesRateController } from '@controllers';
import { Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { IPopUp } from '@hooks/usePopUp';
import {
    Button,
    CircularProgress,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import EmptyPage from '@pages/setting/components/EmptyPage';
import { ExpenseRate } from '@WardenOps/model';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = IPopUp & {
    title?: string;
    message?: string;
    wardenId: number;
};

export default function PopUpHistoryChangeRate(props: Props) {
    const [pagingHistories, setPagingHistories] = useState<Paging<ExpenseRate>>(defaultPaging({ pageSize: 5 }));
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const isEmpty = !pagingHistories.rows.length && !loading;

    useEffect(() => {
        setLoading(true);
        if (!props.wardenId || !props.open) return;
        expensesRateController
            .list({
                filter: { WardenId: props.wardenId },
                page: page,
                pageSize: 5,
                sorts: ['-EffectFromDate', '-Created'],
            })
            .then((res) => {
                setPagingHistories(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.wardenId, props.open, page]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'md',
                PaperProps: {
                    sx: { minHeight: 375 },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={<Typography variant="h4">{props.title}</Typography>}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack alignItems="center">
                    <TableContainer component={Box}>
                        {loading ? (
                            <Stack direction="row" justifyContent="center" alignItems="center" minHeight={375}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <Stack alignItems="center">
                                {isEmpty ? (
                                    <EmptyPage stackProps={{ sx: { minHeight: 375 } }} />
                                ) : (
                                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                        <TableRow>
                                            <BaseHead align="center" title="Effective from" />
                                            <BaseHead align="center" title="Expense rate" />
                                            <BaseHead align="center" title="Updated by" />
                                            <BaseHead align="center" title="Updated at" />
                                        </TableRow>
                                        <TableBody>
                                            {pagingHistories.rows.map((item, index) => {
                                                return (
                                                    <TableRow>
                                                        <CellOfRow
                                                            value={moment(item.EffectFromDate).format('DD/MM/YYYY')}
                                                            align="center"
                                                        />
                                                        <CellOfRow value={item.Rate} align="center" />
                                                        <CellOfRow value={item.UpdatedByEmail!} align="center" />
                                                        <CellOfRow
                                                            value={moment(item.Created)
                                                                .tz('Europe/London')
                                                                .format('HH:mm:ss DD/MM/YYYY')}
                                                            align="center"
                                                        />
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                )}
                                {pagingHistories.totalPages > 1 && (
                                    <Pagination
                                        count={pagingHistories.totalPages}
                                        page={pagingHistories.page}
                                        onChange={(e: any, value: any) => {
                                            setPage(value);
                                        }}
                                        shape="rounded"
                                        sx={{
                                            mt: 2,
                                            '& .Mui-selected': {
                                                background: '#3479BB1A !important',
                                            },
                                            '& .MuiPaginationItem-previousNext': {
                                                background: '#EEEEEE',
                                            },
                                        }}
                                        renderItem={(item) => (
                                            <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                        )}
                                    />
                                )}
                            </Stack>
                        )}
                    </TableContainer>
                </Stack>
            }
            customActions={
                <Stack width="100%" mt={3} direction={'row'} justifyContent="flex-end">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setPage(1);
                        }}
                    >
                        Cancel
                    </Button>
                </Stack>
            }
        />
    );
}
