import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AvgPCNVisitColor, Location, LocationWithRelations, TotalPCNsIssued, TotalPcnFilter } from '@LocationOps/model';
import { LocationStatistics } from '@LocationOps/model/LocationStatistics';
import { AxiosInstance } from 'axios';
import {
    GetLocationCustomProps,
    ILocationController,
    LocationColor,
    UpdateLocationWithRelations,
} from '../ILocationController';

export class LocationHttpController extends BaseHttpController<LocationWithRelations> implements ILocationController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'location', client);
    }

    async getStatistics(id: number): Promise<LocationStatistics[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/${id}/statistics`);
        return res.data;
    }

    list(params: GetLocationCustomProps): Promise<Paging<LocationWithRelations>> {
        return super.list(params);
    }

    async listAssignableToCluster(props: ListProps<Location>): Promise<Paging<LocationWithRelations>> {
        const res = await this.doPost({
            path: 'valid-location',
            body: props,
        });

        return res.data;
    }

    async updateLocationWithRelations(props: UpdateLocationWithRelations): Promise<LocationWithRelations> {
        const response = await this.doPost({ path: 'update-with-relations', body: props });
        return response.data;
    }

    async getColorLocation(id: number): Promise<LocationColor> {
        const res = await this.doGet({ path: `${id}/color` });
        return res.data;
    }

    async pcnsIssuedPerHour(props: TotalPcnFilter): Promise<TotalPCNsIssued> {
        const response = await this.doPost({ path: 'pcn-issued-per-hour', body: props });
        return response.data;
    }
}
