import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcSiteEarlyOff(props: SvgIconProps) {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.928589 1.5C0.928589 0.671571 1.60016 0 2.42859 0H4.57145C4.92648 0 5.2143 0.28782 5.2143 0.642857C5.2143 0.997894 4.92648 1.28571 4.57145 1.28571H2.42859C2.31024 1.28571 2.2143 1.38165 2.2143 1.5V10.5C2.2143 10.6184 2.31024 10.7143 2.42859 10.7143H4.57145C4.92648 10.7143 5.2143 11.0021 5.2143 11.3571C5.2143 11.7122 4.92648 12 4.57145 12H2.42859C1.60016 12 0.928589 11.3284 0.928589 10.5V1.5ZM9.87656 5.35714H5.00003C4.645 5.35714 4.35718 5.64496 4.35718 6C4.35718 6.35505 4.645 6.64286 5.00003 6.64286H9.87656L8.18833 8.33117C7.93727 8.58223 7.93727 8.9892 8.18833 9.24026C8.43939 9.49131 8.84636 9.49131 9.09742 9.24026L11.8831 6.45457C12.1342 6.20352 12.1342 5.79648 11.8831 5.54543L9.09742 2.75972C8.84636 2.50867 8.43939 2.50867 8.18833 2.75972C7.93727 3.01077 7.93727 3.41781 8.18833 3.66885L9.87656 5.35714Z"
                fill="currentColor"
            />
        </svg>
    );
}
