import { Paging } from '@Core';
import { Expenses, ExpensesStatistic, PeriodTimesheets, Timesheets, TimesheetsStatistic } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import {
    IGetExpensesDetailProps,
    IGetExpensesStatisticProps,
    IGetOperativesTimesheetsDetailProps,
    IGetTimesheetsDetailProps,
    IGetTimesheetsStatisticProps,
    IPaymentHttpController,
} from '../IPaymentHttpController';

export class PaymentHttpController implements IPaymentHttpController {
    protected serviceURL: string;
    protected basePath: string;
    protected client: AxiosInstance;

    constructor(serviceURL: string, client: AxiosInstance) {
        this.serviceURL = serviceURL;
        this.basePath = 'payment';
        this.client = client;
    }

    async getExpensesStatistic(props: IGetExpensesStatisticProps): Promise<Paging<ExpensesStatistic>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/expenses/statistics`, props);
        return res.data;
    }

    async getExpensesDetails(props: IGetExpensesDetailProps): Promise<Paging<Expenses>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/expenses/details`, props);
        return res.data;
    }

    async downloadExpensesStatistic(props: IGetExpensesStatisticProps): Promise<{ buffer: Buffer }> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/expenses/download`, props);
        return res.data;
    }

    async getTimesheetsStatistic(props: IGetTimesheetsStatisticProps): Promise<Paging<TimesheetsStatistic>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/timesheets/statistics`, props);
        return res.data;
    }

    async getTimesheetsDetails(props: IGetTimesheetsDetailProps): Promise<PeriodTimesheets> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/timesheets/details`, props);
        return res.data;
    }

    async downloadTimesheetsDetails(props: IGetOperativesTimesheetsDetailProps): Promise<{ buffer: Buffer }> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/timesheets/details/download`, props);
        return res.data;
    }

    async downloadTimesheetsStatistic(props: IGetTimesheetsStatisticProps): Promise<{ buffer: Buffer }> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/timesheets/download`, props);
        return res.data;
    }

    async updateWeeklyTimesheets(props: Timesheets[]): Promise<Timesheets[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/timesheets/update`, props);
        return res.data;
    }

    async getStatusAutoGenExpenses(): Promise<boolean> {
        const res = await this.client.get(
            `${this.serviceURL}/${this.basePath}/expenses/get-status-auto-generate-expense`
        );
        return res.data;
    }

    async updateStatusAutoGenExpenses(props: { status: boolean }): Promise<boolean> {
        const res = await this.client.post(
            `${this.serviceURL}/${this.basePath}/expenses/update-status-auto-generate-expense`,
            props
        );
        return res.data;
    }
}
