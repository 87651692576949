import { BankHoliday } from '@LocationOps/model';
import { IGetWorkCalendarResult } from '@WardenOps/controller';
import { BaseTimesheetsStatistic, LeaveDay, LeaveDayType, Rota, RotaType } from '@WardenOps/model';
import { CStack } from '@components/FlexedStack';
import PopUpBase from '@components/PopUpBase';
import { pushSuccess } from '@components/StyledToast';
import IcNewEdit from '@components/icon/IcNewEdit';
import IcRoute from '@components/icon/navbar/IcRoute';
import { DEFAULT_WEEKLY_PERIOD, END_DAY_MINUTE, SHIFT_STEP, ShiftStatus } from '@components/rota-table';
import RotaWorkCalendar from '@components/rota-table/RotaWorkCalendar';
import StyledShift from '@components/rota-table/Shift';
import { rotaController, wardenController } from '@controllers';
import { getMin, getTimeByMin, rounded } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import { Button, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import FilterDate from '@pages/rota-coverage/components/FilterDate';
import { Filter, ShiftRota } from '@pages/rota-coverage/hooks/useRota';
import ShiftActionRota from '@pages/setting/location/detail/components/ShiftActionRota';
import { ShiftWithStatus } from '@pages/setting/location/detail/components/ShiftItem';
import PopUpAddShift from '@pages/setting/warden/detail/components/calendar-schedule/PopUpAddShift';
import useThrottle from '@rooks/use-throttle';
import { cloneDeep, groupBy, initial, intersection, isNil, upperFirst } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiErrorCircle } from 'react-icons/bi';
import { IoCalendarOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import color from 'src/theme/color';
import { v4 } from 'uuid';
import { useWarden } from '..';
import PopUpViewMap from './PopUpViewMap';

export type RotaWarden = Rota & { _id?: string; startMin?: number; endMin?: number };
export interface RotaResponse extends IGetWorkCalendarResult {
    Rotas: RotaWarden[];
}
export type WeeklyCalendarShift = ShiftWithStatus & {
    _rota?: RotaWarden;
    _leaveDay?: LeaveDay;
    _bankHoliday?: BankHoliday;
};
export type WeeklyWorkCalendar = Record<string, WeeklyCalendarShift[]>;

export const isPast = (date?: Date) => {
    return moment(date).isBefore(moment().startOf('day'));
};
export const isAfter4Week = (option: WeeklyCalendarShift | ShiftRota) => {
    const dateOption = moment(option.date).format('YYYY-MM-DD');
    const endOfMonth = moment(moment().startOf('isoWeek').toDate())
        .add(4, 'weeks')
        .endOf('isoWeek')
        .format('YYYY-MM-DD');
    const valueMonth = moment(dateOption).isAfter(endOfMonth);
    return valueMonth;
};

export const convertRota = (shift: WeeklyCalendarShift, wardenId: number) => {
    const { hour: startHour, min: startMin } = getTimeByMin(shift.startMin);
    const { hour: endHour, min: endMin } = getTimeByMin(shift.endMin);

    const timeTo =
        shift.endMin === END_DAY_MINUTE
            ? moment(shift.date).endOf('date').toDate()
            : moment(shift.date).hour(endHour).minutes(endMin).toDate();
    return {
        TimeFrom: moment(shift.date).hour(startHour).minutes(startMin).toDate(),
        TimeTo: timeTo,
        BreakHours: shift.breakHours,
        LocationId: shift.location?.Id,
        Location: shift.location,
        ClusterId: shift.cluster?.Id,
        Cluster: shift.cluster,
        WardenId: wardenId,
    } as Rota;
};
export const isWarning = (option: WeeklyCalendarShift) => {
    return (
        option.location?.Assignable === 'invalid' ||
        option.location?.Assignable === 'part-valid' ||
        option.cluster?.Assignable === 'invalid' ||
        option.cluster?.Assignable === 'part-valid'
    );
};

export const renderTitleWarning = (option: WeeklyCalendarShift) => {
    let message = '';
    if (option.location?.Assignable === 'invalid' || option.cluster?.Assignable === 'invalid') {
        message = 'PO will not see this shift in iTicket because this location/cluster is invalid now.';
    } else if (option.location?.Assignable === 'part-valid' || option.cluster?.Assignable === 'part-valid') {
        message = 'This cluster includes 1 or more invalid locations.';
    }
    return message;
};

export const createArray = (startMin: number, endMin: number, step: number) => {
    const arr = [];
    for (let i = startMin; i <= endMin; i += step) {
        arr.push(i);
    }

    return arr;
};

export default function WorkCalendar() {
    const params = useParams<{ id: string }>();
    const theme = useTheme();
    const { t } = useTranslation();
    const { warden } = useWarden();

    const popupViewMap = usePopUp();
    const popUpAddShift = usePopUp();
    const popUpConfirmSendRota = usePopUp();

    const [filter, setFilter] = useState<Filter>({
        startDate: moment().startOf('isoWeek').toDate(),
    });

    const [weeklyCalendarResponse, setWeeklyCalendarResponse] = useState<RotaResponse>({
        Rotas: [],
        LeaveDays: [],
        BankHolidays: [],
        TimesheetsStatistics: {} as BaseTimesheetsStatistic,
    });

    const { TimesheetsStatistics } = weeklyCalendarResponse;

    const [selectedShift, setSelectedShift] = useState<WeeklyCalendarShift>();
    const [selectDate, setSelectDate] = useState<Date>();
    const [loading, setLoading] = useState(true);

    const handleChange = async (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const weeklyCalendar = useMemo(() => {
        const _rota = weeklyCalendarResponse.Rotas.map((item) => ({
            ...item,
            Weekday: moment(item.TimeFrom).weekday(),
        }));
        const leaveDays = weeklyCalendarResponse.LeaveDays.map((item) => ({
            ...item,
            Weekday: moment(item.TimeFrom).weekday(),
        }));
        const bankHolidays = weeklyCalendarResponse.BankHolidays.map((item) => ({
            ...item,
            Weekday: moment(item.TimeFrom).weekday(),
        }));

        const rotaByWeekday = groupBy(_rota, 'Weekday');
        const leaveDayByWeekday = groupBy(leaveDays, 'Weekday');
        const bankHolidaysWeekday = groupBy(bankHolidays, 'Weekday');

        console.log(`leaveDayByWeekday`, leaveDayByWeekday);
        const _weeklyCalendar: WeeklyWorkCalendar = cloneDeep(DEFAULT_WEEKLY_PERIOD);

        Object.entries(rotaByWeekday).forEach(([weekday, rotas]) => {
            _weeklyCalendar[weekday] = rotas.map((rota) => {
                const isEndDay = moment(rota.TimeTo).endOf('day').isSame(rota.TimeTo);

                const status =
                    rota.RotaType && Object.values(LeaveDayType).includes(rota.RotaType as LeaveDayType)
                        ? ShiftStatus.overlapped
                        : (rota.Location && rota.Location.Id) || (rota.Cluster && rota.Cluster.Id)
                        ? ShiftStatus.assigned
                        : ShiftStatus.unassigned;

                const _shift: WeeklyCalendarShift = {
                    startMin: status === ShiftStatus.overlapped ? 0 : getMin(rota.TimeFrom),
                    endMin: status === ShiftStatus.overlapped ? 0 : isEndDay ? END_DAY_MINUTE : getMin(rota.TimeTo),
                    id: isNil(rota.Id) ? rota.Id : rota.Id,
                    status: status,
                    _id: rota._id,
                    breakHours: rota.BreakHours,
                    date: moment(rota.TimeFrom).toDate(),
                    location: rota.Location,
                    weekday: rota.Weekday,
                    cluster: rota.Cluster,
                    type: rota.ClusterId ? 'cluster' : 'static',
                    _rota: rota,
                };
                return _shift;
            });
        });
        console.log('Object.entries(leaveDayByWeekday)', Object.entries(leaveDayByWeekday));
        Object.entries(leaveDayByWeekday).forEach(([weekday, leaveDays]) => {
            leaveDays.forEach((day) => {
                const isEndDay = moment(day.TimeTo).endOf('day').isSame(day.TimeTo);

                _weeklyCalendar[weekday].push({
                    _id: v4(),
                    startMin: getMin(day.TimeFrom),
                    endMin: isEndDay ? END_DAY_MINUTE : getMin(day.TimeTo),
                    date: moment(day.TimeFrom).toDate(),
                    weekday: Number(weekday),
                    status: [LeaveDayType.HOLIDAY, LeaveDayType.LIEU].includes(day.Type)
                        ? ShiftStatus.lieuLeave
                        : ShiftStatus.otherEvents,
                    _leaveDay: day,
                });
            });
        });
        Object.entries(bankHolidaysWeekday).forEach(([weekday, bankHolidays]) => {
            bankHolidays.forEach((day) => {
                _weeklyCalendar[weekday].push({
                    _id: v4(),
                    startMin: 0,
                    endMin: 0,
                    weekday: Number(weekday),
                    status: ShiftStatus.bankHolidays,
                });
            });
        });
        return _weeklyCalendar;
    }, [weeklyCalendarResponse]);

    console.log(`weeklyCalendar`, weeklyCalendarResponse, weeklyCalendar);

    const isReadOnly = (option: WeeklyCalendarShift) => {
        const hasLocation =
            Boolean(Object.keys(option.location ?? {}).length > 0) ||
            Boolean(Object.keys(option.cluster ?? {}).length > 0);
        const disabledOtherEvents = option.status === ShiftStatus.otherEvents;
        return (isPast(option.date) && !hasLocation) || isAfter4Week(option) || disabledOtherEvents;
    };

    const handleChangeShift = (checkedWeekdays: number[], editingShift: ShiftWithStatus) => {
        console.log('handleChangeShift', checkedWeekdays, editingShift);
        let rotas = cloneDeep(weeklyCalendarResponse.Rotas);

        const getRotaType = (props: {
            weeklyCalendar: WeeklyWorkCalendar;
            weekday: number;
            startMin: number;
            endMin: number;
        }): RotaType => {
            const isOverlapped = weeklyCalendar[props.weekday]
                .filter((s) => s.status === ShiftStatus.lieuLeave)
                .some(
                    (shift) =>
                        !!intersection(
                            initial(createArray(props.startMin, props.endMin, SHIFT_STEP)),
                            initial(createArray(shift.startMin, shift.endMin, SHIFT_STEP))
                        ).length
                );

            console.log('getRotaType', props, isOverlapped);

            return isOverlapped ? RotaType.WORK_HARD : RotaType.WORK;
        };
        const { weekday, startMin, endMin } = editingShift;

        switch (editingShift.shiftStatus) {
            case 'deleted':
                if (!editingShift._id) {
                    alert('No _id');
                    console.log('No _id', editingShift);
                    return;
                }
                rotas = rotas?.filter((p) => p._id !== editingShift._id);
                break;
            case 'change':
                rotas = rotas?.map((p) => {
                    return editingShift._id === p._id
                        ? {
                              ...p,
                              ...convertRota(editingShift, warden.Id!),
                              RotaType: getRotaType({
                                  weeklyCalendar: weeklyCalendar,
                                  weekday: weekday!,
                                  startMin,
                                  endMin,
                              }),
                          }
                        : p;
                });
                break;
            case 'new':
                const RotaType = getRotaType({
                    weeklyCalendar: weeklyCalendar,
                    weekday: weekday!,
                    startMin,
                    endMin,
                });
                rotas.push({ ...convertRota(editingShift, warden.Id!), _id: editingShift._id, RotaType: RotaType });
                break;
        }

        for (const weekday of checkedWeekdays) {
            if (weekday === editingShift.weekday) continue;
            const { hour: startHour, min: startMin } = getTimeByMin(editingShift.startMin);
            const { hour: endHour, min: endMin } = getTimeByMin(editingShift.endMin);

            const _weekday = weekday === 0 ? 7 : weekday;
            const timeFrom = moment(editingShift?.date).hour(startHour).minutes(startMin).isoWeekday(_weekday).toDate();

            const timeTo =
                editingShift.endMin === END_DAY_MINUTE
                    ? moment(editingShift.date).isoWeekday(_weekday).endOf('day').toDate()
                    : moment(editingShift?.date).hour(endHour).minutes(endMin).isoWeekday(_weekday).toDate();

            rotas.push({
                _id: v4(),
                TimeFrom: timeFrom,
                TimeTo: timeTo,
                BreakHours: editingShift?.breakHours,
                WardenId: Number(params.id),
                Location: editingShift.location,
                LocationId: editingShift.location?.Id,
                ClusterId: editingShift.cluster?.Id,
                Cluster: editingShift.cluster,
                RotaType: getRotaType({
                    weeklyCalendar: weeklyCalendar,
                    weekday,
                    startMin: editingShift.startMin,
                    endMin: editingShift.endMin,
                }),
            } as RotaWarden);
        }

        console.log('periods', rotas);
        setLoading(true);
        rotaController
            .updateWorkCalendar({
                list: rotas,
                TimeFrom: moment(filter.startDate).startOf('day').toDate(),
                TimeTo: moment(filter.startDate).endOf('isoWeek').toDate(),
                WardenId: Number(params.id),
            })
            .then((res) => {
                setWeeklyCalendarResponse({
                    Rotas: res.Rotas.map((r) => ({ ...r, _id: v4() })),
                    LeaveDays: res.LeaveDays,
                    BankHolidays: res.BankHolidays,
                    TimesheetsStatistics: res.TimesheetsStatistics,
                });
                pushSuccess('Saved successfully!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const paymentHours =
        (TimesheetsStatistics.WorkedHour || 0) + (TimesheetsStatistics.HolidayHour + TimesheetsStatistics.LieuHour);
    const remainHours = Math.max(Number(rounded((warden.ContractHours ?? 0) - paymentHours)), 0);
    const overTimeHours = Math.max(Number(rounded(paymentHours - (warden.ContractHours ?? 0))), 0);

    function handleSendRota() {
        popUpConfirmSendRota.onClose();
        wardenController
            .sendWorkCalendar({
                sendType: 'specific',
                startOfWeek: filter.startDate,
                wardenIds: [warden.Id!],
            })
            .then((res) => {
                pushSuccess('Sent successfully!');
            });
    }

    const handleSubmit = (checkedWeekdays: number[], shiftSelected: ShiftWithStatus) => {
        popUpAddShift.onClose();
        handleChangeShift(checkedWeekdays, shiftSelected);
    };

    const [throttleSubmit] = useThrottle(handleSubmit, 2000);

    useEffect(() => {
        const init = () => {
            if (!params.id) return;
            setLoading(true);
            rotaController
                .getWorkCalendar({
                    filter: {
                        WardenId: params.id as any,
                        TimeFrom: moment(filter.startDate).startOf('day').toDate(),
                        TimeTo: moment(filter.startDate).endOf('isoWeek').toDate(),
                    },
                    pageSize: 1000,
                })
                .then((res) => {
                    setWeeklyCalendarResponse({
                        Rotas: res.Rotas.map((r) => ({ ...r, _id: v4() })),
                        LeaveDays: res.LeaveDays,
                        BankHolidays: res.BankHolidays,
                        TimesheetsStatistics: res.TimesheetsStatistics,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        init();
    }, [params.id, filter.startDate]);

    const today = moment(new Date()).startOf('weeks').toDate();
    const disabledInPastDay = moment(filter.startDate).startOf('day').isBefore(today);
    const disabledSendRota =
        !weeklyCalendarResponse.Rotas.some((rota) => rota.ClusterId || rota.LocationId) || disabledInPastDay;
    const isOverTime = !!Boolean(paymentHours > 48);

    return (
        <Box>
            <Box>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography fontSize={20} fontWeight={500}>
                        {t('wardenDetailsPage.title.workCalendar')}
                    </Typography>
                    <FilterDate startDate={filter.startDate} onChange={(startDate) => handleChange({ startDate })} />
                </Stack>

                <CStack
                    justifyContent="space-between"
                    sx={{ background: '#3479BB1A', borderRadius: 1, p: '8px 8px 8px 16px' }}
                >
                    <Stack width="100%" direction="row" gap={2}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <IcNewEdit />
                            <Typography variant="body1">
                                {t('wardenDetailsPage.text.contractedHours')}: {warden.ContractHours}h
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={0.5} sx={{ color: theme.palette.primary.main }}>
                            <IoCalendarOutline fontSize={16} color={color.success} />
                            <Stack direction="row" alignItems="center" spacing={0.2}>
                                <Typography variant="body1" color={'success.main'}>
                                    Payment hours:{' '}
                                    <Typography
                                        component="span"
                                        variant="body1"
                                        color={isOverTime ? 'error.main' : 'success.main'}
                                    >
                                        {paymentHours}h
                                    </Typography>
                                </Typography>
                                {isOverTime && (
                                    <Tooltip
                                        title="Note: Working hours exceed 48 hours"
                                        arrow
                                        placement="top"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    background: '#FBE8E5',
                                                    color: 'red',
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                },
                                            },
                                            arrow: {
                                                sx: {
                                                    color: '#FBE8E5',
                                                },
                                            },
                                        }}
                                    >
                                        <BiErrorCircle color="#E01B00" fontSize={18} />
                                    </Tooltip>
                                )}
                            </Stack>
                            <Typography variant="body1" color="success.main">
                                {`(Worked: ${TimesheetsStatistics.WorkedHour || 0}h; Holiday/lieu: ${
                                    (TimesheetsStatistics.HolidayHour ?? 0) + (TimesheetsStatistics.LieuHour ?? 0)
                                }h)`}
                            </Typography>
                        </Stack>

                        <Stack direction="row" gap={2} alignItems="center">
                            <Typography variant="body1">
                                {t('wardenDetailsPage.text.remainHours')}: {remainHours}h
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {t('wardenDetailsPage.text.overTime')}: {overTimeHours}h
                            </Typography>
                        </Stack>
                    </Stack>

                    <Tooltip
                        title={disabledSendRota ? 'No work schedule has been assigned this week' : ''}
                        placement="top"
                        arrow
                    >
                        <Box>
                            <Button
                                variant="contained"
                                disabled={disabledSendRota}
                                sx={{ whiteSpace: 'nowrap', py: '0.5rem' }}
                                onClick={popUpConfirmSendRota.setTrue}
                            >
                                Send rota
                            </Button>
                        </Box>
                    </Tooltip>
                </CStack>

                <Box mt={3}>
                    <RotaWorkCalendar
                        data={weeklyCalendar}
                        startDate={filter.startDate}
                        loading={loading}
                        getLieuTitle={(shifts) => {
                            const sortBy = { holiday: 0, lieu: 1, sick: 2, absent: 3, 'un-absent': 4, other: 5 };

                            const lieuShifts = shifts
                                .filter(
                                    (s) => s.status === ShiftStatus.lieuLeave || s.status === ShiftStatus.otherEvents
                                )
                                .sort((a, b) => sortBy[a._leaveDay?.Type!] - sortBy[b._leaveDay?.Type!]);

                            const reasonType = !!lieuShifts.length
                                ? lieuShifts.length > 1
                                    ? `${upperFirst(lieuShifts[0]._leaveDay?.Type)} +${lieuShifts.length - 1}`
                                    : `${upperFirst(lieuShifts[0]._leaveDay?.Type)}`
                                : undefined;

                            const bankShift = shifts.find((s) => s.status === ShiftStatus.bankHolidays);

                            return (
                                <Stack spacing={0.5}>
                                    {!!bankShift && (
                                        <Tooltip title={<Typography>Bank holiday</Typography>} arrow placement="top">
                                            <Box>
                                                <DayOffTag title="Holiday" color="#F09453" />
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {!!reasonType && (
                                        <Tooltip
                                            title={
                                                <>
                                                    {lieuShifts.map((s, i) => {
                                                        return (
                                                            <Typography>{`${upperFirst(s._leaveDay?.Type)}: ${
                                                                (s.endMin - s.startMin) / 60
                                                            }h (${upperFirst(
                                                                s._leaveDay?.Duration.replace('-', ' ')
                                                            )})`}</Typography>
                                                        );
                                                    })}
                                                </>
                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <Box>
                                                {/* <Badge
                                                    variant="dot"
                                                    invisible={!shifts.some((s) => s.status === ShiftStatus.overlapped)}
                                                    sx={{
                                                        '& .MuiBadge-badge': {
                                                            left: 'auto',
                                                            backgroundColor: '#F09453',
                                                        },
                                                    }}
                                                >
                                                </Badge> */}
                                                <DayOffTag title={reasonType} color="#FD5F6A" />
                                            </Box>
                                        </Tooltip>
                                    )}
                                </Stack>
                            );
                        }}
                        renderOption={(option: WeeklyCalendarShift) => {
                            const { Assignable } = option.location ?? option.cluster ?? {};

                            const warning =
                                Assignable === 'invalid'
                                    ? 'error'
                                    : Assignable === 'part-valid'
                                    ? 'warning'
                                    : undefined;

                            return (
                                <StyledShift
                                    key={option.id}
                                    boxProps={{ sx: { flex: 1, borderRight: 'none', height: '100%' } }}
                                    readonly={isReadOnly(option)}
                                    shift={option}
                                    onAddPeriod={(shift) => {
                                        setSelectedShift(shift);
                                        popUpAddShift.setTrue();
                                    }}
                                    warning={warning}
                                    toolTipProps={{
                                        title:
                                            !option.status || option.status === ShiftStatus.lieuLeave ? undefined : (
                                                <ShiftTooltip option={option} />
                                            ),
                                    }}
                                    renderActions={(shift) => {
                                        return (
                                            <>
                                                {!isPast(shift.date) ? (
                                                    <ShiftActionRota
                                                        key={shift.id}
                                                        shift={shift}
                                                        onEdit={(shift) => {
                                                            setSelectedShift(shift);
                                                            console.log('select shift', shift);
                                                            popUpAddShift.setTrue();
                                                        }}
                                                    />
                                                ) : undefined}
                                            </>
                                        );
                                    }}
                                />
                            );
                        }}
                        renderAction={(shifts, weekday, date) => (
                            <CStack sx={{ width: 80 }}>
                                <Tooltip title={'Routes'} placement="top" arrow>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            popupViewMap.setTrue();
                                            const _weekday = weekday === 0 ? 7 : weekday;
                                            const _date = moment(date).weekday(_weekday).toDate();
                                            setSelectDate(_date);
                                        }}
                                    >
                                        <IcRoute style={{ width: 25, height: 25 }} />
                                    </IconButton>
                                </Tooltip>
                            </CStack>
                        )}
                    />
                </Box>
            </Box>

            <PopUpAddShift
                {...popUpAddShift}
                selectShift={selectedShift}
                onConfirm={(checkedWeekdays, shiftSelected) => {
                    throttleSubmit(checkedWeekdays, shiftSelected);
                }}
                startDate={filter.startDate}
                workCalendar={weeklyCalendar}
            />

            <PopUpViewMap
                {...popupViewMap}
                allShifts={weeklyCalendar}
                selectDate={selectDate}
                warden={warden}
                startDateOfSelectedWeek={filter.startDate}
            />

            <PopUpBase
                {...popUpConfirmSendRota}
                dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
                title={<Typography variant="h4">Send rota calendar</Typography>}
                hideConfirm
                hideClose
                subTitleProps={{ sx: { color: 'gray' } }}
                minWidthButton={150}
                desc={
                    <Stack mt={0} pb={0.5}>
                        <Typography textAlign={'center'}>
                            Do you want to send work calendar from {moment(filter.startDate).format('DD/MM/YYYY')} to{' '}
                            {moment(filter.startDate).endOf('isoWeek').format('DD/MM/YYYY')} to {warden.FullName}?
                        </Typography>

                        <Stack mt={3} direction={'row'} justifyContent="space-between">
                            <Button sx={{ minWidth: 130 }} variant="cancel" onClick={popUpConfirmSendRota.onClose}>
                                Cancel
                            </Button>
                            <Button sx={{ minWidth: 130 }} variant="contained" color="success" onClick={handleSendRota}>
                                Send now
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Box>
    );
}

export function ShiftTooltip({ option }: { option: WeeklyCalendarShift }) {
    return (
        <>
            {option.status === ShiftStatus.assigned && (
                <>
                    <Typography color="#fff" variant="body2">
                        Break: {option.breakHours} hour(s)
                    </Typography>
                    <Typography color="#fff" variant="body2">
                        {option.cluster ? `Cluster: ${option.cluster.Name}` : `Location: ${option.location?.Name}`}
                    </Typography>
                </>
            )}
            {option.status === ShiftStatus.unassigned && (
                <Typography color="#fff" align="center" variant="body2">
                    Not assigned to site
                </Typography>
            )}
            {option.status === ShiftStatus.otherEvents && (
                <Typography color="#fff" align="center" variant="body2">
                    Reason: {upperFirst(option._leaveDay?.Type)}
                </Typography>
            )}
            {isWarning(option) && (
                <Typography variant="body2" fontStyle={'italic'}>
                    {`Note: ${renderTitleWarning(option)}`}
                </Typography>
            )}
        </>
    );
}

const DayOffTag = ({ title, color }: { title: string; color: string }) => {
    return (
        <Box
            sx={{
                width: 80,
                px: 0.5,
                py: 0.2,
                background: color,
                textAlign: 'center',
                borderRadius: '3px',
                color: 'white',
                fontSize: 12,
                cursor: 'pointer',
            }}
        >
            {title}
        </Box>
    );
};
