import { BaseModel } from '@Core';
import { BaseRotaTemplateDetail, LeaveDayDuration, LeaveDayType, Warden } from '@WardenOps/model';
import { LocationWithZones } from './../../location-ops-model/model/Location';

export interface Rota extends BaseModel, BaseRotaTemplateDetail {
    WardenId: number;
    Warden?: Warden;

    TimeFrom: Date;
    TimeTo: Date;

    RotaType: RotaType | LeaveDayType;
}

export interface RotaWithLeaveDay extends Rota {
    Weekday?: number;
    TimeFromNumber?: number;
    TimeToNumber?: number;
    RotaLevel?: number;
    LeaveDayDuration?: LeaveDayDuration;
}

export enum RotaType {
    WORK = 'work',
    WORK_HARD = 'work-hard',
}

export interface RotaWithLocation extends Rota {
    Locations?: LocationWithZones[];
}
