import { CheckInDetail, WardenEvent } from '@LocationOps/model/WardenEvent';
import { Stack, Typography } from '@mui/material';
import {
    BaseWardenEventItem,
    MapItemCreated,
    ReturnElement,
    secondToHHMMSS,
    WardenEventItemProps,
} from './BaseWardenEventItem';
import { rounded } from '@helpers';
const meterToMile = 1609.344;
export const CheckIn: ReturnElement = {
    ListItem: (props: WardenEventItemProps<CheckInDetail>) => {
        const text = `IN ${props.item.Location?.Name}`;
        return (
            <BaseWardenEventItem
                {...props}
                text={text}
                details={() => (
                    <Stack direction={'row'} justifyContent="space-between">
                        <Typography variant="caption" color="GrayText">
                            Travel time to site: {secondToHHMMSS(props.item.Detail.TravelTimeToSite)}
                        </Typography>
                        <Typography variant="caption" color="GrayText">
                            {`Driving distance to site: ${
                                isNaN(props.item.Detail.DistanceToSite)
                                    ? NaN
                                    : rounded(props.item.Detail.DistanceToSite / meterToMile) + ' miles'
                            }`}
                        </Typography>
                    </Stack>
                )}
            />
        );
    },
    MapItem: (props: WardenEvent<CheckInDetail>) => (
        <div>
            <Typography>Check in {props.Location?.Name}</Typography>
            <MapItemCreated Created={props.Created!} />
        </div>
    ),
};
