import { wardenController } from '@controllers';
import { WardenWithRelations } from '@WardenOps/model';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function RedirectToDetailsPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const externalId = searchParams.get('badgeNumber');

    useEffect(() => {
        if (!externalId) navigate(`/admin/parking-operative/${0}/details`);
        wardenController.list({ filter: { ExternalId: externalId ?? '' } }).then((res) => {
            const warden: WardenWithRelations = res.rows[0];
            if (warden) {
                navigate(`/admin/parking-operative/${warden.Id}/details`);
            } else {
                navigate(`/admin/parking-operative/${0}/details`);
            }
        });
    }, []);

    return <div></div>;
}
