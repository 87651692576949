const color = {
    primary: '#3EB6E4',
    secondary: 'rgba(0, 157, 79, 1)',
    white: '#FFFFFF',
    orange: '#FF9900',
    textPrimary: '#000000',
    textprimary2: '#101010',

    darkPrimary: '#1A2B6D',
    darkSecondary: '#339790',

    lightPrimary: 'rgba(0, 124, 186, 0.1)',
    lightSecondary: '#E8F5E9',

    maskPopup: 'rgba(0, 0, 0, 0.5);',

    danger: '#E01B00',
    darkDanger: '#BE1700',

    grey100: '#FAFAFA',
    grey200: '#EEEEEE',
    grey300: '#DDDDDD',
    grey400: '#C5C5C5',
    grey600: '#85858A',

    warning: '#FFDC83',

    success: 'rgba(0, 157, 79, 1)',
    error: '#E9645B',

    urlText: 'rgba(0, 123, 255, 1)',
};
export default color;
