import { _weekdays } from '@helpers';
import { IPopUp } from '@hooks/usePopUp';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { WeeklyCalendarShift } from '@pages/setting/warden/detail/components/WorkCalendar';
import { PopUpViewMapBase } from '@pages/setting/warden/detail/components/PopUpViewMap';
import { Warden } from '@WardenOps/model';
import _, { isNumber, values } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

type Props = IPopUp & {
    weekday?: number;
    allShifts: Record<string, WeeklyCalendarShift[]>;
    warden: Warden;
};

export default function PopUpScheduleMap(props: Props) {
    const [selectedWeekday, setSelectedWeekday] = useState<number>();

    const shiftsOfDay = values(props.allShifts)
        .flat()
        .filter((item) => item.weekday === selectedWeekday);

    const _shiftsOfDay = useMemo(() => {
        const [shiftHaveClusters, shiftNoCluster] = _.partition(
            shiftsOfDay,
            (item) => item.cluster && item.cluster.Locations
        );
        shiftHaveClusters.forEach((shift) => {
            shift.cluster?.Locations?.forEach((location: any) => {
                shiftNoCluster.push({ ...shift, location });
            });
        });

        return shiftNoCluster.sort((a, b) => a.startMin - b.startMin);
    }, [shiftsOfDay]);

    useEffect(() => {
        setSelectedWeekday(props.weekday);
    }, [props.weekday]);

    if (!isNumber(selectedWeekday)) return <></>;

    return (
        <PopUpViewMapBase
            {...props}
            hasResult={!!_shiftsOfDay.length}
            shiftsOfDay={_shiftsOfDay}
            selectComponent={
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="select-id">Day</InputLabel>
                    <Select
                        labelId="select-id"
                        id="demo-simple-select"
                        value={selectedWeekday}
                        label="Day"
                        sx={{ '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': { py: '9.95px' } }}
                        onChange={(e) => {
                            setSelectedWeekday(e.target.value as number);
                        }}
                    >
                        {_weekdays.map((w) => {
                            return (
                                <MenuItem key={w} value={w}>
                                    {moment().weekday(w).format('dddd')}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            }
            title={`View working route on ${moment().weekday(selectedWeekday).format('dddd')}`}
        />
    );
}
