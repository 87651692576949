import Checkbox from '@components/Checkbox';
import { CStack } from '@components/FlexedStack';
import PopUpBase from '@components/PopUpBase';
import { END_DAY_MINUTE, Shift, SHIFT_STEP, TOTAL_DAY_SHIFT } from '@components/rota-table';
import { fakeArray, getWeekday, _weekdays } from '@helpers';
import { Button, FormControl, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import _, { cloneDeep, difference } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiPlusSm } from 'react-icons/hi';
import { IPopUp } from 'src/hooks/usePopUp';
import { v4 } from 'uuid';
import ShiftItem, { ShiftWithStatus } from './ShiftItem';

type Props = Omit<IPopUp, 'onConfirm'> & {
    selectShift?: Shift;
    shifts: Shift[];
    onConfirm?(shifts: ShiftWithStatus[], selectedWeekdays: number[]): void;
};
export const allHours = fakeArray(25).map((_, index) => index);

export const getArr = (start: number, end: number) => {
    const arr = [];
    if (!isNaN(start) && !isNaN(end)) {
        for (let index = start; index < end; index++) {
            arr.push(index);
        }
    }
    return arr;
};

export const getDisabledHours = (start: number, end: number) => {
    const validHours = [];
    for (let i = start; i <= end; i++) {
        validHours.push(i);
    }
    return difference(allHours, validHours);
};

export const getValidHour = (startMin: number, limitStartMin = 0, limitEndMin = END_DAY_MINUTE) => {
    const validStartMins = [];
    const validEndMins = [];

    for (let i = limitStartMin; i <= limitEndMin; i += SHIFT_STEP) {
        if (i !== limitEndMin) validStartMins.push(i);
        if (i !== limitStartMin && i > startMin) validEndMins.push(i);
    }

    return {
        validStartMins,
        validEndMins,
    };
};

export function limitStartEndShifts<T extends ShiftWithStatus>(shifts: T[]) {
    const _shifts = cloneDeep(shifts.filter((s) => s.shiftStatus !== 'deleted'))
        .sort((a, b) => a.startMin - b.startMin)
        .map((shift, index, arr) => {
            const { validEndMins, validStartMins } = getValidHour(
                shift.startMin,
                arr[index - 1]?.endMin,
                arr[index + 1]?.startMin
            );
            const _shift: T = {
                ...shift,
                validStartMins,
                validEndMins,
            };
            return _shift;
        });
    return _shifts;
}

export default function PopUpAddPeriod(props: Props) {
    const is24hShiftProps =
        props.shifts.length === 1 && props.selectShift?.startMin === 0 && props.selectShift.endMin === END_DAY_MINUTE;

    const defaultShift24h = useMemo(
        () =>
            ({
                _id: v4(),
                startMin: 0,
                endMin: END_DAY_MINUTE,
                requiredWarden: is24hShiftProps ? props.selectShift?.requiredWarden : 1,
                defaultOpen: true,
                shiftStatus: 'new',
                weekday: props.selectShift?.weekday,
            } as ShiftWithStatus),
        [is24hShiftProps, props.selectShift?.requiredWarden, props.selectShift?.weekday]
    );

    const prevState = useRef<ShiftWithStatus[]>([defaultShift24h]);
    console.log(`prevState`, prevState);
    const [state, setState] = useState<ShiftWithStatus[]>([]);
    const [checkedDays, setCheckedDays] = useState<number[]>([]);
    const [is24hChecked, setIs24hChecked] = useState(false);

    function handleCheck24Hours() {
        setState(cloneDeep(prevState.current));
        prevState.current = cloneDeep(state);
    }

    const findIndex = (shifts: ShiftWithStatus[], shift: ShiftWithStatus) =>
        shifts.findIndex((s) => s._id === shift._id);

    const handleDelete = (shift: ShiftWithStatus) => {
        setState((prev) => {
            const newList = prev.map((p) =>
                p._id === shift._id ? ({ ...p, ...shift, shiftStatus: 'deleted' } as any) : p
            );
            return newList;
        });
    };

    const handleAdd = (index: number) => {
        setOpenIndex(index);
        setState((prev) => prev.map((item) => ({ ...item, defaultOpen: false })));
        setState((prev) => {
            const newShift = {
                _id: v4(),
                requiredWarden: 1,
                defaultOpen: true,
                shiftStatus: 'new',
                weekday: props.selectShift?.weekday,
            } as ShiftWithStatus;
            return prev.concat([newShift]);
        });
    };

    const handleChange = (value: ShiftWithStatus) => {
        const _value = { ...value };
        if (_value.startMin === _value.endMin) {
            _value.endMin = undefined as any;
        }

        setState((prev) => {
            return prev.map((p) =>
                p._id === _value._id ? { ...p, ..._value, shiftStatus: _value.shiftStatus || 'change' } : p
            );
        });
    };

    const [openIndex, setOpenIndex] = useState(0);

    useEffect(() => {
        if (state.length === 1) setOpenIndex(0);
    }, [state.length]);

    useEffect(() => {
        const init = () => {
            if (!props.open) {
                setState((prev) => prev.map((p) => ({ ...p, defaultOpen: false })));
                return;
            }
            const propsShift = props.selectShift;
            if (!propsShift) return;

            let shifts: ShiftWithStatus[] = props.shifts.map((s) => ({
                ...s,
                defaultOpen: propsShift?._id === s._id ? true : undefined,
            }));

            if (propsShift && !propsShift.id && !propsShift.status) {
                const newShift: ShiftWithStatus = {
                    ...propsShift,
                    requiredWarden: 1,
                    shiftStatus: 'new',
                    defaultOpen: true,
                    // startMin: shifts.length < 1 ? 0 : propsShift.startMin,
                    // endMin: shifts.length < 1 ? 1440 : propsShift.endMin,
                };

                shifts.push(newShift);

                setOpenIndex(props.shifts.length);
            } else {
                const openIndex = props.shifts.findIndex((s) => s._id === propsShift?._id);
                setOpenIndex(openIndex);
            }
            setState(shifts);

            console.log(`is24hShift`, is24hShiftProps, props.selectShift);
            setIs24hChecked(is24hShiftProps);
            prevState.current = is24hShiftProps ? shifts : [defaultShift24h];

            setCheckedDays((prev) => _.uniq([propsShift.weekday!]));
        };
        init();
    }, [is24hShiftProps, props.open, props.selectShift, props.shifts, defaultShift24h]);

    const toggleOpen = (shift: Shift) => {
        setState((prev) => {
            const newArr = prev.slice();
            const index = findIndex(prev, shift);
            newArr[index].defaultOpen = !newArr[index].defaultOpen;
            return newArr;
        });
    };

    const availableShift = state
        .map((s) => {
            const f = limitStartEndShifts(state).find((sd) => sd._id === s._id);
            return { ...s, ...f };
        })
        .filter((s) => s.shiftStatus !== 'deleted');

    const disabled = availableShift.some((a) => {
        return (
            isNaN(a.startMin) ||
            isNaN(a.endMin) ||
            !a.requiredWarden ||
            isNaN(a.requiredWarden as any) ||
            a.requiredWarden < 1
        );
    });

    function handleCheckDay(weekday: number, checked: boolean) {
        let selectDays: number[] = [...checkedDays];
        if (weekday === -1) {
            selectDays = checked ? _weekdays : [props.selectShift?.weekday!];
        } else if (weekday === props.selectShift?.weekday) {
            return;
        } else {
            selectDays = checked ? [...selectDays, weekday] : selectDays.filter((d) => d !== weekday);
        }

        setCheckedDays(selectDays);
    }

    function handleSubmit() {
        let _shiftOfDay = state;
        if (is24hChecked && !is24hShiftProps)
            _shiftOfDay = _shiftOfDay.concat(props.shifts.map((shift) => ({ ...shift, shiftStatus: 'deleted' })));

        console.log('_shiftOfDay', _shiftOfDay);

        props.onConfirm?.(_shiftOfDay, checkedDays);
    }

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{ fullWidth: true, maxWidth: 'sm' }}
            onClose={props.onClose}
            onConfirm={() => {
                props.onConfirm?.(state, checkedDays);
            }}
            title={
                <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Edit operational period</Typography>

                    <FormControlLabel
                        sx={{
                            marginRight: 0,
                            '& .MuiFormControlLabel-label': {
                                fontSize: 14,
                            },
                        }}
                        control={
                            <Switch
                                checked={is24hChecked}
                                onChange={(e, c) => {
                                    setIs24hChecked(c);
                                    handleCheck24Hours();
                                }}
                            />
                        }
                        label="All day"
                    />
                </Stack>
            }
            // subTitle={`Day: ${moment()
            //     .weekday(props.selectShift?.weekday || 0)
            //     .format('dddd')}`}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            hideClose
            hideConfirm
            desc={
                <Stack>
                    <Stack sx={{ border: '1px solid #ddd', borderRadius: '5px', p: '8px', pt: '10px' }}>
                        <CStack justifyContent={'space-between'}>
                            <FormControl component="fieldset" fullWidth>
                                <FormGroup aria-label="position" row sx={{ justifyContent: 'space-evenly' }}>
                                    <FormControlLabel
                                        key={v4()}
                                        value={-1}
                                        checked={!difference(_weekdays, checkedDays).length}
                                        onChange={(_, checked) => handleCheckDay(-1, checked)}
                                        control={<Checkbox />}
                                        label="All days"
                                        labelPlacement="bottom"
                                        sx={{ userSelect: 'none' }}
                                    />
                                    {getWeekday().map((w) => {
                                        return (
                                            <FormControlLabel
                                                key={w.weekday}
                                                value={w.weekday}
                                                control={<Checkbox />}
                                                checked={checkedDays.includes(w.weekday)}
                                                onChange={(_, checked) => handleCheckDay(w.weekday, checked)}
                                                sx={{ userSelect: 'none' }}
                                                label={
                                                    <Typography textAlign={'center'}>
                                                        {moment(w.date).format('ddd')}
                                                    </Typography>
                                                }
                                                labelPlacement="bottom"
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        </CStack>
                    </Stack>

                    <Stack mt={2} spacing={2}>
                        {availableShift.map((s, index) => {
                            return (
                                <ShiftItem
                                    key={s._id}
                                    shift={s}
                                    open={index === openIndex}
                                    onChange={handleChange}
                                    onDelete={handleDelete}
                                    toggleOpen={() => setOpenIndex(index)}
                                    disabledSelectHour={is24hChecked}
                                    disabledDelete={is24hChecked}
                                    // displayCheckedHours={Boolean(
                                    //     state.filter((s) => s.shiftStatus !== 'deleted').length <= 1
                                    // )}
                                />
                            );
                        })}
                    </Stack>

                    {/* {availableShift.length < 3 && ( */}
                    <Stack>
                        <Button
                            disabled={disabled || is24hChecked}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&.Mui-disabled': {
                                    backgroundColor: 'unset',
                                },
                            }}
                            size="small"
                            startIcon={<HiPlusSm />}
                            onClick={() => handleAdd(availableShift.length)}
                        >
                            Add period
                        </Button>
                    </Stack>
                    {/* )} */}

                    <Stack mt={3} direction={'row'} justifyContent="space-between">
                        <Button sx={{ minWidth: 150 }} variant="cancel" onClick={props.onClose}>
                            Close
                        </Button>
                        <Button disabled={disabled} sx={{ minWidth: 150 }} variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
