import _ from 'lodash';
import { useEffect, useRef } from 'react';

export function deepCompareEquals(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

function useDeepCompareMemoize(value: any) {
    const ref = useRef(value);

    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export default function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
    useEffect(function () {
        return callback();
    }, dependencies.map(useDeepCompareMemoize));
}
