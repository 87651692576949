import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcApproval(props: SvgIconProps) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5098 7.51089H12.3514C10.426 7.50349 8.84779 9.03823 8.80243 10.9627V20.8793C8.75985 22.8352 10.3103 24.4561 12.2663 24.4996C12.295 24.4996 12.3227 24.5005 12.3514 24.4996H19.7465C21.6849 24.4209 23.2122 22.8195 23.1983 20.8793V12.3947L18.5098 7.51089Z"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.2671 7.5V10.1927C18.2671 11.5072 19.3297 12.5726 20.6442 12.5763H23.1944"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.0932 19.1712H13.0946"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.2004 15.6977H13.0939"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
