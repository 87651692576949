import { SvgIconProps } from "@mui/material";
import React from "react";

type Props = {};

export default function IcAddWarden(props: SvgIconProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.72463 8.11954C4.91847 7.64762 4.37681 6.77262 4.37681 5.77263V3.71738C4.37681 2.21739 5.59419 1 7.09419 1C8.59418 1 9.81157 2.21739 9.81157 3.71738V5.77263C9.81157 6.77262 9.2699 7.64762 8.46375 8.11954V8.84055C8.53388 8.84072 8.60359 8.84267 8.67285 8.84635C7.70634 9.67694 7.09419 10.9083 7.09419 12.2826C7.09419 12.9267 7.22864 13.5394 7.47102 14.0942H2.56522C2.06522 14.0942 1.65942 13.6884 1.65942 13.1884V12.9166C1.65942 10.6703 3.48007 8.84599 5.72463 8.84055V8.11954Z"
                fill="#85858A"
            />
            <path
                d="M11.1703 15H12.0761C12.3261 15 12.5289 14.7971 12.5289 14.5471V13.1884H13.8876C14.1376 13.1884 14.3405 12.9855 14.3405 12.7355V11.8297C14.3405 11.5797 14.1376 11.3768 13.8876 11.3768H12.5289V10.0181C12.5289 9.76813 12.3261 9.56524 12.0761 9.56524H11.1703C10.9203 9.56524 10.7174 9.76813 10.7174 10.0181V11.3768H9.35867C9.10867 11.3768 8.90577 11.5797 8.90577 11.8297V12.7355C8.90577 12.9855 9.10867 13.1884 9.35867 13.1884H10.7174V14.5471C10.7174 14.7971 10.9203 15 11.1703 15Z"
                fill="#85858A"
            />
        </svg>
    );
}
