import { Paging } from '@Core';
import { Expenses, Warden } from '@WardenOps/model';
import PopUpBase from '@components/PopUpBase';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { BaseHead, CellOfRow } from '@components/utils';
import { appConfig } from '@config';
import { paymentController } from '@controllers';
import { defaultPaging, formatDate, rounded } from '@helpers';
import { IPopUp } from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import { Filter } from '@pages/setting/warden/list/hooks/useFilterWarden';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PermitDuplicate from './PermitDuplicate';
type Props = IPopUp & {
    startDate: Date;
    totalStatistic?: TotalStatistic;
    warden?: Warden;
    filter?: Filter;
    isActive?: boolean;
    onUpdateNewExpenseByIdWarden: (wardenId: number, totalExpensesAmount: number, totalDistances: number) => void;
};
export type TotalStatistic = {
    totalExpensesAmount: number;
    totalDistances: number;
};
export default function PopUpExpenses(props: Props) {
    const [expensesDetails, setExpensesDetails] = useState<Paging<Expenses>>(defaultPaging({ pageSize: 10 }));
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [totalNewDetailExpense, setTotalNewDetailExpense] = useState<TotalStatistic>({
        totalDistances: 0,
        totalExpensesAmount: 0,
    });

    const TimeTo = moment(props.startDate).add(13, 'days').endOf('day').toDate();

    const isEmpty = !expensesDetails.rows.length && !loading;
    const paramsObj = {
        from: moment(props.startDate).startOf('d').toISOString(),
        to: moment(TimeTo).endOf('d').toISOString(),
    };
    const searchParams = new URLSearchParams(paramsObj);

    const getTravelTime = (travelTime: number) => {
        const hours = Math.floor(travelTime / 60);
        const minutes = travelTime % 60;

        const hoursString = hours > 0 ? `${hours} hr(s)` : '';
        const minutesString = `${minutes} min(s)`;

        const result = [hoursString, minutesString].filter((i) => i).join(' ');
        return result;
    };
    const handleListExpense = () => {
        setLoading(true);
        if (!props.warden?.Id || props.open === false) return;
        paymentController
            .getExpensesDetails({
                filter: { WardenId: props.warden.Id },
                TimeFrom: props.startDate,
                TimeTo,
                page: page,
                pageSize: 10,
            })
            .then((res) => {
                handleDetails();
                setExpensesDetails(res);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setTotalNewDetailExpense((prev) => ({
            ...prev,
            totalDistances: props.totalStatistic?.totalDistances ?? 0,
            totalExpensesAmount: props.totalStatistic?.totalExpensesAmount ?? 0,
        }));
        handleListExpense();
    }, [props.warden?.Id, page, props.open]);

    useEffect(() => {
        setPage(1);
    }, [props.warden?.Id]);

    const handleDetails = () => {
        if (props.isActive) {
            paymentController
                .getExpensesStatistic({
                    filter: {
                        Id: props.warden?.Id,
                    },
                    TimeFrom: props.filter?.startDate!,
                    TimeTo: TimeTo!,
                })
                .then((res) => {
                    setTotalNewDetailExpense((prev) => ({
                        ...prev,
                        totalDistances: res.rows[0].TotalDistance,
                        totalExpensesAmount: res.rows[0].TotalExpenseAmount,
                    }));
                });
        }
    };

    return (
        <PopUpBase
            {...props}
            fixOverflow
            dialogProps={{
                scroll: 'paper',
                fullWidth: true,
                maxWidth: 'lg',
                PaperProps: {
                    sx: { minHeight: 830 },
                },
            }}
            onConfirm={() => {
                props.onConfirm?.();
            }}
            dialogActionsProps={{ sx: { justifyContent: 'flex-end' } }}
            title={
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Stack alignItems="flex-start">
                        <Typography fontSize={18} fontWeight={600}>
                            {`Details expenses of ${props.warden?.FullName}`}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            {`From ${formatDate(props.startDate)} to ${formatDate(TimeTo)}`}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1.5}>
                        <Typography variant="body2" color="secondary">
                            {`Total distance: ${rounded(
                                totalNewDetailExpense.totalDistances || props.totalStatistic?.totalDistances || 0
                            )} mile(s)`}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            {`Total expense amount: £${rounded(
                                totalNewDetailExpense.totalExpensesAmount ||
                                    props.totalStatistic?.totalExpensesAmount ||
                                    0
                            )}`}
                        </Typography>
                    </Stack>
                </Stack>
            }
            desc={
                <Box>
                    {props.totalStatistic?.totalDistances === totalNewDetailExpense.totalDistances ||
                    props.totalStatistic?.totalExpensesAmount === totalNewDetailExpense.totalExpensesAmount ? (
                        <></>
                    ) : (
                        <PermitDuplicate />
                    )}
                    <Stack mt={2} alignItems="center">
                        <TableContainer component={Box}>
                            {loading ? (
                                <Stack direction="row" justifyContent="center" alignItems="center" minHeight={650}>
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <Stack alignItems="center">
                                    {isEmpty ? (
                                        <EmptyPage stackProps={{ sx: { minHeight: 555 } }} />
                                    ) : (
                                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="left"
                                                        title="Date"
                                                    />
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="left"
                                                        title="From"
                                                    />
                                                    <BaseHead sx={{ background: '#E8F5E9' }} align="left" title="To" />
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="center"
                                                        title="Travel time to site"
                                                    />
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="center"
                                                        title="Distance (miles)"
                                                    />
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="center"
                                                        title="Rate"
                                                    />
                                                    <BaseHead
                                                        sx={{ background: '#E8F5E9' }}
                                                        align="right"
                                                        title="Total amount"
                                                    />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {expensesDetails.rows.map((ep, index) => {
                                                    const Distance = rounded(ep.Distance);
                                                    const TotalAmount = rounded(Distance * ep.Rate);

                                                    return (
                                                        <TableRow>
                                                            <CellOfRow value={formatDate(ep.WorkDate)} align="left" />
                                                            <CellOfRow
                                                                value={ep.FromLocation?.Name ?? ''}
                                                                align="left"
                                                            />
                                                            <CellOfRow value={ep.ToLocation?.Name ?? ''} align="left" />
                                                            <CellOfRow value={getTravelTime(ep.TravelTime)} />
                                                            <CellOfRow value={`${Distance} mile(s)`} />
                                                            <CellOfRow value={ep.Rate} />
                                                            <CellOfRow
                                                                value={`£${TotalAmount.toFixed(2)}`}
                                                                align="right"
                                                            />
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    )}
                                    {expensesDetails.totalPages > 1 && (
                                        <Pagination
                                            count={expensesDetails.totalPages}
                                            page={expensesDetails.page}
                                            onChange={(e: any, value: any) => {
                                                setPage(value);
                                            }}
                                            shape="rounded"
                                            sx={{
                                                mt: 2,
                                                '& .Mui-selected': {
                                                    background: '#3479BB1A !important',
                                                },
                                                '& .MuiPaginationItem-previousNext': {
                                                    background: '#EEEEEE',
                                                },
                                            }}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    components={{ previous: IcPrev, next: IcNext }}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    )}
                                </Stack>
                            )}
                        </TableContainer>
                    </Stack>
                </Box>
            }
            customActions={
                <Stack direction="row" width="100%" justifyContent="space-between">
                    {!!expensesDetails.rows.length && (
                        <a
                            href={`${appConfig.publicUrl}/admin/parking-operative/${
                                props.warden?.Id
                            }/iticket-tracker?${searchParams.toString()}`}
                            target="blank"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="outlined" color="secondary" sx={{ mt: 2, minWidth: 135 }}>
                                Open iTicket tracker in new tab
                            </Button>
                        </a>
                    )}
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setPage(1);
                            if (props.warden?.Id) {
                                props.onUpdateNewExpenseByIdWarden(
                                    props.warden?.Id,
                                    totalNewDetailExpense.totalExpensesAmount,
                                    totalNewDetailExpense.totalDistances
                                );
                            }
                        }}
                        sx={{ mt: 2, minWidth: 135 }}
                    >
                        Cancel
                    </Button>
                </Stack>
            }
        />
    );
}
