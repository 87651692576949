import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Cluster, ClusterWithRelations } from '@LocationOps/model';
import { Rota } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { IClusterController, IClusterGetWardenWorkingsProps } from '../IClusterController';

export interface CustomGetListCluster extends ListProps<Cluster> {
    CountryRegionId?: number;
    RequireAssignable?: boolean;
}

export class ClusterHttpController extends BaseHttpController<ClusterWithRelations> implements IClusterController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'cluster', client);
    }

    async list(props: CustomGetListCluster): Promise<Paging<ClusterWithRelations>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/filter`, props);
        return res.data;
    }

    async getWardenWorkings(props: IClusterGetWardenWorkingsProps): Promise<Paging<Rota>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/warden-workings`, props);
        return res.data;
    }

    async downloadCluster(props: { ClusterId: number }): Promise<File> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/export-cluster`, props, {
            responseType: 'blob',
        });
        return res.data;
    }
}
