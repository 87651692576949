import { useMsal } from '@azure/msal-react';
import { appConfig } from '@config';
import EmptyPage from '@pages/setting/components/EmptyPage';
import axios from 'axios';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
export default function WardenOpsReport() {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [embedUrl, setEmbedUrl] = useState('');
    const [hasPermission, setHasPermission] = useState(true);

    useEffect(() => {
        const getTokenAndReport = async () => {
            setHasPermission(true);
            try {
                const accessTokenRequest = {
                    scopes: ['https://analysis.windows.net/powerbi/api/.default'],
                    account: accounts[0],
                };

                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                const _accessToken = accessTokenResponse.accessToken;
                setAccessToken(_accessToken);

                const reportResponse = await axios.get(
                    `https://api.powerbi.com/v1.0/myorg/reports/${appConfig.powerBIReportId}`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + _accessToken,
                        },
                    }
                );
                const _embedUrl = reportResponse.data.embedUrl;
                setEmbedUrl(_embedUrl);
            } catch (error: any) {
                console.error(`error`, error);
                setHasPermission(false);
                return Promise.reject(error);
            }
        };
        getTokenAndReport();
    }, []);

    return (
        <>
            {hasPermission ? (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: appConfig.powerBIReportId,
                        embedUrl: embedUrl,
                        accessToken: accessToken, // Keep as empty string, null or undefined
                        tokenType: models.TokenType.Aad,
                        settings: {
                            filterPaneEnabled: false,
                            navContentPaneEnabled: false,
                            layoutType: models.LayoutType.Custom,
                            customLayout: {
                                pagesLayout: {
                                    default: {
                                        defaultLayout: {
                                            width: 1600,
                                            height: 860,
                                            displayState: {
                                                mode: models.VisualContainerDisplayMode.Hidden,
                                            },
                                        },
                                        visualsLayout: {},
                                    },
                                },
                                displayOption: models.DisplayOption.FitToWidth,
                            },
                        },
                    }}
                    cssClassName="revenue-container"
                />
            ) : (
                <EmptyPage
                    title=" Sorry, you do not have permission to visit this page."
                    subTitle={'Please contact with us to get more information.'}
                    stackProps={{ sx: { mt: 28} }}
                />
            )}
        </>
    );
}
