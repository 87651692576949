import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcTimeSheets(props: SvgIconProps) {
    return (
        <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.01513 0.539308L5.7615 6.48962L11.7119 9.74331L12.7914 7.76918L10.1399 6.31931C10.7514 6.18978 11.3749 6.12465 12 6.125C16.9149 6.125 20.875 10.0851 20.875 15C20.875 19.9149 16.9149 23.875 12 23.875C7.08512 23.875 3.125 19.9149 3.125 15C3.125 13.9712 3.29906 12.9846 3.6185 12.067L1.5985 11.057C1.13162 12.2834 0.875 13.6124 0.875 15C0.875 21.1308 5.86919 26.125 12 26.125C18.1308 26.125 23.125 21.1308 23.125 15C23.125 8.86918 18.1308 3.875 12 3.875C11.1811 3.875 10.3828 3.965 9.61375 4.13425L10.9893 1.61862L9.01513 0.539246V0.539308ZM15.532 8.68799L11.4375 14.8297V22H12.5625V15.1703L16.468 9.312L15.532 8.68799Z"
                fill="currentColor"
            />
        </svg>
    );
}
