import styled from '@emotion/styled';
import { formatSingleNumber, getMin } from '@helpers';
import { Lock } from '@mui/icons-material';
import {
    BoxProps,
    Grid,
    Stack,
    SvgIconProps,
    SxProps,
    Tooltip,
    TooltipProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import { Box } from '@mui/system';
import { omit } from 'lodash';
import { ReactNode, SVGProps } from 'react';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { IoDocumentLock } from 'react-icons/io5';
import { useElementSize } from 'usehooks-ts';
import { END_DAY_MINUTE, Shift, ShiftStatus, SHIFT_STEP, TOTAL_DAY_SHIFT } from '.';

export type ShiftTypeDisplay = 'assigned' | 'time';

type Props<T extends Shift> = {
    shift: T;
    displayType?: ShiftTypeDisplay;
    renderActions?(shift: T): ReactNode;
    readonly?: boolean;
    readonlyOutside?: boolean;
    renderTitleShift?(shift?: T): ReactNode;
    onAddPeriod?(shift: T): void;
    titleProps?: TypographyProps;
    // renderHover?: ReactNode;
    toolTipProps?: Partial<TooltipProps>;
    renderWarning?: ReactNode;
    // isWarning?: boolean;
    warning?: 'error' | 'warning';
    boxProps?: BoxProps;
};

const BaseShiftStack = styled(Stack)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    transition: '0.1s',
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 500,
});

const Assigned = styled(BaseShiftStack)({
    color: '#009D4F',
    backgroundColor: '#E8F5E9',
    borderLeft: '1px solid #009D4F',
});

const UnAssigned = styled(BaseShiftStack)({
    color: '#E01B00',
    backgroundColor: '#FFDC83',
    borderLeft: '1px solid #E01B00',
});

const LieuLeave = styled(BaseShiftStack)({
    color: 'transparent',
    backgroundColor: '#FD5F6A',
    ':hover': {
        color: 'white',
        // backgroundColor: '#dc4953',
        filter: 'brightness(0.9)',
    },
    // borderLeft: '1px solid white',
});

const OtherEvents = styled(BaseShiftStack)({
    color: '#fff',
    backgroundColor: '#FD5F6A',
    borderLeft: '1px solid #fff',
});
const Outside = styled(BaseShiftStack)({
    backgroundColor: '#DDDDDD',
    color: 'transparent',
    ':hover': {
        color: 'white',
        // backgroundColor: '#ccc',
        filter: 'brightness(0.9)',
    },
});

const OutsideReadonly = styled(BaseShiftStack)({
    backgroundColor: '#DDDDDD',
    color: '#DDDDDD',
    cursor: 'initial',
});

const actionClass = 'action-3';

export const formatHour = (minutes: number) => {
    const h = Math.floor(minutes / 60);
    const min = minutes % 60;
    return `${formatSingleNumber(h)}:${formatSingleNumber(min)}`;
};

export default function StyledShift<T extends Shift>(props: Props<T>) {
    const { shift: s, displayType, renderActions } = props;
    const StyledStack =
        s.status === ShiftStatus.lieuLeave
            ? LieuLeave
            : s.status === ShiftStatus.otherEvents
            ? OtherEvents
            : s.status === ShiftStatus.assigned
            ? Assigned
            : s.status === ShiftStatus.unassigned
            ? UnAssigned
            : props.readonly || props.readonlyOutside
            ? OutsideReadonly
            : Outside;

    const getBorderRadius = (boolean: boolean) => {
        return boolean ? '5px' : undefined;
    };

    const _startHour = Math.floor(s.startMin / 60);
    const _startMin = (s.startMin / 60 - _startHour) * 60;

    const _endHour = Math.floor(s.endMin / 60);
    const _endMin = (s.endMin / 60 - _endHour) * 60;

    const _formatStart = `${formatSingleNumber(_startHour)}:${formatSingleNumber(_startMin)}`;
    const _formatEnd = `${formatSingleNumber(_endHour)}:${formatSingleNumber(_endMin)}`;

    const getTitle = (s: Shift, props: Props<any>, displayType?: ShiftTypeDisplay) => {
        if (!s.status || s.status === ShiftStatus.lieuLeave) {
            return props.readonly ? null : '+';
        }

        if (props.renderTitleShift) {
            return props.renderTitleShift(props.shift);
        }

        if (displayType === 'assigned') {
            return `${s.assignedWardens?.length}(${s.requiredWarden})`;
        }

        return `${formatHour(s.startMin)} - ${formatHour(s.endMin)}`;
    };

    const title = getTitle(s, props, displayType);

    return (
        <Tooltip
            title={s.isOverlapped ? '' : props.toolTipProps?.title || `${_formatStart} - ${_formatEnd}`}
            disableInteractive
            arrow
            placement="top"
            {...omit(props.toolTipProps, 'title')}
        >
            <Box
                gridColumn={`span ${s.endMin / SHIFT_STEP - s.startMin / SHIFT_STEP}`}
                sx={{
                    [`:hover .${actionClass}`]: {
                        visibility: 'visible',
                    },
                    [`:hover .tooltip-shift`]: {
                        visibility: 'visible',
                        opacity: 1,
                    },
                    position: 'relative',
                    borderRight: _startMin === 45 && s.endMin !== END_DAY_MINUTE ? '1px solid #eee' : undefined,
                    // overflow: 'hidden',
                    height: '40px',
                    zIndex: 2,
                    minWidth: 0,
                    ...(props.boxProps?.sx as any),
                }}
                {...omit(props.boxProps as any, ['sx'])}
            >
                <StyledStack
                    direction={'row'}
                    alignItems="center"
                    justifyContent={'center'}
                    sx={{
                        borderTopLeftRadius: getBorderRadius(s.startMin === 0),
                        borderTopRightRadius: getBorderRadius(s.endMin === END_DAY_MINUTE),
                        borderBottomLeftRadius: getBorderRadius(s.startMin === 0),
                        borderBottomRightRadius: getBorderRadius(s.endMin === END_DAY_MINUTE),
                        position: 'relative',
                        ':hover': {
                            filter: s.isOverlapped || props.readonly ? 'unset !important' : undefined,
                        },
                        pointerEvents: s.isOverlapped ? 'none' : undefined,
                        cursor: s.isOverlapped || props.readonly ? 'not-allowed !important' : undefined,
                    }}
                    onClick={() => {
                        !props.readonly && props.onAddPeriod?.(props.shift);
                    }}
                    gap={0.7}
                >
                    <Typography
                        color="inherit"
                        fontWeight={'inherit'}
                        lineHeight={1}
                        textAlign="center"
                        style={{
                            fontWeight: 'bold',
                        }}
                        {...props.titleProps}
                        noWrap
                    >
                        {title}
                    </Typography>
                    {s.status === ShiftStatus.otherEvents && <HiOutlineLockClosed fontSize={20} />}
                    {props.warning && s.status !== ShiftStatus.overlapped && (
                        <Stack mt="-3px">
                            {props.warning === 'error' ? (
                                <ErrorIcon />
                            ) : props.warning === 'warning' ? (
                                <WarningIcon />
                            ) : null}
                        </Stack>
                    )}
                    <Stack
                        className={actionClass}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            display: 'flex',
                            flexDirection: 'row',
                            visibility: 'hidden',
                        }}
                    >
                        {!props.readonly && renderActions?.(s)}
                    </Stack>
                </StyledStack>
            </Box>
        </Tooltip>
    );
}

function WarningIcon(props: SvgIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
            <path
                fill="#FFDC83"
                fillRule="evenodd"
                stroke="#FFDC83"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 .5a8.5 8.5 0 110 17 8.5 8.5 0 010-17z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#FF6A00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.995 5.512v4.06"
            ></path>
            <path
                stroke="#FF6A00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.995 12.488h.01"
            ></path>
        </svg>
    );
}

function ErrorIcon(props: SvgIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
            <path
                fill="#E01B00"
                fillRule="evenodd"
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 .5a7.5 7.5 0 110 15 7.5 7.5 0 010-15z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.996 4.922v3.583"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7.996 11.078h.008"
            ></path>
        </svg>
    );
}

// L3: Assign/Unassign
// L2: Leave
// L1: Not working

const FullDayShift = () => {
    const [ref, size] = useElementSize();
    return (
        <Stack ref={ref} direction={'row'} flex={1}>
            {[...new Array(TOTAL_DAY_SHIFT)].map((_, index) => {
                return (
                    <Stack
                        key={index}
                        sx={{
                            flexBasis: size.width / TOTAL_DAY_SHIFT,
                            flexGrow: 0,
                            backgroundColor: 'eee',
                            height: '40px',
                        }}
                    >
                        +
                    </Stack>
                );
            })}
        </Stack>
    );
};
