import PopUpBase from '@components/PopUpBase';
import StyledSelect from '@components/select-search/StyledSelect';
import { wardenController } from '@controllers';
import { Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { IPopUp } from '@hooks/usePopUp';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    Radio,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import SelectOption from '@pages/rota-coverage/components/MobileRotaItem/SelectOption';
import { SendWorkCalendar, Warden } from '@WardenOps/model';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type Props = Omit<IPopUp, 'onConfirm'> & {
    startOfWeek: Date;
    onConfirm(props: SendWorkCalendar): void;
};
type Mode = 'all' | 'specific';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function PopUpSendMail(props: Props) {
    const [pagingWarden, setPagingWarden] = useState<Paging<Warden>>(defaultPaging({ pageSize: 20 }));
    const [selectedWardens, setSelectedWardens] = useState<Warden[]>([]);
    const [mode, setMode] = useState<Mode>('all');
    const [valueSearch, setValueSearch] = useState('');

    const filteredWarden = _.differenceBy(pagingWarden.rows, selectedWardens, (i) => i.Id);
    const debouncedValue = useDebounce<string>(valueSearch, 300);

    const hasListChip = Boolean(selectedWardens.length > 0);
    const hasMore = Boolean(pagingWarden.page < pagingWarden.totalPages);

    const disabled = mode === 'specific' && !selectedWardens.length;

    const handleChange = (e: any) => {
        setMode(e.target.value);
    };

    const onGetMoreWardens = async () => {
        await wardenController
            .list({
                page: pagingWarden.page + 1,
                pageSize: 20,
                search: {
                    content: debouncedValue,
                    fields: ['FullName'],
                },
            })
            .then((res) => {
                const _res = { ...res, rows: [...pagingWarden.rows, ...res.rows] };
                setPagingWarden(_res);
            });
    };
    useEffect(() => {
        if (props.open) {
            wardenController
                .list({
                    pageSize: 10000,
                    search: {
                        content: debouncedValue,
                        fields: ['FullName'],
                    },
                })
                .then((res) => setPagingWarden(res));
        }
    }, [props.open, debouncedValue]);

    return (
        <PopUpBase
            {...props}
            fixOverflow
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '700px', overflowY: 'unset' },
                },
            }}
            title={`Send rota calendar`}
            subTitle={`Weekly rota from ${moment(props.startOfWeek).format('DD/MM/YYYY')} to ${moment(props.startOfWeek)
                .add(6, 'days')
                .format('DD/MM/YYYY')}`}
            onConfirm={() => {}}
            desc={
                <Stack spacing={2}>
                    <Stack direction="row" justifyContent={'center'} width="100%" alignItems="center" spacing={1}>
                        <Typography variant="h6">Receivers: </Typography>
                        <FormControlLabel
                            value="all"
                            control={<Radio checked={mode === 'all'} onChange={handleChange} color="secondary" />}
                            label="All assigned POs"
                        />
                        <FormControlLabel
                            value="specific"
                            control={<Radio checked={mode === 'specific'} onChange={handleChange} color="secondary" />}
                            label="Some specific POs"
                        />
                    </Stack>
                    {mode === 'specific' && (
                        <Stack spacing={2}>
                            <Autocomplete
                                size="small"
                                multiple
                                fullWidth
                                id="checkboxes-tags-demo"
                                options={pagingWarden.rows}
                                value={selectedWardens}
                                sx={{
                                    '&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                        minHeight: 40,
                                    },
                                }}
                                onChange={(e, value) => setSelectedWardens(value)}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.FullName || ''}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.FullName}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selected operative"
                                        placeholder="Please select operative"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            label={option.FullName}
                                            size="small"
                                            {...getTagProps({ index })}
                                            sx={{ borderRadius: '5px' }}
                                        />
                                    ))
                                }
                            />

                            {/* <StyledSelect
                                renderNoOption
                                label="Warden"
                                data={filteredWarden}
                                value={{}}
                                hasMore={hasMore}
                                searchValue={valueSearch}
                                onChangeSearch={(text) => setValueSearch(text)}
                                next={onGetMoreWardens}
                                onChange={(option) => {
                                    setListChip((prev) => {
                                        const _clonePrev = cloneDeep(prev);
                                        _clonePrev.push(option ?? ({} as any));
                                        return _clonePrev;
                                    });
                                    setValueSearch('');
                                }}
                                renderValue={(value) => {
                                    return (
                                        <Typography noWrap>
                                            {value?.FullName || 'Please select operative to send rota'}
                                        </Typography>
                                    );
                                }}
                                renderOption={(option) => <SelectOption option={option} />}
                                sx={{
                                    width: '100%',
                                }}
                            />
                            {hasListChip && (
                                <Stack spacing={1}>
                                    <Typography variant="body1">
                                        {`Selected parking operatives `}
                                        <Typography
                                            variant="body1"
                                            display="inline-block"
                                            fontStyle="italic"
                                            color="secondary"
                                        >
                                            {`(${listChip.length})`}
                                        </Typography>
                                        :
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        sx={{ px: 1, pb: 1, border: '1px solid #ddd', borderRadius: 1 }}
                                        flexWrap="wrap"
                                        justifyContent="start"
                                    >
                                        {_.uniqBy(listChip, (i) => i.Id).map((l) => {
                                            return (
                                                <Chip
                                                    size="small"
                                                    sx={{ mt: 1, mr: 1, borderRadius: '5px' }}
                                                    label={l.FullName}
                                                    onDelete={() => {
                                                        setListChip((prev) => {
                                                            const _clonePrev = cloneDeep(prev);
                                                            const a = _clonePrev.filter((pr) => pr.Id !== l.Id);
                                                            return a;
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </Stack>
                                </Stack>
                            )} */}
                        </Stack>
                    )}
                    <Box sx={{ p: 1, borderRadius: 1, background: '#EEEEEE', textAlign: 'center' }}>
                        All above selected POs will receive their own calendar via email.
                    </Box>
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setMode('all');
                            setSelectedWardens([]);
                            setValueSearch('');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        disabled={disabled}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            props.onConfirm({
                                sendType: mode,
                                wardenIds: mode === 'all' ? undefined : selectedWardens.map((c) => c.Id!),
                                startOfWeek: props.startOfWeek,
                            });
                            setSelectedWardens([]);
                        }}
                    >
                        Send now
                    </Button>
                </Stack>
            }
        />
    );
}
