import { Timesheets } from '@WardenOps/model';
type Props = {
    timesheetDetails: Timesheets[];
};
interface SummaryTimesheets {
    AssignedHour: number;
    BreakHour: number;
    SickHour: number;
    Absent: number;
    Unabsent: number;
    HolidayHour: number;
    LieuHour: number;
}
export default function useTimeSheets(props: Props) {
    const statistic: SummaryTimesheets = {
        AssignedHour: 0,
        BreakHour: 0,
        SickHour: 0,
        Absent: 0,
        Unabsent: 0,
        HolidayHour: 0,
        LieuHour: 0,
    };
    props.timesheetDetails?.forEach((t) => {
        statistic.AssignedHour += t.AssignedHour!;
        statistic.BreakHour += t.BreakHour;
        statistic.SickHour += t.SickHour;
        statistic.Absent += t.Absent;
        statistic.Unabsent += t.Unabsent;
        statistic.HolidayHour += t.HolidayHour;
        statistic.LieuHour += t.LieuHour;
    });
    const workedHours =
        statistic.AssignedHour -
        statistic.BreakHour -
        statistic.SickHour -
        statistic.Absent -
        statistic.Unabsent -
        statistic.HolidayHour -
        statistic.LieuHour;
    const totalTimesheets = workedHours + (statistic.LieuHour + statistic.HolidayHour);

    return { statistic, totalTimesheets };
}
