import { BaseModel } from '@Core';
import { Cluster, Location } from '@LocationOps/model';
import { Zone } from '@LocationOps/model/Zone';
import { Warden } from './Warden';

export enum AlertType {
    SiteLateLogin,
    PhoneStation,
    NoTicket,
    SiteEarlyLogOff,
}

export interface Alert extends BaseModel {
    LocationId?: number;
    Location?: Location;

    ZoneId?: number;
    Zone?: Zone;

    ClusterId?: number;
    Cluster?: Cluster;

    WardenId: number;
    Warden?: Warden;

    RotaTimeFrom: Date;
    RotaTimeTo: Date;

    LogsAtTime: Date;
    LogsStandardTime: Date;

    AlertType: AlertType;
    ReasonRemove?: string;
}

export interface AlertRegister extends BaseModel {
    Email: string;
    Token: string;
}

export interface AlertResponse {
    AlertType: AlertType;
    Amount: number;
}
