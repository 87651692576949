import { CStack } from '@components/FlexedStack';
import { getValidHour } from '@pages/setting/location/detail/components/PopUpAddPeriod';
import { rotaController, wardenController } from '@controllers';
import { getTimeByMin, isEndDate } from '@helpers';
import { Location, RotaCoverage } from '@LocationOps/model';
import { CloseRounded } from '@mui/icons-material';
import {
    Avatar,
    Button,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { ShiftRota } from '@pages/rota-coverage/hooks/useRota';
import { Rota, Warden, WardenWithRelations } from '@WardenOps/model';
import { uniq } from 'lodash';
import moment from 'moment';
import { useEffect, useId, useState } from 'react';
import { toast } from 'react-toastify';
import { pushError, pushSuccess } from '@components/StyledToast';
import { IPopUp } from 'src/hooks/usePopUp';
import { END_DAY_MINUTE, SHIFT_STEP, TOTAL_DAY_SHIFT } from '.';
import PopUpBase from '../PopUpBase';
import ShiftRange from './ShiftRange';

type Props<T> = Omit<IPopUp, 'onConfirm'> & {
    location: Location;
    shift: T;
    onConfirm(rota: RotaCoverage[]): void;
    isPast?: boolean;
};

export default function PopUpEditCalendar<T extends ShiftRota>(props: Props<T>) {
    console.log(`props`, props.shift);
    const [state, setState] = useState<T>(props.shift);
    const [deleted, setDeleted] = useState<number[]>([]);
    console.log(`state`, state);
    const startId = useId();

    const full = state.rota?.Missing === 0;

    const handleRemoveWarden = (warden: Warden) => {
        setDeleted((prev) => [...prev, warden.Id!]);
        setState((prev) => {
            const wardens = prev.rota?.Wardens?.filter((w) => w.Id !== warden.Id) || [];
            const required = prev.rota?.RequireWarden || 0;
            const missing = wardens.length >= required ? 0 : required - wardens.length;

            return {
                ...prev,
                rota: {
                    ...prev.rota,
                    Wardens: wardens,
                    Missing: missing,
                },
            };
        });
    };

    const [wardens, setWardens] = useState<WardenWithRelations[]>([]);

    useEffect(() => {
        if (props.open) {
            setState(props.shift);

            if (!props.shift.date) return;
            wardenController
                .getWardenActualWeekWorkHour({
                    TimeFrom: props.shift.date,
                    WardenIds: uniq(props.shift.assignedWardens?.map((w) => w.Id)) || [],
                })
                .then((res) => {
                    setWardens(res);
                });
        }
    }, [props.open, props.shift]);

    const handleConfirm = async () => {
        const { hour: startHour, min: startMin } = getTimeByMin(props.shift.startMin);
        const { hour: endHour, min: endMin } = getTimeByMin(props.shift.endMin);

        const rotas = deleted.map((wardenId) => {
            console.log('props.shift.date', props.shift.date);
            return {
                WardenId: wardenId,
                TimeFrom: moment(props.shift.date).hour(startHour).minutes(startMin).toDate(),
                TimeTo:
                    props.shift.endMin === END_DAY_MINUTE
                        ? moment(props.shift.date).endOf('date').toDate()
                        : moment(props.shift.date).hour(endHour).minutes(endMin).toDate(),
                LocationId: props.location.Id!,
            } as Rota;
        });
        console.log(`rotas`, rotas);

        try {
            const res = await rotaController.bulkDeleteByLocation({ LocationId: props.location.Id!, List: rotas });
            props.onConfirm(res);
            setDeleted([]);
            pushSuccess('Saved successfully!');
        } catch (error) {
            pushError('Server error!');
        }

        // console.log(`res`, res);
    };

    return (
        <PopUpBase
            {...props}
            dialogProps={{ maxWidth: 'sm', fullWidth: true, PaperProps: { sx: { maxWidth: '700px' } } }}
            title={`${!props.isPast ? 'Edit' : 'View'} calendar on ${moment(props.shift.date).format(
                'dddd, DD/MM/YYYY'
            )}`}
            subTitle={`Location: ${props.location.Name}`}
            onConfirm={() => {}}
            hideClose
            hideConfirm
            desc={
                <Stack spacing={3}>
                    <CStack justifyContent="space-between">
                        <ShiftRange
                            disabled
                            value={{ startMin: props.shift.startMin, endMin: props.shift.endMin }}
                            startMins={[props.shift.startMin]}
                            endMins={[props.shift.endMin]}
                        />
                        <Stack
                            sx={{
                                bgcolor: full ? 'primary.light' : 'warning.main',
                                px: 2,
                                borderRadius: (theme) => theme.shape.borderRadius + 'px',
                                minWidth: 110,
                            }}
                        >
                            <Typography color={full ? 'success.main' : 'error.main'} sx={{ lineHeight: '40px' }}>
                                {`Missing: ${state.rota?.Missing}(${props.shift.rota?.RequireWarden})`}
                            </Typography>
                        </Stack>
                    </CStack>

                    <Stack>
                        <TableContainer>
                            <Table
                                sx={{
                                    '& th, td': {
                                        padding: 1,
                                    },
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Parking operative</TableCell>
                                        <TableCell>Actual hours</TableCell>
                                        <TableCell>Contracted hours</TableCell>
                                        {!props.isPast && <TableCell align="right"></TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.rota?.Wardens?.map((w) => (
                                        <TableRow key={w.Id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <CStack justifyContent={'flex-start'} spacing={1} width="180px">
                                                    <Avatar src={w.Picture}>
                                                        {w.FullName?.charAt(0).toUpperCase()}
                                                    </Avatar>
                                                    <Typography>{w.FullName}</Typography>
                                                </CStack>
                                            </TableCell>
                                            <TableCell>
                                                {wardens.find((wd) => wd.Id === w.Id)?.ActualWeekWorkHour ?? 0}
                                                {'(h)'}
                                            </TableCell>
                                            <TableCell>
                                                {wardens.find((wd) => wd.Id === w.Id)?.ContractHours ?? 0}
                                                {'(h)'}
                                            </TableCell>
                                            {!props.isPast && (
                                                <TableCell align="right">
                                                    <IconButton
                                                        size="small"
                                                        color="error"
                                                        onClick={() => handleRemoveWarden(w)}
                                                    >
                                                        <CloseRounded />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>

                    <CStack justifyContent={!props.isPast ? 'space-between' : 'center'}>
                        <Button
                            sx={{ minWidth: 150 }}
                            variant="cancel"
                            onClick={() => {
                                props.onClose?.();
                                setDeleted([]);
                            }}
                        >
                            Close
                        </Button>
                        {!props.isPast && (
                            <Button
                                disabled={!deleted.length}
                                sx={{ minWidth: 150 }}
                                variant="contained"
                                onClick={handleConfirm}
                            >
                                Submit
                            </Button>
                        )}
                    </CStack>
                </Stack>
            }
        />
    );
}
