import { Paging } from '@Core';
import { Alert, AlertRegister } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import {
    AlertStatistics,
    IAlertHttpController,
    IGetListAlertProps,
    IRemoveAlertByDate,
    IRemoveAlertProps,
} from '../IAlertHttpController';

export class AlertHttpController implements IAlertHttpController {
    protected serviceURL: string;
    protected basePath: string;
    protected client: AxiosInstance;

    constructor(serviceURL: string, client: AxiosInstance) {
        this.serviceURL = serviceURL;
        this.basePath = 'alert';
        this.client = client;
    }

    async registerReceiveAlert(props: AlertRegister): Promise<AlertRegister> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/register-alert`, props);
        return res.data;
    }

    async alertStatistics(props: IGetListAlertProps): Promise<AlertStatistics> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/statistics`, props);
        return res.data;
    }

    async listAlerts(props: IGetListAlertProps): Promise<Paging<Alert>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/filter`, props);
        return res.data;
    }

    async removeAlert(props: IRemoveAlertProps): Promise<boolean> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/remove`, props);
        return res.data;
    }

    async removeAlertByDate(props: IRemoveAlertByDate): Promise<boolean> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/remove-by-date`, props);
        return res.data;
    }
}
