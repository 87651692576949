import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import IcSearch from '@components/icon/IcSearch';
import { pushSuccess } from '@components/StyledToast';
import { clusterController } from '@controllers';
import { ListProps, Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { ClusterWithRelations } from '@LocationOps/model';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import useThrottle from '@rooks/use-throttle';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseList from '../../components/BaseList';
import EmptyPage from '../../components/EmptyPage';
import ClusterCard from './components/ClusterCard';

const breadcrumbs: IBreadCrumbs[] = [{ title: 'Admin' }, { title: 'Cluster' }];

type Filter = {} & Pick<ListProps<any>, 'page' | 'search'>;

export default function ClusterPage() {
    const [filter, setFilter] = useState<Filter>({
        page: 1,
        search: {
            content: '',
            fields: ['Name'],
        },
    });

    const [cluster, setCluster] = useState<Paging<ClusterWithRelations>>(defaultPaging());
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);

    const handleChangeFilter = async (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const handleDelete = (cluster: ClusterWithRelations) => {
        clusterController.delete(cluster.Id?.toString() || '').then((res) => {
            pushSuccess('Deleted successfully!');
            clusterController.list({ pageSize: 10, sorts: ['Name'] }).then((res) => {
                setCluster(res);
            });
        });
    };
    const [throttleDelete] = useThrottle(handleDelete, 2000);

    const debSearchClusters = useCallback(
        debounce((nextValue) => {
            setFilter((prev) => ({ ...prev, search: { ...prev.search, content: nextValue }, page: 1 }));
        }, 300),
        []
    );

    const handleChangeSearchClusters = (e: any) => {
        const text = e.target.value;
        setSearchValue(text);
        setLoading(true);
        debSearchClusters(text);
    };

    useEffect(() => {
        setLoading(true);
        clusterController
            .list({
                page: filter.page || 1,
                search: {
                    content: filter.search?.content || '',
                    fields: ['Name'],
                },
                sorts: ['Name'],
                pageSize: 10,
            })
            .then((res) => {
                setCluster(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => setLoading(false));
    }, [filter.page, filter.search?.content]);

    return (
        <div>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                <Typography fontSize={24}>Cluster</Typography>
                <Link to="new" style={{ all: 'unset' }}>
                    <Button variant="contained" sx={{ minWidth: 165 }}>
                        Add cluster +
                    </Button>
                </Link>
            </Stack>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2 }}>
                <InputLabel htmlFor="outlined-adornment" sx={{ color: '#DDDDDD' }}>
                    Search by name cluster
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle search" edge="end">
                                <IcSearch />
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Search by name route"
                    sx={{ py: '1.5px' }}
                    value={searchValue}
                    onChange={handleChangeSearchClusters}
                />
            </FormControl>

            <Box mt={2} mb={10}>
                <BaseList
                    count={cluster.totalPages}
                    page={cluster.page}
                    onChangePage={(page) => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        handleChangeFilter({ page });
                    }}
                    list={cluster.rows}
                    renderItem={(cluster) => <ClusterCard cluster={cluster} onDelete={throttleDelete} />}
                    renderEmpty={() => <EmptyPage />}
                    loading={loading}
                />
            </Box>
        </div>
    );
}
