import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import { pushSuccess } from '@components/StyledToast';
import { wardenController } from '@controllers';
import { defaultPaging } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import { Box, Button, Stack, Typography } from '@mui/material';
import useThrottle from '@rooks/use-throttle';
import { GetWardenCustomProps } from '@WardenOps/controller';
import { Warden, WardenType, WardenWithRelations } from '@WardenOps/model';
import { useEffect, useState } from 'react';
import BaseList from '../../../components/BaseList';
import EmptyPage from '../../../components/EmptyPage';
import useFilterWarden from '../hooks/useFilterWarden';
import BaseFilterWarden from './BaseFilterWarden';
import PopUpAddParkingOperative, { WardenDetailsType } from './PopUpAddParkingOperative';
import WardenCard from './WardenCard';
import { useNavigate } from 'react-router-dom';

export default function WardenBase() {
    const breadcrumbs: IBreadCrumbs[] = [{ title: 'Admin' }, { title: 'Parking operative' }];
    const navigate = useNavigate();

    const wardenTypes: WardenDetailsType[] = [
        { Name: 'Permanent', Value: WardenType.PERMANENT_STAFF },
        { Name: 'Temporary', Value: WardenType.TEMPORARY_STAFF },
        { Name: 'STS', Value: WardenType.STS },
        // { Name: 'iTicket Lite', Value: WardenType.ITICKET_LITE },
    ];

    const popUpAddParkingOperative = usePopUp();

    const {
        filter,
        filteredSubRegion,
        handleChange,
        handleReset,
        hasMoreRegions,
        hasMoreSubRegions,
        loading,
        onGetMoreRegions,
        onGetMoreSubRegions,
        regions,
        searchSubRegion,
        searchValue,
        setLoading,
        setSearchSubRegion,
        subRegions,
        handleChangeSearchWarden,
    } = useFilterWarden({});

    const [pagingWarden, setPagingWarden] = useState(defaultPaging<Warden>());
    const [loadingPopUp, setLoadingPopUp] = useState(false);

    const init = () => {
        setLoading(true);
        const filterProps: GetWardenCustomProps = {
            page: filter.page || 1,
            search: {
                content: filter.search?.content ?? '',
                fields: ['FirstName', 'LastName', 'Email'],
            },
            filter: { CountrySubRegionId: filter.subRegion?.Id, WardenType: filter.wardenType?.Value },
            CountryRegionId: filter.region?.Id,
            sorts: ['FirstName'],
            pageSize: 10,
        };

        if (filterProps.filter?.CountrySubRegionId) delete filterProps.CountryRegionId;

        wardenController
            .list(filterProps)
            .then((res) => {
                setPagingWarden(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => setLoading(false));
    };
    const handleDelete = (warden: WardenWithRelations) => {
        wardenController.delete(warden.Id?.toString()!).then((res) => {
            init();
            pushSuccess('Deleted parking operative successfully!');
        });
    };
    const [throttleDelete] = useThrottle(handleDelete, 2000);

    useEffect(() => {
        init();
    }, [filter.page, filter.region?.Id, filter.search?.content, filter.subRegion?.Id, filter.wardenType?.Value]);

    return (
        <div>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography fontSize={24}>Parking operative</Typography>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                </Stack>
                <Button variant="contained" color="secondary" onClick={() => popUpAddParkingOperative.setTrue()}>
                    Add parking operative
                </Button>
            </Stack>

            <BaseFilterWarden
                searchValue={searchValue}
                onChangeSearch={handleChangeSearchWarden}
                hasMoreRegions={hasMoreRegions}
                onGetMoreRegion={onGetMoreRegions}
                regions={regions}
                region={filter.region}
                onChangeRegion={(option) => {
                    handleChange({
                        region: option,
                        page: 1,
                        subRegion: undefined,
                    });
                }}
                hasMoreSubRegions={hasMoreSubRegions}
                onGetMoreSubRegion={onGetMoreSubRegions}
                subRegions={filteredSubRegion}
                subRegion={filter.subRegion}
                onChangeSubRegion={(option) => {
                    handleChange({
                        subRegion: option,
                        page: 1,
                    });
                }}
                wardenTypes={wardenTypes}
                wardenType={filter.wardenType}
                onChangeWardenType={(option) => {
                    handleChange({ wardenType: option, page: 1 });
                }}
                searchSubRegionValue={searchSubRegion}
                onSearchSubRegion={(text) => setSearchSubRegion(text)}
                totalSubRegions={subRegions.total}
                onReset={handleReset}
                isDisplayStatictis={false}
            />
            <Box mt={2}>
                <BaseList
                    list={pagingWarden.rows}
                    renderItem={(warden) => <WardenCard warden={warden} onDelete={throttleDelete} />}
                    count={pagingWarden.totalPages}
                    page={pagingWarden.page}
                    loading={loading}
                    onChangePage={(value) => {
                        handleChange({ page: value });
                    }}
                    renderEmpty={() => <EmptyPage />}
                />
            </Box>
            <PopUpAddParkingOperative
                {...popUpAddParkingOperative}
                title="Add new parking operative"
                onConfirm={async (warden) => {
                    const _warden = { ...warden };
                    setLoadingPopUp(true);
                    await wardenController
                        .create(_warden)
                        .then((res) => {
                            // init();
                            navigate(`/admin/parking-operative/${res.Id}/details`);
                            pushSuccess('Created parking operative successfully!');
                        })
                        .catch((err) => setLoadingPopUp(false))
                        .finally(() => setLoadingPopUp(false));
                    popUpAddParkingOperative.onClose();
                }}
                loading={loadingPopUp}
            />
        </div>
    );
}
