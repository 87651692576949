import { Paging } from '@Core';
import { IGetExpensesStatisticProps } from '@WardenOps/controller/IPaymentHttpController';
import { ExpensesStatistic, Warden } from '@WardenOps/model';
import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import { pushError, pushSuccess } from '@components/StyledToast';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { BaseHead, CellOfRow } from '@components/utils';
import { paymentController } from '@controllers';
import { defaultPaging, rounded } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import BaseFilterWarden from '@pages/setting/warden/list/components/BaseFilterWarden';
import useFilterWarden from '@pages/setting/warden/list/hooks/useFilterWarden';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import FilterDate from './components/FilterDate';
import PopUpExpenses, { TotalStatistic } from './components/PopUpExpenses';
import IOSSwitch from '@components/IOSSwitch';
import PopUpWarning from '@components/PopUpWarning';

export default function ExpensesPage() {
    const breadcrumbs: IBreadCrumbs[] = [{ title: 'Payment' }, { title: 'Expenses' }];
    const [isActive, setIsActive] = useState<boolean>(true);

    const popUpExpenses = usePopUp();
    const popUpWarning = usePopUp();
    const popUpWarningExportList = usePopUp();

    const {
        filter,
        filteredSubRegion,
        handleChange,
        handleReset,
        hasMoreRegions,
        hasMoreSubRegions,
        loading,
        onGetMoreRegions,
        onGetMoreSubRegions,
        regions,
        searchSubRegion,
        searchValue,
        setLoading,
        setSearchSubRegion,
        subRegions,
        handleChangeSearchWarden,
    } = useFilterWarden({ mode: 'expenses' });

    const [expenses, setExpenses] = useState<Paging<ExpensesStatistic>>(defaultPaging({ pageSize: 10 }));
    const [warden, setWarden] = useState<Warden>({} as Warden);
    const [totalStatistic, setTotalStatistic] = useState<TotalStatistic>({
        totalExpensesAmount: 0,
        totalDistances: 0,
    });
    const [isUpdatingStatusAutoGenExpenses, setIsUpdatingStatusAutoGenExpenses] = useState(false);

    const TimeTo = useMemo(() => moment(filter.startDate).add(13, 'days').endOf('day').toDate(), [filter.startDate]);
    const isEmpty = !loading && !expenses.rows.length;

    const handleExportExcel = () => {
        paymentController
            .downloadExpensesStatistic({
                TimeFrom: filter.startDate,
                TimeTo: TimeTo,
            })
            .then((res) => {
                const bufRes = (res as any).buffer.data;

                const buf = new Uint8Array(bufRes).buffer;
                const buftype = 'application/vnd.ms-excel;charset=utf-8';
                let blob = new Blob([buf], {
                    type: buftype,
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${moment(filter.startDate).format('DD MMMM YYYY')} - ${moment(TimeTo).format(
                        'DD MMMM YYYY'
                    )} Expenses Report.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                if (isActive) {
                    popUpWarningExportList.setTrue();
                }
                pushSuccess('Downloaded successfully');
            });
    };

    useEffect(() => {
        setLoading(true);
        const filterProps: IGetExpensesStatisticProps = {
            page: filter.page || 1,
            search: {
                content: filter.search?.content ?? '',
                fields: ['FirstName', 'LastName', 'Email'],
            },
            filter: { CountrySubRegionId: filter.subRegion?.Id },
            CountryRegionId: filter.region?.Id,
            sorts: ['FirstName'],
            pageSize: 10,
            TimeFrom: filter.startDate,
            TimeTo: TimeTo,
        };

        if (filterProps.filter?.CountrySubRegionId) delete filterProps.CountryRegionId;

        paymentController
            .getExpensesStatistic(filterProps)
            .then((res) => {
                setExpenses(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => setLoading(false));
    }, [filter.page, filter.region?.Id, filter.search?.content, filter.subRegion?.Id, filter.startDate]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newStatus = event.target.checked;
        popUpWarning.setTrue();
        setIsActive(newStatus);
    };
    const handleOnConfirm = async (status: boolean) => {
        setIsUpdatingStatusAutoGenExpenses(true);
        await paymentController
            .updateStatusAutoGenExpenses({ status: status })
            .then(() => {
                pushSuccess('Change successfully!');
            })
            .catch((error) => {
                setIsActive(!status);
                pushError(error.message || 'Server error!');
            })
            .finally(() => {
                setIsUpdatingStatusAutoGenExpenses(false);
            });
    };
    const handleGetStatusAutoGenExpenses = async () => {
        await paymentController.getStatusAutoGenExpenses().then((res) => {
            setIsActive(res);
        });
    };
    useEffect(() => {
        handleGetStatusAutoGenExpenses();
    }, []);

    const onUpdateNewExpenseByIdWarden = (id: number, totalExpensesAmount: number, totalDistances: number) => {
        setExpenses((prev) => ({
            ...prev,
            rows: prev.rows.map((items) => {
                if (items.Warden.Id === id) {
                    items.TotalDistance = totalDistances;
                    items.TotalExpenseAmount = totalExpensesAmount;
                }
                return items;
            }),
        }));
    };

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingStatusAutoGenExpenses}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                <Typography fontSize={24}>Expenses</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <FilterDate
                        startDate={filter.startDate}
                        onChange={(startDate) => handleChange({ startDate })}
                        limitEndDate={13}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ minWidth: 135 }}
                        endIcon={
                            <svg
                                width="10"
                                height="14"
                                viewBox="0 0 10 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.46967 9.7803C4.76256 10.0732 5.23744 10.0732 5.53033 9.7803L9.2803 6.03033C9.5732 5.73744 9.5732 5.26256 9.2803 4.96967C8.9874 4.67678 8.5126 4.67678 8.2197 4.96967L5.75 7.43934V0.75C5.75 0.33579 5.41421 0 5 0C4.58579 0 4.25 0.33579 4.25 0.75V7.43934L1.78033 4.96967C1.48744 4.67678 1.01256 4.67678 0.71967 4.96967C0.42678 5.26256 0.42678 5.73744 0.71967 6.03033L4.46967 9.7803ZM0.75 12.0001C0.33579 12.0001 0 12.3359 0 12.7501C0 13.1643 0.33579 13.5001 0.75 13.5001H9.25C9.6642 13.5001 10 13.1643 10 12.7501C10 12.3359 9.6642 12.0001 9.25 12.0001H0.75Z"
                                    fill="#fff"
                                />
                            </svg>
                        }
                        onClick={() => {
                            handleExportExcel();
                        }}
                    >
                        Export
                    </Button>
                </Stack>
            </Stack>

            <BreadCrumbs breadcrumbs={breadcrumbs} />

            <Stack direction={'row'} gap={1} justifyContent="end" alignItems="center">
                <Typography fontSize={16}>Generate data automatically:</Typography>
                <IOSSwitch
                    label={<Typography>{isActive ? 'On' : 'Off'}</Typography>}
                    iosSwitchProps={{
                        checked: isActive,
                        onChange(event, checked) {
                            handleOnChange(event);
                        },
                    }}
                />
            </Stack>

            <BaseFilterWarden
                searchValue={searchValue}
                onChangeSearch={handleChangeSearchWarden}
                hasMoreRegions={hasMoreRegions}
                onGetMoreRegion={onGetMoreRegions}
                regions={regions}
                region={filter.region}
                onChangeRegion={(option) => {
                    handleChange({
                        region: option,
                        page: 1,
                        subRegion: undefined,
                    });
                }}
                hasMoreSubRegions={hasMoreSubRegions}
                onGetMoreSubRegion={onGetMoreSubRegions}
                subRegions={filteredSubRegion}
                subRegion={filter.subRegion}
                onChangeSubRegion={(option) => {
                    handleChange({
                        subRegion: option,
                        page: 1,
                    });
                }}
                searchSubRegionValue={searchSubRegion}
                onSearchSubRegion={(text) => setSearchSubRegion(text)}
                totalSubRegions={subRegions.total}
                onReset={handleReset}
            />
            <Grid
                container
                xs={12}
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: '0px 8px 8px 8px;',
                    p: '20px 16px 20px 16px',
                }}
                justifyContent="center"
                mt={2}
            >
                <TableContainer component={Box}>
                    {loading ? (
                        <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Stack alignItems="center">
                            {isEmpty ? (
                                <EmptyPage />
                            ) : (
                                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#FAFAFA' }}>
                                            <BaseHead align="left" title="Parking operative" />
                                            <BaseHead align="center" title="Distance (Miles)" />
                                            <BaseHead align="center" title="Total expense amount(£)" />
                                            <BaseHead title="" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {expenses.rows.map((ts, index) => {
                                            const total = ts.TotalExpenseAmount;
                                            return (
                                                <TableRow key={index}>
                                                    <CellOfRow value={ts.Warden.FullName!} align={'left'} />
                                                    <CellOfRow
                                                        value={rounded(ts.TotalDistance ?? 0)}
                                                        align={'center'}
                                                    />
                                                    <CellOfRow value={rounded(total)} align={'center'} />
                                                    <TableCell align="right">
                                                        <Button
                                                            color="secondary"
                                                            size="small"
                                                            sx={{ flexBasis: 80, py: 0.8, fontSize: 13 }}
                                                            onClick={() => {
                                                                setWarden(ts.Warden);
                                                                setTotalStatistic((prev) => ({
                                                                    ...prev,
                                                                    totalExpensesAmount: total,
                                                                    totalDistances: ts.TotalDistance,
                                                                }));
                                                                popUpExpenses.setTrue();
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                            {expenses.totalPages > 1 && (
                                <Pagination
                                    count={expenses.totalPages}
                                    page={expenses.page}
                                    onChange={(e: any, value: any) => {
                                        handleChange({ page: value });
                                    }}
                                    shape="rounded"
                                    sx={{
                                        mt: 2,
                                        '& .Mui-selected': {
                                            background: '#3479BB1A !important',
                                        },
                                        '& .MuiPaginationItem-previousNext': {
                                            background: '#EEEEEE',
                                        },
                                    }}
                                    renderItem={(item) => (
                                        <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                    )}
                                />
                            )}
                        </Stack>
                    )}
                </TableContainer>
            </Grid>
            <PopUpExpenses
                {...popUpExpenses}
                startDate={filter.startDate}
                warden={warden}
                totalStatistic={totalStatistic}
                filter={filter}
                isActive={isActive}
                onUpdateNewExpenseByIdWarden={onUpdateNewExpenseByIdWarden}
            />
            <PopUpWarning
                {...popUpWarning}
                title="Confirm"
                onClose={() => {
                    setIsActive(!isActive);
                    popUpWarning.onClose();
                }}
                message={`Do you want to ${isActive ? 'enable' : 'disable'} the automatic data regeneration feature?`}
                onConfirm={() => {
                    popUpWarning.onClose();
                    handleOnConfirm(isActive);
                }}
            />
            <PopUpWarning
                {...popUpWarningExportList}
                title="Confirm"
                onClose={() => {
                    popUpWarningExportList.onClose();
                }}
                message="Do you want to disable the automatic data regeneration feature?"
                onConfirm={() => {
                    setIsActive(!isActive);
                    popUpWarningExportList.onClose();
                    handleOnConfirm(false);
                }}
            />
        </div>
    );
}
