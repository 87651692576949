import { Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { TiWarningOutline } from 'react-icons/ti';
import color from 'src/theme/color';

type Props = { option: any; isDisplay?: boolean; hideIcon?: boolean };

export const WrapSelectOption = (props: { sx?: SxProps<Theme>; children: ReactNode }) => {
    return (
        <Stack
            direction={'row'}
            sx={{
                py: 1,
                borderBottom: '1px solid #eee',
                minHeight: '55px',
                alignItems: 'center',
                ...props.sx,
            }}
        >
            {props.children}
        </Stack>
    );
};

export default function SelectOption({ option, isDisplay = true, hideIcon = true }: Props) {
    return (
        <Stack
            direction={'row'}
            sx={{
                py: 1,
                borderBottom: '1px solid #eee',
                minHeight: '55px',
                alignItems: 'center',
            }}
        >
            <Stack flex={1}>
                <Typography>{option.Name}</Typography>
                {isDisplay && !!option.MissingWarden && (
                    <Typography color="error" variant="body2" visibility={!option.MissingWarden ? 'hidden' : undefined}>
                        Require: {option.MissingWarden}
                    </Typography>
                )}
            </Stack>
            <Stack alignItems={'flex-end'}>
                {option.DistanceWithAWarden && <Typography>{option.DistanceWithAWarden / 1000}km</Typography>}
                {hideIcon && (
                    <Stack
                        sx={{
                            bgcolor: 'warning.main',
                            borderRadius: '50%',
                            p: '3px',
                            display: 'grid',
                            placeItems: 'center',
                            visibility: !option.MissingWarden ? 'hidden' : undefined,
                            width: 20,
                            height: 20,
                            paddingTop: '1px',
                        }}
                    >
                        <TiWarningOutline
                            color={color.error}
                            // sx={{ fontSize: "0.75rem" }}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}
