import { IssuePCNDetail, WardenEvent } from '@LocationOps/model/WardenEvent';
import { Typography } from '@mui/material';
import React from 'react';
import { BaseWardenEventItem, MapItemCreated, ReturnElement, WardenEventItemProps } from './BaseWardenEventItem';

export const IssuePCN: ReturnElement = {
    ListItem: (props: WardenEventItemProps) => {
        const text = `PCN ${props.item.Location?.Name} - ${props.item.Detail.TicketNumber}`;

        return <BaseWardenEventItem {...props} text={text} />;
    },
    MapItem: (props: WardenEvent<IssuePCNDetail>) => (
        <div>
            <Typography>Issued PCN</Typography>
            <MapItemCreated Created={props.Created!} />
        </div>
    ),
};
