import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { LeaveDay } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { ILeaveDayController, ILeaveDayStatistics } from '../ILeaveDayController';

export interface CustomGetListLeaveDay extends ListProps<LeaveDay> {
    WardenName?: string;
}

export class LeaveDayHttpController extends BaseHttpController<LeaveDay> implements ILeaveDayController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'leave-day', client);
    }

    list(params: CustomGetListLeaveDay): Promise<Paging<LeaveDay>> {
        return super.list(params);
    }

    async leaveDayStatistics(): Promise<ILeaveDayStatistics> {
        return await this.doPost({ path: 'statistics' }).then((res) => res.data);
    }
}
