
export default function IcWarning() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" rx="9" fill="#FFDC83" />
            <g clipPath="url(#clip0_7945_38745)">
                <path d="M9 6.91671V9.41671M9 12.75C7.10535 12.75 6.15802 12.75 5.61039 12.3522C5.13272 12.0051 4.82474 11.4717 4.76302 10.8845C4.69226 10.2113 5.16593 9.39086 6.11325 7.75004C7.06058 6.10922 7.53424 5.28881 8.15264 5.01349C8.69203 4.77334 9.30798 4.77334 9.84737 5.01349C10.4658 5.28881 10.9394 6.10922 11.8868 7.75004C12.8341 9.39086 13.3077 10.2113 13.237 10.8845C13.1753 11.4717 12.8673 12.0051 12.3896 12.3522C11.842 12.75 10.8947 12.75 9 12.75Z" stroke="#E01B00" strokeLinecap="round" />
                <path d="M9.41671 10.6667C9.41671 10.8968 9.23016 11.0833 9.00004 11.0833C8.76992 11.0833 8.58337 10.8968 8.58337 10.6667C8.58337 10.4365 8.76992 10.25 9.00004 10.25C9.23016 10.25 9.41671 10.4365 9.41671 10.6667Z" fill="#E01B00" />
            </g>
            <defs>
                <clipPath id="clip0_7945_38745">
                    <rect width="10" height="10" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}
