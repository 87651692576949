import {
    AvgPCNVisitColor,
    Cluster,
    LocationStatus,
    LocationType,
    LocationWithRelations,
    OperationalPeriod,
} from '@LocationOps/model';
import { ServiceType } from '@LocationOps/model/Service';
import { Zone } from '@LocationOps/model/Zone';
import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import Checkbox from '@components/Checkbox';
import Circle from '@components/Circle';
import PopUpBase from '@components/PopUpBase';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/StyledToast';
import IcNext from '@components/icon/IcNext';
import IcPcnIssued from '@components/icon/IcPcnIssued';
import IcPcnIssuedPerCheckIn from '@components/icon/IcPcnIssuedPerCheckIn';
import IcPrev from '@components/icon/IcPrev';
import RotaTable, { DEFAULT_WEEKLY_PERIOD, Shift, ShiftStatus } from '@components/rota-table';
import StyledShift from '@components/rota-table/Shift';
import { locationController, zoneController, zoneEventLogController } from '@controllers';
import { defaultPaging, formatDate } from '@helpers';
import usePopUp, { IPopUp } from '@hooks/usePopUp';
import {
    Button,
    Chip,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    MenuItem,
    Pagination,
    PaginationItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import PopUpAddPeriod from '@pages/setting/location/detail/components/PopUpAddPeriod';
import { Tab } from '@pages/setting/warden/detail';
import useThrottle from '@rooks/use-throttle';
import { cloneDeep, difference, groupBy, isNil, isNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import color from 'src/theme/color';
import { v4 } from 'uuid';
import { colorLocationMapping } from '../list/components/LocationListItem';
import ZoneEventLogs from './components/ZoneEventLogs';
import ShiftActions from './components/ShiftActions';
import { ShiftWithStatus } from './components/ShiftItem';
import StatusIcon from './components/StatusIcon';
import { ZoneEventLog } from '@WardenOps/model/ZoneEventLog';

export const UNIT = 60;

const locationStatusName: Record<LocationStatus, string> = {
    [LocationStatus.Prospective]: 'Prospective',
    [LocationStatus.Live]: 'Live',
    [LocationStatus.Transferred]: 'Transferred',
    [LocationStatus.Decommissioned]: 'Decommissioned',
    [LocationStatus.OnHold]: 'OnHold',
};

export type Period = OperationalPeriod & { _id?: string };
interface ClusterWithRelations extends Cluster {
    Locations?: LocationWithRelations[];
}
export interface LocationWithFakeId extends LocationWithRelations {
    OperationalPeriods?: Period[];
}

export const BaseTitle = styled(Typography)({
    fontSize: 16,
    fontWeight: 500,
});

export default function LocationDetails() {
    const theme = useTheme();
    const params = useParams<{ id: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locationRoute = useLocation();
    const popUpAddPeriod = usePopUp();
    const popUpWarning = usePopUp();
    const popUpSuspendZones = usePopUp();
    const popUpSuspendAllRedZones = usePopUp();

    const [location, setLocation] = useState<LocationWithFakeId>({} as LocationWithFakeId);
    const [selectedShift, setSelectedShift] = useState<Shift>();

    const [loading, setLoading] = useState(true);
    const [loadingGetTotalPCNsIssued, setLoadingGetTotalPCNsIssued] = useState(false);

    const prevState = useRef<LocationWithFakeId>();

    const convertToCompare = (location: LocationWithFakeId) => {
        if (!location) return;
        const convertedOperationalPeriods = location.OperationalPeriods?.map((period) => ({
            Weekday: period.Weekday,
            TimeFrom: period.TimeFrom,
            TimeTo: period.TimeTo,
        }));

        return {
            LocationType: location.LocationType,
            OperationalPeriods: convertedOperationalPeriods,
        };
    };
    const convertedPrevState = convertToCompare(prevState.current!);
    const convertedState = convertToCompare(location);

    const addFakeId = (res: LocationWithRelations) => {
        return { ...res, OperationalPeriods: res.OperationalPeriods?.map((p) => ({ ...p, _id: v4() })) };
    };

    const handleConfirm = (applyChangesFromDate: Date) => {
        const _location: LocationWithFakeId = {
            ...location,
            OperationalPeriods: isMobileType ? [] : location.OperationalPeriods,
        };
        locationController
            .updateLocationWithRelations({ locationWithRelations: _location, applyChangesFromDate })
            .then((res) => {
                setLocation(addFakeId(res));
                pushSuccess('Saved successfully!');
            });
    };

    const [throttleConfirm] = useThrottle(handleConfirm, 2000);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocation((prev) => ({ ...prev, LocationType: event.target.value as LocationType }));
    };

    const convertData = (periods: Period[]) => {
        const data = groupBy(periods, 'Weekday');
        const _d: Record<string, Shift[]> = cloneDeep(DEFAULT_WEEKLY_PERIOD);

        Object.entries(data).forEach(([key, shifts]) => {
            _d[key] = shifts.map((s) => ({
                startMin: s.TimeFrom,
                endMin: s.TimeTo,
                requiredWarden: s.RequireWarden,
                assignedWardens: [] as any,
                status: ShiftStatus.unassigned,
                weekday: s.Weekday,
                id: isNil(s.Id) ? s.Id : s.Id,
                _id: s._id,
            }));
        });
        return _d;
    };

    const convertShift = (shift: ShiftWithStatus) => {
        return {
            TimeFrom: shift.startMin,
            TimeTo: shift.endMin,
            RequireWarden: shift.requiredWarden,
            Weekday: shift.weekday ?? 1,
        } as OperationalPeriod;
    };

    const handleChangeShift = (shifts: ShiftWithStatus[], checkedWeekdays: number[]) => {
        setLocation((prev) => {
            const clonePrev = cloneDeep(prev);
            let periods = clonePrev.OperationalPeriods || [];
            const _shifts = shifts.filter((s) => s.startMin !== undefined && s.endMin !== undefined);

            _shifts.forEach((_shift) => {
                switch (_shift.shiftStatus) {
                    case 'deleted':
                        periods = periods?.filter((p) => p._id !== _shift._id);
                        break;
                    case 'change':
                        periods = periods?.map((p) => {
                            return _shift._id === p._id ? { ...p, ...convertShift(_shift) } : p;
                        });
                        break;
                    case 'new':
                        periods.push({ ...convertShift(_shift), LocationId: location.Id || 0, _id: _shift._id });
                        break;
                    default:
                        break;
                }
            });

            console.log(`periods selectedPeriod`, periods);
            const selectedPeriod = periods.filter((p) => {
                return p.Weekday === selectedShift?.weekday;
            });
            console.log(`selectedPeriod`, selectedPeriod);

            const addWeekdays = difference(checkedWeekdays, [selectedShift?.weekday!]);
            periods = periods.filter((p) => !addWeekdays.includes(p.Weekday));
            console.log(`periods`, periods);

            addWeekdays.forEach((weekday) => {
                if (weekday !== selectedShift?.weekday) {
                    selectedPeriod.forEach((p) => {
                        periods.push({ ...p, Weekday: weekday, _id: v4() } as Period);
                    });
                }
            });

            clonePrev.OperationalPeriods = periods;
            return clonePrev;
        });
    };

    useEffect(() => {
        const init = async () => {
            if (!params.id) return;

            const currentPath = locationRoute.pathname.replace(/\/$/, '');
            const pattern = new RegExp(`/${params.id}(\\/)?$`);
            if (pattern.test(currentPath)) {
                navigate(`${currentPath}/total-pcns-issued`, { replace: true });
            }

            const res = await locationController.get(params.id);

            setLocation({
                ...res,
                OperationalPeriods: res.OperationalPeriods?.map((p) => ({ ...p, _id: v4() })),
            });
            prevState.current = res;

            setLoading(false);
        };

        init();
    }, [params]);

    const shifts = convertData(location.OperationalPeriods || [])[selectedShift?.weekday || 0].sort(
        (a, b) => a.startMin - b.startMin
    );

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Admin' },
        { title: 'Location', href: '/admin/location', onBack: true },
        { title: location.Name ?? '' },
    ];
    const isMobileType = location.LocationType === 'mobile';
    const periodShifts = isMobileType ? convertData([]) : convertData(location.OperationalPeriods || []);

    const [zoneQuery, setZoneQuery] = useState({ page: 1, pageSize: 5, locationId: Number(params.id) });
    const [pagingZone, setPagingZone] = useState(defaultPaging<Zone>());
    const [pagingEventLog, setPagingEventLog] = useState(defaultPaging<ZoneEventLog>({ pageSize: 5 }));

    const checkValidZone = (zone: Zone) => {
        return zone.Services?.some((service) => service.ServiceType === ServiceType.WardenPatrol);
    };

    const [totalValidZone, setTotalValidZone] = useState(0);
    const [colorLocation, setColorLocation] = useState<AvgPCNVisitColor>(AvgPCNVisitColor.NoColour);

    const isStaticHasZones = !location.CountrySubRegionId && location.LocationType === 'static';

    useEffect(() => {
        if (zoneQuery.locationId) {
            zoneController.countZoneWithServiceValid(zoneQuery.locationId).then((res) => {
                setTotalValidZone(res.count);
            });
            locationController.getColorLocation(zoneQuery.locationId).then((res) => {
                setColorLocation(res.Color);
            });
        }
    }, [zoneQuery.locationId]);

    useEffect(() => {
        zoneController
            .list({
                page: zoneQuery.page,
                pageSize: zoneQuery.pageSize,
                filter: {
                    LocationId: zoneQuery.locationId,
                },
            })
            .then((res) => {
                setPagingZone(res);
            });
    }, [zoneQuery.page, zoneQuery.locationId, zoneQuery.pageSize]);

    const getEventLogs = async () => {
        if (!params.id) return;
        setLoading(true);
        await zoneEventLogController
            .list({
                page: pagingEventLog.page,
                pageSize: pagingEventLog.pageSize,
                sorts: ['-Created'],
                locationId: Number(params.id),
            })
            .then((res) => {
                setPagingEventLog(res);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getEventLogs();
    }, [pagingEventLog.page, pagingEventLog.pageSize]);

    const isLocationLive = location.LocationStatus === LocationStatus.Live;

    const isUpdatedAt = location.PeriodLastUpdatedAt
        ? `Last updated on ${moment(location.PeriodLastUpdatedAt).tz('Europe/London').format('DD/MM/YYYY')}`
        : '';
    const isUpdatedBy = location.PeriodLastUpdatedBy ? `by ${location.PeriodLastUpdatedBy}` : '';

    const [suspendZone, setSuspendZone] = useState<Zone[]>([]);
    const [selectedTab, setSelectedTab] = useState<Tab>({} as Tab);

    const onSuspendZone = async (props?: Zone[]) => {
        setLoading(true);
        await zoneController
            .suspendZones(props ?? suspendZone)
            .then((res) => {
                const messageSuccess = props
                    ? 'Suspend all zones successfully'
                    : suspendZone[0].SuspendZone
                    ? 'End suspend zone successfully'
                    : 'Suspend zone successfully';

                pushSuccess(messageSuccess);
                setPagingZone(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setLoading(false);
            });
        await getEventLogs();
    };

    const onSuspendAllRedZones = async () => {
        const cloneZones: Zone[] = [...pagingZone.rows];
        const _data: Zone[] = cloneZones
            .filter((zone) => zone.AvgPCNVisitColor === AvgPCNVisitColor.Red)
            .filter((zone) => !zone.SuspendZone);

        onSuspendZone(_data);
    };

    const tabs: Tab[] = [
        {
            id: 1,
            title: 'Total PCNs Issued',
            icon: <IcPcnIssued />,
            path: 'total-pcns-issued',
        },
        {
            id: 2,
            title: 'Total PCNs Issued per check-in',
            icon: <IcPcnIssuedPerCheckIn />,
            path: 'total-pcns-per-check-in',
        },
    ];

    useEffect(() => {
        const _selectedTab = tabs.find((t) => locationRoute.pathname.includes(t.path));
        setSelectedTab(_selectedTab ?? ({} as Tab));
    }, [locationRoute]);

    return (
        <div style={{ position: 'relative', pointerEvents: loading ? 'none' : undefined, height: '100vh' }}>
            {loading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        zIndex: 2,
                    }}
                />
            )}
            <Box sx={{ filter: loading ? 'blur(4px)' : undefined, transition: 'all 0.3s' }}>
                {/* <Stack direction={'row'} alignItems="center" spacing={2}>
                    <Typography fontSize={24}>{location.Name}</Typography>
                    {isNumber(location.LocationStatus) && (
                        <Chip
                            label={locationStatusName[location.LocationStatus]}
                            size="small"
                            sx={{
                                backgroundColor: isLocationLive ? '#E8F5E9' : '#e01b001a',
                                color: isLocationLive ? 'success.main' : 'error.main',
                                height: 23,
                                minWidth: 55,
                                fontSize: '12px',
                            }}
                        />
                    )}
                </Stack> */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack
                        sx={{ backgroundColor: colorLocationMapping[colorLocation], p: 1, borderRadius: 0.5 }}
                        direction="row"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack direction={'row'} alignItems="center" spacing={2}>
                            <Typography fontSize={24}>{location.Name}</Typography>
                            {isNumber(location.LocationStatus) && (
                                <Chip
                                    label={locationStatusName[location.LocationStatus]}
                                    size="small"
                                    sx={{
                                        backgroundColor: isLocationLive ? '#E8F5E9' : '#e01b001a',
                                        color: isLocationLive ? 'success.main' : 'error.main',
                                        height: 23,
                                        minWidth: 55,
                                        fontSize: '12px',
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>

                    <Button
                        variant="errorOutlined"
                        sx={{ width: 200, p: 2 }}
                        onClick={() => {
                            popUpSuspendAllRedZones.setTrue();
                        }}
                    >
                        Suspend all red zones
                    </Button>
                </Stack>

                <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between">
                    <BreadCrumbs breadcrumbs={breadcrumbs} />

                    <Typography variant="body1" color="#85858A">
                        {`${isUpdatedAt} ${isUpdatedBy}`}
                    </Typography>
                </Stack>

                <FormControl sx={{ mt: 2, height: '32px' }}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={location.LocationType || ''}
                        onChange={handleChange}
                    >
                        <Stack direction="row" alignItems="center" gap={2}>
                            <BaseTitle color="primary.main">Location type:</BaseTitle>
                            <FormControlLabel value="mobile" control={<Radio />} label="Mobile" />
                            <FormControlLabel value="static" control={<Radio />} label="Static" />
                            {isStaticHasZones && (
                                <Typography fontSize={14} color="error" ml={-3.5}>
                                    (Please setup sub-region for this location!)
                                </Typography>
                            )}
                        </Stack>
                    </RadioGroup>
                </FormControl>

                {/* {!!location.Clusters?.length && (
                    <Stack direction="row" alignItems="center" spacing={1} p="10px 0">
                        {' '}
                        <Typography fontSize={14}>{`Cluster: `}</Typography>
                        <Stack direction="row" spacing={1}>
                            {location.Clusters.map((c: ClusterWithRelations) => {
                                return (
                                    <Tooltip
                                        title={
                                            <Typography>{`Locations: ${c.Locations?.map((l) => l.Name).join(
                                                ', '
                                            )}`}</Typography>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <Chip label={c.Name} sx={{ minWidth: 100 }} />
                                    </Tooltip>
                                );
                            })}
                        </Stack>
                    </Stack>
                )} */}

                <Stack mt={4}>
                    <Stack direction={'row'} justifyContent="space-between">
                        <BaseTitle color="primary.main">Zone details</BaseTitle>

                        <Stack direction={'row'} spacing={3}>
                            <BaseTitle>Total zones: {pagingZone.total}</BaseTitle>
                            <Typography
                                fontSize={16}
                                fontWeight={500}
                                color={totalValidZone ? 'success.main' : 'error.main'}
                            >
                                Valid zones: {`${totalValidZone}/${pagingZone.total}`}
                            </Typography>
                        </Stack>
                    </Stack>

                    {!!pagingZone.total && (
                        <Box mt={2}>
                            <TableContainer component={Box}>
                                <Stack alignItems="center">
                                    <Table
                                        sx={{
                                            minWidth: 500,
                                            '& th, td': {
                                                padding: '8px',
                                            },
                                            '& th:first-child, td:first-child': {
                                                paddingLeft: '16px',
                                            },
                                        }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow sx={{ background: '#FAFAFA' }}>
                                                {[
                                                    {
                                                        name: 'Zone name',
                                                        pl: 2,
                                                        desc: '',
                                                    },
                                                    {
                                                        name: 'WPS status',
                                                        required: true,
                                                        align: 'center',
                                                        desc: 'Service must be enabled for the Zone in order for iTicket to work.',
                                                        subTitle: true,
                                                    },
                                                    {
                                                        name: 'PCN Type',
                                                        required: true,
                                                        align: 'center',
                                                        desc: 'The WPS Status service configuration options “Physical” or “Virutal” or both must be selected.',
                                                        subTitle: true,
                                                    },
                                                    {
                                                        name: 'Rate',
                                                        required: true,
                                                        align: 'center',
                                                        desc: 'The upper and lower PCN values must be defined on location level.',
                                                    },
                                                    {
                                                        name: 'Contravention',
                                                        required: true,
                                                        align: 'center',
                                                        desc: 'At least one contravention must be enabled on location level.',
                                                    },
                                                    {
                                                        name: 'Consideration',
                                                        align: 'center',
                                                        desc: 'Optional. Consideration period (minutes) defined on WPS Status service configuration level.',
                                                        subTitle: true,
                                                    },
                                                    {
                                                        name: 'First seen',
                                                        align: 'center',
                                                        desc: 'Optional. First Seen Period (minutes) defined on WPS Status service configuration level.',
                                                        subTitle: true,
                                                    },
                                                    {
                                                        name: '',
                                                        align: 'center',
                                                        subTitle: false,
                                                    },
                                                ].map((column) => {
                                                    return (
                                                        <TableCell
                                                            key={column.name}
                                                            align={column.align as TableCellProps['align']}
                                                        >
                                                            <Tooltip title={column.desc!} arrow placement="top">
                                                                <Typography
                                                                    variant="h6"
                                                                    fontWeight={600}
                                                                    pl={column.pl}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    {column.name}
                                                                    {column.required && !column.subTitle && (
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    )}
                                                                </Typography>
                                                            </Tooltip>
                                                            {column.subTitle && (
                                                                <Box component={'span'}>
                                                                    (Self ticketing new/Parking Patrol
                                                                    {column.required && (
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    )}
                                                                    )
                                                                </Box>
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pagingZone.rows.map((zone, index) => {
                                                const {
                                                    WPSStatus,
                                                    Considerations,
                                                    isSetupContravention,
                                                    PCNTypes,
                                                    isSetupRate,
                                                    FirstSeen,
                                                    Services,
                                                    Name,
                                                    Id,
                                                    SuspendZone,
                                                    AvgPCNVisitColor: zoneColorCoded,
                                                } = zone;

                                                const name = `${index * pagingZone.page + 1}. ${Name}`;

                                                return (
                                                    <TableRow key={Id}>
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            <Stack direction={'row'} alignItems="center" spacing={1}>
                                                                <Circle
                                                                    size={8}
                                                                    color={
                                                                        !zone.assignable ? '#C5C5C5' : 'success.main'
                                                                    }
                                                                />
                                                                <Typography
                                                                    color={!zone.assignable ? '#85858A' : undefined}
                                                                >
                                                                    {name}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>

                                                        {[
                                                            {
                                                                name: 'WPS status',
                                                                STS: WPSStatus?.STS,
                                                                warden: WPSStatus?.Warden,
                                                            },
                                                            {
                                                                name: 'PCN Type',
                                                                STS: PCNTypes?.STS,
                                                                warden: PCNTypes?.Warden,
                                                            },
                                                            { name: 'Rate', error: isSetupRate },
                                                            {
                                                                name: 'Contravention',
                                                                error: isSetupContravention,
                                                            },
                                                            {
                                                                name: 'Consideration',
                                                                STS: Considerations?.STS,
                                                                warden: Considerations?.Warden,
                                                            },
                                                            {
                                                                name: 'First seen',
                                                                STS: FirstSeen?.STS,
                                                                warden: FirstSeen?.Warden,
                                                            },
                                                        ].map((td) => {
                                                            return (
                                                                <TableCell key={td.name + index} align={'center'}>
                                                                    {td.error != null ? (
                                                                        <StatusIcon error={!td.error} />
                                                                    ) : (
                                                                        <Stack
                                                                            direction={'row'}
                                                                            gap={1}
                                                                            alignItems={'center'}
                                                                            justifyContent={'center'}
                                                                        >
                                                                            <StatusIcon error={!td.STS} />
                                                                            <Box component={'span'} color={'#85858A'}>
                                                                                /
                                                                            </Box>
                                                                            <StatusIcon error={!td.warden} />
                                                                        </Stack>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}

                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            {zoneColorCoded === AvgPCNVisitColor.Red &&
                                                                !SuspendZone && (
                                                                    <Button
                                                                        variant={'errorOutlined'}
                                                                        sx={{ padding: '4px 16px', width: 120 }}
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSuspendZone([zone]);
                                                                            popUpSuspendZones.setTrue();
                                                                        }}
                                                                    >
                                                                        Suspend
                                                                    </Button>
                                                                )}

                                                            {SuspendZone && (
                                                                <Button
                                                                    variant={'outlined'}
                                                                    sx={{ padding: '4px 16px', width: 120 }}
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSuspendZone([zone]);
                                                                        popUpSuspendZones.setTrue();
                                                                    }}
                                                                >
                                                                    End suspension
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>

                                    {pagingZone.totalPages > 1 && (
                                        <Pagination
                                            count={pagingZone.totalPages}
                                            page={pagingZone.page}
                                            onChange={(_, page) => {
                                                setZoneQuery((prev) => ({ ...prev, page }));
                                            }}
                                            shape="rounded"
                                            sx={{
                                                mt: 2.5,
                                                '& .Mui-selected': {
                                                    background: '#3479BB1A !important',
                                                },
                                                '& .MuiPaginationItem-previousNext': {
                                                    background: '#EEEEEE',
                                                },
                                            }}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    components={{ previous: IcPrev, next: IcNext }}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    )}
                                </Stack>
                            </TableContainer>
                        </Box>
                    )}
                    {!!pagingEventLog.rows.length && (
                        <ZoneEventLogs
                            eventLogs={pagingEventLog}
                            onChangePage={(page) => {
                                setPagingEventLog((prev) => ({ ...prev, page }));
                            }}
                        />
                    )}
                </Stack>

                <Collapse in={location.LocationType === 'static'}>
                    <Stack mt={4} spacing={2}>
                        <BaseTitle color="primary.main">Operational period</BaseTitle>
                        <RotaTable
                            data={periodShifts}
                            hideTitle
                            renderOption={(option, index, arr) => {
                                return (
                                    <StyledShift
                                        key={index}
                                        // readonly={isMobileType}
                                        shift={option}
                                        renderTitleShift={(shift) => shift?.requiredWarden || 1}
                                        onAddPeriod={(shift) => {
                                            setSelectedShift(shift);
                                            popUpAddPeriod.setTrue();
                                        }}
                                        renderActions={(shift) => (
                                            <ShiftActions
                                                key={shift.id}
                                                shift={shift}
                                                onEdit={(shift) => {
                                                    setSelectedShift(shift);
                                                    popUpAddPeriod.setTrue();
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                        <PopUpAddPeriod
                            {...popUpAddPeriod}
                            selectShift={selectedShift}
                            shifts={shifts}
                            onConfirm={(shifts, checkedWeekdays) => {
                                handleChangeShift(shifts, checkedWeekdays);
                                popUpAddPeriod.onClose();
                            }}
                        />
                        <PopUpWarningChangeLocationType
                            {...popUpWarning}
                            title="Confirm"
                            onConfirm={(applyChangesFromDate) => {
                                throttleConfirm(applyChangesFromDate);
                                popUpWarning.onClose();
                            }}
                        />
                        <PopUpWarning
                            {...popUpSuspendZones}
                            title="Confirm"
                            message={`Are you sure you want to ${
                                !!suspendZone.length && suspendZone[0].SuspendZone ? 'end suspension' : 'suspend'
                            } this zone?`}
                            onConfirm={() => {
                                popUpSuspendZones.onClose();
                                onSuspendZone();
                            }}
                        />
                        <PopUpWarning
                            {...popUpSuspendAllRedZones}
                            title="Confirm"
                            message={'Are you sure you want to suspend all red zones for this location?'}
                            onConfirm={() => {
                                popUpSuspendAllRedZones.onClose();
                                onSuspendAllRedZones();
                            }}
                        />
                    </Stack>
                </Collapse>

                <Stack direction="row" gap={1} mt={4}>
                    {tabs.map((item, index) => {
                        return (
                            <Stack
                                key={index + 'z'}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    p: 1,
                                    background: selectedTab.id === item.id ? '#E8F5E9' : '#FAFAFA',
                                    borderRadius: '8px 8px 0px 0px',
                                    color: selectedTab.id === item.id ? theme.palette.success.main : color.grey600,
                                    cursor: 'pointer',
                                    transition: '.2s',
                                }}
                                gap={1}
                                onClick={() => {
                                    navigate(`${item.path}`);
                                }}
                            >
                                {item.icon}
                                <Typography
                                    variant="body1"
                                    color={selectedTab.id === item.id ? theme.palette.success.main : color.grey600}
                                    sx={{ userSelect: 'none' }}
                                >
                                    {item.title}
                                </Typography>
                            </Stack>
                        );
                    })}
                </Stack>
                <Box
                    sx={{
                        border: '1px solid #DDDDDD',
                        borderRadius: '0px 8px 8px 8px;',
                        p: '40px 16px',
                    }}
                >
                    {loadingGetTotalPCNsIssued ? (
                        <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Outlet />
                    )}
                </Box>

                <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    mt={5}
                    sx={{
                        '& button': {
                            minWidth: 150,
                        },
                    }}
                >
                    <Button variant="cancel" onClick={() => navigate(-1)}>
                        {t('wardenDetailsPage.button.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => popUpWarning.setTrue()}
                        disabled={JSON.stringify(convertedPrevState) === JSON.stringify(convertedState)}
                    >
                        Save
                    </Button>
                </Stack>
            </Box>
        </div>
    );
}

type Props = Omit<IPopUp, 'onConfirm'> & {
    title?: string;
    content?: React.ReactNode;
    onConfirm?(applyChangesFromDate: Date): void;
};

function PopUpWarningChangeLocationType(props: Props) {
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [confirmed, setConfirmed] = useState(false);
    const applyChangesFromDate =
        selectedDay === 0
            ? moment(new Date()).startOf('date').toDate()
            : moment(new Date()).add(1, 'week').startOf('isoWeek').toDate();
    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '500px', overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Typography variant="h4" color="error">
                    {props.title || 'Warning'}
                </Typography>
            }
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack mt={1} pb={0.5} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">This period will be applied from:</Typography>
                        <FormControl sx={{ minWidth: 250 }} size="small">
                            <Select
                                labelId="select-id"
                                id="demo-simple-select"
                                value={selectedDay}
                                sx={{
                                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                                        py: '9.95px',
                                    },
                                }}
                                onChange={(e) => {
                                    setSelectedDay(e.target.value as any);
                                }}
                            >
                                {[
                                    { title: 'Today', value: 0 },
                                    { title: 'Next monday', value: 1 },
                                ].map((w) => {
                                    return (
                                        <MenuItem key={w.title} value={w.value}>
                                            {w.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Typography variant="body1">
                        <Typography variant="body1" fontWeight={700} component="span">
                            {`Note: `}
                        </Typography>
                        Changing new operational period can affect all previous assigned shifts from{' '}
                        <Typography component="span" fontWeight={700}>
                            {selectedDay === 0
                                ? formatDate(moment(new Date()).toDate())
                                : formatDate(moment(new Date()).add(1, 'week').startOf('isoWeek').toDate())}
                        </Typography>{' '}
                        and after. Please visit Rota management to assign again.
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                            checked={confirmed}
                            onChange={(e, c) => {
                                setConfirmed(c);
                            }}
                        />
                        <Typography variant="body1">
                            I confirm that I totally understand and agree with this change.
                        </Typography>
                    </Stack>
                    <Stack mt={3} direction={'row'} justifyContent="space-between">
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="cancel"
                            onClick={() => {
                                props.onClose?.();
                                setSelectedDay(0);
                                setConfirmed(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="errorContained"
                            disabled={!confirmed}
                            onClick={() => {
                                props.onConfirm?.(applyChangesFromDate);
                                setSelectedDay(0);
                                setConfirmed(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
