import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import { pushSuccess } from '@components/StyledToast';
import { BaseHead, CellOfRow } from '@components/utils';
import { paymentController } from '@controllers';
import { Paging } from '@Core';
import { defaultPaging, rounded } from '@helpers';
import usePopUp from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import { IGetTimesheetsStatisticProps } from '@WardenOps/controller/IPaymentHttpController';
import { TimesheetsStatistic, WardenType } from '@WardenOps/model';
import moment from 'moment';
import { useEffect, useState } from 'react';
import BaseFilterWarden from '../setting/warden/list/components/BaseFilterWarden';
import useFilterWarden from '../setting/warden/list/hooks/useFilterWarden';
import DatePickerTimeSheets from './components/DatePickerTimeSheets';
import PopUpTimeSheets from './components/PopUpTimeSheets';

export default function TimeSheets() {
    const breadcrumbs: IBreadCrumbs[] = [{ title: 'Payment' }, { title: 'Weekly timesheets' }];
    const popUpTimeSheets = usePopUp();
    const {
        filter,
        filteredSubRegion,
        handleChange,
        handleReset,
        hasMoreRegions,
        hasMoreSubRegions,
        loading,
        onGetMoreRegions,
        onGetMoreSubRegions,
        regions,
        searchSubRegion,
        searchValue,
        setLoading,
        setSearchSubRegion,
        subRegions,
        handleChangeSearchWarden,
    } = useFilterWarden({ mode: 'time-sheets' });

    const [timesheets, setTimesheets] = useState<Paging<TimesheetsStatistic>>(defaultPaging());

    const [periodsTimesheetId, setPeriodsTimesheetId] = useState<number | undefined>(undefined);

    const isEmpty = !timesheets.rows.length && !loading;
    const TimeTo = moment(filter.startDate).add(6, 'days').toDate();

    const currentWeek = moment().startOf('isoWeek').toDate();
    const enabledExport = moment(filter.startDate).isBefore(currentWeek);

    const handleExportExcel = () => {
        paymentController.downloadTimesheetsStatistic(getFilterProps()).then((res) => {
            const bufRes = (res as any).buffer.data;
            const buf = new Uint8Array(bufRes).buffer;
            const buftype = 'application/vnd.ms-excel;charset=utf-8';
            let blob = new Blob([buf], {
                type: buftype,
            });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${moment(filter.startDate).format('DD MMMM YYYY')} - ${moment(TimeTo).format(
                        'DD MMMM YYYY'
                    )} Timesheets Report.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                pushSuccess('Downloaded successfully');
            });
    };

    const init = () => {
        setLoading(true);
        paymentController
            .getTimesheetsStatistic(getFilterProps())
            .then((res) => {
                setTimesheets(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => setLoading(false));
    };

    const getFilterProps = () => {
        const filterProps: IGetTimesheetsStatisticProps = {
            page: filter.page || 1,
            search: {
                content: filter.search?.content ?? '',
                fields: ['FirstName', 'LastName', 'Email'],
            },
            filter: { CountrySubRegionId: filter.subRegion?.Id, WardenType: filter.wardenType?.Value },
            CountryRegionId: filter.region?.Id,
            sorts: ['FirstName'],
            pageSize: 10,
            TimeFrom: filter.startDate,
        };

        if (filterProps.filter?.CountrySubRegionId) delete filterProps.CountryRegionId;
        return filterProps;
    };

    useEffect(() => {
        init();
    }, [
        filter.page,
        filter.region?.Id,
        filter.search?.content,
        filter.subRegion?.Id,
        filter.startDate,
        filter.wardenType?.Value,
    ]);

    return (
        <div>
            <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                <Typography fontSize={24}>Weekly timesheets</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <DatePickerTimeSheets
                        startDate={filter.startDate}
                        onChange={(startDate) => handleChange({ startDate })}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ minWidth: 135 }}
                        endIcon={
                            <svg
                                width="10"
                                height="14"
                                viewBox="0 0 10 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.46967 9.7803C4.76256 10.0732 5.23744 10.0732 5.53033 9.7803L9.2803 6.03033C9.5732 5.73744 9.5732 5.26256 9.2803 4.96967C8.9874 4.67678 8.5126 4.67678 8.2197 4.96967L5.75 7.43934V0.75C5.75 0.33579 5.41421 0 5 0C4.58579 0 4.25 0.33579 4.25 0.75V7.43934L1.78033 4.96967C1.48744 4.67678 1.01256 4.67678 0.71967 4.96967C0.42678 5.26256 0.42678 5.73744 0.71967 6.03033L4.46967 9.7803ZM0.75 12.0001C0.33579 12.0001 0 12.3359 0 12.7501C0 13.1643 0.33579 13.5001 0.75 13.5001H9.25C9.6642 13.5001 10 13.1643 10 12.7501C10 12.3359 9.6642 12.0001 9.25 12.0001H0.75Z"
                                    fill={`${!enabledExport ? '#A6A6A6' : '#fff'}`}
                                />
                            </svg>
                        }
                        onClick={handleExportExcel}
                        disabled={!enabledExport}
                    >
                        Export
                    </Button>
                </Stack>
            </Stack>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            <BaseFilterWarden
                searchValue={searchValue}
                onChangeSearch={handleChangeSearchWarden}
                hasMoreRegions={hasMoreRegions}
                onGetMoreRegion={onGetMoreRegions}
                regions={regions}
                region={filter.region}
                onChangeRegion={(option) => {
                    handleChange({
                        region: option,
                        page: 1,
                        subRegion: undefined,
                    });
                }}
                hasMoreSubRegions={hasMoreSubRegions}
                onGetMoreSubRegion={onGetMoreSubRegions}
                subRegions={filteredSubRegion}
                subRegion={filter.subRegion}
                onChangeSubRegion={(option) => {
                    handleChange({
                        subRegion: option,
                        page: 1,
                    });
                }}
                searchSubRegionValue={searchSubRegion}
                onSearchSubRegion={(text) => setSearchSubRegion(text)}
                totalSubRegions={subRegions.total}
                onReset={handleReset}
                wardenType={filter.wardenType}
                wardenTypes={[
                    { Name: 'Permanent', Value: WardenType.PERMANENT_STAFF },
                    { Name: 'Temporary', Value: WardenType.TEMPORARY_STAFF },
                ]}
                onChangeWardenType={(option) => {
                    handleChange({ wardenType: option, page: 1 });
                }}
            />
            <Grid
                container
                xs={12}
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: '0px 8px 8px 8px;',
                    p: '20px 16px',
                }}
                justifyContent="center"
                mt={2}
            >
                <TableContainer component={Box}>
                    {loading ? (
                        <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Stack alignItems="center">
                            {isEmpty && <EmptyPage />}
                            {!isEmpty && (
                                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#FAFAFA' }}>
                                            <BaseHead align="left" title="Parking operative" />
                                            <BaseHead align="center" title="Contracted hours" />
                                            <BaseHead align="center" title="Worked hours" />
                                            <BaseHead align="center" title="OT" />
                                            <BaseHead align="center" title="Holidays/Lieu" />
                                            <BaseHead align="center" title="Payment hours" />
                                            <BaseHead align="center" title="Status" />
                                            <BaseHead title="" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {timesheets.rows.map((ts, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <CellOfRow value={ts.Warden.FullName ?? ''} align="left" />
                                                    <CellOfRow value={ts.ContractedHour ?? 0} />
                                                    <CellOfRow value={rounded(ts.PeriodStatistics.WorkedHour ?? 0)} />
                                                    <CellOfRow value={rounded(ts.OverTimeHour) ?? 0} />
                                                    <CellOfRow
                                                        value={rounded(
                                                            ts.PeriodStatistics.HolidayHour +
                                                                ts.PeriodStatistics.LieuHour
                                                        )}
                                                    />
                                                    <CellOfRow value={rounded(ts.Total) ?? 0} />
                                                    <CellOfRow
                                                        value={
                                                            ts.Approved != null
                                                                ? ts.Approved
                                                                    ? 'Approved'
                                                                    : 'Open'
                                                                : ''
                                                        }
                                                    />
                                                    <TableCell align="right">
                                                        <Button
                                                            color="secondary"
                                                            size="small"
                                                            sx={{ flexBasis: 80, py: 0.8, fontSize: 13 }}
                                                            onClick={() => {
                                                                setPeriodsTimesheetId(ts.PeriodTimesheetsId);
                                                                popUpTimeSheets.setTrue();
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                            {timesheets.totalPages > 1 && (
                                <Pagination
                                    count={timesheets.totalPages}
                                    page={timesheets.page}
                                    onChange={(e: any, value: any) => {
                                        handleChange({ page: value });
                                    }}
                                    shape="rounded"
                                    sx={{
                                        mt: 2,
                                        '& .Mui-selected': {
                                            background: '#3479BB1A !important',
                                        },
                                        '& .MuiPaginationItem-previousNext': {
                                            background: '#EEEEEE',
                                        },
                                    }}
                                    renderItem={(item) => (
                                        <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                    )}
                                />
                            )}
                        </Stack>
                    )}
                </TableContainer>
            </Grid>

            <PopUpTimeSheets
                {...popUpTimeSheets}
                startDate={filter.startDate}
                // selectedTimeSheet={selectedTimeSheet}
                periodsTimesheetId={periodsTimesheetId!}
                onApprove={(timesheetDetails) => {
                    paymentController.updateWeeklyTimesheets(timesheetDetails).then((res) => {
                        init();
                        pushSuccess('Updated successfully!');
                    });
                }}
            />
        </div>
    );
}
