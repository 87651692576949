import Checkbox from '@components/Checkbox';
import { CStack } from '@components/FlexedStack';
import PopUpBase from '@components/PopUpBase';
import { END_DAY_MINUTE, ShiftStatus } from '@components/rota-table';
import { formatDate, getWeekday, _weekdays } from '@helpers';
import useDeepCompareEffect from '@hooks/useDeepCompareEffect';
import { Button, FormControl, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { getValidHour } from '@pages/setting/location/detail/components/PopUpAddPeriod';
import { ShiftWithStatus } from '@pages/setting/location/detail/components/ShiftItem';
import { useWarden } from '@pages/setting/warden/detail';
import {
    isPast,
    RotaWarden,
    WeeklyCalendarShift,
    WeeklyWorkCalendar,
} from '@pages/setting/warden/detail/components/WorkCalendar';
import _, { cloneDeep, difference, uniq } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { IPopUp } from 'src/hooks/usePopUp';
import { v4 } from 'uuid';
import BaseShift from './BaseShift';
import { TemplateDetail } from './RotaWeeklyItem';

export type RotaProps = Omit<TemplateDetail & RotaWarden, 'TimeFrom' | 'TimeTo'> & {
    TimeFrom: Date;
    TimeTo: Date;
};

type Props = Omit<IPopUp, 'onConfirm'> & {
    selectShift?: WeeklyCalendarShift;
    onConfirm?(selectedWeekdays: number[], selectedShift: ShiftWithStatus): void;
    startDate?: Date;
    workCalendar: WeeklyWorkCalendar;
    disabledPast?: boolean;
    isShowSubtitle?: boolean;
};

export default function PopUpAddShift(props: Props) {
    const { warden } = useWarden();
    const { isShowSubtitle = true } = props;
    const { pagingCluster } = useWarden();
    const _isPast = isPast(props.selectShift?.date);
    const dayShifts = !props.selectShift ? [] : props.workCalendar[props.selectShift.weekday!];

    const [selectedShift, setSelectShift] = useState<ShiftWithStatus>();
    const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>([]);

    const invalidWeekdays = useMemo(() => {
        if (!selectedShift) return [];
        return uniq(
            Object.values(props.workCalendar)
                .flat()
                .filter((r) => {
                    return (
                        r.status !== ShiftStatus.lieuLeave &&
                        !(
                            (selectedShift.startMin < r.startMin && selectedShift.endMin <= r.startMin) ||
                            selectedShift.startMin >= r.endMin ||
                            r._id === selectedShift._id
                        )
                    );
                })
                .map((s) => s.weekday!)
        );
    }, [props.workCalendar, selectedShift]);
    console.log(`invalidWeekdays`, invalidWeekdays);

    const checkLimitStartEnd = useMemo(() => {
        const _shiftsOfDay = [...dayShifts].filter((shift) => shift.status !== ShiftStatus.lieuLeave);
        const isEmptyShift = !props.selectShift?.status || props.selectShift?.status === ShiftStatus.lieuLeave;
        if (isEmptyShift) _shiftsOfDay.push(props.selectShift!);
        const sortedShifts = _.sortBy(_shiftsOfDay, 'startMin');
        const index = props.selectShift
            ? _.findIndex(sortedShifts, (s) => s._id === props.selectShift?._id)
            : undefined;
        return {
            isEmptyShift,
            sortedShifts,
            index,
        };
    }, [dayShifts, props.selectShift]);

    const { isEmptyShift, sortedShifts, index } = checkLimitStartEnd;

    const handleChange = (editingShift: ShiftWithStatus) => {
        if (editingShift.startMin === editingShift.endMin) editingShift.endMin = undefined as any;
        const { shiftStatus } = editingShift;

        const { validStartMins, validEndMins } = getValidHour(
            editingShift.startMin,
            sortedShifts[index! - 1]?.endMin,
            sortedShifts[index! + 1]?.startMin
        );

        setSelectShift((prev) => ({
            ...prev,
            ...{ ...editingShift, validStartMins, validEndMins },
            shiftStatus: shiftStatus === 'change' ? 'change' : shiftStatus,
        }));
        //Reset checked weekdays when change shift
        setSelectedWeekdays([props.selectShift?.weekday!]);
    };

    const handleDelete = (shift: ShiftWithStatus) => {
        props.onConfirm?.(selectedWeekdays, { ...shift, shiftStatus: 'deleted' });
    };

    useDeepCompareEffect(() => {
        if (!props.selectShift || !props.open) return;
        const { validEndMins, validStartMins } = getValidHour(
            props.selectShift.startMin,
            sortedShifts[index! - 1]?.endMin,
            sortedShifts[index! + 1]?.startMin
        );

        const _shift: ShiftWithStatus = {
            ...props.selectShift,
            shiftStatus: isEmptyShift ? 'new' : 'change',
            validStartMins,
            validEndMins,
        };
        console.log(`popupaddshift`, props.selectShift);

        setSelectShift(_shift);
        setSelectedWeekdays([props.selectShift.weekday!]);
    }, [props.selectShift, props.open]);

    function toggleWeekday(weekday: number, checked: boolean) {
        if (weekday === props.selectShift?.weekday) return;

        let selectDays: number[] = [...selectedWeekdays];
        if (weekday === -1) selectDays = checked ? _weekdays : [props.selectShift?.weekday!];
        else selectDays = checked ? [...selectDays, weekday] : selectDays.filter((d) => d !== weekday);

        setSelectedWeekdays(selectDays);
    }

    const getValidWeekdays = (editingShift: ShiftWithStatus, workCalendar: WeeklyWorkCalendar) => {
        const validWeekdays: number[] = [];

        Object.entries(workCalendar).forEach(([weekday, shifts]) => {
            // non-past
            const _weekday = Number(weekday);
            if (_weekday === editingShift.weekday) {
                validWeekdays.push(_weekday);
                return;
            }
            const nonPast = !isPast(
                moment(editingShift.date)
                    .isoWeekday(_weekday === 0 ? 7 : _weekday)
                    .toDate()
            );

            // empty shift in day
            const free = shifts
                .filter((s) => s.status !== ShiftStatus.lieuLeave)
                .every((shift) => shift.endMin <= editingShift.startMin || shift.startMin >= editingShift.endMin);

            // has location period
            const hasLocationPeriod = !editingShift.location
                ? true
                : !!editingShift.location?.OperationalPeriods?.filter((p) => p.Weekday === _weekday).some(
                      (p) => editingShift.startMin >= p.TimeFrom && editingShift.endMin <= p.TimeTo
                  );

            if (nonPast && free && hasLocationPeriod) validWeekdays.push(_weekday);
        });

        return validWeekdays;
    };

    const validWeekdays = !selectedShift ? [] : getValidWeekdays(selectedShift, props.workCalendar);
    console.log(`validWeekdays`, validWeekdays);

    const handleSubmit = () => {
        const _shift = cloneDeep(selectedShift!);
        if (_shift.type === 'static') delete _shift.cluster;
        if (_shift.type === 'cluster') delete _shift.location;

        props.onConfirm?.(selectedWeekdays, _shift);
    };
    const disabledInvalid =
        (selectedShift?.type === 'static' && selectedShift?.location?.Assignable === 'invalid') ||
        (selectedShift?.type === 'cluster' && selectedShift?.cluster?.Assignable === 'invalid');

    const disabledSubmit = isNaN(selectedShift?.startMin ?? 0) || isNaN(selectedShift?.endMin ?? END_DAY_MINUTE);
    const isNewShift = !props.selectShift?.status || props.selectShift?.status === ShiftStatus.lieuLeave;

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                keepMounted: true,
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '700px', overflowY: 'unset' },
                },
            }}
            hideClose
            hideConfirm
            title={`${!_isPast ? 'Edit' : 'View'} calendar`}
            subTitle={isShowSubtitle ? `Date: ${formatDate(props.selectShift?.date!)}` : undefined}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            fixOverflow
            desc={
                <Stack>
                    <Stack gap={2}>
                        {selectedShift && (
                            <BaseShift
                                shift={selectedShift}
                                onChange={handleChange}
                                onDelete={handleDelete}
                                warden={warden}
                                isPast={_isPast}
                                clusters={pagingCluster.rows}
                            />
                        )}
                        {!_isPast && (
                            <Stack sx={{ border: '1px solid #ddd', borderRadius: '5px', p: '8px', py: '10px' }}>
                                <CStack justifyContent={'space-between'}>
                                    <FormControl component="fieldset" fullWidth>
                                        <FormGroup aria-label="position" row sx={{ justifyContent: 'space-evenly' }}>
                                            <FormControlLabel
                                                key={v4()}
                                                value={-1}
                                                checked={!difference(_weekdays, selectedWeekdays).length}
                                                onChange={(_, checked) => toggleWeekday(-1, checked)}
                                                control={<Checkbox />}
                                                label="All days"
                                                labelPlacement="bottom"
                                                disabled={validWeekdays.length !== 7}
                                                sx={{ userSelect: 'none' }}
                                            />
                                            {getWeekday(props.startDate).map((w) => {
                                                const disabled = !validWeekdays.includes(w.weekday);

                                                return (
                                                    <FormControlLabel
                                                        key={w.weekday}
                                                        value={w.weekday}
                                                        control={<Checkbox />}
                                                        checked={selectedWeekdays.includes(w.weekday)}
                                                        onChange={(_, checked) => {
                                                            toggleWeekday(w.weekday, checked);
                                                        }}
                                                        sx={{ userSelect: 'none' }}
                                                        disabled={disabled}
                                                        label={
                                                            <Typography
                                                                textAlign={'center'}
                                                                color={disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
                                                            >
                                                                {moment(w.date).format('ddd')}
                                                            </Typography>
                                                        }
                                                        labelPlacement="bottom"
                                                    />
                                                );
                                            })}
                                        </FormGroup>
                                    </FormControl>
                                </CStack>
                            </Stack>
                        )}
                    </Stack>

                    <Stack mt={3} direction={'row'} justifyContent={!_isPast ? 'space-between' : 'center'}>
                        <Button sx={{ minWidth: 150 }} variant="cancel" onClick={props.onClose}>
                            Close
                        </Button>
                        {!_isPast && (
                            <Stack direction="row" spacing={1}>
                                {!isNewShift && (
                                    <Button
                                        sx={{ minWidth: 150 }}
                                        variant="contained"
                                        onClick={() => {
                                            handleDelete(selectedShift ?? ({} as ShiftWithStatus));
                                        }}
                                        color="error"
                                    >
                                        Delete shift
                                    </Button>
                                )}
                                <Button
                                    sx={{ minWidth: 150 }}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={disabledSubmit || disabledInvalid}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            }
        />
    );
}
