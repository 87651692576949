import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, Typography } from '@mui/material';

type Props = IPopUp & {
    title: string;
    message: string;
    subMessage1?: string;
    subMessage2?: string;
    renderApplyDate(): JSX.Element;
};

export default function PopUpConfirmUpdate(props: Props) {
    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '490px', overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Typography variant="h4" color="primary">
                    {props.title}
                </Typography>
            }
            hideConfirm
            hideClose
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack justifyContent="center">
                    <Stack pt={1} spacing={1}>
                        <Stack direction="row" alignItems="center" width="100%" spacing={1}>
                            <Typography fontSize={14}>{props.message}</Typography>
                            {props.renderApplyDate()}
                        </Stack>
                        <Typography fontSize={14}>{props.subMessage1}</Typography>
                        <Typography fontSize={14}>{props.subMessage2}</Typography>
                    </Stack>
                    <Stack mt={3} direction={'row'} justifyContent="space-between">
                        <Button sx={{ minWidth: 130 }} variant="cancel" onClick={props.onClose}>
                            No
                        </Button>
                        <Button sx={{ minWidth: 130 }} color="primary" variant="contained" onClick={props.onConfirm}>
                            Yes
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}
