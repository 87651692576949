import { Paging } from '@Core';
import { CountryRegion } from '@LocationOps/model';
import IcNoTicket from '@components/icon/alert-tab/IcNoTicket';
import IcPhoneStation from '@components/icon/alert-tab/IcPhoneStation';
import IcSiteEarlyOff from '@components/icon/alert-tab/IcSiteEarlyOff';
import IcSiteLogin from '@components/icon/alert-tab/IcSiteLogin';
import StyledSelect from '@components/select-search/StyledSelect';
import { regionController } from '@controllers';
import { defaultPaging } from '@helpers';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption from '@pages/rota-coverage/components/SelectOption';
import { Tab } from '@pages/setting/warden/detail';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useNotifyContext } from 'src/contexts/NotificationProvider';
import BaseSelectDate from './BaseSelectDate';

export default function WrapperAlertPage() {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<Tab>({} as Tab);
    const { statistic, filter, setFilter } = useNotifyContext();
    const [pagingRegions, setPagingRegions] = useState<Paging<CountryRegion>>(defaultPaging());

    const onGetMoreRegions = async () => {
        await regionController
            .list({
                page: pagingRegions.page + 1,
                pageSize: 10,
                sorts: ['Name'],
            })
            .then((res) => {
                const _res = { ...res, rows: [...pagingRegions.rows, ...res.rows] };
                setPagingRegions(_res);
            });
    };
    useEffect(() => {
        regionController.list({ pageSize: 1000, sorts: ['Name'] }).then((res) => setPagingRegions(res));
    }, []);

    useEffect(() => {
        const _selectedTab = tabs.find((t) => location.pathname.includes(t.path));
        setSelectedTab(_selectedTab ?? ({} as Tab));
    }, [location]);

    const tabs = [
        {
            id: 1,
            title: 'Site login',
            icon: <IcSiteLogin />,
            path: 'site-login',
            statistic: statistic.SiteLateLogin || 0,
        },
        {
            id: 2,
            title: 'Phone stationary',
            icon: <IcPhoneStation />,
            path: 'phone-stationary',
            statistic: statistic.PhoneStation || 0,
        },
        {
            id: 3,
            title: 'No tickets',
            icon: <IcNoTicket />,
            path: 'no-tickets',
            statistic: statistic.AlertNoTicket || 0,
        },
        {
            id: 4,
            title: 'Site early logoff',
            icon: <IcSiteEarlyOff />,
            path: 'early-logoff',
            statistic: statistic.SiteEarlyLogoff || 0,
        },
    ];

    return (
        <div>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize={24}>Alerts on {moment(filter.startDate).format('dddd, DD/MM/YYYY')}</Typography>
                <Stack width="50%" direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                    <StyledSelect
                        label="Region"
                        disabledSearch
                        data={pagingRegions.rows}
                        value={filter.region}
                        hasMore={Boolean(pagingRegions.page < pagingRegions.totalPages)}
                        next={onGetMoreRegions}
                        onChange={(region) => {
                            setFilter((prev) => ({ ...prev, region }));
                        }}
                        renderValue={(value) => {
                            return <Typography noWrap>{value?.Name || 'All regions'}</Typography>;
                        }}
                        renderDefaultOption={() => <DefaultOption title="All regions" />}
                        renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                        sx={{ width: 200 }}
                    />
                    <BaseSelectDate
                        startDate={filter.startDate}
                        onChange={(startDate) => setFilter((prev) => ({ ...prev, startDate }))}
                    />
                </Stack>
            </Stack>
            <Stack direction="row" gap={1} mt={5}>
                {tabs.map((item, index) => {
                    return (
                        <Stack
                            key={index + 'z'}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                width: 200,
                                p: 1,
                                background: selectedTab.id === item.id ? '#E8F5E9' : '#FAFAFA',
                                borderRadius: '8px 8px 0px 0px',
                                color: selectedTab.id === item.id ? theme.palette.secondary.main : 'inherit',
                                cursor: 'pointer',
                                transition: '.2s',
                            }}
                            gap={1}
                            onClick={() => {
                                navigate(`${item.path}`);
                            }}
                        >
                            {item.icon}
                            <Typography
                                variant="body1"
                                color={selectedTab.id === item.id ? theme.palette.secondary.main : 'inherit'}
                                sx={{ userSelect: 'none' }}
                            >
                                {item.title}
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color={'error'}
                                    sx={{ display: 'inline-block', pl: 0.5 }}
                                >
                                    {`(${item.statistic})`}
                                </Typography>
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>

            <Box
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: '0px 8px 8px 8px;',
                    p: '20px 16px',
                }}
            >
                <Outlet />
            </Box>
        </div>
    );
}

// type ContextType = {
//     filter: Filter;
//     setFilter: Dispatch<SetStateAction<Filter>>;
// };
// export function useContextAlert() {
//     return useOutletContext<ContextType>();
// }
