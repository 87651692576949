import { WardenEvent } from '@LocationOps/model/WardenEvent';
import { Typography } from '@mui/material';
import React from 'react';
import { BaseWardenEventItem, MapItemCreated, ReturnElement, WardenEventItemProps } from './BaseWardenEventItem';

export const TrackGPS: ReturnElement = {
    ListItem: (props: WardenEventItemProps) => {
        return <BaseWardenEventItem {...props} text="GPS location" />;
    },
    MapItem: (props: WardenEvent) => (
        <div>
            <Typography>GPS location</Typography>
            <MapItemCreated Created={props.Created!} />
        </div>
    ),
};
