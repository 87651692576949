import Checkbox from '@components/Checkbox';
import { CStack } from '@components/FlexedStack';
import { END_DAY_MINUTE, Shift } from '@components/rota-table';
import { formatHour } from '@components/rota-table/Shift';
import ShiftRange from '@components/rota-table/ShiftRange';
import { ClusterWithRelations, LocationWithRelations } from '@LocationOps/model';
import { Close } from '@mui/icons-material';
import { Collapse, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdArrowDropUp } from 'react-icons/md';

export type ShiftWithStatus = Shift & {
    defaultOpen?: boolean;
    shiftStatus?: 'deleted' | 'new' | 'change';
    validStartMins?: number[];
    validEndMins?: number[];
    location?: LocationWithRelations;
    cluster?: ClusterWithRelations;
    type?: 'static' | 'cluster';
};
export type Value = Pick<Shift, 'startMin' | 'endMin' | 'requiredWarden'>;

type ShiftItemProps = {
    shift: ShiftWithStatus;
    open?: boolean;
    onChange(value: ShiftWithStatus): void;
    onDelete?(shift: ShiftWithStatus): void;
    toggleOpen?(shift: ShiftWithStatus): void;
    displayCheckedHours?: boolean;
    disabledSelectHour?: boolean;
    disabledDelete?: boolean;
};

const ShiftItem = (props: ShiftItemProps) => {
    const open = props.open;
    const [isFullDay, setIsFullDay] = useState(true);

    const isValid = !isNaN(props.shift.startMin) && !isNaN(props.shift.endMin);

    const handleChange = (value: Partial<Value>) => {
        props.onChange({ ...props.shift, ...value });
    };

    const isError =
        !props.shift.requiredWarden || isNaN(props.shift.requiredWarden as any) || props.shift.requiredWarden < 1;

    useEffect(() => {
        if (
            (props.shift.startMin >= 0 && props.shift.endMin < END_DAY_MINUTE) ||
            (props.shift.startMin > 0 && props.shift.endMin <= END_DAY_MINUTE)
        ) {
            setIsFullDay(false);
        }
    }, [props.shift.defaultOpen]);

    return (
        <Stack direction="row" width="100%" justifyContent="space-between" gap={2}>
            <Stack
                width="90%"
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: 1,
                    p: open ? '8px 16px 16px 16px' : '8px 16px',
                    cursor: 'pointer',
                    transition: '0.3s',
                }}
                justifyContent="space-between"
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack
                        onClick={() => props.toggleOpen?.(props.shift)}
                        direction="row"
                        flex={1}
                        alignItems="center"
                        justifyContent={'space-between'}
                        sx={{ bgcolor: '#FAFAFA', p: '2px 4px', pl: 1, borderRadius: '5px' }}
                    >
                        <Stack direction="row" gap={2}>
                            <Typography color={!isValid ? 'error' : 'inherit'}>
                                {!isValid
                                    ? `Please fill operational period`
                                    : `${formatHour(props.shift.startMin)} - ${formatHour(props.shift.endMin)} (${
                                          props.shift.endMin / 60 - props.shift.startMin / 60
                                      }h)`}
                            </Typography>
                        </Stack>
                        <CStack
                            sx={{
                                transform: `rotate(${open ? -180 : 0}deg)`,
                                color: '#85858A',
                                transition: 'all 0.3s',
                            }}
                        >
                            <MdArrowDropUp fontSize={24} />
                        </CStack>
                    </Stack>
                </Stack>

                <Collapse in={!!open}>
                    <Stack mt={1} spacing={'10px'}>
                        <Stack>
                            <Typography>
                                Time
                                <span style={{ color: '#EF1320', textShadow: '#EF1320' }}> *</span>
                            </Typography>

                            <Stack direction="row" width="100%" spacing={1} alignItems="center">
                                <ShiftRange
                                    fullWidth
                                    value={{
                                        startMin: props.shift.startMin,
                                        endMin: props.shift.endMin,
                                    }}
                                    onChange={(value) => {
                                        setIsFullDay(false);
                                        handleChange({ startMin: value.startMin, endMin: value.endMin });
                                    }}
                                    startMins={props.shift.validStartMins || []}
                                    endMins={props.shift.validEndMins || []}
                                    disabled={props.disabledSelectHour}
                                />
                                {props.displayCheckedHours && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isFullDay}
                                                onChange={() => {
                                                    setIsFullDay(true);
                                                    handleChange({ startMin: 0, endMin: 1440 });
                                                }}
                                            />
                                        }
                                        label="24hrs"
                                    />
                                )}
                            </Stack>
                        </Stack>

                        <Stack>
                            <Typography>
                                No. of parking operatives
                                <span style={{ color: '#EF1320', textShadow: '#EF1320' }}> *</span>
                            </Typography>
                            <TextField
                                size="small"
                                fullWidth
                                value={props.shift.requiredWarden}
                                type="number"
                                onChange={(e) =>
                                    handleChange({
                                        requiredWarden: e.target.value === '' ? ('' as any) : Number(e.target.value),
                                    })
                                }
                                error={isError}
                                helperText={isError ? 'Field is required!' : ''}
                            />
                        </Stack>
                    </Stack>
                </Collapse>
            </Stack>
            <Stack>
                <IconButton
                    disabled={props.disabledDelete}
                    size="small"
                    color="error"
                    onClick={() => props.onDelete?.(props.shift)}
                >
                    <Close />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default ShiftItem;
