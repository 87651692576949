import Checkbox from '@components/Checkbox';
import { CStack } from '@components/FlexedStack';
import PopUpBase from '@components/PopUpBase';
import StyledSelect from '@components/select-search/StyledSelect';
import { locationController, regionController, subRegionController } from '@controllers';
import { ListProps } from '@Core';
import { defaultPaging } from '@helpers';
import usePopUp, { IPopUp } from '@hooks/usePopUp';
import { GetLocationCustomProps } from '@LocationOps/controller/ILocationController';
import { CountryRegion, CountrySubRegion, LocationWithRelations } from '@LocationOps/model';
import {
    Box,
    CircularProgress,
    Pagination,
    PaginationItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption from '@pages/rota-coverage/components/SelectOption';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Filter } from '../../../location/list';
import EmptyPage from '../../../components/EmptyPage';
import PopUpViewMore from './PopUpViewMore';
import IcPrev from '@components/icon/IcPrev';
import IcNext from '@components/icon/IcNext';
import IcWarning from '@components/icon/IcWarning';
import { colorLocationMapping } from '@pages/setting/location/list/components/LocationListItem';

type Props = Omit<IPopUp, 'onConfirm'> & {
    selectedLocations: LocationWithRelations[];
    onConfirm: (locations: LocationWithRelations[]) => void;
};

export default function PopUpAddLocation(props: Props) {
    const popUpViewMore = usePopUp();

    const [locations, setLocations] = useState<LocationWithRelations[]>(props.selectedLocations);
    const [state, setState] = useState({
        pagingLocation: defaultPaging<LocationWithRelations>(),
        pagingRegion: defaultPaging<CountryRegion>(),
        pagingSubRegion: defaultPaging<CountrySubRegion>(),
    });
    const [filter, setFilter] = useState<Filter>({
        page: 1,
    });

    const [subRegionSearch, setSubRegionSearch] = useState('');
    const [loadingSubRegion, setLoadingSubRegion] = useState(false);
    const [loading, setLoading] = useState(true);

    const isEmpty = !state.pagingLocation.rows.length && !loading;

    const hasMoreRegion = Boolean(state.pagingRegion.page < state.pagingRegion.totalPages);
    const onGetMoreRegions = async () => {
        await regionController
            .list({
                page: state.pagingRegion.page + 1,
                pageSize: 10,
                sorts: ['Name'],
            })
            .then((res) => {
                const _res = { ...res, rows: [...state.pagingRegion.rows, ...res.rows] };
                setState((prev) => ({ ...prev, pagingRegion: _res }));
            });
    };

    const hasMoreSubRegion = Boolean(state.pagingSubRegion.page < state.pagingSubRegion.totalPages);

    const filteredSubRegion = state.pagingSubRegion.rows.filter((subR) =>
        subR.Name.toLocaleLowerCase().includes((subRegionSearch || '').toLocaleLowerCase())
    );
    const getSubRegion = (props?: { listProps?: ListProps<CountrySubRegion>; options?: { fetchMore?: boolean } }) => {
        setLoadingSubRegion(true);
        subRegionController
            .list({
                page: 1,
                pageSize: 1000,
                filter: { CountryRegionId: filter.region?.Id },
                sorts: ['Name'],
                search: {
                    content: subRegionSearch,
                    fields: ['Name'],
                },
                ...props?.listProps,
            })
            .then((res) => {
                if (props?.options?.fetchMore) {
                    setState((prev) => ({
                        ...prev,
                        pagingSubRegion: { ...res, rows: [...state.pagingSubRegion.rows, ...res.rows] },
                    }));
                } else {
                    setState((prev) => ({ ...prev, pagingSubRegion: res }));
                }
            })
            .finally(() => {
                setLoadingSubRegion(false);
            });
    };
    const onGetMoreSubRegions = async () => {
        getSubRegion({
            listProps: {
                page: state.pagingSubRegion.page + 1,
            },
            options: {
                fetchMore: true,
            },
        });
    };
    const handleChangeSearchSubRegion = (text: string) => {
        setSubRegionSearch(text);
    };

    const handleSelectLocation = (location: LocationWithRelations) => {
        const isExist = locations.some((l) => l.Id === location.Id);
        if (isExist) setLocations(locations.filter((l) => l.Id !== location.Id));
        else setLocations((p) => [...p, location]);
    };

    const getLocation = (listProps: GetLocationCustomProps) => {
        setLoading(true);
        locationController
            .listAssignableToCluster({
                page: filter.page || 1,
                search: { content: filter.search?.content || '', fields: ['Name'] },
                filter: {
                    CountryRegionId: filter.region?.Id,
                    CountrySubRegionId: filter.subRegion?.Id,
                    LocationType: ['mobile', 'static'],
                },
                pageSize: 5,
                sorts: ['Name'],
                ...listProps,
            })
            .then((res) => {
                setState((prev) => ({ ...prev, pagingLocation: res }));
            })
            .finally(() => setLoading(false));
    };

    const debSearchLocation = useCallback(
        debounce((nextValue) => {
            return getLocation({
                search: {
                    content: nextValue,
                    fields: ['Name'],
                },
                page: 1,
            });
        }, 300),
        [filter.region?.Id, filter.subRegion?.Id]
    );

    const handleChangeSearchLocation = (e: any) => {
        setLoading(true);
        setFilter((prev) => ({
            ...prev,
            search: {
                ...prev.search,
                content: e.target.value,
            },
            page: 1,
        }));
        debSearchLocation(e.target.value);
    };

    const resetFilter = () => {
        setFilter({ page: 1, region: undefined, subRegion: undefined, search: { content: '', fields: ['Name'] } });
    };

    useEffect(() => {
        if (props.open === false) return;
        regionController.list({ pageSize: 1000, sorts: ['Name'] }).then((res) => {
            setState((prev) => ({ ...prev, pagingRegion: res }));
        });
    }, [props.open]);

    useEffect(() => {
        if (props.open === false) return;
        getSubRegion({});
    }, [filter.region?.Id, props.open]);

    useEffect(() => {
        if (props.open === false) return;
        getLocation({});
    }, [filter.region?.Id, filter.subRegion?.Id, filter.page, props.open]);

    useEffect(() => {
        setLocations(props.selectedLocations);
    }, [props.selectedLocations]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'sm',
                PaperProps: {
                    sx: { minHeight: '600px' },
                },
            }}
            onClose={() => {
                resetFilter();
                props.onClose?.();
            }}
            onConfirm={() => {
                resetFilter();
                props.onConfirm?.(locations);
            }}
            title={'Add mobile sites to cluster'}
            subTitle={
                <Stack>
                    <Typography color="GrayText" fontSize={14}>
                        Selected {locations.length} sites{' '}
                        <Typography
                            component={'span'}
                            color="primary.main"
                            sx={{ cursor: 'pointer' }}
                            onClick={popUpViewMore.setTrue}
                        >
                            (View)
                        </Typography>
                    </Typography>
                </Stack>
            }
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack mt={1} spacing={2}>
                    <Stack>
                        <TextField
                            size="small"
                            label="Search by name"
                            value={filter.search?.content}
                            onChange={handleChangeSearchLocation}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <StyledSelect
                            label="Region"
                            disabledSearch
                            data={state.pagingRegion.rows}
                            value={filter.region}
                            hasMore={hasMoreRegion}
                            next={onGetMoreRegions}
                            onChange={(region) => {
                                setFilter((prev) => ({ ...prev, region, subRegion: undefined, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All regions'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All regions" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{
                                width: '50%',
                            }}
                        />
                        <StyledSelect
                            renderNoOption
                            loadingSearchOption={loadingSubRegion}
                            label="Sub-region"
                            data={filteredSubRegion}
                            value={filter.subRegion}
                            hasMore={hasMoreSubRegion}
                            next={onGetMoreSubRegions}
                            searchValue={subRegionSearch}
                            onChangeSearch={handleChangeSearchSubRegion}
                            onChange={(subRegion) => {
                                setFilter((prev) => ({ ...prev, subRegion, page: 1 }));
                            }}
                            renderValue={(value) => {
                                return <Typography noWrap>{value?.Name || 'All sub-regions'}</Typography>;
                            }}
                            renderDefaultOption={() => <DefaultOption title="All sub-regions" />}
                            renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                            sx={{
                                width: '50%',
                            }}
                        />
                    </Stack>

                    <Stack
                        mt={2}
                        direction="row"
                        justifyContent={'center'}
                        alignItems="center"
                        minHeight={389.06 + 48 + 16}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : isEmpty ? (
                            <Stack
                                flex={1}
                                spacing={3}
                                justifyContent="center"
                                bgcolor="rgba(250, 250, 250, 1)"
                                px={3}
                                alignSelf="stretch"
                            >
                                <Typography textAlign={'center'}>
                                    The location you’re searching doesn't exist or is invalid. Please check again.
                                </Typography>

                                <Stack sx={{ border: '1px solid #DDDDDD', borderRadius: '5px', padding: '16px' }}>
                                    <Typography>
                                        <span style={{ fontWeight: 'bold' }}>Note:</span> Conditions to be added into a
                                        cluster of a location are:
                                        <br />
                                        1. Location status is Live
                                        <br />
                                        2. Location must be configured: Rate, Contravention Reason(s).
                                        <br />
                                        3. At least 1 zone with active Warden Patrol Service.
                                    </Typography>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack flex={1} alignSelf="stretch">
                                <Stack flex={1}>
                                    {state.pagingLocation.rows.map((l) => {
                                        // const disabled = props.selectedLocations.some((lc) => lc.Id === l.Id);
                                        const disabled = false;
                                        return (
                                            <Stack
                                                key={l.Id}
                                                direction="row"
                                                spacing={'12px'}
                                                alignItems="center"
                                                sx={{
                                                    padding: 1,
                                                    borderBottom: '1px solid #eee',
                                                    cursor: !disabled ? 'pointer' : undefined,
                                                    ':hover': {
                                                        bgcolor: '#eee',
                                                    },
                                                    transition: '0.25s',
                                                    borderRadius: '5px',
                                                    backgroundColor: disabled
                                                        ? '#eee'
                                                        : `${colorLocationMapping[l.AvgPCNVisitColor!]}`,
                                                }}
                                                onClick={() => !disabled && handleSelectLocation(l)}
                                            >
                                                <Stack>
                                                    <Checkbox
                                                        checked={locations.some((lc) => lc.Id === l.Id)}
                                                        disabled={disabled}
                                                    />
                                                </Stack>

                                                <Stack>
                                                    <Typography>{l.Name}</Typography>
                                                    <Typography variant="caption" color="GrayText">
                                                        Region: {l.CountrySubRegion?.CountryRegion?.Name} | Sub-region:{' '}
                                                        {l.CountrySubRegion?.Name} | Location ID: {l.Id}
                                                    </Typography>
                                                    <Typography variant="caption" color="GrayText">
                                                        Address: {l.Address}
                                                    </Typography>
                                                </Stack>

                                                {!l.isSetupSTS && (
                                                    <Tooltip
                                                        title={'This location is not available for STS users.'}
                                                        placement="top"
                                                    >
                                                        <Stack
                                                            justifyContent={'flex-end'}
                                                            direction={'row'}
                                                            flexGrow={1}
                                                        >
                                                            <IcWarning />
                                                        </Stack>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                        );
                                    })}
                                </Stack>

                                {state.pagingLocation.totalPages > 1 && !loading && (
                                    <CStack>
                                        <Pagination
                                            disabled={loading}
                                            shape="rounded"
                                            count={state.pagingLocation.totalPages}
                                            page={filter.page}
                                            onChange={(_, page) => {
                                                setFilter((prev) => ({ ...prev, page }));
                                            }}
                                            sx={{
                                                mt: 2,
                                                '& .Mui-selected': {
                                                    background: '#3479BB1A !important',
                                                },
                                                '& .MuiPaginationItem-previousNext': {
                                                    background: '#EEEEEE',
                                                },
                                            }}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    components={{ previous: IcPrev, next: IcNext }}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </CStack>
                                )}
                            </Stack>
                        )}
                    </Stack>

                    <PopUpViewMore {...popUpViewMore} locations={locations} onDelete={handleSelectLocation} />
                </Stack>
            }
        />
    );
}
