import { WardenEvent } from '@LocationOps/model/WardenEvent';
import { Typography } from '@mui/material';
import { BaseWardenEventItem, MapItemCreated, WardenEventItemProps } from './BaseWardenEventItem';

export const StartShift = {
    ListItem: (props: WardenEventItemProps) => {
        return <BaseWardenEventItem {...props} text="Start shift" />;
    },
    MapItem: (props: WardenEvent) => (
        <div>
            <Typography>Start shift</Typography>
            <MapItemCreated Created={props.Created!} />
        </div>
    ),
};
