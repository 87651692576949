import { Stack, styled, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect } from 'react';

type Props = {
    startDate: Date;
    onChange(startDate: Date): void;
    isEdit?: boolean;
};

const STextField = styled(TextField)({
    // '& .MuiInputBase-input.MuiOutlinedInput-input': {
    //     padding: '10px 0px 10px 14px',
    // },
    maxWidth: 200,
});

export function validateDatePickerValue(date: Date) {
    const currentDate = moment().startOf('date').toDate();
    const isAfterCurrentDate = moment(currentDate).isSameOrAfter(date);

    if (!isAfterCurrentDate) return 'Invalid';

    return null;
}

export default function BaseSelectDate({ onChange, startDate, isEdit = false }: Props) {
    const [value, setValue] = React.useState<Date | null>(startDate);

    const handleChange = (newValue: any | null) => {
        console.log('change', newValue);
        setValue(newValue);
        if (
            newValue &&
            newValue._isValid &&
            validateDatePickerValue(newValue) == null &&
            !moment(newValue).isSame(startDate)
        ) {
            onChange(newValue._d);
        }
    };

    useEffect(() => {
        setValue(startDate);
    }, [startDate]);

    return (
        <DesktopDatePicker
            label=""
            inputFormat="DD/MM/YYYY"
            value={value}
            onChange={handleChange}
            renderInput={(params) => {
                return (
                    <STextField
                        sx={{ maxWidth: 'none', width: 200 }}
                        size="small"
                        {...params}
                        onBlur={() => {
                            if (!value || !moment(value).isValid() || validateDatePickerValue(value) !== null) {
                                setValue(startDate);
                            }
                        }}
                    />
                );
            }}
            onError={(reason, value) => {
                console.log('reason, value', reason, value);
            }}
            shouldDisableDate={(day) => validateDatePickerValue(day) !== null}
            disabled={isEdit}
        />
    );
}
