import { appConfig } from '@config';
import { ArrowLeftRounded, ArrowRightRounded } from '@mui/icons-material';
import { IconButton, Stack, styled, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect } from 'react';

type Props = {
    startDate: Date;
    onChange(startDate: Date): void;
    hideEndDate?: boolean;
    limitEndDate?: number;
};

const STextField = styled(TextField)({
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
        padding: '10px 14px',
    },
    maxWidth: 200,
});

export default function DatePickerTimeSheets({ onChange, startDate, hideEndDate, limitEndDate }: Props) {
    const [value, setValue] = React.useState<Date | null>(startDate);

    const validDate = moment('2023-03-12T00:00:00.000Z').startOf('isoWeek').toDate();
    const enabledPrevWeek = moment(validDate).isBefore(startDate);

    const currentWeek = moment().startOf('isoWeek').toDate();
    const subCurrentWeek = moment(currentWeek).subtract(1, 'week').startOf('isoWeek').toDate();
    // const disabledNext = moment(startDate).isBefore(currentWeek);
    const enabledNextWeek = moment(startDate).isBefore(currentWeek);

    const isStartOfWeek = Boolean(moment().startOf('date').isoWeekday() === 1);
    const yesterday = moment().startOf('date').subtract(1, 'days').toDate();

    const validateDatePickerValue = (date: Date) => {
        const isValidDate = moment(validDate).isSameOrBefore(date);

        // const isAfterCurrentWeek = moment(currentWeek).isAfter(date);
        const isAfterCurrentWeek = moment(currentWeek).isSameOrAfter(date);

        if (![1].includes(moment(date).isoWeekday())) {
            return 'Not first week';
        }
        if (!isValidDate) return 'Not valid date';
        if (!isAfterCurrentWeek && appConfig.disabledFutureTimesheet) return 'Not valid date';

        return null;
    };

    const getEndDate = (value: any | null) => {
        return moment(value).isSameOrAfter(currentWeek) && !isStartOfWeek
            ? yesterday
            : moment(value)
                  .add(limitEndDate ? limitEndDate : 6, 'days')
                  .toDate();
    };

    console.log(`!appConfig.disabledFutureTimesheet`, appConfig.disabledFutureTimesheet);

    const handleChange = (newValue: any | null) => {
        console.log('change', newValue);
        setValue(newValue);
        if (
            newValue &&
            newValue._isValid &&
            validateDatePickerValue(newValue) == null &&
            !moment(newValue).isSame(startDate)
        ) {
            onChange(newValue._d);
        }
    };

    useEffect(() => {
        setValue(startDate);
    }, [startDate]);

    return (
        <Stack direction={'row'} spacing={1} justifyContent="flex-end">
            <IconButton
                sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '5px',
                    backgroundColor: '#EEEEEE',
                    '&:disabled': {
                        backgroundColor: '#DDDDDD',
                        cursor: 'not-allowed',
                    },
                }}
                onClick={() =>
                    onChange(
                        moment(startDate)
                            .subtract(limitEndDate ? 2 : 1, 'weeks')
                            .toDate()
                    )
                }
                disabled={!enabledPrevWeek}
            >
                <ArrowLeftRounded />
            </IconButton>

            <DesktopDatePicker
                label="Start date"
                inputFormat="DD/MM/YYYY"
                value={value}
                onChange={handleChange}
                renderInput={(params) => {
                    return (
                        <STextField
                            size="small"
                            {...params}
                            onBlur={() => {
                                if (!value || !moment(value).isValid() || validateDatePickerValue(value) !== null) {
                                    setValue(startDate);
                                }
                            }}
                        />
                    );
                }}
                onError={(reason, value) => {
                    console.log('reason, value', reason, value);
                }}
                shouldDisableDate={(day) => validateDatePickerValue(day) !== null}
            />

            {!hideEndDate && (
                <>
                    <Typography sx={{ lineHeight: '40px', fontWeight: 600 }}>-</Typography>

                    <DesktopDatePicker
                        label="End date"
                        inputFormat="DD/MM/YYYY"
                        value={getEndDate(value)}
                        onChange={handleChange}
                        renderInput={(params) => <STextField {...params} error={false} size="small" />}
                        disabled
                    />
                </>
            )}
            <IconButton
                sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '5px',
                    backgroundColor: '#EEEEEE',
                    '&:disabled': {
                        backgroundColor: '#DDDDDD',
                        cursor: 'not-allowed',
                    },
                }}
                onClick={() =>
                    onChange(
                        moment(startDate)
                            .add(limitEndDate ? 2 : 1, 'weeks')
                            .toDate()
                    )
                }
                disabled={!enabledNextWeek && appConfig.disabledFutureTimesheet}
            >
                <ArrowRightRounded />
            </IconButton>
        </Stack>
    );
}
