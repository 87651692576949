import IcSearch from '@components/icon/IcSearch';
import StyledSelect from '@components/select-search/StyledSelect';
import { Paging } from '@Core';
import { CountryRegion, CountrySubRegion } from '@LocationOps/model';
import { FilterAltOffOutlined } from '@mui/icons-material';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption from '@pages/rota-coverage/components/SelectOption';
import { WardenDetailsType } from './PopUpAddParkingOperative';
type Props = {
    searchValue: string;
    onChangeSearch(e: any): void;
    regions: Paging<CountryRegion>;
    region: CountryRegion;
    hasMoreRegions: boolean;
    onGetMoreRegion(): void;
    onChangeRegion(option?: CountryRegion): void;
    subRegions: CountrySubRegion[];
    subRegion: CountrySubRegion;
    wardenTypes?: WardenDetailsType[];
    wardenType?: WardenDetailsType;
    totalSubRegions: number;
    hasMoreSubRegions: boolean;
    onGetMoreSubRegion(): void;
    onChangeSubRegion(option?: CountrySubRegion): void;
    onChangeWardenType?(option: WardenDetailsType): void;
    searchSubRegionValue: string;
    onSearchSubRegion(text: string): void;
    onReset(): void;
    isDisplayStatictis?: boolean;
};
export default function BaseFilterWarden(props: Props) {
    const { isDisplayStatictis = true } = props;
    return (
        <Stack width="100%" direction="row" spacing={2} mt={2}>
            <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment" sx={{ color: '#DDDDDD' }}>
                    Search by name or email
                </InputLabel>
                <OutlinedInput
                    autoComplete="off"
                    value={props.searchValue}
                    onChange={(e: any) => {
                        props.onChangeSearch(e);
                    }}
                    id="outlined-adornment"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle search" edge="end">
                                <IcSearch />
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Search by name or email"
                    sx={{ py: '1.5px' }}
                />
            </FormControl>
            <StyledSelect
                label="Region"
                disabledSearch
                data={props.regions.rows}
                value={props.region}
                hasMore={props.hasMoreRegions}
                next={() => {
                    props.onGetMoreRegion();
                }}
                onChange={(option) => {
                    props.onChangeRegion?.(option);
                }}
                renderValue={(value) => {
                    return (
                        <Typography noWrap>
                            {value?.Name || `All regions ${isDisplayStatictis ? `(${props.regions.total})` : ''}`}
                        </Typography>
                    );
                }}
                renderDefaultOption={() => <DefaultOption title="All regions" />}
                renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                sx={{
                    width: '100%',
                }}
            />

            <StyledSelect
                renderNoOption
                label="Sub-region"
                data={props.subRegions}
                value={props.subRegion}
                hasMore={props.hasMoreSubRegions}
                searchValue={props.searchSubRegionValue}
                onChangeSearch={(text) => props.onSearchSubRegion(text)}
                next={props.onGetMoreSubRegion}
                onChange={(option) => {
                    props.onChangeSubRegion?.(option);
                }}
                renderValue={(value) => {
                    return (
                        <Typography noWrap>
                            {value?.Name || `All sub-regions ${isDisplayStatictis ? `(${props.totalSubRegions})` : ''}`}
                        </Typography>
                    );
                }}
                renderDefaultOption={() => <DefaultOption title="All sub-regions" />}
                renderOption={(option) => <SelectOption option={option} isDisplay={false} />}
                sx={{
                    width: '100%',
                }}
            />
            {!!props.wardenTypes && (
                <StyledSelect
                    label="Warden type"
                    disabledSearch
                    data={props.wardenTypes!}
                    value={props.wardenType}
                    hasMore={true}
                    next={() => {}}
                    onChange={(option) => {
                        props.onChangeWardenType?.(option!);
                    }}
                    renderValue={(value) => {
                        return <Typography noWrap>{value?.Name || 'All types'}</Typography>;
                    }}
                    renderDefaultOption={() => <DefaultOption title="All types" />}
                    renderOption={(option) => {
                        return <SelectOption option={option} isDisplay={false} />;
                    }}
                    sx={{ flex: 1 }}
                />
            )}
            <Tooltip title="Reset filter" arrow placement="top">
                <IconButton
                    size="medium"
                    sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', maxHeight: 40 }}
                    onClick={props.onReset}
                >
                    <FilterAltOffOutlined />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
