import { Breadcrumbs, Typography, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import color from 'src/theme/color';

export type IBreadCrumbs = {
    title: string;
    href?: string;
    onBack?: boolean;
};
type Props = {
    breadcrumbs: IBreadCrumbs[];
};
export default function BreadCrumbs({ breadcrumbs }: Props) {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" sx={{ color: color.darkPrimary }} />}
            aria-label="breadcrumb"
            color={theme.palette.primary.dark}
        >
            {breadcrumbs.map((bc, index) => {
                return bc.onBack ? (
                    <Typography
                        key={index}
                        style={{ color: color.darkPrimary, textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => {
                            bc.href && navigate(-1);
                        }}
                    >
                        {bc.title}
                    </Typography>
                ) : (
                    <Link key={index} style={{ color: color.darkPrimary, textDecoration: 'none' }} to={bc.href || '#'}>
                        {bc.title}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
}
