import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Zone } from '@LocationOps/model/Zone';
import { AxiosInstance } from 'axios';
import { IZoneController } from '../IZoneController';
import { Paging } from '@Core';

export class ZoneHttpController extends BaseHttpController<Zone> implements IZoneController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'zone', client);
    }

    async suspendZones(props: Zone[]): Promise<Paging<Zone>> {
        const res = await this.doPost({ path: 'suspend', body: props });
        return res.data;
    }

    async suspendAllRedZones(): Promise<string> {
        const res = await this.doPost({ path: 'suspend-all', body: {} });
        return res.data;
    }

    async countZoneWithServiceValid(locationId: number): Promise<{ count: number }> {
        const res = await this.doGet({ path: `${locationId}/count-zone-valid` });
        return res.data;
    }
}
