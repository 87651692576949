import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcInformation(props: SvgIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16" {...props}>
            <path
                fill="#F09453"
                fillRule="evenodd"
                stroke="#F09453"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.5 1.5a6.5 6.5 0 110 13 6.5 6.5 0 010-13z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.496 5.333v3.105"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7.496 10.668h.007"
            ></path>
        </svg>
    );
}
