import { useFeatureFlag } from '@components/layouts/Flag';
import { FeatureName } from '@config';
import { Navigate, Route, Routes } from 'react-router-dom';
import WrapperAlertPage from './components/WrapperAlertPage';
import EarlyLogoff from './components/EarlyLogoff';
import NoTickets from './components/NoTickets';
import PhoneStationary from './components/PhoneStationary';
import SiteLogin from './components/SiteLogin';
import useFirebaseFCM from '@hooks/useFirebaseFCM';
type RouteItem = {
    path: string;
    element: JSX.Element;
};
export default function AlertPage() {
    const firebaseHook = useFirebaseFCM();

    const routes: {
        index?: boolean;
        path?: string;
        children?: RouteItem[];
        element: JSX.Element;
        name?: FeatureName;
    }[] = [
        {
            index: true,
            element: <Navigate to={FeatureName.ALERTS__SITE_LOGIN} />,
        },
        {
            path: '/',
            element: <WrapperAlertPage />,
            name: FeatureName.ALERTS,
            children: [
                {
                    path: FeatureName.ALERTS__SITE_LOGIN,
                    element: <SiteLogin />,
                },
                {
                    path: FeatureName.ALERTS__PHONE_STATIONARY,
                    element: <PhoneStationary />,
                },
                {
                    path: FeatureName.ALERTS__NO_TICKETS,
                    element: <NoTickets />,
                },
                {
                    path: FeatureName.ALERTS__EARLY_LOGOFF,
                    element: <EarlyLogoff />,
                },
            ],
        },
        {
            path: '*',
            element: <Navigate to={'/'} />,
        },
    ];

    const { getActive } = useFeatureFlag();

    return (
        <Routes>
            {routes.map((r, index) => {
                if (!r.name || getActive(r.name)) {
                    return (
                        <Route key={index} index={r.index} path={r.path} element={r.element}>
                            {r.children?.map((rc) => {
                                return <Route key={rc.path} path={rc.path} element={rc.element} />;
                            })}
                        </Route>
                    );
                }

                return <></>;
            })}
        </Routes>
    );
}
