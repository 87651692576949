import { useFeatureFlag } from '@components/layouts/Flag';
import { FeatureName } from '@config';
import { alertController, leaveDayController } from '@controllers';
import { CountryRegion } from '@LocationOps/model';
import { AlertStatistics, IGetListAlertProps } from '@WardenOps/controller/IAlertHttpController';
import { AlertResponse } from '@WardenOps/model';
import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';

const initState = {
    totalNewApprovals: 0,
    totalAlerts: 0,
};

type LastAlert = AlertResponse & { id: string };

type Filter = {
    startDate: Date;
    region?: CountryRegion;
};

function useNotification() {
    const [lastAlert, setLastAlert] = useState<LastAlert>();
    const [state, setState] = useState(initState);
    const [statistic, setStatistic] = useState<AlertStatistics>({} as AlertStatistics);
    const { getActive } = useFeatureFlag();
    const activeAlert = getActive(FeatureName.ALERTS);
    const activeLieu = getActive(FeatureName.LIEU_LEAVE);
    const [filter, setFilter] = useState<Filter>({ startDate: moment().startOf('date').toDate(), region: undefined });

    function getStatisticAlert(startDate?: Date, countryRegionId?: number) {
        const props: IGetListAlertProps = {
            TimeFrom: startDate ? startDate : moment().startOf('date').toDate(),
            CountryRegionId: countryRegionId,
        };
        if (!props.CountryRegionId) delete props.CountryRegionId;
        alertController.alertStatistics(props).then((res) => {
            const totalAlerts = Object.values(res).reduce((prev, cur) => prev + cur, 0);
            setStatistic(res);
            setState((prev) => ({ ...prev, totalAlerts }));
        });
    }

    function getNewApproveRequest() {
        leaveDayController.leaveDayStatistics().then((res) => {
            setState((prev) => ({ ...prev, totalNewApprovals: res.Pending }));
        });
    }

    function onNewMessage(alert: AlertResponse) {
        setLastAlert({ ...alert, id: v4() });
    }

    // useEffect(() => {
    //     if (!activeFeature) return;
    //     const interval = setInterval(() => {
    //         init();
    //     }, 1 * 60 * 1000);
    //     return () => clearInterval(interval);
    // }, [activeFeature]);

    useEffect(() => {
        if (!activeLieu) return;
        getNewApproveRequest();

        const interval = setInterval(() => {
            getNewApproveRequest();
        }, 1 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!activeAlert) return;
        getStatisticAlert(moment(filter.startDate).startOf('date').toDate(), filter.region?.Id);
    }, [activeAlert, lastAlert]);

    const [lastFocus, setLastFocus] = useState(moment().toDate());

    const handleVisibilityChange = useCallback(() => {
        const isFocus = document.visibilityState === 'visible';
        const duration = moment().diff(lastFocus, 'seconds');

        if (!isFocus) setLastFocus(moment().toDate());
        else if (duration > 10) getStatisticAlert(moment(filter.startDate).startOf('date').toDate(), filter.region?.Id);
    }, [lastFocus]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    return {
        ...state,
        getStatisticAlert,
        statistic,
        lastAlert,
        setLastAlert,
        onNewMessage,
        filter,
        setFilter,
    };
}

type Type = ReturnType<typeof useNotification>;

export const NotificationContext = createContext<Type>(initState as Type);

export default function NotificationProvider(props: React.PropsWithChildren) {
    const hook = useNotification();
    return <NotificationContext.Provider value={hook}>{props.children}</NotificationContext.Provider>;
}

export function useNotifyContext() {
    const context = useContext(NotificationContext);
    return context;
}
