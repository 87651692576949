import { pushError, pushSuccess, pushWarning } from '@components/StyledToast';
import { ListTest } from '@hooks/useLoadMore';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Coming = () => {
    const handleClickSuccess = () => {
        pushSuccess('Data has been synced!');
    };
    const navigate = useNavigate();

    return (
        <Stack spacing={2}>
            <Typography>Coming soon!</Typography>

            <ListTest />

            <Stack direction={'row'} spacing={2}>
                <Button variant="outlined" onClick={handleClickSuccess}>
                    Success
                </Button>

                <Button
                    variant="outlined"
                    onClick={() => {
                        pushError('error');
                    }}
                >
                    Test
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));
                        toast.promise(resolveAfter3Sec, {
                            pending: 'Promise is pending',
                            success: 'Promise resolved 👌',
                            error: 'Promise rejected 🤯',
                        });
                    }}
                >
                    Error
                </Button>

                <Button
                    variant="outlined"
                    onClick={() => {
                        pushWarning('Message', {
                            onClick(event) {
                                console.log(event);
                                navigate('/alerts');
                            },
                        });
                    }}
                >
                    Warning
                </Button>
            </Stack>
        </Stack>
    );
};

export default Coming;
