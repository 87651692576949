import { BaseModel } from '@Core';
import { WardenEvent } from '@LocationOps/model/WardenEvent';
import { Alert } from './Alert';
import { ExpenseRate } from './ExpenseRate';
import { LeaveDay } from './LeaveDay';
import { Rota, RotaType } from './Rota';
import { RotaTemplate } from './RotaTemplate';
import { PeriodTimesheets } from './Timesheets';

export interface Warden extends BaseModel, WardenWorkingSummary {
    ExternalId?: string;
    CountrySubRegionId?: number;

    Email?: string;
    FullName?: string;
    FirstName?: string;
    LastName?: string;
    PhoneNumber?: string;
    Postcode?: string;
    Picture?: string;

    Latitude?: number;
    Longitude?: number;

    WardenType?: WardenType;
    ClusterId?: number;

    LeaveDays?: LeaveDay[];
    JobTitle?: string;
    PerformanceID?: string;
    FinalDayOfEmployment?: Date;
}

interface WardenWorkingSummary {
    IWardenNumber?: string;
    ContractHours?: number;
    StartDate?: Date;
    PayrollId?: string;
}

export interface WardenWithRelations extends Warden {
    Allocated?: number;
    Rotas?: Rota[];
    HasRotaTemplate?: boolean;
    RotaTemplates?: RotaTemplate[];
    WardenFreeTimes?: WardenFreeTime[];
    ActualWeekWorkHour?: number;
    DistanceWithALocation?: number;
    Events?: WardenEvent[];
    Alerts?: Alert[];
    PeriodTimesheets?: PeriodTimesheets[];
}

export interface WardenWithExpenseRate extends Warden {
    CurrentExpenseRate: number;
    ExpenseRates: ExpenseRate[];
}

export interface WardenFreeTime {
    Weekday: number;
    FreeFrom: Date;
    FreeTo: Date;
    Status: 'out-of-working' | 'no-location';
    RotaType: RotaType;
}

export interface IWardenNotification {
    subject: string;
    content: string;
    recipient: {
        email: string;
    };
    sender?: {
        email: string;
        fullName: string;
    };
}

export enum WardenType {
    PERMANENT_STAFF,
    TEMPORARY_STAFF,
    STS,
    ITICKET_LITE,
}
