import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcMap(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 7.025C1 7.017 1 7.008 1 7C1 3.137 4.137 0 8 0C11.863 0 15 3.137 15 7C15 7.008 15 7.017 15 7.025C14.984 12.256 9.95 15.378 8.359 15.934C8.253 15.971 8.126 16 8 16C7.874 16 7.753 15.976 7.641 15.934C6.05 15.378 1.016 12.256 1 7.025ZM8 4C9.656 4 11 5.344 11 7C11 8.656 9.656 10 8 10C6.344 10 5 8.656 5 7C5 5.344 6.344 4 8 4Z"
                fill="currentColor"
            />
        </svg>
    );
}
