import { Location, TotalPCNsIssued, TotalPcnsPerHour, TotalPcnsPerWeekday } from '@LocationOps/model';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Warden } from '@WardenOps/model';
import moment from 'moment';
import React from 'react';
import { fakeArray, _weekdays } from 'src/helpers';
import { v4 } from 'uuid';
import { ShiftTypeDisplay } from './Shift';
import _ from 'lodash';

export enum ShiftStatus {
    assigned = 'full',
    unassigned = 'notFull',
    lieuLeave = 'lieu_leave',
    bankHolidays = 'bank_holidays',
    overlapped = 'overlapped',
    otherEvents = 'other_events',
}

export type Shift<T extends Warden = any> = {
    _id?: string;
    id?: number;
    startMin: number;
    endMin: number;
    status?: ShiftStatus;
    assignedWardens?: T[];
    requiredWarden?: number;
    weekday?: number;
    breakHours?: number;
    date?: Date;
    isOverlapped?: boolean;
};

export enum Weekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 0,
}

export enum ISOWeekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export const DEFAULT_WEEKLY_PERIOD: Record<Weekday, any[]> = {
    [Weekday.Monday]: [],
    [Weekday.Tuesday]: [],
    [Weekday.Wednesday]: [],
    [Weekday.Thursday]: [],
    [Weekday.Friday]: [],
    [Weekday.Saturday]: [],
    [Weekday.Sunday]: [],
};

export const TOTAL_HOURS = 24;
export const SHIFT_STEP = 15; // Minute
export const TOTAL_DAY_SHIFT = TOTAL_HOURS * (60 / SHIFT_STEP); // Total shift
export const END_DAY_MINUTE = TOTAL_HOURS * 60;
export const SHIFTS_PER_HOUR = 60 / SHIFT_STEP;

type Props<T> = {
    data: {
        [weekday: string]: T[];
    };
    startDate?: Date;
    displayType?: ShiftTypeDisplay;
    renderOption(option: T, index: number, arr: T[]): JSX.Element;
    renderAction?(shifts: T[], weekday: number, date?: Date): JSX.Element;
    isDisplayDate?: boolean;
    hideLieuLeave?: boolean;
    hideTitle?: boolean;
    loading?: boolean;
    getLieuTitle?: (option: T) => string;
    totalPCnsIssued?: TotalPCNsIssued;
};

const TIMESTEP = 60;
const ENDTIME = 1440;

const completeTimeRanges: TotalPcnsPerHour[] = _.range(0, ENDTIME, TIMESTEP).map((time) => ({
    TimeFrom: time,
    TimeTo: time + TIMESTEP,
    TotalPcns: 0,
}));

export default function RotaTable<T extends Shift>(props: Props<T>) {
    const { data, displayType, renderOption, renderAction, isDisplayDate = true, hideTitle, totalPCnsIssued } = props;

    const getDayShifts = (assignedShifts: T[], weekday: number, date = props.startDate) => {
        let fullDayShifts: T[] = [...assignedShifts];

        for (let i = 0; i < TOTAL_DAY_SHIFT; i++) {
            const hasShift = assignedShifts.some((s) => i * SHIFT_STEP >= s.startMin && i * SHIFT_STEP < s.endMin);
            if (hasShift) continue;
            const emptyShift: T = {
                _id: v4(),
                startMin: i * SHIFT_STEP,
                endMin: (i + 1) * SHIFT_STEP,
                weekday,
                date: !date
                    ? undefined
                    : moment(date)
                          .isoWeekday(weekday === 0 ? 7 : weekday)
                          .startOf('day')
                          .toDate(),
            } as T;
            fullDayShifts.push(emptyShift);
        }
        return fullDayShifts.sort((a, b) => a.startMin - b.startMin);
    };

    const dateCol = `110px`;
    const hoursCol = `repeat(${TOTAL_DAY_SHIFT}, 1fr) 20px`;
    const actionCol = renderAction ? '80px' : '';
    const gridTemplateCols = `${dateCol} ${hoursCol} ${actionCol}`;

    const _dataTotalPcnsPerHour: TotalPcnsPerHour[] = completeTimeRanges.map((item) => {
        const matchedItem = totalPCnsIssued?.TotalPcnsPerHour?.find((t) => t.TimeFrom === item.TimeFrom);
        if (matchedItem) {
            item.TotalPcns = matchedItem.TotalPcns;
        } else {
            item.TotalPcns = 0;
        }
        return item;
    });

    const sortedTotalPcnsPerWeekday: TotalPcnsPerWeekday[] =
        totalPCnsIssued?.TotalPcnsPerWeekday.sort((a, b) => {
            if (a.Weekday === 0) return 1;
            if (b.Weekday === 0) return -1;
            return a.Weekday - b.Weekday;
        }) || [];

    return (
        <div style={{ position: 'relative', pointerEvents: props.loading ? 'none' : undefined }}>
            {props.loading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        zIndex: 2,
                        // transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
            <Box sx={{ filter: props.loading ? 'blur(4px)' : undefined, transition: 'all 0.3s' }}>
                <Box display="grid" gridTemplateColumns="1fr 0fr" height="100%" gap={props.totalPCnsIssued ? 1 : 0}>
                    <Box width={'100%'}>
                        <Box
                            display={'grid'}
                            gridTemplateColumns={gridTemplateCols}
                            sx={{ backgroundColor: '#FAFAFA', borderRadius: '5px', p: '8px 16px', pr: 0 }}
                        >
                            <Box gridColumn={`span 1`}>
                                <Typography color="success.main">Hour</Typography>
                            </Box>
                            {[...new Array(TOTAL_HOURS)].map((_, index, arr) => {
                                return (
                                    <Stack
                                        key={index}
                                        direction={'row'}
                                        alignItems="center"
                                        justifyContent={'space-between'}
                                        gridColumn={`span ${60 / SHIFT_STEP}`}
                                    >
                                        <Typography color="success.main" variant="subtitle2" ml={'-4.5px'}>
                                            {index}
                                        </Typography>
                                        {index === arr.length - 1 && (
                                            <Typography color="success.main" variant="subtitle2" mr={'-4.5px'}>
                                                {24}
                                            </Typography>
                                        )}
                                    </Stack>
                                );
                            })}
                        </Box>

                        {_weekdays
                            .map((i) => data[i])
                            .map((shifts, index) => {
                                return (
                                    <Box
                                        key={v4()}
                                        display={'grid'}
                                        gridTemplateColumns={gridTemplateCols}
                                        pl={2}
                                        sx={{ py: 1, borderBottom: '1px solid #ddd' }}
                                        alignItems="center"
                                    >
                                        <Box
                                            gridColumn={`span 1`}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <Typography>
                                                <Typography
                                                    component={'span'}
                                                    variant="body2"
                                                    color={[5, 6].includes(index) ? 'red' : 'initial'}
                                                >
                                                    {moment(props.startDate)
                                                        .weekday(index + 1)
                                                        .format('ddd')}{' '}
                                                </Typography>

                                                {props.startDate && isDisplayDate && (
                                                    <Typography component={'span'} variant="body2" color="gray">
                                                        {moment(props.startDate)
                                                            .weekday(index + 1)
                                                            .format('(DD/MM)')}
                                                    </Typography>
                                                )}
                                            </Typography>

                                            {props.getLieuTitle && shifts.some((s, i) => s.status === 'lieu_leave') && (
                                                <Box
                                                    sx={{
                                                        width: 80,
                                                        px: 0.5,
                                                        py: 0.2,
                                                        background: '#FD5F6A',
                                                        textAlign: 'center',
                                                        borderRadius: '3px',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {props.getLieuTitle?.(
                                                        shifts.find((s) => s.status === 'lieu_leave')!
                                                    )}
                                                </Box>
                                            )}
                                        </Box>

                                        {getDayShifts(shifts, _weekdays[index]).map((s, index, arr) => {
                                            return (
                                                <React.Fragment key={`opt_key_${index}`}>
                                                    {renderOption(s, index, arr)}
                                                </React.Fragment>
                                            );
                                        })}

                                        {renderAction?.(
                                            shifts,
                                            _weekdays[index],
                                            !props.startDate
                                                ? undefined
                                                : moment(props.startDate)
                                                      .weekday(_weekdays[index])
                                                      .startOf('day')
                                                      .toDate()
                                        )}

                                        {props.totalPCnsIssued && (
                                            <Box
                                                gridColumn="span 1"
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                pl="50px"
                                            >
                                                <Typography
                                                    color={(theme) => theme.palette.primary.dark}
                                                    variant="subtitle2"
                                                    textAlign="center"
                                                >
                                                    {(() => {
                                                        const weekday = index === 6 ? 0 : index + 1;
                                                        return (
                                                            sortedTotalPcnsPerWeekday.find((i) => i.Weekday === weekday)
                                                                ?.TotalPcns || 0
                                                        );
                                                    })()}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        {props.totalPCnsIssued && (
                            <>
                                <Box
                                    mt={1}
                                    display={'grid'}
                                    gridTemplateColumns={gridTemplateCols}
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.primary.light,
                                        borderRadius: '5px',
                                        p: '8px 16px',
                                        pr: 0,
                                    }}
                                >
                                    <Box gridColumn={`span 1`}>
                                        <Typography color={(theme) => theme.palette.primary.dark}>Total</Typography>
                                    </Box>
                                    {_dataTotalPcnsPerHour.map((item, index, arr) => {
                                        return (
                                            <Stack
                                                key={index}
                                                direction={'row'}
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                                gridColumn={`span ${60 / SHIFT_STEP}`}
                                                pl={'50%'}
                                            >
                                                <Typography
                                                    color={(theme) => theme.palette.primary.dark}
                                                    variant="subtitle2"
                                                    ml={'-4.5px'}
                                                >
                                                    {item.TotalPcns}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                </Box>

                                <Box mt={1} sx={{ borderBottom: '1px solid #ddd' }} />
                            </>
                        )}
                    </Box>

                    {props.totalPCnsIssued && (
                        <Box
                            p={1}
                            display={'grid'}
                            height={'100%'}
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.light,
                                borderRadius: '5px',
                                justifyContent: 'center',
                            }}
                        >
                            <Box gridColumn={`span 1`}>
                                <Typography color={(theme) => theme.palette.primary.dark}>Total</Typography>
                            </Box>
                        </Box>
                    )}
                </Box>

                {!hideTitle && (
                    <Stack mt={2} direction={'row'} spacing={2} justifyContent="flex-end">
                        {[
                            { color: '#E8F5E9', label: 'Assigned' },
                            { color: '#FFDC83', label: 'Unassigned' },
                            { color: '#FD5F6A', label: 'Not working', hidden: props.hideLieuLeave },
                            { color: '#DDDDDD', label: 'Outside of working hours' },
                        ]
                            .filter((c) => !c.hidden)
                            .map((t) => {
                                return (
                                    <Stack key={t.color} direction={'row'} alignItems="center">
                                        <Box
                                            sx={{
                                                width: '80px',
                                                height: '16px',
                                                bgcolor: t.color,
                                                mr: 1,
                                                borderRadius: '2px',
                                            }}
                                        ></Box>
                                        <Typography variant="body2">{t.label}</Typography>
                                    </Stack>
                                );
                            })}
                    </Stack>
                )}
            </Box>
        </div>
    );
}
