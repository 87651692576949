import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcPcnIssuedPerCheckIn(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.6666 8.66634V5.99967C14.6666 2.66634 13.3333 1.33301 9.99992 1.33301H5.99992C2.66659 1.33301 1.33325 2.66634 1.33325 5.99967V9.99967C1.33325 13.333 2.66659 14.6663 5.99992 14.6663H8.66659"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.88672 9.65985L6.47339 7.59984C6.70006 7.30651 7.12006 7.25318 7.41339 7.47985L8.6334 8.43985C8.92674 8.66652 9.34672 8.61318 9.57338 8.32652L11.1134 6.33984"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9867 10.547L13.1733 10.9269C13.2666 11.1136 13.5 11.287 13.7066 11.327L13.96 11.367C14.72 11.4936 14.9 12.0536 14.3533 12.607L14.12 12.8403C13.9666 13.0003 13.88 13.3069 13.9267 13.5203L13.96 13.6603C14.1666 14.5803 13.68 14.9336 12.88 14.4536L12.7066 14.3536C12.5 14.2336 12.1666 14.2336 11.96 14.3536L11.7866 14.4536C10.98 14.9403 10.4933 14.5803 10.7066 13.6603L10.74 13.5203C10.7866 13.3069 10.7 13.0003 10.5466 12.8403L10.3133 12.607C9.76664 12.0536 9.94664 11.4936 10.7066 11.367L10.96 11.327C11.16 11.2936 11.4 11.1136 11.4933 10.9269L11.68 10.547C12.04 9.82029 12.6267 9.82029 12.9867 10.547Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
