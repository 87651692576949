import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { RotaTemplate } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import { IRotaTemplateController } from '../IRotaTemplateController';

export interface CustomBulkUpsertRotaTemplate {
    list: RotaTemplate[];
    WardenId: number;
}

export class RotaTemplateHttpController extends BaseHttpController<RotaTemplate> implements IRotaTemplateController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'rota-template', client);
    }

    async bulkUpsert(props: CustomBulkUpsertRotaTemplate): Promise<RotaTemplate[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/bulk-upsert`, props);
        return res.data;
    }
}
