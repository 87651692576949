import { Paging } from '@Core';
import { EmailSents, EmailStatus } from '@WardenOps/model/EmailSents';
import PopUpBase from '@components/PopUpBase';
import { pushError } from '@components/StyledToast';
import IcNext from '@components/icon/IcNext';
import IcPrev from '@components/icon/IcPrev';
import IcSearch from '@components/icon/IcSearch';
import StyledSelect from '@components/select-search/StyledSelect';
import { BaseHead, CellOfRow } from '@components/utils';
import { wardenController } from '@controllers';
import { IPopUp } from '@hooks/usePopUp';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import EmptyPage from '@pages/setting/components/EmptyPage';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import color from 'src/theme/color';
import { useDebounce } from 'usehooks-ts';

type Props = Omit<IPopUp, 'onConfirm'> & {
    startOfWeek: Date;
};

interface OptionsStatusEmail {
    value: number;
    label: string;
}

export default function PopUpCheckPOsEmail(props: Props) {
    const [listPOs, setListPOs] = useState<Paging<Partial<EmailSents>>>({} as Paging<Partial<EmailSents>>);

    const [page, setPage] = useState<number>(1);

    const [loading, setLoading] = useState(true);

    const [valueSearch, setValueSearch] = useState<string>('');
    const debouncedValue = useDebounce<string>(valueSearch, 300);

    const emailStatusOptions: OptionsStatusEmail[] = [
        { value: EmailStatus.Sent, label: 'Unopened and Unread' },
        { value: EmailStatus.Open, label: 'Opened and Read' },
    ];

    const [selectedStatusComplete, setSelectedStatusComplete] = useState<OptionsStatusEmail>();

    const isEmpty = listPOs.total === 0 && !loading;

    const handleOnChangeSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValueSearch(e.target.value);
    };

    const checkListPOsEmail = () => {
        setLoading(true);
        wardenController
            .getEmailSents({
                page: page,
                pageSize: 6,
                startOfWeek: props.startOfWeek,
                search: debouncedValue,
                status: selectedStatusComplete?.value,
            })

            .then((res) => {
                setListPOs(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!props.open) return;
        checkListPOsEmail();
    }, [props.open, page, debouncedValue, selectedStatusComplete?.value]);

    return (
        <PopUpBase
            {...props}
            fixOverflow
            dialogProps={{
                maxWidth: 'lg',
                fullWidth: true,
                PaperProps: {
                    sx: { maxWidth: '866px', overflowY: 'unset' },
                },
            }}
            title={`List of POs that have received emails`}
            subTitle={`Weekly rota from ${moment(props.startOfWeek).format('DD/MM/YYYY')} to ${moment(props.startOfWeek)
                .add(6, 'days')
                .format('DD/MM/YYYY')}`}
            onConfirm={() => {}}
            desc={
                <Stack spacing={2}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel htmlFor="outlined-adornment" sx={{ color: '#DDDDDD' }}>
                                Search by name
                            </InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                value={valueSearch}
                                onChange={(e) => {
                                    handleOnChangeSearch(e);
                                    setPage(1);
                                }}
                                id="outlined-adornment"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle search" edge="end">
                                            <IcSearch />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search by name"
                                sx={{ py: '1.5px' }}
                            />
                        </FormControl>
                        <Autocomplete
                            size="small"
                            fullWidth
                            sx={{
                                '&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                    minHeight: 40,
                                },
                            }}
                            onChange={(e, value) => {
                                setSelectedStatusComplete(value!);
                                setPage(1);
                            }}
                            options={emailStatusOptions}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Selected statuses" />}
                        />
                    </Stack>
                    <Typography variant="body2" color="secondary" textAlign={'end'}>
                        {`Total POs: ${listPOs.total ?? 0}`}
                    </Typography>
                    <TableContainer component={Box}>
                        {loading ? (
                            <Stack direction="row" justifyContent="center" alignItems="center" minHeight={430}>
                                <CircularProgress color={'success'} />
                            </Stack>
                        ) : (
                            <Stack alignItems="center">
                                {isEmpty ? (
                                    <EmptyPage stackProps={{ sx: { minHeight: 375 } }} />
                                ) : (
                                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                        <TableRow
                                            sx={{
                                                '&.MuiTableRow-root': {
                                                    backgroundColor: '#E8F5E9',
                                                    borderRadius: '10px',
                                                },
                                            }}
                                        >
                                            <BaseHead align="center" title="Parking operative" />
                                            <BaseHead align="center" title="Status" />
                                        </TableRow>
                                        <TableBody>
                                            {listPOs.rows.map((items) => {
                                                return (
                                                    <TableRow>
                                                        <CellOfRow
                                                            value={items.Warden?.FullName ?? ''}
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            color={`${
                                                                items.Status === EmailStatus.Open
                                                                    ? color.secondary
                                                                    : color.darkDanger
                                                            }`}
                                                            value={
                                                                items.Status === EmailStatus.Open
                                                                    ? 'Opened and read'
                                                                    : items.Status === EmailStatus.Sent
                                                                    ? 'Unopened and Unread'
                                                                    : 'Unsent letter'
                                                            }
                                                            align="center"
                                                        />
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                )}
                                {listPOs.totalPages > 1 && (
                                    <Pagination
                                        count={listPOs.totalPages}
                                        page={listPOs.page}
                                        onChange={(e: any, value: any) => {
                                            setPage(value);
                                        }}
                                        shape="rounded"
                                        sx={{
                                            mt: 2,
                                            '& .Mui-selected': {
                                                background: '#3479BB1A !important',
                                            },
                                            '& .MuiPaginationItem-previousNext': {
                                                background: '#EEEEEE',
                                            },
                                        }}
                                        renderItem={(item) => (
                                            <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                        )}
                                    />
                                )}
                            </Stack>
                        )}
                    </TableContainer>
                </Stack>
            }
            customActions={
                <Button
                    sx={{ minWidth: 130 }}
                    variant="cancel"
                    onClick={() => {
                        setPage(1);
                        setValueSearch('');
                        setSelectedStatusComplete(undefined);
                        props.onClose?.();
                    }}
                >
                    Close
                </Button>
            }
        />
    );
}
