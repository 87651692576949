import Coming from '@components/ComingSoon';
import AuthLayout from '@components/layouts/AuthLayout';
import { useFeatureFlag } from '@components/layouts/Flag';
import { FeatureName } from '@config';
import Alerts from '@pages/alerts';
import LieuLeaveApproval from '@pages/lieu-leave';
import Logout from '@pages/logout';
import PaymentPage from '@pages/payment';
import RedirectToDetailsPage from '@pages/RedirectToDetailsPage';
import ReportPage from '@pages/reports';
import StaticRota from '@pages/rota-coverage/static';
import ClusterDetail from '@pages/setting/cluster/detail';
import ClusterPage from '@pages/setting/cluster/list';
import LocationDetails from '@pages/setting/location/detail';
import TotalPCNsIssuedTab from '@pages/setting/location/detail/components/TotalPCNsIssued';
import TotalPCNsIssuedPerCheckIn from '@pages/setting/location/detail/components/TotalPCNsIssuedPerCheckIn';
import LocationBase from '@pages/setting/location/list';
import WardenDetailsWrapper from '@pages/setting/warden/detail';
import CalendarAndScheduleTab from '@pages/setting/warden/detail/components/CalendarAndScheduleTab';
import ITicketHoursTab from '@pages/setting/warden/detail/components/ITicketHoursTab';
import ITicketTrackerTab from '@pages/setting/warden/detail/components/ITicketTrackerTab';
import PersonalInformation from '@pages/setting/warden/detail/components/PersonalInformation';
import SendMail from '@pages/setting/warden/detail/components/SendMail';
import WardenBase from '@pages/setting/warden/list/components/WardenBase';
import ThemePage from '@pages/ThemePage';
import { pick } from 'lodash';
import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export interface BaseRoute {
    index?: boolean;
    path?: string;
    element?: ReactNode;
    name?: FeatureName;
    children?: BaseRoute[];
}

function App() {
    const routes: BaseRoute[] = [
        {
            element: <AuthLayout />,
            path: '/',
            children: [
                {
                    index: true,
                    element: <Navigate to={'rota-static'} />,
                    name: FeatureName.ROTA_STATIC,
                },
                { path: 'static', element: <Navigate to={'/rota-static'} /> },
                { path: 'rota-static', element: <StaticRota />, name: FeatureName.ROTA_STATIC },
                {
                    path: 'dashboard',
                    element: <Coming />,
                    name: FeatureName.DASHBOARD,
                },
                {
                    path: 'insights',
                    element: <ReportPage />,
                    name: FeatureName.REPORT,
                },
                {
                    path: 'admin',
                    // element: <SettingPage />,
                    name: FeatureName.SETTING,
                    children: [
                        {
                            index: true,
                            element: <Navigate to={'warden'} />,
                            name: FeatureName.SETTING__WARDEN,
                        },
                        {
                            path: 'parking-operative',
                            element: <WardenBase />,
                            name: FeatureName.SETTING__WARDEN,
                        },
                        {
                            path: 'parking-operative/resolution/*',
                            element: <RedirectToDetailsPage />,
                        },
                        {
                            path: 'parking-operative/:id',
                            element: <WardenDetailsWrapper />,
                            name: FeatureName.SETTING__WARDEN,
                            children: [
                                {
                                    path: 'details',
                                    element: <PersonalInformation />,
                                },
                                {
                                    path: 'calendar-schedule',
                                    element: <CalendarAndScheduleTab />,
                                },
                                {
                                    path: 'send-email',
                                    element: <SendMail />,
                                },
                                {
                                    path: 'iticket-tracker',
                                    element: <ITicketTrackerTab />,
                                    name: FeatureName.SETTING__WARDEN__TICKET_TRACKER,
                                },
                                {
                                    path: 'iticket-hours',
                                    element: <ITicketHoursTab />,
                                    name: FeatureName.SETTING__WARDEN__TICKET_HOURS,
                                },
                                // {
                                //     path: 'lieu-day',
                                //     element: <LieudaySchedule />,
                                // },
                            ],
                        },
                        {
                            path: 'location',
                            element: <LocationBase />,
                            name: FeatureName.SETTING__LOCATION,
                        },
                        {
                            path: 'location/:id',
                            element: <LocationDetails />,
                            name: FeatureName.SETTING__LOCATION,
                            children: [
                                {
                                    path: 'total-pcns-issued',
                                    element: <TotalPCNsIssuedTab />,
                                },
                                {
                                    path: 'total-pcns-per-check-in',
                                    element: <TotalPCNsIssuedPerCheckIn />,
                                },
                            ],
                        },
                        {
                            path: 'cluster',
                            element: <ClusterPage />,
                            name: FeatureName.SETTING__CLUSTER,
                        },
                        {
                            path: 'cluster/new',
                            element: <ClusterDetail />,
                            name: FeatureName.SETTING__CLUSTER,
                        },
                        {
                            path: 'cluster/:id',
                            element: <ClusterDetail />,
                            name: FeatureName.SETTING__CLUSTER,
                        },
                        {
                            path: '*',
                            element: <Navigate to={'/'} />,
                        },
                    ],
                },
                {
                    path: 'approvals/*',
                    element: <LieuLeaveApproval />,
                    name: FeatureName.LIEU_LEAVE,
                },
                {
                    path: 'payment/*',
                    element: <PaymentPage />,
                    name: FeatureName.PAYMENT,
                },
                {
                    path: 'alerts/*',
                    element: <Alerts />,
                    name: FeatureName.ALERTS,
                },
                {
                    path: 'theme',
                    element: <ThemePage />,
                },
                {
                    path: 'logout',
                    element: <Logout />,
                },
                {
                    path: '*',
                    element: <Navigate to={'/rota-static'} />,
                },
            ],
        },
    ];

    const { getActive } = useFeatureFlag();

    const renderRoute = (routes: BaseRoute[]) => {
        return routes.map((r, index) => {
            const enabled = !r.name || getActive(r.name);
            if (!enabled) return <></>;
            const children = r.children ? renderRoute(r.children) : undefined;
            return <Route key={index} {...pick(r, ['index', 'path', 'element'])} children={children} />;
        });
    };

    return <Routes>{renderRoute(routes)}</Routes>;
}

export default App;
