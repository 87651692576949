import { CStack } from '@components/FlexedStack';
import { TypeWardenEvent, WardenEvent } from '@LocationOps/model/WardenEvent';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { ReactNode } from 'react';
import { DMY } from 'src/config/moment';
import { AbortPCN } from './AbortPCN';
import { AddFirstSeen } from './AddFirstSeen';
import { AddGracePeriod } from './AddGracePeriod';
import { CheckIn } from './CheckIn';
import { CheckOut } from './CheckOut';
import { EndBreak } from './EndBreak';
import { EndShift } from './EndShift';
import { IssuePCN } from './IssuePCN';
import { StartBreak } from './StartBreak';
import { StartShift } from './StartShift';
import { TrackGPS } from './TrackGPS';

export type BaseWardenEventItemProps<T> = WardenEventItemProps<T> & {
    text: ReactNode;
    details?(wardenEvent: WardenEvent<T>): ReactNode;
};

export function BaseWardenEventItem<T>(props: BaseWardenEventItemProps<T>) {
    const color = wardenEvents[props.item.Type]?.itemColor;

    return (
        <Stack
            direction={'row'}
            sx={{ width: '100%', border: '1px solid #EEEEEE', borderRadius: '5px', padding: '8px' }}
            spacing={2}
        >
            <Box>
                <CStack
                    sx={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: color,
                        cursor: 'pointer',
                    }}
                    onClick={() => props.onSelectEvent(props.item)}
                >
                    <Typography lineHeight={'20px'}>{props.index + 1}</Typography>
                </CStack>
            </Box>

            <Stack flex={1}>
                <Typography variant="caption" color="GrayText">
                    {moment(props.item.Created).local().format('DD/MM/YYYY HH:mm:ss')}
                </Typography>

                <Stack sx={{ backgroundColor: color, borderRadius: '2px', padding: '4px 8px' }}>
                    {props.text || `TypeId: ${props.item.Type}`}
                </Stack>

                {props.details?.(props.item)}

                {/* <Stack direction={'row'} justifyContent="space-between">
                    <Typography variant="caption" color="GrayText">
                        Travel time to site: 00:03:05
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        Driving distance to site: 12.3mi
                    </Typography>
                </Stack> */}
            </Stack>
        </Stack>
    );
}

export type WardenEventItemProps<T = any> = {
    item: WardenEvent<T>;
    index: number;
    onSelectEvent(item: WardenEvent): void;
};

export type ReturnElement = {
    ListItem: (props: WardenEventItemProps) => JSX.Element;
    MapItem: (props: WardenEvent) => JSX.Element;
};

export type WardenEventReturn = {
    itemColor: string;
    mapColor: string;
    textColor: string;
    components: ReturnElement;
};

export const wardenEvents: Record<TypeWardenEvent, WardenEventReturn> = {
    '0': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: StartShift }, // StartShift
    '1': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: EndShift }, // EndShift
    '2': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: CheckIn }, // CheckIn
    '3': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: CheckOut }, // CheckOut
    '4': { itemColor: 'rgba(255, 183, 0, 0.1)', mapColor: '#FFDC83', textColor: 'black', components: StartBreak }, // StartBreak
    '5': { itemColor: 'rgba(255, 183, 0, 0.1)', mapColor: '#FFDC83', textColor: 'black', components: EndBreak }, // EndBreak
    '6': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: AddFirstSeen }, // AddFirstSeen
    '7': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: AddGracePeriod }, // AddGracePeriod
    '8': { itemColor: '#EEEEEE', mapColor: '#EEEEEE', textColor: 'black', components: TrackGPS }, // TrackGPS
    '9': { itemColor: 'rgba(224, 27, 0, 0.1)', mapColor: 'rgb(255 211 203)', textColor: 'white', components: IssuePCN }, // IssuePCN
    '10': { itemColor: '#E8F5E9', mapColor: '#009D4F', textColor: 'white', components: AbortPCN }, // AbortPCN
};

export const secondToHHMMSS = (secs: number) => {
    if (!secs) return NaN;
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;

    return [hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v)).join(':');
};

export const MapItemCreated = (props: { Created: Date }) => {
    const date = moment(props.Created).local();
    return (
        <Typography>
            At: {date.format('HH:mm:ss')} on {date.format(DMY)}
        </Typography>
    );
};

// 69 74 16
