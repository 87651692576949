import { Divider, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import color from 'src/theme/color';

export default function PermitDuplicate() {
    const OrangeStack = styled(Stack)({
        background: color.warning,
        borderRadius: `4px`,
        borderLeft: `4px solid ${color.orange}`,
        padding: `12px 16px`,
    });

    return (
        <OrangeStack direction={'column'} gap={1}>
            <Typography>The data has been changed after regenerating the data</Typography>
        </OrangeStack>
    );
}
