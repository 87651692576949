import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcPcnIssued(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.49992 14.6663H10.4999C13.8333 14.6663 15.1666 13.333 15.1666 9.99967V5.99967C15.1666 2.66634 13.8333 1.33301 10.4999 1.33301H6.49992C3.16659 1.33301 1.83325 2.66634 1.83325 5.99967V9.99967C1.83325 13.333 3.16659 14.6663 6.49992 14.6663Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.38672 9.65984L6.97339 7.59984C7.20005 7.30651 7.62005 7.25318 7.91339 7.47984L9.13339 8.43984C9.42672 8.66651 9.84672 8.61318 10.0734 8.32651L11.6134 6.33984"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
