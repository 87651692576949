import PopUpBase from '@components/PopUpBase';
import StyledSelect from '@components/select-search/StyledSelect';
import { clusterController, subRegionController, wardenController } from '@controllers';
import { BaseModel, Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { IPopUp } from '@hooks/usePopUp';
import { Cluster, ClusterWithRelations, CountrySubRegion } from '@LocationOps/model';
import { Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import DefaultOption from '@pages/rota-coverage/components/DefaultOption';
import SelectOption from '@pages/rota-coverage/components/SelectOption';
import useThrottle from '@rooks/use-throttle';
import { Warden, WardenType, WardenWithExpenseRate } from '@WardenOps/model';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import font from 'src/theme/fonts';
import BaseFilterDateRequired from './BaseFilterDateRequired';
import BaseTextFieldRequired from './BaseTextFieldRequired';
import { postcodeValidator } from 'postcode-validator';

type Props = Omit<IPopUp, 'onConfirm'> & {
    title?: string;
    message?: string;
    loading?: boolean;
    onConfirm?(warden: WardenWithExpenseRate): void;
};
export interface WardenDetailsType extends BaseModel {
    Name: string;
    Value: WardenType;
}
type FormValues = {
    firstName: string;
    lastName: string;
    postCode: string;
    email: string;
    phoneNumber: string;
    wardenType: WardenDetailsType;
    // wardenNumber: string;
    payrollId: string;
    cluster: Cluster;
    subRegion: CountrySubRegion;
    contractedHours: number;
    // expenseRate: number;
    startDate: Date;
};
export const PhoneTextField = styled(PhoneInput)(({ theme }) => ({
    '&.react-tel-input .form-control:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
    '& .special-label': {
        fontSize: '11.5px !important',
        color: 'rgba(0, 0, 0, 0.6)',
        left: '10px !important',
    },
    '& .selected-flag': {
        padding: '0px 0px 0px 14px !important',
    },
}));
const PopUpAddParkingOperative = (props: Props) => {
    const theme = useTheme();
    const wardenTypes: WardenDetailsType[] = [
        { Name: 'Temporary staff', Value: WardenType.TEMPORARY_STAFF },
        { Name: 'STS', Value: WardenType.STS },
    ];

    // const [loading, setLoading] = useState(false);
    const { loading } = props;
    const [subRegions, setSubRegions] = useState<Paging<CountrySubRegion>>(defaultPaging({ pageSize: 10 }));
    const [cluster, setCluster] = useState<Paging<ClusterWithRelations>>(defaultPaging({ pageSize: 10 }));
    const [searchSubRegion, setSearchSubRegion] = useState('');
    const [searchCluster, setSearchCluster] = useState('');

    const filteredSubRegion = subRegions.rows.filter((subR) =>
        subR.Name.toLocaleLowerCase().includes((searchSubRegion || '').toLocaleLowerCase())
    );

    const filteredCluster = cluster.rows.filter((clt) =>
        clt.Name.toLocaleLowerCase().includes((searchCluster || '').toLocaleLowerCase())
    );

    const withValueCap = (inputObj: any, maxValue: number) => {
        const { value } = inputObj;
        if (value <= maxValue) return true;
        return false;
    };

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        mode: 'onSubmit',
        defaultValues: {
            firstName: '',
            lastName: '',
            postCode: '',
            email: '',
            phoneNumber: '',
            wardenType: wardenTypes[0],
            // wardenNumber: '',
            payrollId: '',
            cluster: undefined,
            subRegion: undefined,
            contractedHours: 0,
            // expenseRate: 0.27,
            startDate: moment().toDate(),
        },
    });

    const handleConfirm = async (data: FormValues) => {
        try {
            const isExisted = await wardenController.getByEmail(getValues().email);
            if (isExisted) {
                setError('email', { message: 'This email already exists.' });
            } else {
                const _warden: WardenWithExpenseRate = {
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    Postcode: data.postCode,
                    Email: data.email,
                    PhoneNumber: data.phoneNumber,
                    WardenType: data.wardenType.Value,
                    // ExternalId: data.wardenNumber,
                    PayrollId: data.payrollId,
                    ClusterId: data.cluster?.Id,
                    CountrySubRegionId: data.subRegion?.Id,
                    StartDate: moment(data.startDate).startOf('date').toDate(),
                    ContractHours: Number(data.contractedHours),
                    ExpenseRates: [],
                    CurrentExpenseRate: 0.27,
                    // CurrentExpenseRate: Number(data.expenseRate),
                };
                props.onConfirm?.(_warden);
                // setLoading(true);
            }
        } catch (error: any) {
            console.log(error);
        }
    };
    const [throttleConfirm] = useThrottle(handleConfirm, 500);

    useEffect(() => {
        if (!props.open) return;
        reset();
        // setLoading(false);
        subRegionController.list({ sorts: ['Name'], pageSize: 1000 }).then((res) => setSubRegions(res));
        clusterController.list({ sorts: ['Name'], pageSize: 1000 }).then((res) => setCluster(res));
    }, [props.open]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                keepMounted: true,
                maxWidth: 'sm',
                PaperProps: {
                    sx: { overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={<Typography variant="h4">{props.title}</Typography>}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            fixOverflow
            desc={
                <Stack gap={2}>
                    <Typography variant="h5">Personal information</Typography>
                    <Stack direction="row" alignItems="flex-start" gap={1}>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter first name.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextFieldRequired
                                    {...field}
                                    placeholder="Enter first name"
                                    labelText="First name"
                                    maxLength={20}
                                    fullWidth
                                    required
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter last name.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextFieldRequired
                                    {...field}
                                    placeholder="Enter last name"
                                    labelText="Last name"
                                    maxLength={20}
                                    fullWidth
                                    required
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name="postCode"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter post code.',
                                },
                                validate: (value) => postcodeValidator(value, 'GB') || 'Please enter valid postcode.',
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextFieldRequired
                                    {...field}
                                    placeholder="Enter post code"
                                    labelText="Post code"
                                    maxLength={10}
                                    fullWidth
                                    required
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" gap={1} mt={1}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter an email.',
                                },
                                pattern:
                                    watch().wardenType.Value === WardenType.STS
                                        ? {
                                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                              message: 'Email is invalid',
                                          }
                                        : {
                                              value: /^[A-Z0-9._%+-]{4,}@ukparkingcontrol\.com$/i,
                                              message: 'Email must be valid and from UKPC.',
                                          },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextFieldRequired
                                    {...field}
                                    placeholder="Enter email"
                                    labelText="Email"
                                    fullWidth
                                    required
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <PhoneTextField
                                    {...field}
                                    country={'gb'}
                                    disableDropdown
                                    disableCountryGuess
                                    specialLabel="Phone number"
                                    placeholder="Enter phone number"
                                    sx={{
                                        input: {
                                            '&::placeholder': {
                                                opacity: 0.75,
                                                fontFamily: font,
                                            },
                                        },
                                    }}
                                    inputStyle={{
                                        width: '100%',
                                        height: 40,
                                        padding: '18.5px 14px 18.5px 45px',
                                        fontSize: '0.875rem',
                                        color: theme.palette.grey[900],
                                    }}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} mt={1.5}>
                        <Typography variant="h5">Working summary</Typography>
                        <FormControl
                            sx={{ minWidth: 230, position: 'relative' }}
                            size="small"
                            // disabled={true}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    width: 35,
                                    height: 14,
                                    backgroundColor: '#fff',
                                    position: 'absolute',
                                    top: -7,
                                    left: 10,
                                    zIndex: 1,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                    }}
                                >
                                    Type
                                </Typography>
                            </Stack>
                            <Select
                                labelId="select-id"
                                id="demo-simple-select"
                                value={watch().wardenType.Value}
                                sx={{
                                    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                                        py: '9.95px',
                                    },
                                }}
                                onChange={(e) => {
                                    const selectedWardenType = wardenTypes.find((w) => w.Value === e.target.value);
                                    setValue('wardenType', selectedWardenType as WardenDetailsType);
                                }}
                            >
                                {wardenTypes.map((w) => {
                                    return (
                                        <MenuItem key={w.Name} value={w.Value}>
                                            {w.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" gap={1} mt={1}>
                        {/* <Controller
                            name="wardenNumber"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter PO number.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextFieldRequired
                                    {...field}
                                    placeholder="Enter PO number"
                                    labelText="PO number"
                                    maxLength={20}
                                    fullWidth
                                    required
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        /> */}
                        {watch().wardenType.Value !== WardenType.STS && (
                            <>
                                <Controller
                                    name="payrollId"
                                    control={control}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: 'Please enter payroll ID.',
                                    //     },
                                    // }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextFieldRequired
                                            {...field}
                                            placeholder="Enter payroll ID"
                                            labelText="Payroll ID"
                                            maxLength={20}
                                            fullWidth
                                            // required
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                                <Stack width="100%">
                                    <Controller
                                        name="subRegion"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please enter select sub-region.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <StyledSelect
                                                    {...field}
                                                    renderNoOption
                                                    label={
                                                        <span
                                                            style={{
                                                                color: !!error?.message
                                                                    ? theme.palette.error.main
                                                                    : 'rgba(0, 0, 0, 0.6)',
                                                            }}
                                                        >
                                                            Sub-region<span style={{ color: 'red' }}>{` *`}</span>
                                                        </span>
                                                    }
                                                    data={filteredSubRegion}
                                                    value={watch().subRegion}
                                                    hasMore={false}
                                                    searchValue={searchSubRegion}
                                                    onChangeSearch={(text) => setSearchSubRegion(text)}
                                                    next={() => {}}
                                                    onChange={(option) => {
                                                        setValue('subRegion', option as CountrySubRegion);
                                                        clearErrors('subRegion');
                                                    }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Typography noWrap>
                                                                {value?.Name || `All sub-regions`}
                                                            </Typography>
                                                        );
                                                    }}
                                                    renderDefaultOption={() => (
                                                        <DefaultOption title="All sub-regions" />
                                                    )}
                                                    renderOption={(option) => (
                                                        <SelectOption option={option} isDisplay={false} />
                                                    )}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    maxItemPopover={3}
                                                    isError={!!error?.message}
                                                />
                                                {!!error?.message && (
                                                    <span
                                                        style={{
                                                            color: theme.palette.error.main,
                                                            fontSize: 12,
                                                            marginTop: 4,
                                                            marginLeft: 13,
                                                        }}
                                                    >
                                                        {error?.message}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    />
                                </Stack>
                            </>
                        )}
                        {watch().wardenType.Value === WardenType.STS && (
                            <>
                                <Stack width="100%">
                                    <Controller
                                        name="cluster"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please enter select cluster.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <StyledSelect
                                                    {...field}
                                                    renderNoOption
                                                    label={
                                                        <span
                                                            style={{
                                                                color: !!error?.message
                                                                    ? theme.palette.error.main
                                                                    : 'rgba(0, 0, 0, 0.6)',
                                                            }}
                                                        >
                                                            Cluster<span style={{ color: 'red' }}>{` *`}</span>
                                                        </span>
                                                    }
                                                    data={filteredCluster}
                                                    value={watch().cluster}
                                                    hasMore={false}
                                                    searchValue={searchCluster}
                                                    onChangeSearch={(text) => setSearchCluster(text)}
                                                    next={() => {}}
                                                    onChange={(option) => {
                                                        setValue('cluster', option as Cluster);
                                                        clearErrors('cluster');
                                                    }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Typography noWrap>
                                                                {value?.Name || `All cluster`}
                                                            </Typography>
                                                        );
                                                    }}
                                                    renderDefaultOption={() => <DefaultOption title="All cluster" />}
                                                    renderOption={(option) => (
                                                        <SelectOption option={option} isDisplay={false} />
                                                    )}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    maxItemPopover={3}
                                                    isError={!!error?.message}
                                                />
                                                {!!error?.message && (
                                                    <span
                                                        style={{
                                                            color: theme.palette.error.main,
                                                            fontSize: 12,
                                                            marginTop: 4,
                                                            marginLeft: 13,
                                                        }}
                                                    >
                                                        {error?.message}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    />
                                </Stack>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please select start date.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseFilterDateRequired startDate={watch().startDate} {...field} />
                                    )}
                                />
                            </>
                        )}
                    </Stack>
                    {watch().wardenType.Value !== WardenType.STS && (
                        <Stack direction="row" alignItems="flex-start" gap={1} mt={1}>
                            <Controller
                                name="contractedHours"
                                control={control}
                                rules={
                                    watch().wardenType.Value !== WardenType.STS
                                        ? {
                                              required: {
                                                  value: true,
                                                  message: 'Please enter contracted hour(s).',
                                              },
                                              min: {
                                                  value: 1,
                                                  message: 'Contracted hour(s) must be greater than 0',
                                              },
                                          }
                                        : undefined
                                }
                                render={({ field, fieldState: { error } }) => (
                                    <NumericFormat
                                        {...field}
                                        placeholder="Enter contracted hour(s)"
                                        label={
                                            <span>
                                                Contracted hour(s)<span style={{ color: 'red' }}>{` *`}</span>
                                            </span>
                                        }
                                        customInput={TextField}
                                        fullWidth
                                        InputProps={{
                                            sx: {
                                                height: 40,
                                                width: 272,
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        allowNegative={false}
                                        decimalScale={2}
                                        autoComplete="off"
                                        isAllowed={(value) => withValueCap(value, 99.99)}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                            {/* <Controller
                            name="expenseRate"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter expense rate.',
                                },
                                min: {
                                    value: 0.1,
                                    message: 'Expense rate must be greater than 0',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <NumericFormat
                                    {...field}
                                    placeholder="Enter expense rate"
                                    label={
                                        <span>
                                            Expense rate<span style={{ color: 'red' }}>{` *`}</span>
                                        </span>
                                    }
                                    customInput={TextField}
                                    fullWidth
                                    InputProps={{
                                        sx: {
                                            height: 40,
                                            width: 178,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    allowNegative={false}
                                    autoComplete="off"
                                    decimalScale={2}
                                    isAllowed={(value) => withValueCap(value, 99.99)}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        /> */}
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please select start date.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseFilterDateRequired startDate={watch().startDate} {...field} />
                                )}
                            />
                        </Stack>
                    )}
                </Stack>
            }
            customActions={
                <Stack width="100%" direction={'row'} justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            reset();
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit((data) => throttleConfirm(data))}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
};
export default React.memo(PopUpAddParkingOperative);
