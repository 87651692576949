import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;
export default function IcCalendar(props: Props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#85858A"
            className="svgCalendar"
            {...props}
        >
            <path d="M12 3.16666H3.99998C2.80336 3.16666 1.83331 4.13671 1.83331 5.33332V12C1.83331 13.1966 2.80336 14.1667 3.99998 14.1667H12C13.1966 14.1667 14.1666 13.1966 14.1666 12V5.33332C14.1666 4.13671 13.1966 3.16666 12 3.16666Z" />
            <path d="M4.5 1.83334V4.16668" strokeLinecap="round" />
            <path d="M2.5 6.16666H13.5" strokeLinecap="round" />
            <path d="M11.8333 1.83334V4.16668" strokeLinecap="round" />
            <path
                d="M5.5 9.33334C5.77614 9.33334 6 9.10949 6 8.83334C6 8.5572 5.77614 8.33334 5.5 8.33334C5.22386 8.33334 5 8.5572 5 8.83334C5 9.10949 5.22386 9.33334 5.5 9.33334Z"
                fill="#85858A"
            />
            <path
                d="M8.16669 9.33334C8.44283 9.33334 8.66669 9.10949 8.66669 8.83334C8.66669 8.5572 8.44283 8.33334 8.16669 8.33334C7.89054 8.33334 7.66669 8.5572 7.66669 8.83334C7.66669 9.10949 7.89054 9.33334 8.16669 9.33334Z"
                fill="#85858A"
            />
            <path
                d="M10.8333 9.33334C11.1095 9.33334 11.3333 9.10949 11.3333 8.83334C11.3333 8.5572 11.1095 8.33334 10.8333 8.33334C10.5572 8.33334 10.3333 8.5572 10.3333 8.83334C10.3333 9.10949 10.5572 9.33334 10.8333 9.33334Z"
                fill="#85858A"
            />
            <path
                d="M5.5 12C5.77614 12 6 11.7761 6 11.5C6 11.2239 5.77614 11 5.5 11C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.22386 12 5.5 12Z"
                fill="#85858A"
            />
            <path
                d="M8.16669 12C8.44283 12 8.66669 11.7761 8.66669 11.5C8.66669 11.2239 8.44283 11 8.16669 11C7.89054 11 7.66669 11.2239 7.66669 11.5C7.66669 11.7761 7.89054 12 8.16669 12Z"
                fill="#85858A"
            />
            <path
                d="M10.8333 12C11.1095 12 11.3333 11.7761 11.3333 11.5C11.3333 11.2239 11.1095 11 10.8333 11C10.5572 11 10.3333 11.2239 10.3333 11.5C10.3333 11.7761 10.5572 12 10.8333 12Z"
                fill="#85858A"
            />
        </svg>
    );
}
