import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CountrySubRegion } from '@LocationOps/model';
import { AxiosInstance } from 'axios';
import { ICountrySubRegionController } from '../ICountrySubRegionController';

export class CountrySubRegionHttpController
    extends BaseHttpController<CountrySubRegion>
    implements ICountrySubRegionController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'country-sub-region', client);
    }
}
