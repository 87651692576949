import BreadCrumbs, { IBreadCrumbs } from '@components/BreadCrumbs';
import IciTicketTracker from '@components/icon/navbar/IciTicketTracker';
import IcLocation from '@components/icon/navbar/IcLocation';
import IcWden from '@components/icon/navbar/IcWden';
import { clusterController, wardenController } from '@controllers';
import { Paging } from '@Core';
import { defaultPaging } from '@helpers';
import { ClusterWithRelations } from '@LocationOps/model';
import {
    Box,
    CircularProgress,
    Stack,
    SxProps,
    TableCell,
    TableCellProps,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { Warden, WardenType, WardenWithExpenseRate } from '@WardenOps/model';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMail } from 'react-icons/hi';
import { IoCalendarOutline } from 'react-icons/io5';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import color from 'src/theme/color';
import { TbTicket } from 'react-icons/tb';
import { useFeatureFlag } from '@components/layouts/Flag';
import { FeatureName } from '@config';

export type Tab = {
    id?: number;
    title: string;
    icon?: React.ReactNode;
    path: string;
    active?: boolean;
};

export default function WardenDetailsWrapper() {
    const params = useParams<{ id: string }>();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [warden, setWarden] = useState<WardenWithExpenseRate>({} as WardenWithExpenseRate);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState<Tab>({} as Tab);
    const [pagingCluster, setPagingCluster] = useState(defaultPaging<ClusterWithRelations>());
    const { getActive } = useFeatureFlag();

    const tabs: Tab[] = [
        {
            id: 1,
            title: t('wardenDetailsPage.title.personalInfo'),
            icon: <IcWden />,
            path: 'details',
        },
        {
            id: 2,
            title: t('wardenDetailsPage.title.calendarAndSchedule'),
            icon: <IoCalendarOutline fontSize={18} />,
            path: 'calendar-schedule',
            active: warden.WardenType !== WardenType.STS,
        },
        {
            id: 3,
            title: t('wardenDetailsPage.title.sendEmail'),
            icon: <HiOutlineMail fontSize={18} />,
            path: 'send-email',
        },
        {
            id: 4,
            title: 'iTicket tracker',
            icon: <IciTicketTracker />,
            path: 'iticket-tracker',
            active: getActive(FeatureName.SETTING__WARDEN__TICKET_TRACKER),
        },
        {
            id: 5,
            title: 'Ticket hours',
            icon: <TbTicket fontSize={18} />,
            path: 'iticket-hours',
            active: getActive(FeatureName.SETTING__WARDEN__TICKET_HOURS),
        },
        // {
        //     id: 4,
        //     title: 'Lieu day',
        //     icon: <HiOutlineMail fontSize={18} />,
        //     path: 'lieu-day',
        // },
    ];

    useEffect(() => {
        const init = async () => {
            if (!params.id) return;
            setLoading(true);
            wardenController
                .get(params.id)
                .then((res) => {
                    setWarden(res);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        init();
    }, [params.id]);

    useEffect(() => {
        const _selectedTab = tabs.find((t) => location.pathname.includes(t.path));
        setSelectedTab(_selectedTab ?? ({} as Tab));
    }, [location]);

    useEffect(() => {
        if (selectedTab.id === 2) {
            clusterController.list({ page: 1, pageSize: 1000, RequireAssignable: true }).then((res) => {
                setPagingCluster(res);
            });
        }
    }, [selectedTab]);

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Admin' },
        { title: 'Parking operative', href: '/admin/parking-operative' },
        { title: warden.FullName ?? '' },
    ];

    return (
        <div>
            <Typography fontSize={24}>Parking operative: {warden.FullName}</Typography>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            <Stack direction="row" gap={1} mt={5}>
                {tabs
                    .filter((t) => t.active !== false)
                    .map((item, index) => {
                        const selected = location.pathname.includes(item.path);

                        return (
                            <Stack
                                key={index + 'z'}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    width: 200,
                                    p: 1,
                                    background: selectedTab.id === item.id ? '#E8F5E9' : '#FAFAFA',
                                    borderRadius: '8px 8px 0px 0px',
                                    color: selectedTab.id === item.id ? theme.palette.success.main : color.grey600,
                                    cursor: 'pointer',
                                    transition: '.2s',
                                }}
                                gap={1}
                                onClick={() => {
                                    navigate(`${item.path}`);
                                }}
                            >
                                {item.icon}
                                <Typography
                                    variant="body1"
                                    color={selectedTab.id === item.id ? theme.palette.success.main : color.grey600}
                                    sx={{ userSelect: 'none' }}
                                >
                                    {item.title}
                                </Typography>
                            </Stack>
                        );
                    })}
            </Stack>
            <Box
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: '0px 8px 8px 8px;',
                    p: '40px 16px',
                }}
            >
                {loading ? (
                    <Stack direction="row" justifyContent="center" alignItems="center" minHeight={400}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>{warden?.Id && <Outlet context={{ warden, setWarden, pagingCluster }} />}</>
                )}
            </Box>
        </div>
    );
}
type ContextType = {
    warden: WardenWithExpenseRate;
    setWarden: Dispatch<SetStateAction<WardenWithExpenseRate>>;
    pagingCluster: Paging<ClusterWithRelations>;
};
export function useWarden() {
    return useOutletContext<ContextType>();
}
