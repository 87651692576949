export const firebaseConfig = {
    apiKey: 'AIzaSyCLW-6NH-SybijCtpp9FZMvB9QP9hYHg6o',
    authDomain: 'review-feature-22088.firebaseapp.com',
    databaseURL: 'https://review-feature-22088-default-rtdb.firebaseio.com',
    projectId: 'review-feature-22088',
    storageBucket: 'review-feature-22088.appspot.com',
    messagingSenderId: '643500398682',
    appId: '1:643500398682:web:3dd610b87795c47528e063',
    measurementId: 'G-XPWMNWZNWP',
};
