import { Paging } from '@Core';
import {
    Box,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { BaseTitle } from '..';
import IcPrev from '@components/icon/IcPrev';
import IcNext from '@components/icon/IcNext';
import { ZoneEventLog, ZoneEventLogType } from '@WardenOps/model/ZoneEventLog';

type Props = {
    eventLogs: Paging<ZoneEventLog>;
    onChangePage: (page: number) => void;
};

const eventLogType: Record<ZoneEventLogType, string> = {
    [ZoneEventLogType.Suspension]: 'Suspension',
    [ZoneEventLogType.EndSuspension]: 'End suspension',
};

export default function ZoneEventLogs(props: Props) {
    return (
        <Stack mt={2}>
            <BaseTitle color="primary.main">Event log</BaseTitle>

            <Stack mt={2}>
                <TableContainer>
                    <Stack alignItems="center">
                        <Table
                            sx={{
                                minWidth: 500,
                                '& th, td': {
                                    padding: '8px',
                                },
                                '& th:first-child, td:first-child': {
                                    paddingLeft: '16px',
                                },
                            }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow sx={{ background: '#FAFAFA' }}>
                                    {[
                                        {
                                            name: 'No.',
                                            pl: 0,
                                            width: 80,
                                        },
                                        {
                                            name: 'Date/Time',
                                            align: 'left',
                                            width: 280,
                                        },
                                        {
                                            name: 'User',
                                            align: 'left',
                                            width: 280,
                                        },
                                        {
                                            name: 'Type',
                                            align: 'left',
                                            width: 280,
                                        },
                                        {
                                            name: 'Details',
                                            align: 'left',
                                        },
                                    ].map((column) => {
                                        return (
                                            <TableCell
                                                key={column.name}
                                                align={column.align as TableCellProps['align']}
                                                width={column.width}
                                            >
                                                <Typography variant="h6" fontWeight={600} pl={column.pl}>
                                                    {column.name}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.eventLogs.rows.map((eventLog, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'left' }}>
                                                <Typography>
                                                    {(props.eventLogs.page - 1) * props.eventLogs.pageSize + index + 1}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'left' }}>
                                                <Typography>
                                                    {moment(eventLog.Created).local().format('DD/MM/YYYY HH:mm:ss')}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'left' }}>
                                                <Typography textTransform={'capitalize'}>
                                                    {eventLog.FullName}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'left' }}>
                                                <Typography>{eventLogType[eventLog.Type]}</Typography>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'left' }}>
                                                <Typography>{eventLog.Details}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!props.eventLogs.rows.length && (
                            <Box my={3}>
                                <Typography align="center">No data</Typography>
                            </Box>
                        )}

                        {props.eventLogs.totalPages > 1 && (
                            <Pagination
                                count={props.eventLogs.totalPages}
                                page={props.eventLogs.page}
                                onChange={(_, page) => {
                                    props.onChangePage(page);
                                }}
                                shape="rounded"
                                sx={{
                                    mt: 2.5,
                                    '& .Mui-selected': {
                                        background: '#3479BB1A !important',
                                    },
                                    '& .MuiPaginationItem-previousNext': {
                                        background: '#EEEEEE',
                                    },
                                }}
                                renderItem={(item) => (
                                    <PaginationItem components={{ previous: IcPrev, next: IcNext }} {...item} />
                                )}
                            />
                        )}
                    </Stack>
                </TableContainer>
            </Stack>
        </Stack>
    );
}
