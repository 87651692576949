import { Paging } from '@Core';
import {
    CountryRegionWithRelations,
    CountrySubRegionWithRelations,
    LocationWithRelations,
    RotaCoverage,
} from '@LocationOps/model';
import { WardenWithRelations } from '@WardenOps/model';
import { AxiosInstance } from 'axios';
import {
    GetLocationFreeInTime,
    GetLocationMissingWarden,
    GetRegionMissingWarden,
    GetRotaCoverageProps,
    GetSubRegionMissingWarden,
    GetWardenFreeInTime,
    IRotaCoverageHttpController,
} from '../IRotaCoverageHttpController';

export class RotaCoverageHttpController implements IRotaCoverageHttpController {
    protected serviceURL: string;
    protected basePath: string;
    protected client: AxiosInstance;

    constructor(serviceURL: string, client: AxiosInstance) {
        this.serviceURL = serviceURL;
        this.basePath = 'rota-coverage';
        this.client = client;
    }

    async listRegionMissingWarden(props: GetRegionMissingWarden): Promise<Paging<CountryRegionWithRelations>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/country-region-missing-warden`, props);
        return res.data;
    }

    async listSubRegionMissingWarden(props: GetSubRegionMissingWarden): Promise<Paging<CountrySubRegionWithRelations>> {
        const res = await this.client.post(
            `${this.serviceURL}/${this.basePath}/country-sub-region-missing-warden`,
            props
        );
        return res.data;
    }

    async listLocationMissingWarden(props: GetLocationMissingWarden): Promise<Paging<LocationWithRelations>> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/location-missing-warden`, props);
        return res.data;
    }

    async getRotaCoverage(props: GetRotaCoverageProps): Promise<RotaCoverage[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/rota-coverage`, props);
        return res.data;
    }

    async getWardensFreeTime(props: GetWardenFreeInTime): Promise<WardenWithRelations[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/warden-free-time`, props);
        return res.data;
    }

    async getLocationsFreeTime(props: GetLocationFreeInTime): Promise<LocationWithRelations[]> {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/location-free-time`, props);
        return res.data;
    }
}
