import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Loading } from '@components/layouts/Loading';
import MultiProvider from '@components/MultiProvider';
import i18n from '@i18n/config';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LoadScript } from '@react-google-maps/api';
import { theme } from '@theme';
import momentTimeZone from 'moment-timezone';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { appConfig, msalConfig } from './config';
import { firebaseConfig } from './config/firebase';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
momentTimeZone.tz.setDefault('UTC');

export const firebaseApp = initializeApp(firebaseConfig);
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <MultiProvider
        providers={[
            <ThemeProvider theme={theme} />,
            <MsalProvider instance={msalInstance} />,
            <LocalizationProvider dateAdapter={AdapterMoment} />,
            <I18nextProvider i18n={i18n} />,
            <LoadScript googleMapsApiKey={appConfig.googleMapApiKey} loadingElement={<Loading />} />,
            <BrowserRouter />,
        ]}
    >
        <CssBaseline />
        <App />
        <ToastContainer
            autoClose={2000}
            hideProgressBar
            closeButton={false}
            position={'bottom-left'}
            newestOnTop
            pauseOnFocusLoss={false}
        />
    </MultiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
